import TopSection from "../../components/TopSection";
import BankDetails from "../../assets/photo/account details.png";
import { useTranslation } from "react-i18next";

const TermsAndCondition = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-slate-100 pt-20 lg:pt-36 pb-10 border text-gray-900 ">
      <TopSection title={t("termsandconditions")} />
      <div className="w-full max-w-[1100px] lg:mx-auto lg:p-4 lg:py-8">
        <article className="mx-auto w-full max-w-[1100px]  format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <div className="mt-10 CustomtextSizeTitle">
            <h1 className="text-3xl text-center md:text-xl font-semibold capitalize text-[#003d8c]">
              {t("termsandconditions")}
            </h1>
          </div>
          <div className="px-6">
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("courseFees")}
              </h1>
              <span className="CustomtextSize">{t("include")}</span>
              <ul className="list-disc pl-6 CustomtextSize">
                <li>{t("include1")}</li>
                <li>{t("include2")}</li>
                <li>{t("include3")}</li>
                <li>{t("include4")}</li>
                <li>{t("include5")}</li>
                <li>{t("include6")}</li>
                <li>{t("include7")}</li>
                <li>{t("include8")}</li>
                <li>{t("include9")}</li>
                <li>{t("include10")}</li>
              </ul>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("valueAddedTax")}
              </h1>
              <span className="CustomtextSize">
                {t("VAT is chargeable on all courses")}{" "}
              </span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("Payment")}
              </h1>
              <span className="CustomtextSize">{t("PaymentDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("Bank Transfers")}
              </h1>
              <span className="CustomtextSize">
                {t("BankTransfersDesc1")} <br />
                {t("BankTransfersDesc2")} NCTAR Training Consultants
              </span>
              <img src={BankDetails} alt="" className="py-4" />
            </div>

            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("Substitutions")}
              </h1>
              <span className="CustomtextSize">{t("SubstitutionsDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("Alterations to the Programme")}
              </h1>
              <span className="CustomtextSize">
                {t("AlterationstotheProgrammeDesc")}
              </span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("Cancellations")}
              </h1>
              <span className="CustomtextSize">{t("CancellationsDesc")}</span>
              <ul className="list-disc pl-6 CustomtextSize">
                <li>{t("CancellationsDesc1")}</li>
                <li>{t("CancellationsDesc2")}</li>
                <li>{t("CancellationsDesc3")}</li>
              </ul>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c] CustomtextSizeSubTitle">
                {t("VisasAndRefusals")}
              </h1>
              <span className="CustomtextSize">
                {t("VisasAndRefusalsDisc")}
              </span>
              <ul className="list-disc pl-6 CustomtextSize">
                <li>{t("VisasAndRefusalsDisc1")}</li>
                <li>{t("VisasAndRefusalsDisc2")}</li>
                <li>{t("VisasAndRefusalsDisc3")}</li>
                <li>{t("VisasAndRefusalsDisc4")}</li>
              </ul>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default TermsAndCondition;
