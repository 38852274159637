import { configureStore } from "@reduxjs/toolkit";
import courseReducer from "./course";
import workshopReducer from "./workshop";
import layoutReducer from "./layout";
import messageReducer from "./message";

const store = configureStore({
  reducer: {
    course: courseReducer,
    workshop: workshopReducer,
    layout: layoutReducer,
    message: messageReducer,
  },
});

export default store;
