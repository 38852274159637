import { notification } from "antd";
import { myCourseActions } from ".";
import { createServiceUser } from "./services";

export const bookCourseAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCourseActions.setIsFetching(true));
      const res = await createServiceUser(data);
      if (res?.status === 201) {
        dispatch(myCourseActions.setCourseSubmited(true));
        dispatch(myCourseActions.setNew(res));
        dispatch(myCourseActions.setIsFetching(false));
        notification.success({ message: "Request Sent Succesfully" });
      }
      dispatch(myCourseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
