import ConsultancyCardLeft from "../../components/cards/ConsultancyCardLeft";
import ConsultancyCardRight from "../../components/cards/ConsultancyCardRight";
import TopSection from "../../components/TopSection";
import { useTranslation } from "react-i18next";

const TrainingService = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white  border">
      <TopSection title={t("ConsultancyService")} bgImage={"venueImage"} />
      <div className="w-full max-w-[1600px] lg:mx-auto px-2 lg:p-4 py-8">
        <div className="mx-auto md:px-4 md:py-10">
          <div className="section-header text-center mb-12">
            <h2 className="text-3xl font-bold text-[#3a98d2] ">
              {t("OurConsultancyService")}
            </h2>
          </div>
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <ConsultancyCardRight />
            </div>
            <div>
              <ConsultancyCardLeft />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingService;
