import Finance13 from "../../assets/images/finance13.webp";
import Oil5 from "../../assets/images/oil5.jpg";
import HumanResourse2 from "../../assets/images/humanRe2.jpg";
import Law8 from "../../assets/images/law8.jpg";
import Mskills1 from "../../assets/images/mSkills1.jpg";
import OperationM9 from "../../assets/images/OperationM9.jpg";
import Sales1 from "../../assets/images/sales1.jpg";
import Sskills1 from "../../assets/images/secretaryPractices.jpg";
import PublicRelation1 from "../../assets/images/publicRelation1.jpg";

export const CourseByAllocation = [
  {
    id: 31,
    image: Finance13,
    title: "finance13Title",
    desc: "finance13Desc",
    WhoShouldAttend: [
      "finance13WhoShouldAttend1",
      "finance13WhoShouldAttend2",
      "finance13WhoShouldAttend3",
      "finance13WhoShouldAttend4",
      "finance13WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance13courseExpectation1",
      "finance13courseExpectation2",
      "finance13courseExpectation3",
      "finance13courseExpectation4",
      "finance13courseExpectation5",
      "finance13courseExpectation6",
      "finance13courseExpectation7",
    ],
    courseContent: [
      {
        title: "finance13courseContent1title",
        contents: [
          "finance13courseContent1Desc1",
          "finance13courseContent1Desc2",
          "finance13courseContent1Desc3",
          "finance13courseContent1Desc4",
        ],
      },
      {
        title: "finance13courseContent2title",
        contents: [
          "finance13courseContent2Desc1",
          "finance13courseContent2Desc2",
          "finance13courseContent2Desc3",
          "finance13courseContent2Desc4",
          "finance13courseContent2Desc5",
          "finance13courseContent2Desc6",
        ],
      },
      {
        title: "finance13courseContent3title",
        contents: [
          "finance13courseContent3Desc1",
          "finance13courseContent3Desc2",
          "finance13courseContent3Desc3",
          "finance13courseContent3Desc4",
          "finance13courseContent3Desc5",
        ],
      },
      {
        title: "finance13courseContent4title",
        contents: [
          "finance13courseContent4Desc1",
          "finance13courseContent4Desc2",
          "finance13courseContent4Desc3",
          "finance13courseContent4Desc4",
          "finance13courseContent4Desc5",
        ],
      },
      {
        title: "finance13courseContent5title",
        contents: [
          "finance13courseContent5Desc1",
          "finance13courseContent5Desc2",
          "finance13courseContent5Desc3",
          "finance13courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN13",
  },
  {
    id: 47,
    image: Oil5,
    title: "gasandOIL5Title",
    desc: "gasandOIL5Desc",
    WhoShouldAttend: ["gasandOIL5WhoShouldAttend1"],
    courseExpectation: [
      "gasandOIL5courseExpectation1",
      "gasandOIL5courseExpectation2",
      "gasandOIL5courseExpectation3",
      "gasandOIL5courseExpectation4",
      "gasandOIL5courseExpectation5",
      "gasandOIL5courseExpectation6",
      "gasandOIL5courseExpectation7",
    ],
    courseContent: [
      {
        title: "gasandOIL5coursecourseContent1Title",
        contents: [
          "gasandOIL5coursecourseContent1Desc1",
          "gasandOIL5coursecourseContent1Desc2",
          "gasandOIL5coursecourseContent1Desc3",
          "gasandOIL5coursecourseContent1Desc4",
          "gasandOIL5coursecourseContent1Desc5",
          "gasandOIL5coursecourseContent1Desc6",
          "gasandOIL5coursecourseContent1Desc7",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent2Title",
        contents: [
          "gasandOIL5coursecourseContent2Desc1",
          "gasandOIL5coursecourseContent2Desc2",
          "gasandOIL5coursecourseContent2Desc3",
          "gasandOIL5coursecourseContent2Desc4",
          "gasandOIL5coursecourseContent2Desc5",
          "gasandOIL5coursecourseContent2Desc6",
          "gasandOIL5coursecourseContent2Desc7",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent3Title",
        contents: [
          "gasandOIL5coursecourseContent3Desc1",
          "gasandOIL5coursecourseContent3Desc2",
          "gasandOIL5coursecourseContent3Desc3",
          "gasandOIL5coursecourseContent3Desc4",
          "gasandOIL5coursecourseContent3Desc5",
          "gasandOIL5coursecourseContent3Desc6",
          "gasandOIL5coursecourseContent3Desc7",
          "gasandOIL5coursecourseContent3Desc8",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent4Title",
        contents: [
          "gasandOIL5coursecourseContent4Desc1",
          "gasandOIL5coursecourseContent4Desc2",
          "gasandOIL5coursecourseContent4Desc3",
          "gasandOIL5coursecourseContent4Desc4",
          "gasandOIL5coursecourseContent4Desc5",
          "gasandOIL5coursecourseContent4Desc6",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent5Title",
        contents: [
          "gasandOIL5coursecourseContent5Desc1",
          "gasandOIL5coursecourseContent5Desc2",
          "gasandOIL5coursecourseContent5Desc3",
          "gasandOIL5coursecourseContent5Desc4",
          "gasandOIL5coursecourseContent5Desc5",
          "gasandOIL5coursecourseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO05",
  },
  {
    id: 51,
    image: HumanResourse2,
    title: "humanResources2Title",
    desc: "humanResources2Desc",
    WhoShouldAttend: [
      "humanResources2WhoShouldAttend1",
      "humanResources2WhoShouldAttend2",
      "humanResources2WhoShouldAttend3",
      "humanResources2WhoShouldAttend4",
      "humanResources2WhoShouldAttend5",
    ],
    courseExpectation: [
      "humanResources2courseExpectation1",
      "humanResources2courseExpectation2",
      "humanResources2courseExpectation3",
      "humanResources2courseExpectation4",
      "humanResources2courseExpectation5",
      "humanResources2courseExpectation6",
      "humanResources2courseExpectation7",
      "humanResources2courseExpectation8",
      "humanResources2courseExpectation9",
      "humanResources2courseExpectation10",
      "humanResources2courseExpectation11",
    ],
    courseContent: [
      {
        title: "humanResources2coursecourseContent1Title",
        contents: [
          "humanResources2coursecourseContent1Desc1",
          "humanResources2coursecourseContent1Desc2",
          "humanResources2coursecourseContent1Desc3",
          "humanResources2coursecourseContent1Desc4",
          "humanResources2coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent2Title",
        contents: [
          "humanResources2coursecourseContent2Desc1",
          "humanResources2coursecourseContent2Desc2",
          "humanResources2coursecourseContent2Desc3",
          "humanResources2coursecourseContent2Desc4",
          "humanResources2coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent3Title",
        contents: [
          "humanResources2coursecourseContent3Desc1",
          "humanResources2coursecourseContent3Desc2",
          "humanResources2coursecourseContent3Desc3",
          "humanResources2coursecourseContent3Desc4",
          "humanResources2coursecourseContent3Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent4Title",
        contents: [
          "humanResources2coursecourseContent4Desc1",
          "humanResources2coursecourseContent4Desc2",
          "humanResources2coursecourseContent4Desc3",
          "humanResources2coursecourseContent4Desc4",
          "humanResources2coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent5Title",
        contents: [
          "humanResources2coursecourseContent5Desc1",
          "humanResources2coursecourseContent5Desc2",
          "humanResources2coursecourseContent5Desc3",
          "humanResources2coursecourseContent5Desc4",
          "humanResources2coursecourseContent5Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent6Title",
        contents: [
          "humanResources2coursecourseContent6Desc1",
          "humanResources2coursecourseContent6Desc2",
          "humanResources2coursecourseContent6Desc3",
          "humanResources2coursecourseContent6Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent7Title",
        contents: [
          "humanResources2coursecourseContent7Desc1",
          "humanResources2coursecourseContent7Desc2",
          "humanResources2coursecourseContent7Desc3",
          "humanResources2coursecourseContent7Desc4",
          "humanResources2coursecourseContent7Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent8Title",
        contents: [
          "humanResources2coursecourseContent8Desc1",
          "humanResources2coursecourseContent8Desc2",
          "humanResources2coursecourseContent8Desc3",
          "humanResources2coursecourseContent8Desc4",
          "humanResources2coursecourseContent8Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent9Title",
        contents: [
          "humanResources2coursecourseContent9Desc1",
          "humanResources2coursecourseContent9Desc2",
          "humanResources2coursecourseContent9Desc3",
          "humanResources2coursecourseContent9Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent10Title",
        contents: [
          "humanResources2coursecourseContent10Desc1",
          "humanResources2coursecourseContent10Desc2",
          "humanResources2coursecourseContent10Desc3",
          "humanResources2coursecourseContent10Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent11Title",
        contents: [
          "humanResources2coursecourseContent11Desc1",
          "humanResources2coursecourseContent11Desc2",
          "humanResources2coursecourseContent11Desc3",
          "humanResources2coursecourseContent11Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent12Title",
        contents: [
          "humanResources2coursecourseContent12Desc1",
          "humanResources2coursecourseContent12Desc2",
          "humanResources2coursecourseContent12Desc3",
          "humanResources2coursecourseContent12Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent13Title",
        contents: [
          "humanResources2coursecourseContent13Desc1",
          "humanResources2coursecourseContent13Desc2",
          "humanResources2coursecourseContent13Desc3",
          "humanResources2coursecourseContent13Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent14Title",
        contents: [
          "humanResources2coursecourseContent14Desc1",
          "humanResources2coursecourseContent14Desc2",
          "humanResources2coursecourseContent14Desc3",
          "humanResources2coursecourseContent14Desc4",
        ],
      },
    ],
    courseFess: 7940,
    category: "Human Resources",
    courseCode: "HR02",
  },
  {
    id: 69,
    image: Law8,
    title: "Law8Title",
    desc: "Law8Desc",
    WhoShouldAttend: [
      "Law8WhoShouldAttend1",
      "Law8WhoShouldAttend2",
      "Law8WhoShouldAttend3",
      "Law8WhoShouldAttend4",
      "Law8WhoShouldAttend5",
    ],
    courseExpectation: [
      "Law8courseExpectation1",
      "Law8courseExpectation2",
      "Law8courseExpectation3",
      "Law8courseExpectation4",
      "Law8courseExpectation5",
    ],
    courseContent: [
      {
        title: "Law8coursecourseContent1Title",
        contents: [
          "Law8coursecourseContent1Desc1",
          "Law8coursecourseContent1Desc2",
          "Law8coursecourseContent1Desc3",
          "Law8coursecourseContent1Desc4",
          "Law8coursecourseContent1Desc5",
          "Law8coursecourseContent1Desc6",
        ],
      },
      {
        title: "Law8coursecourseContent2Title",
        contents: [
          "Law8coursecourseContent2Desc1",
          "Law8coursecourseContent2Desc2",
          "Law8coursecourseContent2Desc3",
          "Law8coursecourseContent2Desc4",
          "Law8coursecourseContent2Desc5",
          "Law8coursecourseContent2Desc6",
          "Law8coursecourseContent2Desc7",
        ],
      },
      {
        title: "Law8coursecourseContent3Title",
        contents: [
          "Law8coursecourseContent3Desc1",
          "Law8coursecourseContent3Desc2",
          "Law8coursecourseContent3Desc3",
          "Law8coursecourseContent3Desc4",
          "Law8coursecourseContent3Desc5",
        ],
      },
      {
        title: "Law8coursecourseContent4Title",
        contents: [
          "Law8coursecourseContent4Desc1",
          "Law8coursecourseContent4Desc2",
          "Law8coursecourseContent4Desc3",
          "Law8coursecourseContent4Desc4",
          "Law8coursecourseContent4Desc5",
          "Law8coursecourseContent4Desc6",
        ],
      },
      {
        title: "Law8coursecourseContent5Title",
        contents: [
          "Law8coursecourseContent5Desc1",
          "Law8coursecourseContent5Desc2",
          "Law8coursecourseContent5Desc3",
          "Law8coursecourseContent5Desc4",
          "Law8coursecourseContent5Desc5",
          "Law8coursecourseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW08",
  },
  {
    id: 71,
    image: Mskills1,
    title: "management19Title",
    desc: "management19Desc",
    WhoShouldAttend: [
      "management19WhoShouldAttend1",
      "management19WhoShouldAttend2",
      "management19WhoShouldAttend3",
      "management19WhoShouldAttend4",
      "management19WhoShouldAttend5",
      "management19WhoShouldAttend6",
    ],
    courseExpectation: [
      "management19courseExpectation1",
      "management19courseExpectation2",
      "management19courseExpectation3",
      "management19courseExpectation4",
      "management19courseExpectation5",
      "management19courseExpectation6",
      "management19courseExpectation7",
    ],
    courseContent: [
      {
        title: "management19courseContent1title",
        contents: [
          "management19courseContent1Desc1",
          "management19courseContent1Desc2",
          "management19courseContent1Desc3",
          "management19courseContent1Desc4",
        ],
      },
      {
        title: "management19courseContent2title",
        contents: [
          "management19courseContent2Desc1",
          "management19courseContent2Desc2",
          "management19courseContent2Desc3",
          "management19courseContent2Desc4",
        ],
      },
      {
        title: "management19courseContent3title",
        contents: [
          "management19courseContent3Desc1",
          "management19courseContent3Desc2",
          "management19courseContent3Desc3",
          "management19courseContent3Desc4",
        ],
      },
      {
        title: "management19courseContent4title",
        contents: [
          "management19courseContent4Desc1",
          "management19courseContent4Desc2",
          "management19courseContent4Desc3",
          "management19courseContent4Desc4",
        ],
      },
      {
        title: "management19courseContent5title",
        contents: [
          "management19courseContent5Desc1",
          "management19courseContent5Desc2",
          "management19courseContent5Desc3",
          "management19courseContent5Desc4",
        ],
      },
      {
        title: "management19courseContent6title",
        contents: [
          "management19courseContent6Desc1",
          "management19courseContent6Desc2",
          "management19courseContent6Desc3",
          "management19courseContent6Desc4",
        ],
      },
      {
        title: "management19courseContent7title",
        contents: [
          "management19courseContent7Desc1",
          "management19courseContent7Desc2",
          "management19courseContent7Desc3",
          "management19courseContent7Desc4",
        ],
      },
      {
        title: "management19courseContent8title",
        contents: [
          "management19courseContent8Desc1",
          "management19courseContent8Desc2",
          "management19courseContent8Desc3",
          "management19courseContent8Desc4",
        ],
      },
      {
        title: "management19courseContent9title",
        contents: [
          "management19courseContent9Desc1",
          "management19courseContent9Desc2",
          "management19courseContent9Desc3",
          "management19courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA01",
  },
  {
    id: 87,
    image: OperationM9,
    title: "operationsManagement9Title",
    desc: "operationsManagement9Desc",
    WhoShouldAttend: ["operationsManagement9WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement9courseExpectation1",
      "operationsManagement9courseExpectation2",
      "operationsManagement9courseExpectation3",
      "operationsManagement9courseExpectation4",
      "operationsManagement9courseExpectation5",
      "operationsManagement9courseExpectation6",
      "operationsManagement9courseExpectation7",
      "operationsManagement9courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement9coursecourseContent1Title",
        contents: [
          "operationsManagement9coursecourseContent1Desc1",
          "operationsManagement9coursecourseContent1Desc2",
          "operationsManagement9coursecourseContent1Desc3",
          "operationsManagement9coursecourseContent1Desc4",
          "operationsManagement9coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent2Title",
        contents: [
          "operationsManagement9coursecourseContent2Desc1",
          "operationsManagement9coursecourseContent2Desc2",
          "operationsManagement9coursecourseContent2Desc3",
          "operationsManagement9coursecourseContent2Desc4",
          "operationsManagement9coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent3Title",
        contents: [
          "operationsManagement9coursecourseContent3Desc1",
          "operationsManagement9coursecourseContent3Desc2",
          "operationsManagement9coursecourseContent3Desc3",
          "operationsManagement9coursecourseContent3Desc4",
          "operationsManagement9coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent4Title",
        contents: [
          "operationsManagement9coursecourseContent4Desc1",
          "operationsManagement9coursecourseContent4Desc2",
          "operationsManagement9coursecourseContent4Desc3",
          "operationsManagement9coursecourseContent4Desc4",
          "operationsManagement9coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent5Title",
        contents: [
          "operationsManagement9coursecourseContent5Desc1",
          "operationsManagement9coursecourseContent5Desc2",
          "operationsManagement9coursecourseContent5Desc3",
          "operationsManagement9coursecourseContent5Desc4",
          "operationsManagement9coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM09",
  },
  {
    id: 119,
    image: Sales1,
    title: "salesAndMarketing1Title",
    desc: "salesAndMarketing1Desc",
    WhoShouldAttend: [
      "salesAndMarketing1WhoShouldAttend1",
      "salesAndMarketing1WhoShouldAttend2",
      "salesAndMarketing1WhoShouldAttend3",
      "salesAndMarketing1WhoShouldAttend4",
    ],
    courseExpectation: [
      "salesAndMarketing1courseExpectation1",
      "salesAndMarketing1courseExpectation2",
      "salesAndMarketing1courseExpectation3",
      "salesAndMarketing1courseExpectation4",
      "salesAndMarketing1courseExpectation5",
      "salesAndMarketing1courseExpectation6",
      "salesAndMarketing1courseExpectation7",
      "salesAndMarketing1courseExpectation8",
      "salesAndMarketing1courseExpectation9",
      "salesAndMarketing1courseExpectation10",
      "salesAndMarketing1courseExpectation11",
      "salesAndMarketing1courseExpectation12",
      "salesAndMarketing1courseExpectation13",
    ],
    courseContent: [
      {
        title: "salesAndMarketing1coursecourseContent1Title",
        contents: [
          "salesAndMarketing1coursecourseContent1Desc1",
          "salesAndMarketing1coursecourseContent1Desc2",
          "salesAndMarketing1coursecourseContent1Desc3",
          "salesAndMarketing1coursecourseContent1Desc4",
          "salesAndMarketing1coursecourseContent1Desc5",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent2Title",
        contents: [
          "salesAndMarketing1coursecourseContent2Desc1",
          "salesAndMarketing1coursecourseContent2Desc2",
          "salesAndMarketing1coursecourseContent2Desc3",
          "salesAndMarketing1coursecourseContent2Desc4",
          "salesAndMarketing1coursecourseContent2Desc5",
          "salesAndMarketing1coursecourseContent2Desc6",
          "salesAndMarketing1coursecourseContent2Desc7",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent3Title",
        contents: [
          "salesAndMarketing1coursecourseContent3Desc1",
          "salesAndMarketing1coursecourseContent3Desc2",
          "salesAndMarketing1coursecourseContent3Desc3",
          "salesAndMarketing1coursecourseContent3Desc4",
          "salesAndMarketing1coursecourseContent3Desc5",
          "salesAndMarketing1coursecourseContent3Desc6",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent4Title",
        contents: [
          "salesAndMarketing1coursecourseContent4Desc1",
          "salesAndMarketing1coursecourseContent4Desc2",
          "salesAndMarketing1coursecourseContent4Desc3",
          "salesAndMarketing1coursecourseContent4Desc4",
          "salesAndMarketing1coursecourseContent4Desc5",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent5Title",
        contents: [
          "salesAndMarketing1coursecourseContent5Desc1",
          "salesAndMarketing1coursecourseContent5Desc2",
          "salesAndMarketing1coursecourseContent5Desc3",
          "salesAndMarketing1coursecourseContent5Desc4",
          "salesAndMarketing1coursecourseContent5Desc5",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent6Title",
        contents: [
          "salesAndMarketing1coursecourseContent6Desc1",
          "salesAndMarketing1coursecourseContent6Desc2",
          "salesAndMarketing1coursecourseContent6Desc3",
          "salesAndMarketing1coursecourseContent6Desc4",
          "salesAndMarketing1coursecourseContent6Desc5",
          "salesAndMarketing1coursecourseContent6Desc6",
          "salesAndMarketing1coursecourseContent6Desc7",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent7Title",
        contents: [
          "salesAndMarketing1coursecourseContent7Desc1",
          "salesAndMarketing1coursecourseContent7Desc2",
          "salesAndMarketing1coursecourseContent7Desc3",
          "salesAndMarketing1coursecourseContent7Desc4",
          "salesAndMarketing1coursecourseContent7Desc5",
          "salesAndMarketing1coursecourseContent7Desc6",
          "salesAndMarketing1coursecourseContent7Desc7",
          "salesAndMarketing1coursecourseContent7Desc8",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent8Title",
        contents: [
          "salesAndMarketing1coursecourseContent8Desc1",
          "salesAndMarketing1coursecourseContent8Desc2",
          "salesAndMarketing1coursecourseContent8Desc3",
          "salesAndMarketing1coursecourseContent8Desc4",
          "salesAndMarketing1coursecourseContent8Desc5",
          "salesAndMarketing1coursecourseContent8Desc6",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent9Title",
        contents: [
          "salesAndMarketing1coursecourseContent9Desc1",
          "salesAndMarketing1coursecourseContent9Desc2",
          "salesAndMarketing1coursecourseContent9Desc3",
          "salesAndMarketing1coursecourseContent9Desc4",
          "salesAndMarketing1coursecourseContent9Desc5",
          "salesAndMarketing1coursecourseContent9Desc6",
        ],
      },
    ],
    courseFess: 6080,
    category: "Sales and Marketing",
    courseCode: "SM01",
  },
  {
    id: 122,
    image: Sskills1,
    title: "secretarialPratices1Title",
    desc: "secretarialPratices1Desc",
    WhoShouldAttend: ["secretarialPratices1WhoShouldAttend1"],
    courseExpectation: [
      "secretarialPratices1courseExpectation1",
      "secretarialPratices1courseExpectation2",
      "secretarialPratices1courseExpectation3",
      "secretarialPratices1courseExpectation4",
      "secretarialPratices1courseExpectation5",
      "secretarialPratices1courseExpectation6",
    ],
    courseContent: [
      {
        title: "secretarialPratices1coursecourseContent1Title",
        contents: [
          "secretarialPratices1coursecourseContent1Desc1",
          "secretarialPratices1coursecourseContent1Desc2",
          "secretarialPratices1coursecourseContent1Desc3",
          "secretarialPratices1coursecourseContent1Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent2Title",
        contents: [
          "secretarialPratices1coursecourseContent2Desc1",
          "secretarialPratices1coursecourseContent2Desc2",
          "secretarialPratices1coursecourseContent2Desc3",
          "secretarialPratices1coursecourseContent2Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent3Title",
        contents: [
          "secretarialPratices1coursecourseContent3Desc1",
          "secretarialPratices1coursecourseContent3Desc2",
          "secretarialPratices1coursecourseContent3Desc3",
          "secretarialPratices1coursecourseContent3Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent4Title",
        contents: [
          "secretarialPratices1coursecourseContent4Desc1",
          "secretarialPratices1coursecourseContent4Desc2",
          "secretarialPratices1coursecourseContent4Desc3",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent5Title",
        contents: [
          "secretarialPratices1coursecourseContent5Desc1",
          "secretarialPratices1coursecourseContent5Desc2",
          "secretarialPratices1coursecourseContent5Desc3",
          "secretarialPratices1coursecourseContent5Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent6Title",
        contents: [
          "secretarialPratices1coursecourseContent6Desc1",
          "secretarialPratices1coursecourseContent6Desc2",
          "secretarialPratices1coursecourseContent6Desc3",
          "secretarialPratices1coursecourseContent6Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent7Title",
        contents: [
          "secretarialPratices1coursecourseContent7Desc1",
          "secretarialPratices1coursecourseContent7Desc2",
          "secretarialPratices1coursecourseContent7Desc3",
          "secretarialPratices1coursecourseContent7Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent8Title",
        contents: [
          "secretarialPratices1coursecourseContent8Desc1",
          "secretarialPratices1coursecourseContent8Desc2",
          "secretarialPratices1coursecourseContent8Desc3",
          "secretarialPratices1coursecourseContent8Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent9Title",
        contents: [
          "secretarialPratices1coursecourseContent9Desc1",
          "secretarialPratices1coursecourseContent9Desc2",
          "secretarialPratices1coursecourseContent9Desc3",
          "secretarialPratices1coursecourseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Secretarial Pratices",
    courseCode: "SP01",
  },
  {
    id: 99,
    image: PublicRelation1,
    title: "publicRelations1Title",
    desc: "publicRelations1Desc",
    WhoShouldAttend: [
      "publicRelations1WhoShouldAttend1",
      "publicRelations1WhoShouldAttend2",
      "publicRelations1WhoShouldAttend3",
      "publicRelations1WhoShouldAttend4",
      "publicRelations1WhoShouldAttend5",
      "publicRelations1WhoShouldAttend6",
      "publicRelations1WhoShouldAttend7",
      "publicRelations1WhoShouldAttend8",
      "publicRelations1WhoShouldAttend9",
      "publicRelations1WhoShouldAttend10",
      "publicRelations1WhoShouldAttend11",
    ],
    courseExpectation: [
      "publicRelations1courseExpectation1",
      "publicRelations1courseExpectation2",
      "publicRelations1courseExpectation3",
      "publicRelations1courseExpectation4",
      "publicRelations1courseExpectation5",
      "publicRelations1courseExpectation6",
      "publicRelations1courseExpectation7",
    ],
    courseContent: [
      {
        title: "publicRelations1coursecourseContent1Title",
        contents: [
          "publicRelations1coursecourseContent1Desc1",
          "publicRelations1coursecourseContent1Desc2",
          "publicRelations1coursecourseContent1Desc3",
          "publicRelations1coursecourseContent1Desc4",
          "publicRelations1coursecourseContent1Desc5",
          "publicRelations1coursecourseContent1Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent2Title",
        contents: [
          "publicRelations1coursecourseContent2Desc1",
          "publicRelations1coursecourseContent2Desc2",
          "publicRelations1coursecourseContent2Desc3",
          "publicRelations1coursecourseContent2Desc4",
          "publicRelations1coursecourseContent2Desc5",
          "publicRelations1coursecourseContent2Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent3Title",
        contents: [
          "publicRelations1coursecourseContent3Desc1",
          "publicRelations1coursecourseContent3Desc2",
          "publicRelations1coursecourseContent3Desc3",
          "publicRelations1coursecourseContent3Desc4",
          "publicRelations1coursecourseContent3Desc5",
          "publicRelations1coursecourseContent3Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent4Title",
        contents: [
          "publicRelations1coursecourseContent4Desc1",
          "publicRelations1coursecourseContent4Desc2",
          "publicRelations1coursecourseContent4Desc3",
          "publicRelations1coursecourseContent4Desc4",
          "publicRelations1coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations1coursecourseContent5Title",
        contents: [
          "publicRelations1coursecourseContent5Desc1",
          "publicRelations1coursecourseContent5Desc2",
          "publicRelations1coursecourseContent5Desc3",
          "publicRelations1coursecourseContent5Desc4",
          "publicRelations1coursecourseContent5Desc5",
          "publicRelations1coursecourseContent5Desc6",
          "publicRelations1coursecourseContent5Desc7",
        ],
      },
      {
        title: "publicRelations1coursecourseContent6Title",
        contents: [
          "publicRelations1coursecourseContent6Desc1",
          "publicRelations1coursecourseContent6Desc2",
          "publicRelations1coursecourseContent6Desc3",
          "publicRelations1coursecourseContent6Desc4",
          "publicRelations1coursecourseContent6Desc5",
          "publicRelations1coursecourseContent6Desc6",
          "publicRelations1coursecourseContent6Desc7",
        ],
      },
      {
        title: "publicRelations1coursecourseContent7Title",
        contents: [
          "publicRelations1coursecourseContent7Desc1",
          "publicRelations1coursecourseContent7Desc2",
          "publicRelations1coursecourseContent7Desc3",
          "publicRelations1coursecourseContent7Desc4",
          "publicRelations1coursecourseContent7Desc5",
          "publicRelations1coursecourseContent7Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent8Title",
        contents: [
          "publicRelations1coursecourseContent8Desc1",
          "publicRelations1coursecourseContent8Desc2",
          "publicRelations1coursecourseContent8Desc3",
          "publicRelations1coursecourseContent8Desc4",
          "publicRelations1coursecourseContent8Desc5",
          "publicRelations1coursecourseContent8Desc6",
          "publicRelations1coursecourseContent8Desc7",
        ],
      },
      {
        title: "publicRelations1coursecourseContent9Title",
        contents: [
          "publicRelations1coursecourseContent9Desc1",
          "publicRelations1coursecourseContent9Desc2",
          "publicRelations1coursecourseContent9Desc3",
          "publicRelations1coursecourseContent9Desc4",
          "publicRelations1coursecourseContent9Desc5",
          "publicRelations1coursecourseContent9Desc6",
          "publicRelations1coursecourseContent9Desc7",
        ],
      },
    ],
    courseFess: 5580,
    category: "Public Relations",
    courseCode: "PR01",
  },
];
