import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import RegImage1 from "../../assets/images/regStuff/Reg1.jpg";
import RegImage2 from "../../assets/images/regStuff/Reg2.jpg";
import RegImage3 from "../../assets/images/regStuff/Reg3.jpg";
import RegImage4 from "../../assets/images/regStuff/Reg4.jpg";
import RegImage5 from "../../assets/images/regStuff/Reg5.jpg";
import RegImage6 from "../../assets/images/regStuff/Reg6.jpg";
import RegImage7 from "../../assets/images/regStuff/Reg7.jpg";
import HomrImage4 from "../../assets/photo/meetingRoom3.jpg";
// partner
import RegLogo from "../../assets/photo/reg.png";
import RwandaAir from "../../assets/photo/RwandaAir.png";
import Client1 from "../../assets/images/clientImages/client1.png";
import Client2 from "../../assets/images/clientImages/client2.png";
import Client3 from "../../assets/images/clientImages/client3.png";
import Client4 from "../../assets/images/clientImages/client4.png";
import Client5 from "../../assets/images/clientImages/client5.png";
import Client6 from "../../assets/images/clientImages/client6.png";
import Client7 from "../../assets/images/clientImages/client7.png";
import Client8 from "../../assets/images/clientImages/client8.png";
import Client9 from "../../assets/images/clientImages/client9.jpg";
import Client10 from "../../assets/images/clientImages/client10.png";
import Client11 from "../../assets/images/clientImages/client11.jpeg";
import Client12 from "../../assets/images/clientImages/client12.jpeg";
import Client13 from "../../assets/images/clientImages/client13.png";
import Client14 from "../../assets/images/clientImages/client14.png";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/styles/client.css";
import { Autoplay, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";

const Clients = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);
  const images = [
    RegImage1,
    RegImage4,
    RegImage2,
    RegImage3,
    RegImage5,
    RegImage7,
    RegImage6,
  ];

  const progressCircle = useRef<any>(null);
  const progressContent = useRef<any>(null);

  const clients = [
    {
      id: 1,
      name: "Rwanda Energy Group",
      logo: RegLogo,
      link: "https://www.reg.rw/index.php?id=2",
    },
    {
      id: 2,
      name: "Rwanda Airports Company Ltd",
      logo: RwandaAir,
      link: "https://rac.co.rw/",
    },
    {
      id: 3,
      name: " Fusion Capital",
      logo: Client1,
      link: "",
    },
    {
      id: 4,
      name: "National constituency Development Fund",
      logo: Client2,
      link: "",
    },
    {
      id: 5,
      name: "Liverpool VCT Care and Treatment ",
      logo: Client3,
      link: "",
    },
    {
      id: 6,
      name: " Directorat of Monitoring and Evaluation, Ministry of Planning and Devolution (Kenya)",
      logo: Client4,
      link: "",
    },

    {
      id: 7,
      name: "African Union of Brind",
      logo: Client5,
      link: "",
    },
    {
      id: 8,
      name: "Population Services International ",
      logo: Client6,
      link: "",
    },
    {
      id: 9,
      name: "Imperial Bank",
      logo: Client7,
      link: "",
    },
    {
      id: 3,
      name: "Ministry f Foreign Afairs",
      logo: Client8,
      link: "",
    },
    {
      id: 10,
      name: "The Kenyan National Examination Council",
      logo: Client9,
      link: "",
    },
    {
      id: 11,
      name: " G for S",
      logo: Client10,
      link: "",
    },
    {
      id: 12,
      name: "Export Promotion Council",
      logo: Client11,
      link: "",
    },
    {
      id: 13,
      name: "Kenya Industrial Research and Development Institute",
      logo: Client12,
      link: "",
    },
    {
      id: 14,
      name: "Guardian Bank",
      logo: Client13,
      link: "",
    },
    {
      id: 15,
      name: "CSI International Ltd",
      logo: Client14,
      link: "",
    },
  ];

  return (
    <>
      <div
        className="relative pt-32"
        style={{
          backgroundImage: `url(${HomrImage4})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          effect={"fade"}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          onSlideChange={(swiper: any) => setActiveIndex(swiper.realIndex)}
          onInit={(swiper: any) => setActiveIndex(swiper.realIndex)}
          className="clients-swiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 2,
            },
          }}
        >
          {images?.map((image, index) => (
            <SwiperSlide key={index} className="clients-swiper-slide">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="lg:h-[800px] w-full object-contain rounded-md"
              />
              {activeIndex !== index && (
                <div className="absolute inset-0 bg-black opacity-70 w-full"></div>
              )}
            </SwiperSlide>
          ))}

          <div className="autoplay-progress" slot="container-end">
            <svg viewBox="0 0 48 48" ref={progressCircle}>
              <circle cx="24" cy="24" r="20"></circle>
            </svg>
            <span ref={progressContent}></span>
          </div>
        </Swiper>
        <div className="absolute inset-0 bg-black opacity-80"></div>
      </div>
      <section className="bg-[#f6f8fd] py-16">
        <div className="px-6">
          <h2 className="text-3xl font-bold text-[#3a98d2]  text-center mb-12">
            {t("AmazingClients")}
          </h2>
          <div className="xcontainer max-w-xl mx-auto md:px-6">
            <div className="grid grid-cols-2 gap-x-2 mb-2">
              {clients.slice(0, 2)?.map((client) => (
                <div
                  key={client.id}
                  className="overflow-hidden cursor-pointer flex items-center"
                >
                  <a href={client?.link} target="_blank" rel="noreferrer">
                    <div>
                      <img
                        src={client.logo}
                        alt={`${client.name} logo`}
                        className="object-contain bg-white p-1"
                      />
                      <h1 className="text-sm font-sans text-gray-800 mx-2 xtext-center">
                        {client.name}
                      </h1>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full max-w-[1100px] mx-auto px-20">
            <div className="grid sm:grid-cols-5 lg:gap-x-6">
              {clients.slice(2, 20)?.map((client) => (
                <div key={client.id} className="overflow-hidden cursor-pointer">
                  <div className=" py-2">
                    <img
                      src={client.logo}
                      alt={`${client.name} logo`}
                      className=" object-contain"
                    />
                    <h1 className="text-sm font-sans text-gray-800 mb-4 px-2 text-center">
                      {client.name}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
