import { Routes, Route } from "react-router-dom";
import { setHelmet } from "../utils/helmetFn";
import HomePage from "../pages/HomePage";
import Plicies from "../pages/infoPage/Policies";
import Clients from "../pages/clientsPage/Clients";
import CourseAlocation from "../pages/infoPage/CourseAlocation";
import Visa from "../pages/infoPage/Visa";
import FAQ from "../pages/FAQ";
import ManagingDirector from "../pages/ImportantDocuments";
import About from "../pages/aboutPages/About";
import Team from "../pages/aboutPages/Team";
import TrainingConsultant from "../pages/aboutPages/TrainingConsultant";
import ConsultantDetailsPage from "../pages/ConsultantDetailsPage";
import Vanues from "../pages/aboutPages/Vanues";
import Workshop from "../pages/Workshop";
import WorkshopDetails from "../components/cards/WorkshopDetails";
import ContactPage from "../pages/ContactPage";
import CoursePage from "../pages/courses/CoursePage";
import TrainingService from "../pages/service/TrainingService";
import ConsultancyServices from "../pages/service/ConsultancyServices";
import CourseCalendarPage from "../pages/courses/CourseCalendarPage";
import CourseByCategory from "../pages/courses/CourseByCategory";
import CourseDetails from "../pages/courses/CourseDetails";
import RegisterForCourse from "../pages/RegisterForCourse";
import PageNotFound from "../pages/PageNotFound";

import TermsAndCondition from "../pages/infoPage/TermsAndCondition";

const MainRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            {setHelmet("Home ", "Welcome to the home page")}
            <HomePage />
          </>
        }
      />

      <Route
        path="/infomation/Terms"
        element={
          <>
            {setHelmet("Infomation ", "Welcome to the Infomation page")}
            <TermsAndCondition />
          </>
        }
      />

      <Route path="/infomation/policies" element={<Plicies />} />
      <Route
        path="/clients"
        element={
          <>
            {setHelmet("Clients ", "Welcome to the Clients page")}
            <Clients />
          </>
        }
      />
      <Route
        path="/infomation/program/alocation"
        element={
          <>
            {setHelmet(
              "Course Alocation ",
              "Welcome to the Course Alocation page"
            )}
            <CourseAlocation />
          </>
        }
      />
      <Route path="/infomation/visa" element={<Visa />} />
      <Route path="/infomation/faq" element={<FAQ />} />
      <Route
        path="/infomation/manager/director"
        element={<ManagingDirector />}
      />
      <Route
        path="/about"
        element={
          <>
            {setHelmet("About Us", "Welcome to the About Us page")}
            <About />
          </>
        }
      />
      <Route
        path="/about/team"
        element={
          <>
            {setHelmet("CEO's Message ", "Welcome to the CEO's Message page")}
            <Team />
          </>
        }
      />
      <Route
        path="/about/training/consultant"
        element={<TrainingConsultant />}
      />
      <Route
        path="/about/training/consultant/details"
        element={<ConsultantDetailsPage />}
      />
      <Route
        path="/about/vanues"
        element={
          <>
            {setHelmet("Vanues ", "Welcome to the Vanues page")}
            <Vanues />
          </>
        }
      />
      <Route path="/workshop" element={<Workshop />} />
      <Route path="/workshop/details" element={<WorkshopDetails />} />
      <Route
        path="/contact"
        element={
          <>
            {setHelmet("Contact ", "Welcome to the Contact page")}
            <ContactPage />
          </>
        }
      />
      <Route path="/programme" element={<CoursePage />} />
      <Route
        path="/program/training"
        element={
          <>
            {setHelmet("Training ", "Welcome to the Training page")}
            <ConsultancyServices />
          </>
        }
      />
      <Route
        path="/program/consultancy"
        element={
          <>
            {setHelmet("Consultancy ", "Welcome to the Consultancy page")}
            <TrainingService />
          </>
        }
      />
      <Route
        path="/program/calendar"
        element={
          <>
            {setHelmet("Calendar ", "Welcome to the Calendar page")}
            <CourseCalendarPage />
          </>
        }
      />
      <Route path="/program/:catName" element={<CourseByCategory />} />
      <Route path="/program/:catName/:courseId" element={<CourseDetails />} />
      <Route
        path="/program/:catName/:courseId/register"
        element={<RegisterForCourse route="course" />}
      />
      <Route
        path="/workshop/:workshopName/register"
        element={<RegisterForCourse route="workshop" />}
      />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MainRoute;
