import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { getOrganisationDataAction } from "../../../store/layout/actions";
import { useDispatch, useSelector } from "react-redux";
import CountrySelector from "../../country/selector";
import { SelectMenuOption } from "../../../utils/libs/types";
import { COUNTRIES } from "../../../utils/libs/countries";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const OrganisationForm = (props: any) => {
  const { t } = useTranslation();
  const { layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [localStorageUpdate, setLocalStorageUpdate] = useState(0);
  const [formData, setFormData] = useState<any>(null);
  const submissionKey = "courseData";

  useEffect(() => {
    const existingData = localStorage.getItem(submissionKey);

    if (existingData) {
      const parsedData = JSON.parse(existingData);
      setFormData(parsedData);
      getOrganisationDataAction(parsedData)(dispatch);
    }
  }, [localStorageUpdate, dispatch]);

  const onFinish = (values: any) => {
    const existingData = localStorage.getItem(submissionKey);
    const existingItem = JSON.parse(
      localStorage.getItem(submissionKey) || "{}"
    );
    const payload = {
      organisationDetails: { ...values },
    };

    const newObject = { ...existingItem, ...payload };

    if (existingData) {
      localStorage.setItem(submissionKey, JSON.stringify(newObject));
      notification.success({ message: "Added successfully" });
      props?.handleFormUpdate();
      form.resetFields();
      props?.onCancel();
    } else {
      localStorage.setItem(submissionKey, JSON.stringify(newObject));
      notification.success({ message: "Submitted successfully" });
      props?.handleFormUpdate();
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState<SelectMenuOption["value"]>(
    layout?.organisation?.organisationDetails?.country ?? "AF"
  );

  useEffect(() => {
    form.setFieldsValue({
      organisation: layout?.organisation?.organisationDetails?.organisation,
      companyAddress: layout?.organisation?.organisationDetails?.companyAddress,
      country: layout?.organisation?.organisationDetails?.country,
      hrContactName: layout?.organisation?.organisationDetails?.hrContactName,
      hrContactEmail: layout?.organisation?.organisationDetails?.hrContactEmail,
      hrContactPhone: layout?.organisation?.organisationDetails?.hrContactPhone,
      organisationType:
        layout?.organisation?.organisationDetails?.organisationType,
      otherComents: layout?.organisation?.organisationDetails?.otherComents,
    });
  }, [form, layout?.organisation?.organisationDetails]);

  const initialData = {
    organisation: layout?.organisation?.organisationDetails?.organisation,
    companyAddress: layout?.organisation?.organisationDetails?.companyAddress,
    country: layout?.organisation?.organisationDetails?.country,
    hrContactName: layout?.organisation?.organisationDetails?.hrContactName,
    hrContactEmail: layout?.organisation?.organisationDetails?.hrContactEmail,
    hrContactPhone: layout?.organisation?.organisationDetails?.hrContactPhone,
    organisationType:
      layout?.organisation?.organisationDetails?.organisationType,
    otherComents: layout?.organisation?.organisationDetails?.otherComents,
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={initialData || {}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label={t("Your Organisation")}
        name="organisation"
        rules={[{ required: true, message: "Please input your Organisation!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("Company Address")}
        name="companyAddress"
        rules={[
          { required: true, message: "Please input your Company Address!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Country")}
        name="country"
        rules={[{ required: true, message: "Please input your Country!" }]}
      >
        <CountrySelector
          id={"country-selector"}
          open={isOpen}
          onToggle={() => setIsOpen(!isOpen)}
          onChange={setCountry}
          selectedValue={COUNTRIES.find((option) => option.value === country)}
        />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          style={{
            backgroundColor: "#003d97",
            color: "white",
          }}
          className="h-10"
        >
          {layout?.organisation?.organisationDetails
            ? "Update"
            : "Save and Continue"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrganisationForm;
