import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "CourseLibrary",
  initialState: {
    isFetching: false,
    new: null,
    messageSubmited: false,
  },
  reducers: {
    setNew(state, action) {
      state.new = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setMessageSubmited(state, action) {
      state.messageSubmited = action.payload;
    },
  },
});

export const mymessageActions = mySlice.actions;

export default mySlice.reducer;
