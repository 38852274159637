import React from "react";
import ManagingDirectorPng from "../assets/photo/managerApprove.png";

const ImportantDocuments = () => {
  return (
    <div className="w-full max-w-[1100px] md:mx-auto px-2 md:p-4 md:py-8 text-gray-800 pt-8 uppercase font-semibold">
      <div className="pt-28 lg:pt-36">
        <img src={ManagingDirectorPng} alt="" />
      </div>
    </div>
  );
};

export default ImportantDocuments;
