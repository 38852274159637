import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { companyLogos } from "../assets/data";

const PartnerPage = () => {
  const { t } = useTranslation();

  return (
    <section className="pb-14 ">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-cente ">
        <div className="mb-2 text-center">
          <h1 className="mb-2 text-xl tracking-tight font-semibold text-[#3a98d2]  uppercase">
            {t("OurClient")}
          </h1>
        </div>
        <Marquee pauseOnHover>
          {companyLogos?.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-16 mx-4"
            />
          ))}
        </Marquee>
      </div>
    </section>
  );
};

export default PartnerPage;
