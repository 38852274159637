import React from "react";
import { useTranslation } from "react-i18next";

const CompanyDescription = () => {
  const { t } = useTranslation();
  return (
    <div className="block md:rounded bg-[#3a98d2] md:mx-4">
      <div className="flex flex-wrap items-center relative">
        <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12 text-white lg:py-10 py-4 flex items-center justify-center mx-auto">
          <div className="px-6 md:px-12">
            <h2 className="mb-2 text-xl md:text-3xl font-medium text-primary text-center">
              {t("companyDescription")}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDescription;
