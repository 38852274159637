import { useTranslation } from "react-i18next";

const WelcomePage = () => {
  const { t } = useTranslation();
  return (
    <div className="block rounded">
      <div className="flex flex-wrap items-center relative">
        <div className="w-full shrink-0 grow-0 basis-auto xlg:w-6/12 xlx:w-8/12 lg:py-10 pb-4 flex items-center justify-center mx-auto">
          <div className="px-6 md:px-6">
            <h1 className="mb-10 text-xl text-center tracking-tight font-semibold text-[#3a98d2] uppercase">
              {t("welcometoNCTARTrainingConsultants")}
            </h1>
            <p className="text-base sm:text-lg mb-4">
              {" "}
              {t("welcometoNCTARTrainingConsultantsDesc1")}
            </p>
            <p className="text-base sm:text-lg mb-4">
              {" "}
              {t("welcometoNCTARTrainingConsultantsDesc2")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
