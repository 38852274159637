import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ConsultancyCard: React.FC<{ item: any }> = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div className="mb-8 overflow-hidden">
      <Link to={`/program/${item?.category}`}>
        <div className="portfolio-img relative overflow-hidden rounded-md cursor-pointer">
          <img
            src={item?.image}
            className="w-full h-[300px] object-cover"
            alt={item.title}
          />
        </div>
        <div className="portfolio-info mt-4 text-center">
          <h4 className="text-lg font-semibold mb-2">{t(item.category)}</h4>
        </div>
      </Link>
    </div>
  );
};

export default ConsultancyCard;
