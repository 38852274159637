import AdvancedManagement1 from "../../assets/images/advancedCorporateGovernanceFinancialInstitution.png";
import AdvancedManagement2 from "../../assets/images/advanced2.png";
import AdvancedManagement3 from "../../assets/images/advanceManagementSkills.png";
import AdvancedManagement4 from "../../assets/images/advanced2.png";
import AdvancedManagement5 from "../../assets/images/advanced2.png";
import AdvancedManagement6 from "../../assets/images/advanced2.png";
import AdvancedManagement7 from "../../assets/images/advanced2.png";
import AdvancedManagement8 from "../../assets/images/advanced2.png";
import AdvancedManagement9 from "../../assets/images/advanced2.png";
import AdvancedManagement10 from "../../assets/images/advanced2.png";
import AdvancedManagement11 from "../../assets/images/advanced2.png";
import AdvancedManagement12 from "../../assets/images/advanced2.png";
import AdvancedManagement13 from "../../assets/images/advanced2.png";
import AdvancedManagement14 from "../../assets/images/advanced2.png";
import AdvancedManagement15 from "../../assets/images/advanced2.png";
import AdvancedManagement16 from "../../assets/images/advanced2.png";
import AdvancedManagement17 from "../../assets/images/advanced2.png";
import AdvancedManagement18 from "../../assets/images/advanced2.png";
import Mskills1 from "../../assets/images/mSkills1.jpg";
import Mskills2 from "../../assets/images/mSkills2.webp";
import Mskills3 from "../../assets/images/mSkills3.jpg";
import Mskills4 from "../../assets/images/mSkills4.webp";
import Mskills5 from "../../assets/images/mSkills5.png";
import Mskills6 from "../../assets/images/mSkills6.webp";
import Mskills7 from "../../assets/images/mSkills7.webp";
import Mskills8 from "../../assets/images/mSkills8.png";

// Finance Images
import Finance1 from "../../assets/images/finance1.webp";
import Finance2 from "../../assets/images/finance2.webp";
import Finance3 from "../../assets/images/finance3.webp";
import Finance4 from "../../assets/images/finance4.jpg";
import Finance5 from "../../assets/images/finance5.jpg";
import Finance6 from "../../assets/images/finance6.webp";
import Finance7 from "../../assets/images/finance7.webp";
import Finance8 from "../../assets/images/finance8.jpeg";
import Finance9 from "../../assets/images/finance9.jpg";
import Finance10 from "../../assets/images/finance10.webp";
import Finance11 from "../../assets/images/finance11.png";
import Finance12 from "../../assets/images/finance12.jpg";
import Finance13 from "../../assets/images/finance13.webp";
import Finance14 from "../../assets/images/finance14.webp";
import Finance15 from "../../assets/images/finance15.jpg";
import Finance16 from "../../assets/images/finance16.jpg";
import Finance17 from "../../assets/images/finance17.jpg";
import Finance18 from "../../assets/images/finance18.webp";
import Finance19 from "../../assets/images/finance19.jpg";
import Finance20 from "../../assets/images/finance20.jpg";
import Finance21 from "../../assets/images/finance21.png";
import Finance22 from "../../assets/images/finance22.png";
import Finance23 from "../../assets/images/finance23.jpg";
import Finance24 from "../../assets/images/finance24.webp";

// Oil and gas Image
import Oil1 from "../../assets/images/oil1.jpg";
import Oil2 from "../../assets/images/oil2.jpg";
import Oil3 from "../../assets/images/oil3.jpeg";
import Oil4 from "../../assets/images/oil4.jpg";
import Oil5 from "../../assets/images/oil5.jpg";
import Oil6 from "../../assets/images/oil6.jpg";
import Oil7 from "../../assets/images/oil7.jpg";

// Human Resources Image
import HumanResourse1 from "../../assets/images/humanRe1.jpg";
import HumanResourse2 from "../../assets/images/humanRe2.jpg";
import HumanResourse3 from "../../assets/images/humanRe3.jpg";
import HumanResourse4 from "../../assets/images/humanRe4.jpeg";
import HumanResourse5 from "../../assets/images/humanre5.png";
import HumanResourse6 from "../../assets/images/humanRe6.jpeg";
import HumanResourse7 from "../../assets/images/humanRe7.jpeg";
import HumanResourse8 from "../../assets/images/humanRe8.png";
import HumanResourse9 from "../../assets/images/humanRe9.webp";
import HumanResourse10 from "../../assets/images/humanRe10.jpeg";
import HumanResourse11 from "../../assets/images/humanRe11.webp";
import HumanResourse12 from "../../assets/images/humanRe12.jpg";

// Law Image
import Law1 from "../../assets/images/law1.png";
import Law2 from "../../assets/images/law2.jpg";
import Law3 from "../../assets/images/law3.jpg";
import Law4 from "../../assets/images/law4.jpg";
import Law5 from "../../assets/images/law5.jpg";
import Law6 from "../../assets/images/law6.jpg";
import Law7 from "../../assets/images/law7.jpg";
import Law8 from "../../assets/images/law8.png";
import Law9 from "../../assets/images/law9.jpg";

// Operations Management Images
import OperationM1 from "../../assets/images/oManagement1.png";
import OperationM2 from "../../assets/images/oManagement2.avif";
import OperationM3 from "../../assets/images/oManagement3.png";
import OperationM4 from "../../assets/images/oManagement4.png";
import OperationM5 from "../../assets/images/oManagement5.jpg";
import OperationM6 from "../../assets/images/oManagement6.jpg";
import OperationM7 from "../../assets/images/oManagement7.png";
import OperationM8 from "../../assets/images/oManagement8.jpg";
import OperationM9 from "../../assets/images/oManagement9.svg";
import OperationM10 from "../../assets/images/oManagement10.webp";
import OperationM11 from "../../assets/images/oManagement11.png";
import OperationM12 from "../../assets/images/oManagement12.webp";
import OperationM13 from "../../assets/images/oManagement13.png";
import OperationM14 from "../../assets/images/oManagement14.webp";
import OperationM15 from "../../assets/images/oManagement15.png";
import OperationM16 from "../../assets/images/oManagement16.jpg";
import OperationM17 from "../../assets/images/oManagement17.jpeg";
import OperationM18 from "../../assets/images/oManagement18.png";
import OperationM19 from "../../assets/images/oManagement19.jpg";
import OperationM20 from "../../assets/images/oManagement20.png";

// Secretarial Pratices Images
import Sskills1 from "../../assets/images/mSkills1.jpg";
import Sskills2 from "../../assets/images/mSkills2.webp";
import Sskills3 from "../../assets/images/mSkills3.jpg";

// Sales Images
import Sales1 from "../../assets/images/sales1.jpg";
import Sales2 from "../../assets/images/sales2.png";
import Sales3 from "../../assets/images/sales3.png";

export const Courses = [
  //Management Skills

  {
    id: 1,
    image: AdvancedManagement1,
    title: "management1Title",
    desc: "management1Desc",
    WhoShouldAttend: [
      "management1WhoShouldAttend",
      "management1WhoShouldAttend1",
      "management1WhoShouldAttend2",
      "management1WhoShouldAttend3",
      "management1WhoShouldAttend4",
      "management1WhoShouldAttend5",
      "management1WhoShouldAttend6",
      "management1WhoShouldAttend7",
      "management1WhoShouldAttend8",
      "management1WhoShouldAttend9",
    ],
    courseExpectation: [
      "management1courseExpectation1",
      "management1courseExpectation2",
      "management1courseExpectation3",
      "management1courseExpectation4",
      "management1courseExpectation5",
      "management1courseExpectation6",
      "management1courseExpectation7",
    ],
    courseContent: [
      {
        title: "management1courseContent1title",
        contents: [
          "management1courseContent1Desc1",
          "management1courseContent1Desc2",
          "management1courseContent1Desc3",
          "management1courseContent1Desc4",
          "management1courseContent1Desc5",
        ],
      },
      {
        title: "management1courseContent2title",
        contents: [
          "management1courseContent2Desc1",
          "management1courseContent2Desc2",
          "management1courseContent2Desc3",
          "management1courseContent2Desc4",
          "management1courseContent2Desc5",
        ],
      },
      {
        title: "management1courseContent3title",
        contents: [
          "management1courseContent3Desc1",
          "management1courseContent3Desc2",
          "management1courseContent3Desc3",
          "management1courseContent3Desc4",
          "management1courseContent3Desc5",
        ],
      },
      {
        title: "management1courseContent4title",
        contents: [
          "management1courseContent4Desc1",
          "management1courseContent4Desc2",
          "management1courseContent4Desc3",
          "management1courseContent4Desc4",
          "management1courseContent4Desc5",
        ],
      },
      {
        title: "management1courseContent5title",
        contents: [
          "management1courseContent5Desc1",
          "management1courseContent5Desc2",
          "management1courseContent5Desc3",
          "management1courseContent5Desc4",
          "management1courseContent5Desc5",
          "management1courseContent5Desc6",
        ],
      },
      {
        title: "management1courseContent6title",
        contents: [
          "management1courseContent6Desc1",
          "management1courseContent6Desc2",
          "management1courseContent6Desc3",
          "management1courseContent6Desc4",
        ],
      },
      {
        title: "management1courseContent7title",
        contents: [
          "management1courseContent7Desc1",
          "management1courseContent7Desc2",
          "management1courseContent7Desc3",
          "management1courseContent7Desc4",
          "management1courseContent7Desc5",
        ],
      },
      {
        title: "management1courseContent8title",
        contents: [
          "management1courseContent8Desc1",
          "management1courseContent8Desc2",
          "management1courseContent8Desc3",
          "management1courseContent8Desc4",
        ],
      },
      {
        title: "management1courseContent9title",
        contents: [
          "management1courseContent9Desc1",
          "management1courseContent9Desc2",
          "management1courseContent9Desc3",
          "management1courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA01",
  },
  {
    id: 2,
    image: AdvancedManagement2,
    title: "management2Title",
    desc: "management2Desc",
    WhoShouldAttend: [
      "management2WhoShouldAttend1",
      "management2WhoShouldAttend2",
      "management2WhoShouldAttend3",
      "management2WhoShouldAttend4",
      "management2WhoShouldAttend5",
      "management2WhoShouldAttend6",
      "management2WhoShouldAttend7",
      "management2WhoShouldAttend8",
      "management2WhoShouldAttend9",
    ],
    courseExpectation: [
      "management2courseExpectation1",
      "management2courseExpectation2",
      "management2courseExpectation3",
      "management2courseExpectation4",
      "management2courseExpectation5",
      "management2courseExpectation6",
      "management2courseExpectation7",
    ],
    courseContent: [
      {
        title: "management2courseContent1title",
        contents: [
          "management2courseContent1Desc1",
          "management2courseContent1Desc2",
          "management2courseContent1Desc3",
          "management2courseContent1Desc4",
          "management2courseContent1Desc5",
          "management2courseContent1Desc6",
        ],
      },
      {
        title: "management2courseContent2title",
        contents: [
          "management2courseContent2Desc1",
          "management2courseContent2Desc2",
          "management2courseContent2Desc3",
          "management2courseContent2Desc4",
          "management2courseContent2Desc5",
        ],
      },
      {
        title: "management2courseContent3title",
        contents: [
          "management2courseContent3Desc1",
          "management2courseContent3Desc2",
          "management2courseContent3Desc3",
          "management2courseContent3Desc4",
          "management2courseContent3Desc5",
        ],
      },
      {
        title: "management2courseContent4title",
        contents: [
          "management2courseContent4Desc1",
          "management2courseContent4Desc2",
          "management2courseContent4Desc3",
          "management2courseContent4Desc4",
          "management2courseContent4Desc5",
        ],
      },
      {
        title: "management2courseContent5title",
        contents: [
          "management2courseContent5Desc1",
          "management2courseContent5Desc2",
          "management2courseContent5Desc3",
          "management2courseContent5Desc4",
          "management2courseContent5Desc5",
          "management2courseContent5Desc6",
        ],
      },
      {
        title: "management2courseContent6title",
        contents: [
          "management2courseContent6Desc1",
          "management2courseContent6Desc2",
          "management2courseContent6Desc3",
          "management2courseContent6Desc4",
          "management2courseContent6Desc5",
        ],
      },
      {
        title: "management2courseContent7title",
        contents: [
          "management2courseContent7Desc1",
          "management2courseContent7Desc2",
          "management2courseContent7Desc3",
          "management2courseContent7Desc4",
          "management2courseContent7Desc5",
        ],
      },
      {
        title: "management2courseContent8title",
        contents: [
          "management2courseContent8Desc1",
          "management2courseContent8Desc2",
          "management2courseContent8Desc3",
          "management2courseContent8Desc4",
          "management2courseContent8Desc5",
        ],
      },
      {
        title: "management2courseContent9title",
        contents: [
          "management2courseContent9Desc1",
          "management2courseContent9Desc2",
          "management2courseContent9Desc3",
          "management2courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA02",
  },
  {
    id: 3,
    image: AdvancedManagement3,
    title: "management3Title",
    desc: "management3Desc",
    WhoShouldAttend: [
      "management3WhoShouldAttend1",
      "management3WhoShouldAttend2",
      "management3WhoShouldAttend3",
      "management3WhoShouldAttend4",
      "management3WhoShouldAttend5",
      "management3WhoShouldAttend6",
      "management3WhoShouldAttend7",
      "management3WhoShouldAttend8",
      "management3WhoShouldAttend9",
      "management3WhoShouldAttend10",
      "management3WhoShouldAttend11",
    ],
    courseExpectation: [
      "management3courseExpectation1",
      "management3courseExpectation2",
      "management3courseExpectation3",
      "management3courseExpectation4",
      "management3courseExpectation5",
      "management3courseExpectation6",
      "management3courseExpectation7",
      "management3courseExpectation8",
      "management3courseExpectation9",
    ],
    courseContent: [
      {
        title: "management3courseContent1title",
        contents: [
          "management3courseContent1Desc1",
          "management3courseContent1Desc2",
          "management3courseContent1Desc3",
          "management3courseContent1Desc4",
          "management3courseContent1Desc5",
        ],
      },
      {
        title: "management3courseContent2title",
        contents: [
          "management3courseContent2Desc1",
          "management3courseContent2Desc2",
          "management3courseContent2Desc3",
          "management3courseContent2Desc4",
        ],
      },
      {
        title: "management3courseContent3title",
        contents: [
          "management3courseContent3Desc1",
          "management3courseContent3Desc2",
          "management3courseContent3Desc3",
          "management3courseContent3Desc4",
        ],
      },
      {
        title: "management3courseContent4title",
        contents: [
          "management3courseContent4Desc1",
          "management3courseContent4Desc2",
          "management3courseContent4Desc3",
          "management3courseContent4Desc4",
        ],
      },
      {
        title: "management3courseContent5title",
        contents: [
          "management3courseContent5Desc1",
          "management3courseContent5Desc2",
          "management3courseContent5Desc3",
          "management3courseContent5Desc4",
        ],
      },
      {
        title: "management3courseContent6title",
        contents: [
          "management3courseContent6Desc1",
          "management3courseContent6Desc2",
          "management3courseContent6Desc3",
          "management3courseContent6Desc4",
        ],
      },
      {
        title: "management3courseContent7title",
        contents: [
          "management3courseContent7Desc1",
          "management3courseContent7Desc2",
          "management3courseContent7Desc3",
          "management3courseContent7Desc4",
        ],
      },
      {
        title: "management3courseContent8title",
        contents: [
          "management3courseContent8Desc1",
          "management3courseContent8Desc2",
          "management3courseContent8Desc3",
          "management3courseContent8Desc4",
        ],
      },
      {
        title: "management3courseContent9title",
        contents: [
          "management3courseContent9Desc1",
          "management3courseContent9Desc2",
          "management3courseContent9Desc3",
          "management3courseContent9Desc4",
        ],
      },
      {
        title: "management3courseContent10title",
        contents: [
          "management3courseContent10Desc1",
          "management3courseContent10Desc2",
          "management3courseContent10Desc3",
          "management3courseContent10Desc4",
        ],
      },
      {
        title: "management3courseContent11title",
        contents: [
          "management3courseContent11Desc1",
          "management3courseContent11Desc2",
          "management3courseContent11Desc3",
          "management3courseContent11Desc4",
        ],
      },
      {
        title: "management3courseContent12title",
        contents: [
          "management3courseContent12Desc1",
          "management3courseContent12Desc2",
          "management3courseContent12Desc3",
          "management3courseContent12Desc4",
        ],
      },
      {
        title: "management3courseContent13title",
        contents: [
          "management3courseContent13Desc1",
          "management3courseContent13Desc2",
          "management3courseContent13Desc3",
          "management3courseContent13Desc4",
        ],
      },
      {
        title: "management3courseContent14title",
        contents: [
          "management3courseContent14Desc1",
          "management3courseContent14Desc2",
          "management3courseContent14Desc3",
          "management3courseContent14Desc4",
        ],
      },
    ],
    courseFess: 7940,
    category: "Management",
    courseCode: "MA03",
  },
  {
    id: 4,
    image: AdvancedManagement4,
    title: "management4Title",
    desc: "management4Desc",
    WhoShouldAttend: [
      "management4WhoShouldAttend1",
      "management4WhoShouldAttend2",
      "management4WhoShouldAttend3",
    ],
    courseExpectation: [
      "management4courseExpectation1",
      "management4courseExpectation2",
      "management4courseExpectation3",
      "management4courseExpectation4",
      "management4courseExpectation5",
      "management4courseExpectation6",
      "management4courseExpectation7",
    ],
    courseContent: [
      {
        title: "management4courseContent1title",
        contents: [
          "management4courseContent1Desc1",
          "management4courseContent1Desc2",
          "management4courseContent1Desc3",
          "management4courseContent1Desc4",
          "management4courseContent1Desc5",
        ],
      },
      {
        title: "management4courseContent2title",
        contents: [
          "management4courseContent2Desc1",
          "management4courseContent2Desc2",
          "management4courseContent2Desc3",
          "management4courseContent2Desc4",
        ],
      },
      {
        title: "management4courseContent3title",
        contents: [
          "management4courseContent3Desc1",
          "management4courseContent3Desc2",
          "management4courseContent3Desc3",
          "management4courseContent3Desc4",
          "management4courseContent3Desc5",
          "management4courseContent3Desc6",
        ],
      },
      {
        title: "management4courseContent4title",
        contents: [
          "management4courseContent4Desc1",
          "management4courseContent4Desc2",
          "management4courseContent4Desc3",
          "management4courseContent4Desc4",
        ],
      },
      {
        title: "management4courseContent5title",
        contents: [
          "management4courseContent5Desc1",
          "management4courseContent5Desc2",
          "management4courseContent5Desc3",
          "management4courseContent5Desc4",
          "management4courseContent5Desc5",
        ],
      },
      {
        title: "management4courseContent6title",
        contents: [
          "management4courseContent6Desc1",
          "management4courseContent6Desc2",
          "management4courseContent6Desc3",
        ],
      },
      {
        title: "management4courseContent7title",
        contents: [
          "management4courseContent7Desc1",
          "management4courseContent7Desc2",
          "management4courseContent7Desc3",
        ],
      },
      {
        title: "management4courseContent8title",
        contents: [
          "management4courseContent8Desc1",
          "management4courseContent8Desc2",
          "management4courseContent8Desc3",
        ],
      },
      {
        title: "management4courseContent9title",
        contents: [
          "management4courseContent9Desc1",
          "management4courseContent9Desc2",
          "management4courseContent9Desc3",
          "management4courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA04",
  },
  {
    id: 5,
    image: AdvancedManagement5,
    title: "management5Title",
    desc: "management5Desc",
    WhoShouldAttend: [
      "management5WhoShouldAttend1",
      "management5WhoShouldAttend2",
      "management5WhoShouldAttend3",
    ],
    courseExpectation: [
      "management5courseExpectation1",
      "management5courseExpectation2",
      "management5courseExpectation3",
      "management5courseExpectation4",
    ],
    courseContent: [
      {
        title: "management5courseContent1title",
        contents: [
          "management5courseContent1Desc1",
          "management5courseContent1Desc2",
          "management5courseContent1Desc3",
          "management5courseContent1Desc4",
          "management5courseContent1Desc5",
          "management5courseContent1Desc6",
          "management5courseContent1Desc7",
        ],
      },
      {
        title: "management5courseContent2title",
        contents: [
          "management5courseContent2Desc1",
          "management5courseContent2Desc2",
          "management5courseContent2Desc3",
          "management5courseContent2Desc4",
          "management5courseContent2Desc5",
        ],
      },
      {
        title: "management5courseContent3title",
        contents: [
          "management5courseContent3Desc1",
          "management5courseContent3Desc2",
          "management5courseContent3Desc3",
          "management5courseContent3Desc4",
        ],
      },
      {
        title: "management5courseContent4title",
        contents: [
          "management5courseContent4Desc1",
          "management5courseContent4Desc2",
          "management5courseContent4Desc3",
          "management5courseContent4Desc4",
          "management5courseContent4Desc5",
        ],
      },
      {
        title: "management5courseContent5title",
        contents: [
          "management5courseContent5Desc1",
          "management5courseContent5Desc2",
          "management5courseContent5Desc3",
          "management5courseContent5Desc4",
          "management5courseContent5Desc5",
        ],
      },
      {
        title: "management5courseContent6title",
        contents: [
          "management5courseContent6Desc1",
          "management5courseContent6Desc2",
          "management5courseContent6Desc3",
          "management5courseContent6Desc4",
          "management5courseContent6Desc5",
          "management5courseContent6Desc6",
        ],
      },
      {
        title: "management5courseContent7title",
        contents: [
          "management5courseContent7Desc1",
          "management5courseContent7Desc2",
          "management5courseContent7Desc3",
          "management5courseContent7Desc4",
          "management5courseContent7Desc5",
        ],
      },
      {
        title: "management5courseContent8title",
        contents: [
          "management5courseContent8Desc1",
          "management5courseContent8Desc2",
          "management5courseContent8Desc3",
          "management5courseContent8Desc4",
          "management5courseContent8Desc5",
        ],
      },
      {
        title: "management5courseContent9title",
        contents: [
          "management5courseContent9Desc1",
          "management5courseContent9Desc2",
          "management5courseContent9Desc3",
          "management5courseContent9Desc4",
          "management5courseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA05",
  },
  {
    id: 6,
    image: AdvancedManagement6,
    title: "management6Title",
    desc: "management6Desc",
    WhoShouldAttend: [
      "management6WhoShouldAttend1",
      "management6WhoShouldAttend2",
      "management6WhoShouldAttend3",
      "management6WhoShouldAttend4",
      "management6WhoShouldAttend5",
      "management6WhoShouldAttend6",
      "management6WhoShouldAttend7",
      "management6WhoShouldAttend8",
      "management6WhoShouldAttend9",
      "management6WhoShouldAttend10",
      "management6WhoShouldAttend11",
      "management6WhoShouldAttend12",
      "management6WhoShouldAttend13",
      "management6WhoShouldAttend14",
    ],
    courseExpectation: [
      "management6courseExpectation1",
      "management6courseExpectation2",
      "management6courseExpectation3",
      "management6courseExpectation4",
      "management6courseExpectation5",
      "management6courseExpectation6",
      "management6courseExpectation7",
      "management6courseExpectation8",
      "management6courseExpectation9",
    ],
    courseContent: [
      {
        title: "management6courseContent1title",
        contents: [
          "management6courseContent1Desc1",
          "management6courseContent1Desc2",
          "management6courseContent1Desc3",
          "management6courseContent1Desc4",
        ],
      },
      {
        title: "management6courseContent2title",
        contents: [
          "management6courseContent2Desc1",
          "management6courseContent2Desc2",
          "management6courseContent2Desc3",
          "management6courseContent2Desc4",
        ],
      },
      {
        title: "management6courseContent3title",
        contents: [
          "management6courseContent3Desc1",
          "management6courseContent3Desc2",
          "management6courseContent3Desc3",
          "management6courseContent3Desc4",
        ],
      },
      {
        title: "management6courseContent4title",
        contents: [
          "management6courseContent4Desc1",
          "management6courseContent4Desc2",
          "management6courseContent4Desc3",
          "management6courseContent4Desc4",
        ],
      },
      {
        title: "management6courseContent5title",
        contents: [
          "management6courseContent5Desc1",
          "management6courseContent5Desc2",
          "management6courseContent5Desc3",
          "management6courseContent5Desc4",
        ],
      },
      {
        title: "management6courseContent6title",
        contents: [
          "management6courseContent6Desc1",
          "management6courseContent6Desc2",
          "management6courseContent6Desc3",
          "management6courseContent6Desc4",
        ],
      },
      {
        title: "management6courseContent7title",
        contents: [
          "management6courseContent7Desc1",
          "management6courseContent7Desc2",
          "management6courseContent7Desc3",
          "management6courseContent7Desc4",
        ],
      },
      {
        title: "management6courseContent8title",
        contents: [
          "management6courseContent8Desc1",
          "management6courseContent8Desc2",
          "management6courseContent8Desc3",
          "management6courseContent8Desc4",
        ],
      },
      {
        title: "management6courseContent9title",
        contents: [
          "management6courseContent9Desc1",
          "management6courseContent9Desc2",
          "management6courseContent9Desc3",
          "management6courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA06",
  },
  {
    id: 7,
    image: AdvancedManagement7,
    title: "management7Title",
    desc: "management7Desc",
    WhoShouldAttend: [
      "management7WhoShouldAttend1",
      "management7WhoShouldAttend2",
      "management7WhoShouldAttend3",
      "management7WhoShouldAttend4",
      "management7WhoShouldAttend5",
      "management7WhoShouldAttend6",
      "management7WhoShouldAttend7",
      "management7WhoShouldAttend8",
    ],
    courseExpectation: [
      "management7courseExpectation1",
      "management7courseExpectation2",
      "management7courseExpectation3",
      "management7courseExpectation4",
      "management7courseExpectation5",
    ],
    courseContent: [
      {
        title: "management7courseContent1title",
        contents: [
          "management7courseContent1Desc1",
          "management7courseContent1Desc2",
          "management7courseContent1Desc3",
          "management7courseContent1Desc4",
          "management7courseContent1Desc5",
        ],
      },
      {
        title: "management7courseContent2title",
        contents: [
          "management7courseContent2Desc1",
          "management7courseContent2Desc2",
          "management7courseContent2Desc3",
          "management7courseContent2Desc4",
          "management7courseContent2Desc5",
          "management7courseContent2Desc6",
        ],
      },
      {
        title: "management7courseContent3title",
        contents: [
          "management7courseContent3Desc1",
          "management7courseContent3Desc2",
          "management7courseContent3Desc3",
          "management7courseContent3Desc4",
          "management7courseContent3Desc5",
        ],
      },
      {
        title: "management7courseContent4title",
        contents: [
          "management7courseContent4Desc1",
          "management7courseContent4Desc2",
          "management7courseContent4Desc3",
          "management7courseContent4Desc4",
          "management7courseContent4Desc5",
        ],
      },
      {
        title: "management7courseContent5title",
        contents: [
          "management7courseContent5Desc1",
          "management7courseContent5Desc2",
          "management7courseContent5Desc3",
          "management7courseContent5Desc4",
          "management7courseContent5Desc5",
          "management7courseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA07",
  },
  {
    id: 8,
    image: AdvancedManagement8,
    title: "management8Title",
    desc: "management8Desc",
    WhoShouldAttend: [
      "management8WhoShouldAttend1",
      "management8WhoShouldAttend2",
      "management8WhoShouldAttend3",
      "management8WhoShouldAttend4",
      "management8WhoShouldAttend5",
      "management8WhoShouldAttend6",
    ],
    courseExpectation: [
      "management8courseExpectation1",
      "management8courseExpectation2",
      "management8courseExpectation3",
      "management8courseExpectation4",
      "management8courseExpectation5",
    ],
    courseContent: [
      {
        title: "management8courseContent1title",
        contents: [
          "management8courseContent1Desc1",
          "management8courseContent1Desc2",
          "management8courseContent1Desc3",
          "management8courseContent1Desc4",
          "management8courseContent1Desc5",
          "management8courseContent1Desc6",
        ],
      },
      {
        title: "management8courseContent2title",
        contents: [
          "management8courseContent2Desc1",
          "management8courseContent2Desc2",
          "management8courseContent2Desc3",
          "management8courseContent2Desc4",
          "management8courseContent2Desc5",
        ],
      },
      {
        title: "management8courseContent3title",
        contents: [
          "management8courseContent3Desc1",
          "management8courseContent3Desc2",
          "management8courseContent3Desc3",
          "management8courseContent3Desc4",
          "management8courseContent3Desc5",
        ],
      },
      {
        title: "management8courseContent4title",
        contents: [
          "management8courseContent4Desc1",
          "management8courseContent4Desc2",
          "management8courseContent4Desc3",
          "management8courseContent4Desc4",
          "management8courseContent4Desc5",
        ],
      },
      {
        title: "management8courseContent5title",
        contents: [
          "management8courseContent5Desc1",
          "management8courseContent5Desc2",
          "management8courseContent5Desc3",
          "management8courseContent5Desc4",
          "management8courseContent5Desc5",
          "management8courseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA08",
  },
  {
    id: 9,
    image: AdvancedManagement9,
    title: "management9Title",
    desc: "management9Desc",
    WhoShouldAttend: [
      "management9WhoShouldAttend1",
      "management9WhoShouldAttend2",
    ],
    courseExpectation: [
      "management9courseExpectation1",
      "management9courseExpectation2",
      "management9courseExpectation3",
      "management9courseExpectation4",
      "management9courseExpectation5",
      "management9courseExpectation6",
    ],
    courseContent: [
      {
        title: "management9courseContent1title",
        contents: [
          "management9courseContent1Desc1",
          "management9courseContent1Desc2",
          "management9courseContent1Desc3",
          "management9courseContent1Desc4",
        ],
      },
      {
        title: "management9courseContent2title",
        contents: [
          "management9courseContent2Desc1",
          "management9courseContent2Desc2",
          "management9courseContent2Desc3",
          "management9courseContent2Desc4",
          "management9courseContent2Desc5",
        ],
      },
      {
        title: "management9courseContent3title",
        contents: [
          "management9courseContent3Desc1",
          "management9courseContent3Desc2",
          "management9courseContent3Desc3",
          "management9courseContent3Desc4",
        ],
      },
      {
        title: "management9courseContent4title",
        contents: [
          "management9courseContent4Desc1",
          "management9courseContent4Desc2",
          "management9courseContent4Desc3",
          "management9courseContent4Desc4",
        ],
      },
      {
        title: "management9courseContent5title",
        contents: [
          "management9courseContent5Desc1",
          "management9courseContent5Desc2",
          "management9courseContent5Desc3",
          "management9courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA09",
  },
  {
    id: 10,
    image: AdvancedManagement10,
    title: "management10Title",
    desc: "",
    WhoShouldAttend: [
      "management10WhoShouldAttend1",
      "management10WhoShouldAttend2",
      "management10WhoShouldAttend3",
      "management10WhoShouldAttend4",
      "management10WhoShouldAttend5",
      "management10WhoShouldAttend6",
      "management10WhoShouldAttend7",
    ],
    courseExpectation: [
      "management10courseExpectation1",
      "management10courseExpectation2",
      "management10courseExpectation3",
      "management10courseExpectation4",
      "management10courseExpectation5",
    ],
    courseContent: [
      {
        title: "management10courseContent1title",
        contents: [
          "management10courseContent1Desc1",
          "management10courseContent1Desc2",
          "management10courseContent1Desc3",
          "management10courseContent1Desc4",
          "management10courseContent1Desc5",
        ],
      },
      {
        title: "management10courseContent2title",
        contents: [
          "management10courseContent2Desc1",
          "management10courseContent2Desc2",
          "management10courseContent2Desc3",
          "management10courseContent2Desc4",
          "management10courseContent2Desc5",
        ],
      },
      {
        title: "management10courseContent3title",
        contents: [
          "management10courseContent3Desc1",
          "management10courseContent3Desc2",
          "management10courseContent3Desc3",
          "management10courseContent3Desc4",
          "management10courseContent3Desc5",
          "management10courseContent3Desc6",
        ],
      },
      {
        title: "management10courseContent4title",
        contents: [
          "management10courseContent4Desc1",
          "management10courseContent4Desc2",
          "management10courseContent4Desc3",
          "management10courseContent4Desc4",
        ],
      },
      {
        title: "management10courseContent5title",
        contents: [
          "management10courseContent5Desc1",
          "management10courseContent5Desc2",
          "management10courseContent5Desc3",
          "management10courseContent5Desc4",
          "management10courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA10",
  },
  {
    id: 11,
    image: AdvancedManagement11,
    title: "management11Title",
    desc: "management11Desc",
    WhoShouldAttend: [
      "management11WhoShouldAttend1",
      "management11WhoShouldAttend2",
      "management11WhoShouldAttend3",
      "management11WhoShouldAttend4",
      "management11WhoShouldAttend5",
      "management11WhoShouldAttend6",
      "management11WhoShouldAttend7",
      "management11WhoShouldAttend8",
      "management11WhoShouldAttend9",
      "management11WhoShouldAttend10",
      "management11WhoShouldAttend11",
      "management11WhoShouldAttend12",
      "management11WhoShouldAttend13",
      "management11WhoShouldAttend14",
    ],
    courseExpectation: [
      "management11courseExpectation1",
      "management11courseExpectation2",
      "management11courseExpectation3",
    ],
    courseContent: [
      {
        title: "management11courseContent1title",
        contents: [
          "management11courseContent1Desc1",
          "management11courseContent1Desc2",
          "management11courseContent1Desc3",
          "management11courseContent1Desc4",
        ],
      },
      {
        title: "management11courseContent2title",
        contents: [
          "management11courseContent2Desc1",
          "management11courseContent2Desc2",
          "management11courseContent2Desc3",
          "management11courseContent2Desc4",
        ],
      },
      {
        title: "management11courseContent3title",
        contents: [
          "management11courseContent3Desc1",
          "management11courseContent3Desc2",
          "management11courseContent3Desc3",
          "management11courseContent3Desc4",
        ],
      },
    ],
    courseFess: 3972,
    category: "Management",
    courseCode: "MA11",
  },
  {
    id: 12,
    image: AdvancedManagement12,
    title: "management12Title",
    desc: "management12Desc",
    WhoShouldAttend: [
      "management12WhoShouldAttend1",
      "management12WhoShouldAttend2",
      "management12WhoShouldAttend3",
      "management12WhoShouldAttend4",
      "management12WhoShouldAttend5",
      "management12WhoShouldAttend6",
      "management12WhoShouldAttend7",
      "management12WhoShouldAttend8",
    ],
    courseExpectation: [
      "management12courseExpectation1",
      "management12courseExpectation2",
      "management12courseExpectation3",
      "management12courseExpectation4",
      "management12courseExpectation5",
    ],
    courseContent: [
      {
        title: "management12courseContent1title",
        contents: [
          "management12courseContent1Desc1",
          "management12courseContent1Desc2",
          "management12courseContent1Desc3",
          "management12courseContent1Desc4",
        ],
      },
      {
        title: "management12courseContent2title",
        contents: [
          "management12courseContent2Desc1",
          "management12courseContent2Desc2",
          "management12courseContent2Desc3",
          "management12courseContent2Desc4",
        ],
      },
      {
        title: "management12courseContent3title",
        contents: [
          "management12courseContent3Desc1",
          "management12courseContent3Desc2",
          "management12courseContent3Desc3",
          "management12courseContent3Desc4",
          "management12courseContent3Desc5",
        ],
      },
      {
        title: "management12courseContent4title",
        contents: [
          "management12courseContent4Desc1",
          "management12courseContent4Desc2",
          "management12courseContent4Desc3",
          "management12courseContent4Desc4",
        ],
      },
      {
        title: "management12courseContent5title",
        contents: [
          "management12courseContent5Desc1",
          "management12courseContent5Desc2",
          "management12courseContent5Desc3",
          "management12courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA12",
  },
  {
    id: 13,
    image: AdvancedManagement13,
    title: "management13Title",
    desc: "management13Desc",
    WhoShouldAttend: [
      "management13WhoShouldAttend1",
      "management13WhoShouldAttend2",
      "management13WhoShouldAttend3",
    ],
    courseExpectation: [
      "management13courseExpectation1",
      "management13courseExpectation2",
      "management13courseExpectation3",
      "management13courseExpectation4",
      "management13courseExpectation5",
      "management13courseExpectation6",
    ],
    courseContent: [
      {
        title: "management13courseContent1title",
        contents: [
          "management13courseContent1Desc1",
          "management13courseContent1Desc2",
          "management13courseContent1Desc3",
          "management13courseContent1Desc4",
          "management13courseContent1Desc5",
        ],
      },
      {
        title: "management13courseContent2title",
        contents: [
          "management13courseContent2Desc1",
          "management13courseContent2Desc2",
          "management13courseContent2Desc3",
          "management13courseContent2Desc4",
        ],
      },
      {
        title: "management13courseContent3title",
        contents: [
          "management13courseContent3Desc1",
          "management13courseContent3Desc2",
          "management13courseContent3Desc3",
        ],
      },
      {
        title: "management13courseContent4title",
        contents: [
          "management13courseContent4Desc1",
          "management13courseContent4Desc2",
          "management13courseContent4Desc3",
          "management13courseContent4Desc4",
        ],
      },
      {
        title: "management13courseContent5title",
        contents: [
          "management13courseContent5Desc1",
          "management13courseContent5Desc2",
          "management13courseContent5Desc3",
          "management13courseContent5Desc4",
          "management13courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA13",
  },
  {
    id: 14,
    image: AdvancedManagement14,
    title: "management14Title",
    desc: "management14Desc",
    WhoShouldAttend: [
      "management14WhoShouldAttend1",
      "management14WhoShouldAttend2",
      "management14WhoShouldAttend3",
    ],
    courseExpectation: [
      "management14courseExpectation1",
      "management14courseExpectation2",
      "management14courseExpectation3",
      "management14courseExpectation4",
      "management14courseExpectation5",
      "management14courseExpectation6",
    ],
    courseContent: [
      {
        title: "management14courseContent1title",
        contents: [
          "management14courseContent1Desc1",
          "management14courseContent1Desc2",
          "management14courseContent1Desc3",
          "management14courseContent1Desc4",
          "management14courseContent1Desc5",
        ],
      },
      {
        title: "management14courseContent2title",
        contents: [
          "management14courseContent2Desc1",
          "management14courseContent2Desc2",
          "management14courseContent2Desc3",
        ],
      },
      {
        title: "management14courseContent3title",
        contents: [
          "management14courseContent3Desc1",
          "management14courseContent3Desc2",
          "management14courseContent3Desc3",
        ],
      },
      {
        title: "management14courseContent4title",
        contents: [
          "management14courseContent4Desc1",
          "management14courseContent4Desc2",
          "management14courseContent4Desc3",
        ],
      },
      {
        title: "management14courseContent5title",
        contents: [
          "management14courseContent5Desc1",
          "management14courseContent5Desc2",
          "management14courseContent5Desc3",
          "management14courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA14",
  },
  {
    id: 15,
    image: AdvancedManagement15,
    title: "management15Title",
    desc: "management15Desc",
    WhoShouldAttend: [
      "management15WhoShouldAttend1",
      "management15WhoShouldAttend2",
      "management15WhoShouldAttend3",
      "management15WhoShouldAttend4",
    ],
    courseExpectation: [
      "management15courseExpectation1",
      "management15courseExpectation2",
      "management15courseExpectation3",
    ],
    courseContent: [
      {
        title: "management15courseContent1title",
        contents: [
          "management15courseContent1Desc1",
          "management15courseContent1Desc2",
          "management15courseContent1Desc3",
          "management15courseContent1Desc4",
          "management15courseContent1Desc5",
          "management15courseContent1Desc6",
          "management15courseContent1Desc7",
        ],
      },
      {
        title: "management15courseContent2title",
        contents: [
          "management15courseContent2Desc1",
          "management15courseContent2Desc2",
          "management15courseContent2Desc3",
          "management15courseContent2Desc4",
          "management15courseContent2Desc5",
        ],
      },
      {
        title: "management15courseContent3title",
        contents: [
          "management15courseContent3Desc1",
          "management15courseContent3Desc2",
          "management15courseContent3Desc3",
          "management15courseContent3Desc4",
        ],
      },
      {
        title: "management15courseContent4title",
        contents: [
          "management15courseContent4Desc1",
          "management15courseContent4Desc2",
          "management15courseContent4Desc3",
          "management15courseContent4Desc4",
          "management15courseContent4Desc5",
        ],
      },
      {
        title: "management15courseContent5title",
        contents: [
          "management15courseContent5Desc1",
          "management15courseContent5Desc2",
          "management15courseContent5Desc3",
          "management15courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA15",
  },
  {
    id: 16,
    image: AdvancedManagement16,
    title: "management16Title",
    desc: "management16Desc",
    WhoShouldAttend: [
      "management16WhoShouldAttend1",
      "management16WhoShouldAttend2",
      "management16WhoShouldAttend3",
    ],
    courseExpectation: [
      "management16courseExpectation1",
      "management16courseExpectation2",
      "management16courseExpectation3",
      "management16courseExpectation4",
    ],
    courseContent: [
      {
        title: "management16courseContent1title",
        contents: [
          "management16courseContent1Desc1",
          "management16courseContent1Desc2",
          "management16courseContent1Desc3",
          "management16courseContent1Desc4",
          "management16courseContent1Desc5",
          "management16courseContent1Desc6",
          "management16courseContent1Desc7",
        ],
      },
      {
        title: "management16courseContent2title",
        contents: [
          "management16courseContent2Desc1",
          "management16courseContent2Desc2",
          "management16courseContent2Desc3",
          "management16courseContent2Desc4",
          "management16courseContent2Desc5",
          "management16courseContent2Desc6",
        ],
      },
      {
        title: "management16courseContent3title",
        contents: [
          "management16courseContent3Desc1",
          "management16courseContent3Desc2",
          "management16courseContent3Desc3",
          "management16courseContent3Desc4",
          "management16courseContent3Desc5",
        ],
      },
      {
        title: "management16courseContent4title",
        contents: [
          "management16courseContent4Desc1",
          "management16courseContent4Desc2",
          "management16courseContent4Desc3",
          "management16courseContent4Desc4",
          "management16courseContent4Desc5",
        ],
      },
      {
        title: "management16courseContent5title",
        contents: [
          "management16courseContent5Desc1",
          "management16courseContent5Desc2",
          "management16courseContent5Desc3",
          "management16courseContent5Desc4",
          "management16courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA16",
  },
  {
    id: 17,
    image: AdvancedManagement17,
    title: "management17Title",
    desc: "management17Desc",
    WhoShouldAttend: [
      "management17WhoShouldAttend1",
      "management17WhoShouldAttend2",
      "management17WhoShouldAttend3",
      "management17WhoShouldAttend4",
      "management17WhoShouldAttend5",
      "management17WhoShouldAttend6",
      "management17WhoShouldAttend7",
      "management17WhoShouldAttend8",
      "management17WhoShouldAttend9",
      "management17WhoShouldAttend10",
      "management17WhoShouldAttend11",
      "management17WhoShouldAttend12",
      "management17WhoShouldAttend13",
      "management17WhoShouldAttend14",
      "management17WhoShouldAttend15",
      "management17WhoShouldAttend16",
    ],
    courseExpectation: [
      "management17courseExpectation1",
      "management17courseExpectation2",
      "management17courseExpectation3",
      "management17courseExpectation4",
      "management17courseExpectation5",
    ],
    courseContent: [
      {
        title: "management17courseContent1title",
        contents: [
          "management17courseContent1Desc1",
          "management17courseContent1Desc2",
          "management17courseContent1Desc3",
          "management17courseContent1Desc4",
        ],
      },
      {
        title: "management17courseContent2title",
        contents: [
          "management17courseContent2Desc1",
          "management17courseContent2Desc2",
          "management17courseContent2Desc3",
          "management17courseContent2Desc4",
        ],
      },
      {
        title: "management17courseContent3title",
        contents: [
          "management17courseContent3Desc1",
          "management17courseContent3Desc2",
          "management17courseContent3Desc3",
          "management17courseContent3Desc4",
        ],
      },
      {
        title: "management17courseContent4title",
        contents: [
          "management17courseContent4Desc1",
          "management17courseContent4Desc2",
          "management17courseContent4Desc3",
          "management17courseContent4Desc4",
        ],
      },
      {
        title: "management17courseContent5title",
        contents: [
          "management17courseContent5Desc1",
          "management17courseContent5Desc2",
          "management17courseContent5Desc3",
          "management17courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA17",
  },
  {
    id: 18,
    image: AdvancedManagement18,
    title: "management18Title",
    desc: "management18Desc",
    WhoShouldAttend: [
      "management18WhoShouldAttend1",
      "management18WhoShouldAttend2",
      "management18WhoShouldAttend3",
      "management18WhoShouldAttend4",
      "management18WhoShouldAttend5",
      "management18WhoShouldAttend6",
      "management18WhoShouldAttend7",
      "management18WhoShouldAttend8",
      "management18WhoShouldAttend9",
      "management18WhoShouldAttend10",
      "management18WhoShouldAttend11",
      "management18WhoShouldAttend12",
      "management18WhoShouldAttend13",
      "management18WhoShouldAttend14",
    ],
    courseExpectation: [
      "management18courseExpectation1",
      "management18courseExpectation2",
      "management18courseExpectation3",
      "management18courseExpectation4",
      "management18courseExpectation5",
    ],
    courseContent: [
      {
        title: "management18courseContent1title",
        contents: [
          "management18courseContent1Desc1",
          "management18courseContent1Desc2",
          "management18courseContent1Desc3",
          "management18courseContent1Desc4",
        ],
      },
      {
        title: "management18courseContent2title",
        contents: [
          "management18courseContent2Desc1",
          "management18courseContent2Desc2",
          "management18courseContent2Desc3",
          "management18courseContent2Desc4",
        ],
      },
      {
        title: "management18courseContent3title",
        contents: [
          "management18courseContent3Desc1",
          "management18courseContent3Desc2",
          "management18courseContent3Desc3",
          "management18courseContent3Desc4",
        ],
      },
      {
        title: "management18courseContent4title",
        contents: [
          "management18courseContent4Desc1",
          "management18courseContent4Desc2",
          "management18courseContent4Desc3",
          "management18courseContent4Desc4",
        ],
      },
      {
        title: "management18courseContent5title",
        contents: [
          "management18courseContent5Desc1",
          "management18courseContent5Desc2",
          "management18courseContent5Desc3",
          "management18courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA18",
  },
  {
    id: 71,
    image: Mskills1,
    title: "management19Title",
    desc: "management19Desc",
    WhoShouldAttend: [
      "management19WhoShouldAttend1",
      "management19WhoShouldAttend2",
      "management19WhoShouldAttend3",
      "management19WhoShouldAttend4",
      "management19WhoShouldAttend5",
      "management19WhoShouldAttend6",
    ],
    courseExpectation: [
      "management19courseExpectation1",
      "management19courseExpectation2",
      "management19courseExpectation3",
      "management19courseExpectation4",
      "management19courseExpectation5",
      "management19courseExpectation6",
      "management19courseExpectation7",
    ],
    courseContent: [
      {
        title: "management19courseContent1title",
        contents: [
          "management19courseContent1Desc1",
          "management19courseContent1Desc2",
          "management19courseContent1Desc3",
          "management19courseContent1Desc4",
        ],
      },
      {
        title: "management19courseContent2title",
        contents: [
          "management19courseContent2Desc1",
          "management19courseContent2Desc2",
          "management19courseContent2Desc3",
          "management19courseContent2Desc4",
        ],
      },
      {
        title: "management19courseContent3title",
        contents: [
          "management19courseContent3Desc1",
          "management19courseContent3Desc2",
          "management19courseContent3Desc3",
          "management19courseContent3Desc4",
        ],
      },
      {
        title: "management19courseContent4title",
        contents: [
          "management19courseContent4Desc1",
          "management19courseContent4Desc2",
          "management19courseContent4Desc3",
          "management19courseContent4Desc4",
        ],
      },
      {
        title: "management19courseContent5title",
        contents: [
          "management19courseContent5Desc1",
          "management19courseContent5Desc2",
          "management19courseContent5Desc3",
          "management19courseContent5Desc4",
        ],
      },
      {
        title: "management19courseContent6title",
        contents: [
          "management19courseContent6Desc1",
          "management19courseContent6Desc2",
          "management19courseContent6Desc3",
          "management19courseContent6Desc4",
        ],
      },
      {
        title: "management19courseContent7title",
        contents: [
          "management19courseContent7Desc1",
          "management19courseContent7Desc2",
          "management19courseContent7Desc3",
          "management19courseContent7Desc4",
        ],
      },
      {
        title: "management19courseContent8title",
        contents: [
          "management19courseContent8Desc1",
          "management19courseContent8Desc2",
          "management19courseContent8Desc3",
          "management19courseContent8Desc4",
        ],
      },
      {
        title: "management19courseContent9title",
        contents: [
          "management19courseContent9Desc1",
          "management19courseContent9Desc2",
          "management19courseContent9Desc3",
          "management19courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA19",
  },
  {
    id: 72,
    image: Mskills2,
    title: "management20Title",
    desc: "management20Desc",
    WhoShouldAttend: [
      "management20WhoShouldAttend1",
      "management20WhoShouldAttend2",
      "management20WhoShouldAttend3",
      "management20WhoShouldAttend4",
      "management20WhoShouldAttend5",
      "management20WhoShouldAttend6",
      "management20WhoShouldAttend7",
      "management20WhoShouldAttend8",
      "management20WhoShouldAttend9",
    ],
    courseExpectation: [
      "management20courseExpectation1",
      "management20courseExpectation2",
      "management20courseExpectation3",
      "management20courseExpectation4",
    ],
    courseContent: [
      {
        title: "management20courseContent1title",
        contents: [
          "management20courseContent1Desc1",
          "management20courseContent1Desc2",
          "management20courseContent1Desc3",
          "management20courseContent1Desc4",
        ],
      },
      {
        title: "management20courseContent2title",
        contents: [
          "management20courseContent2Desc1",
          "management20courseContent2Desc2",
          "management20courseContent2Desc3",
          "management20courseContent2Desc4",
        ],
      },
      {
        title: "management20courseContent3title",
        contents: [
          "management20courseContent3Desc1",
          "management20courseContent3Desc2",
          "management20courseContent3Desc3",
          "management20courseContent3Desc4",
        ],
      },
      {
        title: "management20courseContent4title",
        contents: [
          "management20courseContent4Desc1",
          "management20courseContent4Desc2",
          "management20courseContent4Desc3",
          "management20courseContent4Desc4",
        ],
      },
      {
        title: "management20courseContent5title",
        contents: [
          "management20courseContent5Desc1",
          "management20courseContent5Desc2",
          "management20courseContent5Desc3",
          "management20courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA20",
  },
  {
    id: 73,
    image: Mskills3,
    title: "management21Title",
    desc: "management21Desc",
    WhoShouldAttend: [
      "management21WhoShouldAttend1",
      "management21WhoShouldAttend2",
      "management21WhoShouldAttend3",
      "management21WhoShouldAttend4",
      "management21WhoShouldAttend5",
      "management21WhoShouldAttend6",
      "management21WhoShouldAttend7",
      "management21WhoShouldAttend8",
      "management21WhoShouldAttend9",
    ],
    courseExpectation: [
      "management21courseExpectation1",
      "management21courseExpectation2",
      "management21courseExpectation3",
      "management21courseExpectation4",
    ],
    courseContent: [
      {
        title: "management21courseContent1title",
        contents: [
          "management21courseContent1Desc1",
          "management21courseContent1Desc2",
          "management21courseContent1Desc3",
          "management21courseContent1Desc4",
        ],
      },
      {
        title: "management21courseContent2title",
        contents: [
          "management21courseContent2Desc1",
          "management21courseContent2Desc2",
          "management21courseContent2Desc3",
          "management21courseContent2Desc4",
        ],
      },
      {
        title: "management21courseContent3title",
        contents: [
          "management21courseContent3Desc1",
          "management21courseContent3Desc2",
          "management21courseContent3Desc3",
          "management21courseContent3Desc4",
        ],
      },
      {
        title: "management21courseContent4title",
        contents: [
          "management21courseContent4Desc1",
          "management21courseContent4Desc2",
          "management21courseContent4Desc3",
          "management21courseContent4Desc4",
        ],
      },
      {
        title: "management21courseContent5title",
        contents: [
          "management21courseContent5Desc1",
          "management21courseContent5Desc2",
          "management21courseContent5Desc3",
          "management21courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA21",
  },
  {
    id: 74,
    image: Mskills4,
    title: "management22Title",
    desc: "management22Desc",
    WhoShouldAttend: [
      "management22WhoShouldAttend1",
      "management22WhoShouldAttend2",
      "management22WhoShouldAttend3",
      "management22WhoShouldAttend4",
      "management22WhoShouldAttend5",
      "management22WhoShouldAttend6",
      "management22WhoShouldAttend7",
    ],
    courseExpectation: [
      "management22courseExpectation1",
      "management22courseExpectation2",
      "management22courseExpectation3",
      "management22courseExpectation4",
    ],
    courseContent: [
      {
        title: "management22courseContent1title",
        contents: [
          "management22courseContent1Desc1",
          "management22courseContent1Desc2",
          "management22courseContent1Desc3",
          "management22courseContent1Desc4",
        ],
      },
      {
        title: "management22courseContent2title",
        contents: [
          "management22courseContent2Desc1",
          "management22courseContent2Desc2",
          "management22courseContent2Desc3",
          "management22courseContent2Desc4",
        ],
      },
      {
        title: "management22courseContent3title",
        contents: [
          "management22courseContent3Desc1",
          "management22courseContent3Desc2",
          "management22courseContent3Desc3",
          "management22courseContent3Desc4",
        ],
      },
      {
        title: "management22courseContent4title",
        contents: [
          "management22courseContent4Desc1",
          "management22courseContent4Desc2",
          "management22courseContent4Desc3",
          "management22courseContent4Desc4",
        ],
      },
      {
        title: "management22courseContent5title",
        contents: [
          "management22courseContent5Desc1",
          "management22courseContent5Desc2",
          "management22courseContent5Desc3",
          "management22courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA22",
  },
  {
    id: 75,
    image: Mskills5,
    title: "management23Title",
    desc: "management23Desc",
    WhoShouldAttend: [
      "management23WhoShouldAttend1",
      "management23WhoShouldAttend2",
      "management23WhoShouldAttend3",
      "management23WhoShouldAttend4",
      "management23WhoShouldAttend5",
      "management23WhoShouldAttend6",
    ],
    courseExpectation: [
      "management23courseExpectation1",
      "management23courseExpectation2",
      "management23courseExpectation3",
      "management23courseExpectation4",
    ],
    courseContent: [
      {
        title: "management23courseContent1title",
        contents: [
          "management23courseContent1Desc1",
          "management23courseContent1Desc2",
          "management23courseContent1Desc3",
          "management23courseContent1Desc4",
        ],
      },
      {
        title: "management23courseContent2title",
        contents: [
          "management23courseContent2Desc1",
          "management23courseContent2Desc2",
          "management23courseContent2Desc3",
          "management23courseContent2Desc4",
        ],
      },
      {
        title: "management23courseContent3title",
        contents: [
          "management23courseContent3Desc1",
          "management23courseContent3Desc2",
          "management23courseContent3Desc3",
          "management23courseContent3Desc4",
        ],
      },
      {
        title: "management23courseContent4title",
        contents: [
          "management23courseContent4Desc1",
          "management23courseContent4Desc2",
          "management23courseContent4Desc3",
          "management23courseContent4Desc4",
        ],
      },
      {
        title: "management23courseContent5title",
        contents: [
          "management23courseContent5Desc1",
          "management23courseContent5Desc2",
          "management23courseContent5Desc3",
          "management23courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA23",
  },
  {
    id: 76,
    image: Mskills6,
    title: "management24Title",
    desc: "management24Desc",
    WhoShouldAttend: [
      "management24WhoShouldAttend1",
      "management24WhoShouldAttend2",
      "management24WhoShouldAttend3",
      "management24WhoShouldAttend4",
      "management24WhoShouldAttend5",
      "management24WhoShouldAttend6",
    ],
    courseExpectation: [
      "management24courseExpectation1",
      "management24courseExpectation2",
      "management24courseExpectation3",
      "management24courseExpectation4",
    ],
    courseContent: [
      {
        title: "management24courseContent1title",
        contents: [
          "management24courseContent1Desc1",
          "management24courseContent1Desc2",
          "management24courseContent1Desc3",
          "management24courseContent1Desc4",
        ],
      },
      {
        title: "management24courseContent2title",
        contents: [
          "management24courseContent2Desc1",
          "management24courseContent2Desc2",
          "management24courseContent2Desc3",
          "management24courseContent2Desc4",
        ],
      },
      {
        title: "management24courseContent3title",
        contents: [
          "management24courseContent3Desc1",
          "management24courseContent3Desc2",
          "management24courseContent3Desc3",
          "management24courseContent3Desc4",
        ],
      },
      {
        title: "management24courseContent4title",
        contents: [
          "management24courseContent4Desc1",
          "management24courseContent4Desc2",
          "management24courseContent4Desc3",
          "management24courseContent4Desc4",
        ],
      },
      {
        title: "management24courseContent5title",
        contents: [
          "management24courseContent5Desc1",
          "management24courseContent5Desc2",
          "management24courseContent5Desc3",
          "management24courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA24",
  },
  {
    id: 77,
    image: Mskills7,
    title: "management25Title",
    desc: "management25Desc",
    WhoShouldAttend: [
      "management25WhoShouldAttend1",
      "management25WhoShouldAttend2",
      "management25WhoShouldAttend3",
      "management25WhoShouldAttend4",
      "management25WhoShouldAttend5",
      "management25WhoShouldAttend6",
      "management25WhoShouldAttend7",
      "management25WhoShouldAttend8",
      "management25WhoShouldAttend9",
      "management25WhoShouldAttend10",
    ],
    courseExpectation: [
      "management25courseExpectation1",
      "management25courseExpectation2",
      "management25courseExpectation3",
      "management25courseExpectation4",
      "management25courseExpectation5",
      "management25courseExpectation6",
    ],
    courseContent: [
      {
        title: "management25courseContent1title",
        contents: [
          "management25courseContent1Desc1",
          "management25courseContent1Desc2",
          "management25courseContent1Desc3",
          "management25courseContent1Desc4",
        ],
      },
      {
        title: "management25courseContent2title",
        contents: [
          "management25courseContent2Desc1",
          "management25courseContent2Desc2",
          "management25courseContent2Desc3",
          "management25courseContent2Desc4",
        ],
      },
      {
        title: "management25courseContent3title",
        contents: [
          "management25courseContent3Desc1",
          "management25courseContent3Desc2",
          "management25courseContent3Desc3",
          "management25courseContent3Desc4",
        ],
      },
      {
        title: "management25courseContent4title",
        contents: [
          "management25courseContent4Desc1",
          "management25courseContent4Desc2",
          "management25courseContent4Desc3",
          "management25courseContent4Desc4",
        ],
      },
      {
        title: "management25courseContent5title",
        contents: [
          "management25courseContent5Desc1",
          "management25courseContent5Desc2",
          "management25courseContent5Desc3",
          "management25courseContent5Desc4",
        ],
      },
      {
        title: "management25courseContent6title",
        contents: [
          "management25courseContent6Desc1",
          "management25courseContent6Desc2",
          "management25courseContent6Desc3",
          "management25courseContent6Desc4",
        ],
      },
      {
        title: "management25courseContent7title",
        contents: [
          "management25courseContent7Desc1",
          "management25courseContent7Desc2",
          "management25courseContent7Desc3",
          "management25courseContent7Desc4",
        ],
      },
      {
        title: "management25courseContent8title",
        contents: [
          "management25courseContent8Desc1",
          "management25courseContent8Desc2",
          "management25courseContent8Desc3",
          "management25courseContent8Desc4",
        ],
      },
      {
        title: "management25courseContent9title",
        contents: [
          "management25courseContent9Desc1",
          "management25courseContent9Desc2",
          "management25courseContent9Desc3",
          "management25courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MA25",
  },
  {
    id: 78,
    image: Mskills8,
    title: "management26Title",
    desc: "management26Desc",
    WhoShouldAttend: [
      "management26WhoShouldAttend1",
      "management26WhoShouldAttend2",
      "management26WhoShouldAttend3",
      "management26WhoShouldAttend4",
      "management26WhoShouldAttend5",
      "management26WhoShouldAttend6",
    ],
    courseExpectation: [
      "management26courseExpectation1",
      "management26courseExpectation2",
      "management26courseExpectation3",
      "management26courseExpectation4",
    ],
    courseContent: [
      {
        title: "management26courseContent1title",
        contents: [
          "management26courseContent1Desc1",
          "management26courseContent1Desc2",
          "management26courseContent1Desc3",
          "management26courseContent1Desc4",
        ],
      },
      {
        title: "management26courseContent2title",
        contents: [
          "management26courseContent2Desc1",
          "management26courseContent2Desc2",
          "management26courseContent2Desc3",
          "management26courseContent2Desc4",
        ],
      },
      {
        title: "management26courseContent3title",
        contents: [
          "management26courseContent3Desc1",
          "management26courseContent3Desc2",
          "management26courseContent3Desc3",
          "management26courseContent3Desc4",
        ],
      },
      {
        title: "management26courseContent4title",
        contents: [
          "management26courseContent4Desc1",
          "management26courseContent4Desc2",
          "management26courseContent4Desc3",
          "management26courseContent4Desc4",
        ],
      },
      {
        title: "management26courseContent5title",
        contents: [
          "management26courseContent5Desc1",
          "management26courseContent5Desc2",
          "management26courseContent5Desc3",
          "management26courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MA26",
  },
  //Management Skills

  // Finance
  {
    id: 19,
    image: Finance1,
    title: "finance1Title",
    desc: "finance1Desc",
    WhoShouldAttend: ["finance1WhoShouldAttend"],
    courseExpectation: [
      "finance1courseExpectation1",
      "finance1courseExpectation2",
      "finance1courseExpectation3",
      "finance1courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance1coursecourseContent1Title",
        contents: [
          "finance1coursecourseContent1Desc1",
          "finance1coursecourseContent1Desc2",
          "finance1coursecourseContent1Desc3",
          "finance1coursecourseContent1Desc4",
          "finance1coursecourseContent1Desc5",
          "finance1coursecourseContent1Desc6",
          "finance1coursecourseContent1Desc7",
          "finance1coursecourseContent1Desc8",
          "finance1coursecourseContent1Desc9",
          "finance1coursecourseContent1Desc10",
          "finance1coursecourseContent1Desc11",
          "finance1coursecourseContent1Desc12",
        ],
      },
      {
        title: "finance1coursecourseContent2Title",
        contents: [
          "finance1coursecourseContent2Desc1",
          "finance1coursecourseContent2Desc2",
          "finance1coursecourseContent2Desc3",
          "finance1coursecourseContent2Desc4",
          "finance1coursecourseContent2Desc5",
          "finance1coursecourseContent2Desc6",
          "finance1coursecourseContent2Desc7",
          "finance1coursecourseContent2Desc8",
          "finance1coursecourseContent2Desc9",
          "finance1coursecourseContent2Desc10",
          "finance1coursecourseContent2Desc11",
          "finance1coursecourseContent2Desc12",
        ],
      },
      {
        title: "finance1coursecourseContent3Title",
        contents: [
          "finance1coursecourseContent3Desc1",
          "finance1coursecourseContent3Desc2",
          "finance1coursecourseContent3Desc3",
          "finance1coursecourseContent3Desc4",
          "finance1coursecourseContent3Desc5",
          "finance1coursecourseContent3Desc6",
          "finance1coursecourseContent3Desc7",
          "finance1coursecourseContent3Desc8",
          "finance1coursecourseContent3Desc9",
          "finance1coursecourseContent3Desc10",
          "finance1coursecourseContent3Desc11",
          "finance1coursecourseContent3Desc12",
        ],
      },
      {
        title: "finance1coursecourseContent4Title",
        contents: [
          "finance1coursecourseContent4Desc1",
          "finance1coursecourseContent4Desc2",
          "finance1coursecourseContent4Desc3",
          "finance1coursecourseContent4Desc4",
          "finance1coursecourseContent4Desc5",
          "finance1coursecourseContent4Desc6",
        ],
      },
      {
        title: "finance1coursecourseContent5Title",
        contents: [
          "finance1coursecourseContent5Desc1",
          "finance1coursecourseContent5Desc2",
          "finance1coursecourseContent5Desc3",
          "finance1coursecourseContent5Desc4",
          "finance1coursecourseContent5Desc5",
          "finance1coursecourseContent5Desc6",
          "finance1coursecourseContent5Desc7",
          "finance1coursecourseContent5Desc8",
          "finance1coursecourseContent5Desc9",
          "finance1coursecourseContent5Desc10",
          "finance1coursecourseContent5Desc11",
        ],
      },
      {
        title: "finance1coursecourseContent6Title",
        contents: [
          "finance1coursecourseContent6Desc1",
          "finance1coursecourseContent6Desc2",
          "finance1coursecourseContent6Desc3",
          "finance1coursecourseContent6Desc4",
          "finance1coursecourseContent6Desc5",
          "finance1coursecourseContent6Desc6",
          "finance1coursecourseContent6Desc7",
          "finance1coursecourseContent6Desc18",
        ],
      },
      {
        title: "finance1coursecourseContent7Title",
        contents: [
          "finance1coursecourseContent7Desc1",
          "finance1coursecourseContent7Desc2",
          "finance1coursecourseContent7Desc3",
          "finance1coursecourseContent7Desc4",
          "finance1coursecourseContent7Desc5",
          "finance1coursecourseContent7Desc6",
          "finance1coursecourseContent7Desc7",
        ],
      },
      {
        title: "finance1coursecourseContent8Title",
        contents: [
          "finance1coursecourseContent8Desc1",
          "finance1coursecourseContent8Desc2",
          "finance1coursecourseContent8Desc3",
          "finance1coursecourseContent8Desc4",
          "finance1coursecourseContent8Desc5",
          "finance1coursecourseContent8Desc6",
          "finance1coursecourseContent8Desc7",
          "finance1coursecourseContent8Desc8",
        ],
      },
      {
        title: "finance1coursecourseContent9Title",
        contents: [
          "finance1coursecourseContent9Desc1",
          "finance1coursecourseContent9Desc2",
          "finance1coursecourseContent9Desc3",
          "finance1coursecourseContent9Desc4",
          "finance1coursecourseContent9Desc5",
          "finance1coursecourseContent9Desc6",
          "finance1coursecourseContent9Desc7",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN01",
  },
  {
    id: 20,
    image: Finance2,
    title: "finance2Title",
    desc: "finance2Desc",
    WhoShouldAttend: [
      "finance2WhoShouldAttend1",
      "finance2WhoShouldAttend2",
      "finance2WhoShouldAttend3",
      "finance2WhoShouldAttend4",
      "finance2WhoShouldAttend5",
      "finance2WhoShouldAttend6",
      "finance2WhoShouldAttend7",
      "finance2WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance2courseExpectation1",
      "finance2courseExpectation2",
      "finance2courseExpectation3",
      "finance2courseExpectation4",
      "finance2courseExpectation5",
      "finance2courseExpectation6",
      "finance2courseExpectation7",
    ],
    courseContent: [
      {
        title: "finance2courseContent1title",
        contents: [
          "finance2courseContent1Desc1",
          "finance2courseContent1Desc2",
          "finance2courseContent1Desc3",
          "finance2courseContent1Desc4",
          "finance2courseContent1Desc5",
          "finance2courseContent1Desc6",
        ],
      },
      {
        title: "finance2courseContent2title",
        contents: [
          "finance2courseContent2Desc1",
          "finance2courseContent2Desc2",
          "finance2courseContent2Desc3",
          "finance2courseContent2Desc4",
          "finance2courseContent2Desc5",
        ],
      },
      {
        title: "finance2courseContent3title",
        contents: [
          "finance2courseContent3Desc1",
          "finance2courseContent3Desc2",
          "finance2courseContent3Desc3",
          "finance2courseContent3Desc4",
          "finance2courseContent3Desc5",
        ],
      },
      {
        title: "finance2courseContent4title",
        contents: [
          "finance2courseContent4Desc1",
          "finance2courseContent4Desc2",
          "finance2courseContent4Desc3",
          "finance2courseContent4Desc4",
          "finance2courseContent4Desc5",
        ],
      },
      {
        title: "Mfinance2courseContent5title",
        contents: [
          "finance2courseContent5Desc1",
          "finance2courseContent5Desc2",
          "finance2courseContent5Desc3",
          "finance2courseContent5Desc4",
          "finance2courseContent5Desc5",
        ],
      },
      {
        title: "finance2courseContent6title",
        contents: [
          "finance2courseContent6Desc1",
          "finance2courseContent6Desc2",
          "finance2courseContent6Desc3",
          "finance2courseContent6Desc4",
        ],
      },
      {
        title: "finance2courseContent7title",
        contents: [
          "finance2courseContent7Desc1",
          "finance2courseContent7Desc2",
          "finance2courseContent7Desc3",
          "finance2courseContent7Desc4",
          "finance2courseContent7Desc5",
        ],
      },
      {
        title: "finance2courseContent8title",
        contents: [
          "finance2courseContent8Desc1",
          "finance2courseContent8Desc2",
          "finance2courseContent8Desc3",
          "finance2courseContent8Desc4",
        ],
      },
      {
        title: "finance2courseContent9title",
        contents: [
          "finance2courseContent9Desc1",
          "finance2courseContent9Desc2",
          "finance2courseContent9Desc3",
          "finance2courseContent9Desc4",
          "finance2courseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN02",
  },
  {
    id: 21,
    image: Finance3,
    title: "finance3Title",
    desc: "finance3Desc",
    WhoShouldAttend: [
      "finance3WhoShouldAttend1",
      "finance3WhoShouldAttend2",
      "finance3WhoShouldAttend3",
      "finance3WhoShouldAttend4",
      "finance3WhoShouldAttend5",
      "finance3WhoShouldAttend6",
      "finance3WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance3courseExpectation1",
      "finance3courseExpectation2",
      "finance3courseExpectation3",
      "finance3courseExpectation4",
      "finance3courseExpectation5",
      "finance3courseExpectation6",
      "finance3courseExpectation7",
      "finance3courseExpectation8",
      "finance3courseExpectation8",
    ],
    courseContent: [
      {
        title: "finance3courseContent1title",
        contents: [
          "finance3courseContent1Desc1",
          "finance3courseContent1Desc2",
          "finance3courseContent1Desc3",
          "finance3courseContent1Desc4",
        ],
      },
      {
        title: "finance3courseContent2title",
        contents: [
          "finance3courseContent2Desc1",
          "finance3courseContent2Desc2",
          "finance3courseContent2Desc3",
          "finance3courseContent2Desc4",
        ],
      },
      {
        title: "finance3courseContent3title",
        contents: [
          "finance3courseContent3Desc1",
          "finance3courseContent3Desc2",
          "finance3courseContent3Desc3",
          "finance3courseContent3Desc4",
          "finance3courseContent3Desc5",
        ],
      },
      {
        title: "finance3courseContent4title",
        contents: [
          "finance3courseContent4Desc1",
          "finance3courseContent4Desc2",
          "finance3courseContent4Desc3",
          "finance3courseContent4Desc4",
        ],
      },
      {
        title: "finance3courseContent5title",
        contents: [
          "finance3courseContent5Desc1",
          "finance3courseContent5Desc2",
          "finance3courseContent5Desc3",
          "finance3courseContent5Desc4",
          "finance3courseContent5Desc5",
        ],
      },
      {
        title: "finance3courseContent6title",
        contents: [
          "finance3courseContent6Desc1",
          "finance3courseContent6Desc2",
          "finance3courseContent6Desc3",
        ],
      },
      {
        title: "finance3courseContent7title",
        contents: [
          "finance3courseContent7Desc1",
          "finance3courseContent7Desc2",
          "finance3courseContent7Desc3",
          "finance3courseContent7Desc4",
          "finance3courseContent7Desc5",
          "finance3courseContent7Desc6",
        ],
      },
      {
        title: "finance3courseContent8title",
        contents: [
          "finance3courseContent8Desc1",
          "finance3courseContent8Desc2",
          "finance3courseContent8Desc3",
          "finance3courseContent8Desc4",
          "finance3courseContent8Desc5",
        ],
      },
      {
        title: "finance3courseContent9title",
        contents: [
          "finance3courseContent9Desc1",
          "finance3courseContent9Desc2",
          "finance3courseContent9Desc3",
          "finance3courseContent9Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN03",
  },
  {
    id: 22,
    image: Finance4,
    title: "finance4Title",
    desc: "finance4Desc",
    WhoShouldAttend: ["finance4WhoShouldAttend"],
    courseExpectation: [
      "finance4courseExpectation1",
      "finance4courseExpectation2",
      "finance4courseExpectation3",
      "finance4courseExpectation4",
      "finance4courseExpectation5",
      "finance4courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance4courseContent1title",
        contents: [
          "finance4courseContent1Desc1",
          "finance4courseContent1Desc2",
          "finance4courseContent1Desc3",
          "finance4courseContent1Desc4",
        ],
      },
      {
        title: "finance4courseContent2title",
        contents: [
          "finance4courseContent2Desc1",
          "finance4courseContent2Desc2",
          "finance4courseContent2Desc3",
          "finance4courseContent2Desc4",
        ],
      },
      {
        title: "finance4courseContent3title",
        contents: [
          "finance4courseContent3Desc1",
          "finance4courseContent3Desc2",
          "finance4courseContent3Desc3",
          "finance4courseContent3Desc4",
        ],
      },
      {
        title: "finance4courseContent4title",
        contents: [
          "finance4courseContent4Desc1",
          "finance4courseContent4Desc2",
          "finance4courseContent4Desc3",
          "finance4courseContent4Desc4",
        ],
      },
      {
        title: "finance4courseContent5title",
        contents: [
          "finance4courseContent5Desc1",
          "finance4courseContent5Desc2",
          "finance4courseContent5Desc3",
          "finance4courseContent5Desc4",
        ],
      },
      {
        title: "finance4courseContent6title",
        contents: [
          "finance4courseContent6Desc1",
          "finance4courseContent6Desc2",
          "finance4courseContent6Desc3",
          "finance4courseContent6Desc4",
        ],
      },
      {
        title: "finance4courseContent7title",
        contents: [
          "finance4courseContent7Desc1",
          "finance4courseContent7Desc2",
          "finance4courseContent7Desc3",
          "finance4courseContent7Desc4",
        ],
      },
      {
        title: "finance4courseContent8title",
        contents: [
          "finance4courseContent8Desc1",
          "finance4courseContent8Desc2",
          "finance4courseContent8Desc3",
          "finance4courseContent8Desc4",
          "finance4courseContent8Desc5",
        ],
      },
      {
        title: "finance4courseContent9title",
        contents: [
          "finance4courseContent9Desc1",
          "finance4courseContent9Desc2",
          "finance4courseContent9Desc3",
          "finance4courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN04",
  },
  {
    id: 23,
    image: Finance5,
    title: "finance5Title",
    desc: "finance5Desc",
    WhoShouldAttend: [
      "finance5WhoShouldAttend1",
      "finance5WhoShouldAttend2",
      "finance5WhoShouldAttend3",
      "finance5WhoShouldAttend4",
      "finance5WhoShouldAttend5",
      "finance5WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance5courseExpectation1",
      "finance5courseExpectation2",
      "finance5courseExpectation3",
      "finance5courseExpectation4",
      "finance5courseExpectation5",
      "finance5courseExpectation6",
      "finance5courseExpectation7",
      "finance5courseExpectation8",
    ],
    courseContent: [
      {
        title: "finance5courseContent1title",
        contents: [
          "finance5courseContent1Desc1",
          "finance5courseContent1Desc2",
          "finance5courseContent1Desc3",
          "finance5courseContent1Desc4",
        ],
      },
      {
        title: "finance5courseContent2title",
        contents: [
          "finance5courseContent2Desc1",
          "finance5courseContent2Desc2",
          "finance5courseContent2Desc3",
          "finance5courseContent2Desc4",
        ],
      },
      {
        title: "finance5courseContent3title",
        contents: [
          "finance5courseContent3Desc1",
          "finance5courseContent3Desc2",
          "finance5courseContent3Desc3",
          "finance5courseContent3Desc4",
          "finance5courseContent3Desc5",
        ],
      },
      {
        title: "finance5courseContent4title",
        contents: [
          "finance5courseContent4Desc1",
          "finance5courseContent4Desc2",
          "finance5courseContent4Desc3",
          "finance5courseContent4Desc4",
        ],
      },
      {
        title: "finance5courseContent5title",
        contents: [
          "finance5courseContent5Desc1",
          "finance5courseContent5Desc2",
          "finance5courseContent5Desc3",
          "finance5courseContent5Desc4",
        ],
      },
      {
        title: "finance5courseContent6title",
        contents: [
          "finance5courseContent6Desc1",
          "finance5courseContent6Desc2",
          "finance5courseContent6Desc3",
          "finance5courseContent6Desc4",
        ],
      },
      {
        title: "finance5courseContent7title",
        contents: [
          "finance5courseContent7Desc1",
          "finance5courseContent7Desc2",
          "finance5courseContent7Desc3",
          "finance5courseContent7Desc4",
        ],
      },
      {
        title: "finance5courseContent8title",
        contents: [
          "finance5courseContent8Desc1",
          "finance5courseContent8Desc2",
          "finance5courseContent8Desc3",
          "finance5courseContent8Desc4",
        ],
      },
      {
        title: "finance5courseContent9title",
        contents: [
          "finance5courseContent9Desc1",
          "finance5courseContent9Desc2",
          "finance5courseContent9Desc3",
          "finance5courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN05",
  },
  {
    id: 24,
    image: Finance6,
    title: "finance6Title",
    desc: "finance6Desc",
    WhoShouldAttend: [
      "finance6WhoShouldAttend1",
      "finance6WhoShouldAttend2",
      "finance6WhoShouldAttend3",
      "finance6WhoShouldAttend4",
      "finance6WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance6courseExpectation1",
      "finance6courseExpectation2",
      "finance6courseExpectation3",
      "finance6courseExpectation4",
      "finance6courseExpectation5",
      "finance6courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance6courseContent1title",
        contents: [
          "finance6courseContent1Desc1",
          "finance6courseContent1Desc2",
          "finance6courseContent1Desc3",
          "finance6courseContent1Desc4",
        ],
      },
      {
        title: "finance6courseContent2title",
        contents: [
          "finance6courseContent2Desc1",
          "finance6courseContent2Desc2",
          "finance6courseContent2Desc3",
          "finance6courseContent2Desc4",
        ],
      },
      {
        title: "finance6courseContent3title",
        contents: [
          "finance6courseContent3Desc1",
          "finance6courseContent3Desc2",
          "finance6courseContent3Desc3",
          "finance6courseContent3Desc4",
        ],
      },
      {
        title: "finance6courseContent4title",
        contents: [
          "finance6courseContent4Desc1",
          "finance6courseContent4Desc2",
          "finance6courseContent4Desc3",
          "finance6courseContent4Desc4",
        ],
      },
      {
        title: "finance6courseContent5title",
        contents: [
          "finance6courseContent5Desc1",
          "finance6courseContent5Desc2",
          "finance6courseContent5Desc3",
          "finance6courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN06",
  },
  {
    id: 25,
    image: Finance7,
    title: "finance7Title",
    desc: "finance7Desc",
    WhoShouldAttend: [
      "finance7WhoShouldAttend1",
      "finance7WhoShouldAttend2",
      "finance7WhoShouldAttend3",
      "finance7WhoShouldAttend4",
      "finance7WhoShouldAttend5",
      "finance7WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance7courseExpectation1",
      "finance7courseExpectation2",
      "finance7courseExpectation3",
      "finance7courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance7courseContent1title",
        contents: [
          "finance7courseContent1Desc1",
          "finance7courseContent1Desc2",
          "finance7courseContent1Desc3",
          "finance7courseContent1Desc4",
        ],
      },
      {
        title: "finance7courseContent2title",
        contents: [
          "finance7courseContent2Desc1",
          "finance7courseContent2Desc2",
          "finance7courseContent2Desc3",
          "finance7courseContent2Desc4",
        ],
      },
      {
        title: "finance7courseContent3title",
        contents: [
          "finance7courseContent3Desc1",
          "finance7courseContent3Desc2",
          "finance7courseContent3Desc3",
          "finance7courseContent3Desc4",
          "finance7courseContent3Desc5",
        ],
      },
      {
        title: "finance7courseContent4title",
        contents: [
          "finance7courseContent4Desc1",
          "finance7courseContent4Desc2",
          "finance7courseContent4Desc3",
          "finance7courseContent4Desc4",
        ],
      },
      {
        title: "finance7courseContent5title",
        contents: [
          "finance7courseContent5Desc1",
          "finance7courseContent5Desc2",
          "finance7courseContent5Desc3",
          "finance7courseContent5Desc4",
          "finance7courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN07",
  },
  {
    id: 26,
    image: Finance8,
    title: "finance8Title",
    desc: "finance8Desc",
    WhoShouldAttend: [
      "finance8WhoShouldAttend1",
      "finance8WhoShouldAttend2",
      "finance8WhoShouldAttend3",
      "finance8WhoShouldAttend4",
      "finance8WhoShouldAttend5",
      "finance8WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance8courseExpectation1",
      "finance8courseExpectation2",
      "finance8courseExpectation3",
      "finance8courseExpectation4",
      "finance8courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance8courseContent1title",
        contents: [
          "finance8courseContent1Desc1",
          "finance8courseContent1Desc2",
          "finance8courseContent1Desc3",
          "finance8courseContent1Desc4",
          "finance8courseContent1Desc5",
          "finance8courseContent1Desc6",
        ],
      },
      {
        title: "finance8courseContent2title",
        contents: [
          "finance8courseContent2Desc1",
          "finance8courseContent2Desc2",
          "finance8courseContent2Desc3",
          "finance8courseContent2Desc4",
          "finance8courseContent2Desc5",
        ],
      },
      {
        title: "finance8courseContent3title",
        contents: [
          "finance8courseContent3Desc1",
          "finance8courseContent3Desc2",
          "finance8courseContent3Desc3",
          "finance8courseContent3Desc4",
          "finance8courseContent3Desc5",
        ],
      },
      {
        title: "finance8courseContent4title",
        contents: [
          "finance8courseContent4Desc1",
          "finance8courseContent4Desc2",
          "finance8courseContent4Desc3",
          "finance8courseContent4Desc4",
          "finance8courseContent4Desc5",
        ],
      },
      {
        title: "finance8courseContent5title",
        contents: [
          "finance8courseContent5Desc1",
          "finance8courseContent5Desc2",
          "finance8courseContent5Desc3",
          "finance8courseContent5Desc4",
          "finance8courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN08",
  },
  {
    id: 27,
    image: Finance9,
    title: "finance9Title",
    desc: "finance9Desc",
    WhoShouldAttend: ["finance9WhoShouldAttend"],
    courseExpectation: [
      "finance9courseExpectation1",
      "finance9courseExpectation2",
      "finance9courseExpectation3",
      "finance9courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance9courseContent1title",
        contents: [
          "finance9courseContent1Desc1",
          "finance9courseContent1Desc2",
          "finance9courseContent1Desc3",
          "finance9courseContent1Desc4",
          "finance9courseContent1Desc5",
          "finance9courseContent1Desc6",
          "finance9courseContent1Desc7",
        ],
      },
      {
        title: "finance9courseContent2title",
        contents: [
          "finance9courseContent2Desc1",
          "finance9courseContent2Desc2",
          "finance9courseContent2Desc3",
          "finance9courseContent2Desc4",
          "finance9courseContent2Desc5",
          "finance9courseContent2Desc6",
        ],
      },
      {
        title: "finance9courseContent3title",
        contents: [
          "finance9courseContent3Desc1",
          "finance9courseContent3Desc2",
          "finance9courseContent3Desc3",
          "finance9courseContent3Desc4",
          "finance9courseContent3Desc5",
          "finance9courseContent3Desc6",
        ],
      },
      {
        title: "finance9courseContent4title",
        contents: [
          "finance9courseContent4Desc1",
          "finance9courseContent4Desc2",
          "finance9courseContent4Desc3",
          "finance9courseContent4Desc4",
          "finance9courseContent4Desc5",
          "finance9courseContent4Desc6",
          "finance9courseContent4Desc7",
          "finance9courseContent4Desc8",
        ],
      },
      {
        title: "finance9courseContent5title",
        contents: [
          "finance9courseContent5Desc1",
          "finance9courseContent5Desc2",
          "finance9courseContent5Desc3",
          "finance9courseContent5Desc4",
          "finance9courseContent5Desc5",
          "finance9courseContent5Desc6",
          "finance9courseContent5Desc7",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN09",
  },
  {
    id: 28,
    image: Finance10,
    title: "finance10Title",
    desc: "finance10Desc",
    WhoShouldAttend: ["finance10WhoShouldAttend"],
    courseExpectation: [
      "finance10courseExpectation1",
      "finance10courseExpectation2",
      "finance10courseExpectation3",
      "finance10courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance10courseContent1title",
        contents: [
          "finance10courseContent1Desc1",
          "finance10courseContent1Desc2",
          "finance10courseContent1Desc3",
          "finance10courseContent1Desc4",
          "finance10courseContent1Desc5",
          "finance10courseContent1Desc6",
          "finance10courseContent1Desc7",
          "finance10courseContent1Desc8",
          "finance10courseContent1Desc9",
          "finance10courseContent1Desc10",
          "finance10courseContent1Desc11",
          "finance10courseContent1Desc12",
        ],
      },
      {
        title: "finance10courseContent2title",
        contents: [
          "finance10courseContent2Desc1",
          "finance10courseContent2Desc2",
          "finance10courseContent2Desc3",
          "finance10courseContent2Desc4",
          "finance10courseContent2Desc5",
          "finance10courseContent2Desc6",
          "finance10courseContent2Desc7",
          "finance10courseContent2Desc8",
          "finance10courseContent2Desc9",
          "finance10courseContent2Desc10",
          "finance10courseContent2Desc11",
        ],
      },
      {
        title: "finance10courseContent3title",
        contents: [
          "finance10courseContent3Desc1",
          "finance10courseContent3Desc2",
          "finance10courseContent3Desc3",
          "finance10courseContent3Desc4",
          "finance10courseContent3Desc5",
          "finance10courseContent3Desc6",
          "finance10courseContent3Desc7",
          "finance10courseContent3Desc8",
          "finance10courseContent3Desc9",
          "finance10courseContent3Desc10",
          "finance10courseContent3Desc11",
          "finance10courseContent3Desc12",
        ],
      },
      {
        title: "finance10courseContent4title",
        contents: [
          "finance10courseContent4Desc1",
          "finance10courseContent4Desc2",
          "finance10courseContent4Desc3",
          "finance10courseContent4Desc4",
          "finance10courseContent4Desc5",
          "finance10courseContent4Desc6",
          "finance10courseContent4Desc7",
          "finance10courseContent4Desc8",
          "finance10courseContent4Desc9",
          "finance10courseContent4Desc10",
          "finance10courseContent4Desc11",
        ],
      },
      {
        title: "finance10courseContent5title",
        contents: [
          "finance10courseContent5Desc1",
          "finance10courseContent5Desc2",
          "finance10courseContent5Desc3",
          "finance10courseContent5Desc4",
          "finance10courseContent5Desc5",
          "finance10courseContent5Desc6",
          "finance10courseContent5Desc7",
          "finance10courseContent5Desc8",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN10",
  },
  {
    id: 29,
    image: Finance11,
    title: "finance11Title",
    desc: "finance11Desc",
    WhoShouldAttend: [
      "finance11WhoShouldAttend1",
      "finance11WhoShouldAttend2",
      "finance11WhoShouldAttend3",
      "finance11WhoShouldAttend4",
      "finance11WhoShouldAttend5",
      "finance11WhoShouldAttend6",
      "finance11WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance11courseExpectation1",
      "finance11courseExpectation2",
      "finance11courseExpectation3",
      "finance11courseExpectation4",
      "finance11courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance11courseContent1title",
        contents: [
          "finance11courseContent1Desc1",
          "finance11courseContent1Desc2",
          "finance11courseContent1Desc3",
          "finance11courseContent1Desc4",
          "finance11courseContent1Desc5",
        ],
      },
      {
        title: "finance11courseContent2title",
        contents: [
          "finance11courseContent2Desc1",
          "finance11courseContent2Desc2",
          "finance11courseContent2Desc3",
          "finance11courseContent2Desc4",
          "finance11courseContent2Desc5",
        ],
      },
      {
        title: "finance11courseContent3title",
        contents: [
          "finance11courseContent3Desc1",
          "finance11courseContent3Desc2",
          "finance11courseContent3Desc3",
          "finance11courseContent3Desc4",
        ],
      },
      {
        title: "finance11courseContent4title",
        contents: [
          "finance11courseContent4Desc1",
          "finance11courseContent4Desc2",
          "finance11courseContent4Desc3",
          "finance11courseContent4Desc4",
        ],
      },
      {
        title: "finance11courseContent5title",
        contents: [
          "finance11courseContent5Desc1",
          "finance11courseContent5Desc2",
          "finance11courseContent5Desc3",
          "finance11courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN11",
  },
  {
    id: 30,
    image: Finance12,
    title: "finance12Title",
    desc: "finance12Desc",
    WhoShouldAttend: ["finance12WhoShouldAttend1", "finance12WhoShouldAttend2"],
    courseExpectation: [
      "finance12courseExpectation1",
      "finance12courseExpectation2",
      "finance12courseExpectation3",
      "finance12courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance12courseContent1title",
        contents: [
          "finance12courseContent1Desc1",
          "finance12courseContent1Desc2",
          "finance12courseContent1Desc3",
          "finance12courseContent1Desc4",
        ],
      },
      {
        title: "finance12courseContent2title",
        contents: [
          "finance12courseContent2Desc1",
          "finance12courseContent2Desc2",
          "finance12courseContent2Desc3",
          "finance12courseContent2Desc4",
        ],
      },
      {
        title: "finance12courseContent3title",
        contents: [
          "finance12courseContent3Desc1",
          "finance12courseContent3Desc2",
          "finance12courseContent3Desc3",
          "finance12courseContent3Desc4",
        ],
      },
      {
        title: "finance12courseContent4title",
        contents: [
          "finance12courseContent4Desc1",
          "finance12courseContent4Desc2",
          "finance12courseContent4Desc3",
          "finance12courseContent4Desc4",
        ],
      },
      {
        title: "finance12courseContent5title",
        contents: [
          "finance12courseContent5Desc1",
          "finance12courseContent5Desc2",
          "finance12courseContent5Desc3",
          "finance12courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN12",
  },
  {
    id: 31,
    image: Finance13,
    title: "finance13Title",
    desc: "finance13Desc",
    WhoShouldAttend: [
      "finance13WhoShouldAttend1",
      "finance13WhoShouldAttend2",
      "finance13WhoShouldAttend3",
      "finance13WhoShouldAttend4",
      "finance13WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance13courseExpectation1",
      "finance13courseExpectation2",
      "finance13courseExpectation3",
      "finance13courseExpectation4",
      "finance13courseExpectation5",
      "finance13courseExpectation6",
      "finance13courseExpectation7",
    ],
    courseContent: [
      {
        title: "finance13courseContent1title",
        contents: [
          "finance13courseContent1Desc1",
          "finance13courseContent1Desc2",
          "finance13courseContent1Desc3",
          "finance13courseContent1Desc4",
        ],
      },
      {
        title: "finance13courseContent2title",
        contents: [
          "finance13courseContent2Desc1",
          "finance13courseContent2Desc2",
          "finance13courseContent2Desc3",
          "finance13courseContent2Desc4",
          "finance13courseContent2Desc5",
          "finance13courseContent2Desc6",
        ],
      },
      {
        title: "finance13courseContent3title",
        contents: [
          "finance13courseContent3Desc1",
          "finance13courseContent3Desc2",
          "finance13courseContent3Desc3",
          "finance13courseContent3Desc4",
          "finance13courseContent3Desc5",
        ],
      },
      {
        title: "finance13courseContent4title",
        contents: [
          "finance13courseContent4Desc1",
          "finance13courseContent4Desc2",
          "finance13courseContent4Desc3",
          "finance13courseContent4Desc4",
          "finance13courseContent4Desc5",
        ],
      },
      {
        title: "finance13courseContent5title",
        contents: [
          "finance13courseContent5Desc1",
          "finance13courseContent5Desc2",
          "finance13courseContent5Desc3",
          "finance13courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN13",
  },
  {
    id: 32,
    image: Finance14,
    title: "finance14Title",
    desc: "finance14Desc",
    WhoShouldAttend: [
      "finance14WhoShouldAttend1",
      "finance14WhoShouldAttend2",
      "finance14WhoShouldAttend3",
      "finance14WhoShouldAttend4",
      "finance14WhoShouldAttend5",
      "finance14WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance14courseExpectation1",
      "finance14courseExpectation2",
      "finance14courseExpectation3",
      "finance14courseExpectation4",
      "finance14courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance14courseContent1title",
        contents: [
          "finance14courseContent1Desc1",
          "finance14courseContent1Desc2",
          "finance14courseContent1Desc3",
        ],
      },
      {
        title: "finance14courseContent2title",
        contents: [
          "finance14courseContent2Desc1",
          "finance14courseContent2Desc2",
          "finance14courseContent2Desc3",
          "finance14courseContent2Desc4",
          "finance14courseContent2Desc5",
        ],
      },
      {
        title: "finance14courseContent3title",
        contents: [
          "finance14courseContent3Desc1",
          "finance14courseContent3Desc2",
          "finance14courseContent3Desc3",
        ],
      },
      {
        title: "finance14courseContent4title",
        contents: [
          "finance14courseContent4Desc1",
          "finance14courseContent4Desc2",
          "finance14courseContent4Desc3",
        ],
      },
      {
        title: "finance14courseContent5title",
        contents: [
          "finance14courseContent5Desc1",
          "finance14courseContent5Desc2",
          "finance14courseContent5Desc3",
          "finance14courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN14",
  },
  {
    id: 33,
    image: Finance15,
    title: "finance15Title",
    desc: "finance15Desc",
    WhoShouldAttend: ["finance15WhoShouldAttend"],
    courseExpectation: [
      "finance15courseExpectation1",
      "finance15courseExpectation2",
      "finance15courseExpectation3",
      "finance15courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance15courseContent1title",
        contents: [
          "finance15courseContent1Desc1",
          "finance15courseContent1Desc2",
          "finance15courseContent1Desc3",
          "finance15courseContent1Desc4",
        ],
      },
      {
        title: "finance15courseContent2title",
        contents: [
          "finance15courseContent2Desc1",
          "finance15courseContent2Desc2",
          "finance15courseContent2Desc3",
          "finance15courseContent2Desc4",
        ],
      },
      {
        title: "finance15courseContent3title",
        contents: [
          "finance15courseContent3Desc1",
          "finance15courseContent3Desc2",
          "finance15courseContent3Desc3",
          "finance15courseContent3Desc4",
        ],
      },
      {
        title: "finance15courseContent4title",
        contents: [
          "finance15courseContent4Desc1",
          "finance15courseContent4Desc2",
          "finance15courseContent4Desc3",
          "finance15courseContent4Desc4",
        ],
      },
      {
        title: "finance15courseContent5title",
        contents: [
          "finance15courseContent5Desc1",
          "finance15courseContent5Desc2",
          "finance15courseContent5Desc3",
          "finance15courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN15",
  },
  {
    id: 34,
    image: Finance16,
    title: "finance16Title",
    desc: "finance16Desc",
    WhoShouldAttend: ["finance16WhoShouldAttend"],
    courseExpectation: [
      "finance16courseExpectation1",
      "finance16courseExpectation2",
      "finance16courseExpectation3",
      "finance16courseExpectation4",
      "finance16courseExpectation5",
      "finance16courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance16courseContent1title",
        contents: [
          "finance16courseContent1Desc1",
          "finance16courseContent1Desc2",
          "finance16courseContent1Desc3",
          "finance16courseContent1Desc4",
        ],
      },
      {
        title: "finance16courseContent2title",
        contents: [
          "finance16courseContent2Desc1",
          "finance16courseContent2Desc2",
          "finance16courseContent2Desc3",
          "finance16courseContent2Desc4",
        ],
      },
      {
        title: "finance16courseContent3title",
        contents: [
          "finance16courseContent3Desc1",
          "finance16courseContent3Desc2",
          "finance16courseContent3Desc3",
          "finance16courseContent3Desc4",
        ],
      },
      {
        title: "finance16courseContent4title",
        contents: [
          "finance16courseContent4Desc1",
          "finance16courseContent4Desc2",
          "finance16courseContent4Desc3",
          "finance16courseContent4Desc4",
          "finance16courseContent4Desc5",
          "finance16courseContent4Desc6",
          "finance16courseContent4Desc7",
          "finance16courseContent4Desc8",
          "finance16courseContent4Desc9",
          "finance16courseContent4Desc10",
          "finance16courseContent4Desc11",
        ],
      },
      {
        title: "finance16courseContent5title",
        contents: [
          "finance16courseContent5Desc1",
          "finance16courseContent5Desc2",
          "finance16courseContent5Desc3",
          "finance16courseContent5Desc4",
          "finance16courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN16",
  },
  {
    id: 35,
    image: Finance17,
    title: "finance17Title",
    desc: "finance17Desc",
    WhoShouldAttend: ["finance16WhoShouldAttend"],
    courseExpectation: [
      "finance17courseExpectation1",
      "finance17courseExpectation2",
      "finance17courseExpectation3",
      "finance17courseExpectation4",
      "finance17courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance17courseContent1title",
        contents: [
          "finance17courseContent1Desc1",
          "finance17courseContent1Desc2",
          "finance17courseContent1Desc3",
          "finance17courseContent1Desc4",
        ],
      },
      {
        title: "finance17courseContent2title",
        contents: [
          "finance17courseContent2Desc1",
          "finance17courseContent2Desc2",
          "finance17courseContent2Desc3",
          "finance17courseContent2Desc4",
          "finance17courseContent2Desc5",
          "finance17courseContent2Desc6",
        ],
      },
      {
        title: "finance17courseContent3title",
        contents: [
          "finance17courseContent3Desc1",
          "finance17courseContent3Desc2",
          "finance17courseContent3Desc3",
          "finance17courseContent3Desc4",
        ],
      },
      {
        title: "finance17courseContent4title",
        contents: [
          "finance17courseContent4Desc1",
          "finance17courseContent4Desc2",
          "finance17courseContent4Desc3",
          "finance17courseContent4Desc4",
          "finance17courseContent4Desc5",
          "finance17courseContent4Desc6",
          "finance17courseContent4Desc7",
          "finance17courseContent4Desc8",
          "finance17courseContent4Desc9",
        ],
      },
      {
        title: "finance17courseContent5title",
        contents: [
          "finance17courseContent5Desc1",
          "finance17courseContent5Desc2",
          "finance17courseContent5Desc3",
          "finance17courseContent5Desc4",
          "finance17courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN17",
  },
  {
    id: 36,
    image: Finance18,
    title: "finance18Title",
    desc: "finance18Desc",
    WhoShouldAttend: ["finance16WhoShouldAttend"],
    courseExpectation: [
      "finance18courseExpectation1",
      "finance18courseExpectation2",
      "finance18courseExpectation3",
      "finance18courseExpectation4",
      "finance18courseExpectation5",
      "finance18courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance18courseContent1title",
        contents: [
          "finance18courseContent1Desc1",
          "finance18courseContent1Desc2",
          "finance18courseContent1Desc3",
          "finance18courseContent1Desc4",
        ],
      },
      {
        title: "finance18courseContent2title",
        contents: [
          "finance18courseContent2Desc1",
          "finance18courseContent2Desc2",
          "finance18courseContent2Desc3",
          "finance18courseContent2Desc4",
        ],
      },
      {
        title: "finance18courseContent3title",
        contents: [
          "finance18courseContent3Desc1",
          "finance18courseContent3Desc2",
          "finance18courseContent3Desc3",
          "finance18courseContent3Desc4",
          "finance18courseContent3Desc5",
          "finance18courseContent3Desc6",
          "finance18courseContent3Desc7",
          "finance18courseContent3Desc8",
        ],
      },
      {
        title: "finance18courseContent4title",
        contents: [
          "finance18courseContent4Desc1",
          "finance18courseContent4Desc2",
          "finance18courseContent4Desc3",
          "finance18courseContent4Desc4",
        ],
      },
      {
        title: "finance18courseContent5title",
        contents: [
          "finance18courseContent5Desc1",
          "finance18courseContent5Desc2",
          "finance18courseContent5Desc3",
          "finance18courseContent5Desc4",
          "finance18courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN18",
  },
  {
    id: 37,
    image: Finance19,
    title: "finance19Title",
    desc: "finance19Desc",
    WhoShouldAttend: [
      "finance19WhoShouldAttend1",
      "finance19WhoShouldAttend2",
      "finance19WhoShouldAttend3",
      "finance19WhoShouldAttend4",
      "finance19WhoShouldAttend5",
      "finance19WhoShouldAttend6",
      "finance19WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance19courseExpectation1",
      "finance19courseExpectation2",
      "finance19courseExpectation3",
      "finance19courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance19courseContent1title",
        contents: [
          "finance19courseContent1Desc1",
          "finance19courseContent1Desc2",
          "finance19courseContent1Desc3",
          "finance19courseContent1Desc4",
          "finance19courseContent1Desc5",
        ],
      },
      {
        title: "finance19courseContent2title",
        contents: [
          "finance19courseContent2Desc1",
          "finance19courseContent2Desc2",
          "finance19courseContent2Desc3",
          "finance19courseContent2Desc4",
          "finance19courseContent2Desc5",
        ],
      },
      {
        title: "finance19courseContent3title",
        contents: [
          "finance19courseContent3Desc1",
          "finance19courseContent3Desc2",
          "finance19courseContent3Desc3",
          "finance19courseContent3Desc4",
          "finance19courseContent3Desc5",
          "finance19courseContent3Desc6",
          "finance19courseContent3Desc7",
        ],
      },
      {
        title: "finance19courseContent4title",
        contents: [
          "finance19courseContent4Desc1",
          "finance19courseContent4Desc2",
          "finance19courseContent4Desc3",
          "finance19courseContent4Desc4",
          "finance19courseContent4Desc5",
          "finance19courseContent4Desc6",
        ],
      },
      {
        title: "finance19courseContent5title",
        contents: [
          "finance19courseContent5Desc1",
          "finance19courseContent5Desc2",
          "finance19courseContent5Desc3",
          "finance19courseContent5Desc4",
          "finance19courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN19",
  },
  {
    id: 38,
    image: Finance20,
    title: "finance20Title",
    desc: "finance20Desc",
    WhoShouldAttend: [
      "finance20WhoShouldAttend1",
      "finance20WhoShouldAttend2",
      "finance20WhoShouldAttend3",
      "finance20WhoShouldAttend4",
      "finance20WhoShouldAttend5",
      "finance20WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance20courseExpectation1",
      "finance20courseExpectation2",
      "finance20courseExpectation3",
      "finance20courseExpectation4",
      "finance20courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance20courseContent1title",
        contents: [
          "finance20courseContent1Desc1",
          "finance20courseContent1Desc2",
          "finance20courseContent1Desc3",
          "finance20courseContent1Desc4",
          "finance20courseContent1Desc5",
        ],
      },
      {
        title: "finance20courseContent2title",
        contents: [
          "finance20courseContent2Desc1",
          "finance20courseContent2Desc2",
          "finance20courseContent2Desc3",
          "finance20courseContent2Desc4",
          "finance20courseContent2Desc5",
        ],
      },
      {
        title: "finance20courseContent3title",
        contents: [
          "finance20courseContent3Desc1",
          "finance20courseContent3Desc2",
          "finance20courseContent3Desc3",
          "finance20courseContent3Desc4",
          "finance20courseContent3Desc5",
          "finance20courseContent3Desc6",
          "finance20courseContent3Desc7",
        ],
      },
      {
        title: "finance20courseContent4title",
        contents: [
          "finance20courseContent4Desc1",
          "finance20courseContent4Desc2",
          "finance20courseContent4Desc3",
          "finance20courseContent4Desc4",
          "finance20courseContent4Desc5",
        ],
      },
      {
        title: "finance20courseContent5title",
        contents: [
          "finance20courseContent5Desc1",
          "finance20courseContent5Desc2",
          "finance20courseContent5Desc3",
          "finance20courseContent5Desc4",
          "finance20courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN20",
  },
  {
    id: 39,
    image: Finance21,
    title: "finance21Title",
    desc: "finance21Desc",
    WhoShouldAttend: [
      "finance21WhoShouldAttend1",
      "finance21WhoShouldAttend2",
      "finance21WhoShouldAttend3",
      "finance21WhoShouldAttend4",
      "finance21WhoShouldAttend5",
      "finance21WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance21courseExpectation1",
      "finance21courseExpectation2",
      "finance21courseExpectation3",
      "finance21courseExpectation4",
      "finance21courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance21courseContent1title",
        contents: [
          "finance21courseContent1Desc1",
          "finance21courseContent1Desc2",
          "finance21courseContent1Desc3",
          "finance21courseContent1Desc4",
        ],
      },
      {
        title: "finance21courseContent2title",
        contents: [
          "finance21courseContent2Desc1",
          "finance21courseContent2Desc2",
          "finance21courseContent2Desc3",
          "finance21courseContent2Desc4",
        ],
      },
      {
        title: "finance21courseContent3title",
        contents: [
          "finance21courseContent3Desc1",
          "finance21courseContent3Desc2",
          "finance21courseContent3Desc3",
          "finance21courseContent3Desc4",
          "finance21courseContent3Desc5",
        ],
      },
      {
        title: "finance21courseContent4title",
        contents: [
          "finance21courseContent4Desc1",
          "finance21courseContent4Desc2",
          "finance21courseContent4Desc3",
          "finance21courseContent4Desc4",
        ],
      },
      {
        title: "finance21courseContent5title",
        contents: [
          "finance21courseContent5Desc1",
          "finance21courseContent5Desc2",
          "finance21courseContent5Desc3",
          "finance21courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN21",
  },
  {
    id: 40,
    image: Finance22,
    title: "finance22Title",
    desc: "finance21Desc",
    WhoShouldAttend: [
      "finance21WhoShouldAttend1",
      "finance21WhoShouldAttend3",
      "finance22WhoShouldAttend1",
      "finance22WhoShouldAttend2",
      "finance22WhoShouldAttend3",
      "finance22WhoShouldAttend4",
      "finance22WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance22courseExpectation1",
      "finance22courseExpectation2",
      "finance22courseExpectation3",
      "finance22courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance22courseContent1title",
        contents: [
          "finance22courseContent1Desc1",
          "finance22courseContent1Desc2",
          "finance22courseContent1Desc3",
          "finance22courseContent1Desc4",
          "finance22courseContent1Desc5",
        ],
      },
      {
        title: "finance22courseContent2title",
        contents: [
          "finance22courseContent2Desc1",
          "finance22courseContent2Desc2",
          "finance22courseContent2Desc3",
        ],
      },
      {
        title: "finance22courseContent3title",
        contents: [
          "finance22courseContent3Desc1",
          "finance22courseContent3Desc2",
          "finance22courseContent3Desc3",
          "finance22courseContent3Desc4",
          "finance22courseContent3Desc5",
          "finance22courseContent3Desc6",
        ],
      },
      {
        title: "finance22courseContent4title",
        contents: [
          "finance22courseContent4Desc1",
          "finance22courseContent4Desc2",
          "finance22courseContent4Desc3",
          "finance22courseContent4Desc4",
          "finance22courseContent4Desc5",
        ],
      },
      {
        title: "finance22courseContent5title",
        contents: [
          "finance22courseContent5Desc1",
          "finance22courseContent5Desc2",
          "finance22courseContent5Desc3",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN22",
  },
  {
    id: 41,
    image: Finance23,
    title: "finance23Title",
    desc: "finance23Desc",
    WhoShouldAttend: ["finance23WhoShouldAttend"],
    courseExpectation: [
      "finance23courseExpectation1",
      "finance23courseExpectation2",
      "finance23courseExpectation3",
      "finance23courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance23courseContent1title",
        contents: [
          "finance23courseContent1Desc1",
          "finance23courseContent1Desc2",
          "finance23courseContent1Desc3",
          "finance23courseContent1Desc4",
        ],
      },
      {
        title: "finance23courseContent2title",
        contents: [
          "finance23courseContent2Desc1",
          "finance23courseContent2Desc2",
          "finance23courseContent2Desc3",
          "finance23courseContent2Desc4",
        ],
      },
      {
        title: "finance23courseContent3title",
        contents: [
          "finance23courseContent3Desc1",
          "finance23courseContent3Desc2",
          "finance23courseContent3Desc3",
          "finance23courseContent3Desc4",
        ],
      },
      {
        title: "finance23courseContent4title",
        contents: [
          "finance23courseContent4Desc1",
          "finance23courseContent4Desc2",
          "finance23courseContent4Desc3",
          "finance23courseContent4Desc4",
          "finance23courseContent4Desc5",
          "finance23courseContent4Desc6",
          "finance23courseContent4Desc7",
          "finance23courseContent4Desc8",
          "finance23courseContent4Desc9",
          "finance23courseContent4Desc10",
          "finance23courseContent4Desc11",
        ],
      },
      {
        title: "finance23courseContent5title",
        contents: [
          "finance23courseContent5Desc1",
          "finance23courseContent5Desc2",
          "finance23courseContent5Desc3",
          "finance23courseContent5Desc4",
          "finance23courseContent5Desc5",
        ],
      },
      {
        title: "finance23courseContent6title",
        contents: [
          "finance23courseContent6Desc1",
          "finance23courseContent6Desc2",
          "finance23courseContent6Desc3",
          "finance23courseContent6Desc4",
          "finance23courseContent6Desc5",
          "finance23courseContent6Desc6",
        ],
      },
      {
        title: "finance23courseContent7title",
        contents: [
          "finance23courseContent7Desc1",
          "finance23courseContent7Desc2",
          "finance23courseContent7Desc3",
          "finance23courseContent7Desc4",
        ],
      },
      {
        title: "finance23courseContent8title",
        contents: [
          "finance23courseContent8Desc1",
          "finance23courseContent8Desc2",
          "finance23courseContent8Desc3",
          "finance23courseContent8Desc4",
          "finance23courseContent8Desc5",
          "finance23courseContent8Desc6",
          "finance23courseContent8Desc7",
          "finance23courseContent8Desc8",
          "finance23courseContent8Desc9",
        ],
      },
      {
        title: "finance23courseContent9title",
        contents: [
          "finance23courseContent9Desc1",
          "finance23courseContent9Desc2",
          "finance23courseContent9Desc3",
          "finance23courseContent9Desc4",
          "finance23courseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN23",
  },
  {
    id: 42,
    image: Finance24,
    title: "finance24Title",
    desc: "finance24Desc",
    WhoShouldAttend: [
      "finance24WhoShouldAttend1",
      "finance24WhoShouldAttend2",
      "finance24WhoShouldAttend3",
      "finance24WhoShouldAttend4",
      "finance24WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance24courseExpectation1",
      "finance24courseExpectation2",
      "finance24courseExpectation3",
      "finance24courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance24courseContent1title",
        contents: [
          "finance24courseContent1Desc1",
          "finance24courseContent1Desc2",
          "finance24courseContent1Desc3",
          "finance24courseContent1Desc4",
        ],
      },
      {
        title: "finance24courseContent2title",
        contents: [
          "finance24courseContent2Desc1",
          "finance24courseContent2Desc2",
          "finance24courseContent2Desc3",
          "finance24courseContent2Desc4",
        ],
      },
      {
        title: "finance24courseContent3title",
        contents: [
          "finance24courseContent3Desc1",
          "finance24courseContent3Desc2",
          "finance24courseContent3Desc3",
          "finance24courseContent3Desc4",
          "finance24courseContent3Desc5",
        ],
      },
      {
        title: "finance24courseContent4title",
        contents: [
          "finance24courseContent4Desc1",
          "finance24courseContent4Desc2",
          "finance24courseContent4Desc3",
          "finance24courseContent4Desc4",
        ],
      },
      {
        title: "finance24courseContent5title",
        contents: [
          "finance24courseContent5Desc1",
          "finance24courseContent5Desc2",
          "finance24courseContent5Desc3",
          "finance24courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN24",
  },
  // Finance

  // Oil & Gas
  {
    id: 43,
    image: Oil1,
    title: "gasandOIL1Title",
    desc: "gasandOIL1Desc",
    WhoShouldAttend: [
      "gasandOIL1WhoShouldAttend1",
      "gasandOIL1WhoShouldAttend2",
      "gasandOIL1WhoShouldAttend3",
      "gasandOIL1WhoShouldAttend4",
      "gasandOIL1WhoShouldAttend5",
    ],
    courseExpectation: [
      "gasandOIL1courseExpectation1",
      "gasandOIL1courseExpectation2",
      "gasandOIL1courseExpectation3",
      "gasandOIL1courseExpectation4",
      "gasandOIL1courseExpectation5",
      "gasandOIL1courseExpectation6",
    ],
    courseContent: [
      {
        title: "gasandOIL1coursecourseContent1Title",
        contents: [
          "gasandOIL1coursecourseContent1Desc1",
          "gasandOIL1coursecourseContent1Desc2",
          "gasandOIL1coursecourseContent1Desc3",
          "gasandOIL1coursecourseContent1Desc3",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent2Title",
        contents: [
          "gasandOIL1coursecourseContent2Desc1",
          "gasandOIL1coursecourseContent2Desc2",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent3Title",
        contents: [
          "gasandOIL1coursecourseContent3Desc1",
          "gasandOIL1coursecourseContent3Desc2",
          "gasandOIL1coursecourseContent3Desc3",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent4Title",
        contents: [
          "gasandOIL1coursecourseContent4Desc1",
          "gasandOIL1coursecourseContent4Desc2",
          "gasandOIL1coursecourseContent4Desc3",
          "gasandOIL1coursecourseContent4Desc4",
          "gasandOIL1coursecourseContent4Desc5",
          "gasandOIL1coursecourseContent4Desc6",
          "gasandOIL1coursecourseContent4Desc7",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent5Title",
        contents: [
          "gasandOIL1coursecourseContent5Desc1",
          "gasandOIL1coursecourseContent5Desc2",
          "gasandOIL1coursecourseContent5Desc3",
          "gasandOIL1coursecourseContent5Desc4",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent6Title",
        contents: [
          "gasandOIL1coursecourseContent6Desc1",
          "gasandOIL1coursecourseContent6Desc2",
          "gasandOIL1coursecourseContent6Desc3",
          "gasandOIL1coursecourseContent6Desc4",
          "gasandOIL1coursecourseContent6Desc5",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent7Title",
        contents: [
          "gasandOIL1coursecourseContent7Desc1",
          "gasandOIL1coursecourseContent7Desc2",
          "gasandOIL1coursecourseContent7Desc3",
          "gasandOIL1coursecourseContent7Desc4",
          "gasandOIL1coursecourseContent7Desc5",
          "gasandOIL1coursecourseContent7Desc6",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent8Title",
        contents: [
          "gasandOIL1coursecourseContent8Desc1",
          "gasandOIL1coursecourseContent8Desc2",
          "gasandOIL1coursecourseContent8Desc3",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent9Title",
        contents: [
          "gasandOIL1coursecourseContent9Desc1",
          "gasandOIL1coursecourseContent9Desc2",
          "gasandOIL1coursecourseContent9Desc3",
        ],
      },
      {
        title: "gasandOIL1coursecourseContent10Title",
        contents: [
          "gasandOIL1coursecourseContent10Desc1",
          "gasandOIL1coursecourseContent10Desc2",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO01",
  },
  {
    id: 44,
    image: Oil2,
    title: "gasandOIL2Title",
    desc: "gasandOIL2Desc",
    WhoShouldAttend: [
      "gasandOIL2WhoShouldAttend1",
      "gasandOIL2WhoShouldAttend2",
      "gasandOIL2WhoShouldAttend3",
      "gasandOIL2WhoShouldAttend4",
      "gasandOIL2WhoShouldAttend5",
    ],
    courseExpectation: [
      "gasandOIL2courseExpectatio1",
      "gasandOIL2courseExpectatio2",
      "gasandOIL2courseExpectatio3",
      "gasandOIL2courseExpectatio4",
    ],
    courseContent: [
      {
        title: "gasandOIL2coursecourseContent1Title",
        contents: [
          "gasandOIL2coursecourseContent1Desc1",
          "gasandOIL2coursecourseContent1Desc2",
          "gasandOIL2coursecourseContent1Desc3",
          "gasandOIL2coursecourseContent1Desc4",
          "gasandOIL2coursecourseContent1Desc5",
        ],
      },
      {
        title: "gasandOIL2coursecourseContent2Title",
        contents: [
          "gasandOIL2coursecourseContent2Desc1",
          "gasandOIL2coursecourseContent2Desc2",
          "gasandOIL2coursecourseContent2Desc3",
          "gasandOIL2coursecourseContent2Desc4",
          "gasandOIL2coursecourseContent2Desc5",
        ],
      },
      {
        title: "gasandOIL2coursecourseContent3Title",
        contents: [
          "gasandOIL2coursecourseContent3Desc1",
          "gasandOIL2coursecourseContent3Desc2",
          "gasandOIL2coursecourseContent3Desc3",
          "gasandOIL2coursecourseContent3Desc4",
          "gasandOIL2coursecourseContent3Desc5",
        ],
      },
      {
        title: "gasandOIL2coursecourseContent4Title",
        contents: [
          "gasandOIL2coursecourseContent4Desc1",
          "gasandOIL2coursecourseContent4Desc2",
          "gasandOIL2coursecourseContent4Desc3",
          "gasandOIL2coursecourseContent4Desc4",
          "gasandOIL2coursecourseContent4Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO02",
  },
  {
    id: 45,
    image: Oil3,
    title: "gasandOIL3Title",
    desc: "gasandOIL3Desc",
    WhoShouldAttend: [
      "gasandOIL3WhoShouldAttend1",
      "gasandOIL3WhoShouldAttend2",
      "gasandOIL3WhoShouldAttend3",
    ],
    courseExpectation: [
      "gasandOIL3courseExpectation1",
      "gasandOIL3courseExpectation2",
      "gasandOIL3courseExpectation3",
      "gasandOIL3courseExpectation4",
      "gasandOIL3courseExpectation5",
      "gasandOIL3courseExpectation6",
      "gasandOIL3courseExpectation7",
    ],
    courseContent: [
      {
        title: "gasandOIL3coursecourseContent1Title",
        contents: [
          "gasandOIL3coursecourseContent1Desc1",
          "gasandOIL3coursecourseContent1Desc2",
          "gasandOIL3coursecourseContent1Desc3",
          "gasandOIL3coursecourseContent1Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent2Title",
        contents: [
          "gasandOIL3coursecourseContent2Desc1",
          "gasandOIL3coursecourseContent2Desc2",
          "gasandOIL3coursecourseContent2Desc3",
          "gasandOIL3coursecourseContent2Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent3Title",
        contents: [
          "gasandOIL3coursecourseContent3Desc1",
          "gasandOIL3coursecourseContent3Desc2",
          "gasandOIL3coursecourseContent3Desc3",
          "gasandOIL3coursecourseContent3Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent4Title",
        contents: [
          "gasandOIL3coursecourseContent4Desc1",
          "gasandOIL3coursecourseContent4Desc2",
          "gasandOIL3coursecourseContent4Desc3",
          "gasandOIL3coursecourseContent4Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent5Title",
        contents: [
          "gasandOIL3coursecourseContent5Desc1",
          "gasandOIL3coursecourseContent5Desc2",
          "gasandOIL3coursecourseContent5Desc3",
          "gasandOIL3coursecourseContent5Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent6Title",
        contents: [
          "gasandOIL3coursecourseContent6Desc1",
          "gasandOIL3coursecourseContent6Desc2",
          "gasandOIL3coursecourseContent6Desc3",
          "gasandOIL3coursecourseContent6Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent7Title",
        contents: [
          "gasandOIL3coursecourseContent7Desc1",
          "gasandOIL3coursecourseContent7Desc2",
          "gasandOIL3coursecourseContent7Desc3",
          "gasandOIL3coursecourseContent7Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent8Title",
        contents: [
          "gasandOIL3coursecourseContent8Desc1",
          "gasandOIL3coursecourseContent8Desc2",
          "gasandOIL3coursecourseContent8Desc3",
          "gasandOIL3coursecourseContent8Desc4",
        ],
      },
      {
        title: "gasandOIL3coursecourseContent9Title",
        contents: [
          "gasandOIL3coursecourseContent9Desc1",
          "gasandOIL3coursecourseContent9Desc2",
          "gasandOIL3coursecourseContent9Desc3",
          "gasandOIL3coursecourseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Gas and Oil",
    courseCode: "GO03",
  },
  {
    id: 46,
    image: Oil4,
    title: "gasandOIL4Title",
    desc: "gasandOIL4Desc",
    WhoShouldAttend: [
      "gasandOIL4WhoShouldAttend1",
      "gasandOIL4WhoShouldAttend2",
      "gasandOIL4WhoShouldAttend3",
    ],
    courseExpectation: [
      "gasandOIL4courseExpectation1",
      "gasandOIL4courseExpectation2",
      "gasandOIL4courseExpectation3",
    ],
    courseContent: [
      {
        title: "gasandOIL4coursecourseContent1Title",
        contents: [
          "gasandOIL4coursecourseContent1Desc1",
          "gasandOIL4coursecourseContent1Desc2",
          "gasandOIL4coursecourseContent1Desc3",
          "gasandOIL4coursecourseContent1Desc4",
        ],
      },
      {
        title: "gasandOIL4coursecourseContent2Title",
        contents: [
          "gasandOIL4coursecourseContent2Desc1",
          "gasandOIL4coursecourseContent2Desc2",
          "gasandOIL4coursecourseContent2Desc3",
          "gasandOIL4coursecourseContent2Desc4",
        ],
      },
      {
        title: "gasandOIL4coursecourseContent3Title",
        contents: [
          "gasandOIL4coursecourseContent3Desc1",
          "gasandOIL4coursecourseContent3Desc2",
          "gasandOIL4coursecourseContent3Desc3",
          "gasandOIL4coursecourseContent3Desc4",
        ],
      },
      {
        title: "gasandOIL4coursecourseContent4Title",
        contents: [
          "gasandOIL4coursecourseContent4Desc1",
          "gasandOIL4coursecourseContent4Desc2",
          "gasandOIL4coursecourseContent4Desc3",
          "gasandOIL4coursecourseContent4Desc4",
        ],
      },
      {
        title: "gasandOIL4coursecourseContent5Title",
        contents: [
          "gasandOIL4coursecourseContent5Desc1",
          "gasandOIL4coursecourseContent5Desc2",
          "gasandOIL4coursecourseContent5Desc3",
          "gasandOIL4coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO04",
  },
  {
    id: 47,
    image: Oil5,
    title: "gasandOIL5Title",
    desc: "gasandOIL5Desc",
    WhoShouldAttend: ["gasandOIL5WhoShouldAttend1"],
    courseExpectation: [
      "gasandOIL5courseExpectation1",
      "gasandOIL5courseExpectation2",
      "gasandOIL5courseExpectation3",
      "gasandOIL5courseExpectation4",
      "gasandOIL5courseExpectation5",
      "gasandOIL5courseExpectation6",
      "gasandOIL5courseExpectation7",
    ],
    courseContent: [
      {
        title: "gasandOIL5coursecourseContent1Title",
        contents: [
          "gasandOIL5coursecourseContent1Desc1",
          "gasandOIL5coursecourseContent1Desc2",
          "gasandOIL5coursecourseContent1Desc3",
          "gasandOIL5coursecourseContent1Desc4",
          "gasandOIL5coursecourseContent1Desc5",
          "gasandOIL5coursecourseContent1Desc6",
          "gasandOIL5coursecourseContent1Desc7",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent2Title",
        contents: [
          "gasandOIL5coursecourseContent2Desc1",
          "gasandOIL5coursecourseContent2Desc2",
          "gasandOIL5coursecourseContent2Desc3",
          "gasandOIL5coursecourseContent2Desc4",
          "gasandOIL5coursecourseContent2Desc5",
          "gasandOIL5coursecourseContent2Desc6",
          "gasandOIL5coursecourseContent2Desc7",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent3Title",
        contents: [
          "gasandOIL5coursecourseContent3Desc1",
          "gasandOIL5coursecourseContent3Desc2",
          "gasandOIL5coursecourseContent3Desc3",
          "gasandOIL5coursecourseContent3Desc4",
          "gasandOIL5coursecourseContent3Desc5",
          "gasandOIL5coursecourseContent3Desc6",
          "gasandOIL5coursecourseContent3Desc7",
          "gasandOIL5coursecourseContent3Desc8",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent4Title",
        contents: [
          "gasandOIL5coursecourseContent4Desc1",
          "gasandOIL5coursecourseContent4Desc2",
          "gasandOIL5coursecourseContent4Desc3",
          "gasandOIL5coursecourseContent4Desc4",
          "gasandOIL5coursecourseContent4Desc5",
          "gasandOIL5coursecourseContent4Desc6",
        ],
      },
      {
        title: "gasandOIL5coursecourseContent5Title",
        contents: [
          "gasandOIL5coursecourseContent5Desc1",
          "gasandOIL5coursecourseContent5Desc2",
          "gasandOIL5coursecourseContent5Desc3",
          "gasandOIL5coursecourseContent5Desc4",
          "gasandOIL5coursecourseContent5Desc5",
          "gasandOIL5coursecourseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO05",
  },
  {
    id: 48,
    image: Oil6,
    title: "gasandOIL6Title",
    desc: "gasandOIL6Desc",
    WhoShouldAttend: [
      "gasandOIL6WhoShouldAttend1",
      "gasandOIL6WhoShouldAttend2",
      "gasandOIL6WhoShouldAttend3",
    ],
    courseExpectation: [
      "gasandOIL6courseExpectation1",
      "gasandOIL6courseExpectation2",
      "gasandOIL6courseExpectation3",
      "gasandOIL6courseExpectation4",
    ],
    courseContent: [
      {
        title: "gasandOIL6coursecourseContent1Title",
        contents: [
          "gasandOIL6coursecourseContent1Desc1",
          "gasandOIL6coursecourseContent1Desc2",
          "gasandOIL6coursecourseContent1Desc3",
          "gasandOIL6coursecourseContent1Desc4",
        ],
      },
      {
        title: "gasandOIL6coursecourseContent2Title",
        contents: [
          "gasandOIL6coursecourseContent2Desc1",
          "gasandOIL6coursecourseContent2Desc2",
          "gasandOIL6coursecourseContent2Desc3",
          "gasandOIL6coursecourseContent2Desc4",
        ],
      },
      {
        title: "gasandOIL6coursecourseContent3Title",
        contents: [
          "gasandOIL6coursecourseContent3Desc1",
          "gasandOIL6coursecourseContent3Desc2",
          "gasandOIL6coursecourseContent3Desc3",
          "gasandOIL6coursecourseContent3Desc4",
        ],
      },
      {
        title: "gasandOIL6coursecourseContent4Title",
        contents: [
          "gasandOIL6coursecourseContent4Desc1",
          "gasandOIL6coursecourseContent4Desc2",
          "gasandOIL6coursecourseContent4Desc3",
          "gasandOIL6coursecourseContent4Desc4",
        ],
      },
      {
        title: "gasandOIL6coursecourseContent5Title",
        contents: [
          "gasandOIL6coursecourseContent5Desc1",
          "gasandOIL6coursecourseContent5Desc2",
          "gasandOIL6coursecourseContent5Desc3",
          "gasandOIL6coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO06",
  },
  {
    id: 49,
    image: Oil7,
    title: "gasandOIL7Title",
    desc: "gasandOIL7Desc",
    WhoShouldAttend: [
      "gasandOIL7WhoShouldAttend1",
      "gasandOIL7WhoShouldAttend2",
      "gasandOIL7WhoShouldAttend3",
    ],
    courseExpectation: [
      "gasandOIL7courseExpectation1",
      "gasandOIL7courseExpectation2",
      "gasandOIL7courseExpectation3",
      "gasandOIL7courseExpectation4",
    ],
    courseContent: [
      {
        title: "gasandOIL7coursecourseContent1Title",
        contents: [
          "gasandOIL7coursecourseContent1Desc1",
          "gasandOIL7coursecourseContent1Desc2",
          "gasandOIL7coursecourseContent1Desc3",
          "gasandOIL7coursecourseContent1Desc4",
          "gasandOIL7coursecourseContent1Desc5",
          "gasandOIL7coursecourseContent1Desc6",
          "gasandOIL7coursecourseContent1Desc7",
          "gasandOIL7coursecourseContent1Desc8",
        ],
      },
      {
        title: "gasandOIL7coursecourseContent2Title",
        contents: [
          "gasandOIL7coursecourseContent2Desc1",
          "gasandOIL7coursecourseContent2Desc2",
          "gasandOIL7coursecourseContent2Desc3",
          "gasandOIL7coursecourseContent2Desc4",
          "gasandOIL7coursecourseContent2Desc5",
        ],
      },
      {
        title: "gasandOIL7coursecourseContent3Title",
        contents: [
          "gasandOIL7coursecourseContent3Desc1",
          "gasandOIL7coursecourseContent3Desc2",
          "gasandOIL7coursecourseContent3Desc3",
          "gasandOIL7coursecourseContent3Desc4",
        ],
      },
      {
        title: "gasandOIL7coursecourseContent4Title",
        contents: [
          "gasandOIL7coursecourseContent4Desc1",
          "gasandOIL7coursecourseContent4Desc2",
          "gasandOIL7coursecourseContent4Desc3",
          "gasandOIL7coursecourseContent4Desc4",
          "gasandOIL7coursecourseContent4Desc5",
          "gasandOIL7coursecourseContent4Desc6",
          "gasandOIL7coursecourseContent4Desc7",
          "gasandOIL7coursecourseContent4Desc8",
          "gasandOIL7coursecourseContent4Desc9",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO07",
  },
  // Oil & Gas

  // Human Resources
  {
    id: 50,
    image: HumanResourse1,
    title: "humanResources1Title",
    desc: "humanResources1Desc",
    WhoShouldAttend: [
      "humanResources1WhoShouldAttend1",
      "humanResources1WhoShouldAttend2",
      "humanResources1WhoShouldAttend3",
    ],
    courseExpectation: [
      "humanResources1courseExpectation1",
      "humanResources1courseExpectation2",
      "humanResources1courseExpectation3",
      "humanResources1courseExpectation4",
      "humanResources1courseExpectation5",
      "humanResources1courseExpectation6",
      "humanResources1courseExpectation7",
    ],
    courseContent: [
      {
        title: "humanResources1coursecourseContent1Title",
        contents: [
          "humanResources1coursecourseContent1Desc1",
          "humanResources1coursecourseContent1Desc2",
          "humanResources1coursecourseContent1Desc3",
          "humanResources1coursecourseContent1Desc4",
          "humanResources1coursecourseContent1Desc5",
          "humanResources1coursecourseContent1Desc6",
        ],
      },
      {
        title: "humanResources1coursecourseContent2Title",
        contents: [
          "humanResources1coursecourseContent2Desc1",
          "humanResources1coursecourseContent2Desc2",
          "humanResources1coursecourseContent2Desc3",
          "humanResources1coursecourseContent2Desc4",
          "humanResources1coursecourseContent2Desc5",
          "humanResources1coursecourseContent2Desc6",
        ],
      },
      {
        title: "humanResources1coursecourseContent3Title",
        contents: [
          "humanResources1coursecourseContent3Desc1",
          "humanResources1coursecourseContent3Desc2",
          "humanResources1coursecourseContent3Desc3",
          "humanResources1coursecourseContent3Desc4",
        ],
      },
      {
        title: "humanResources1coursecourseContent4Title",
        contents: [
          "humanResources1coursecourseContent4Desc1",
          "humanResources1coursecourseContent4Desc2",
          "humanResources1coursecourseContent4Desc3",
          "humanResources1coursecourseContent4Desc4",
          "humanResources1coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources1coursecourseContent5Title",
        contents: [
          "humanResources1coursecourseContent5Desc1",
          "humanResources1coursecourseContent5Desc2",
          "humanResources1coursecourseContent5Desc3",
          "humanResources1coursecourseContent5Desc4",
          "humanResources1coursecourseContent5Desc5",
        ],
      },
      {
        title: "humanResources1coursecourseContent6Title",
        contents: [
          "humanResources1coursecourseContent6Desc1",
          "humanResources1coursecourseContent6Desc2",
          "humanResources1coursecourseContent6Desc3",
          "humanResources1coursecourseContent6Desc4",
          "humanResources1coursecourseContent6Desc5",
        ],
      },
      {
        title: "humanResources1coursecourseContent7Title",
        contents: [
          "humanResources1coursecourseContent7Desc1",
          "humanResources1coursecourseContent7Desc2",
          "humanResources1coursecourseContent7Desc3",
          "humanResources1coursecourseContent7Desc4",
          "humanResources1coursecourseContent7Desc5",
        ],
      },
      {
        title: "humanResources1coursecourseContent8Title",
        contents: [
          "humanResources1coursecourseContent8Desc1",
          "humanResources1coursecourseContent8Desc2",
          "humanResources1coursecourseContent8Desc3",
          "humanResources1coursecourseContent8Desc4",
          "humanResources1coursecourseContent8Desc5",
          "humanResources1coursecourseContent8Desc6",
          "humanResources1coursecourseContent8Desc7",
        ],
      },
      {
        title: "humanResources1coursecourseContent9Title",
        contents: [
          "humanResources1coursecourseContent9Desc1",
          "humanResources1coursecourseContent9Desc2",
          "humanResources1coursecourseContent9Desc3",
          "humanResources1coursecourseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Human Resources",
    courseCode: "HR01",
  },
  {
    id: 51,
    image: HumanResourse2,
    title: "humanResources2Title",
    desc: "humanResources2Desc",
    WhoShouldAttend: [
      "humanResources2WhoShouldAttend1",
      "humanResources2WhoShouldAttend2",
      "humanResources2WhoShouldAttend3",
      "humanResources2WhoShouldAttend4",
      "humanResources2WhoShouldAttend5",
    ],
    courseExpectation: [
      "humanResources2courseExpectation1",
      "humanResources2courseExpectation2",
      "humanResources2courseExpectation3",
      "humanResources2courseExpectation4",
      "humanResources2courseExpectation5",
      "humanResources2courseExpectation6",
      "humanResources2courseExpectation7",
      "humanResources2courseExpectation8",
      "humanResources2courseExpectation9",
      "humanResources2courseExpectation10",
      "humanResources2courseExpectation11",
    ],
    courseContent: [
      {
        title: "humanResources2coursecourseContent1Title",
        contents: [
          "humanResources2coursecourseContent1Desc1",
          "humanResources2coursecourseContent1Desc2",
          "humanResources2coursecourseContent1Desc3",
          "humanResources2coursecourseContent1Desc4",
          "humanResources2coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent2Title",
        contents: [
          "humanResources2coursecourseContent2Desc1",
          "humanResources2coursecourseContent2Desc2",
          "humanResources2coursecourseContent2Desc3",
          "humanResources2coursecourseContent2Desc4",
          "humanResources2coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent3Title",
        contents: [
          "humanResources2coursecourseContent3Desc1",
          "humanResources2coursecourseContent3Desc2",
          "humanResources2coursecourseContent3Desc3",
          "humanResources2coursecourseContent3Desc4",
          "humanResources2coursecourseContent3Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent4Title",
        contents: [
          "humanResources2coursecourseContent4Desc1",
          "humanResources2coursecourseContent4Desc2",
          "humanResources2coursecourseContent4Desc3",
          "humanResources2coursecourseContent4Desc4",
          "humanResources2coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent5Title",
        contents: [
          "humanResources2coursecourseContent5Desc1",
          "humanResources2coursecourseContent5Desc2",
          "humanResources2coursecourseContent5Desc3",
          "humanResources2coursecourseContent5Desc4",
          "humanResources2coursecourseContent5Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent6Title",
        contents: [
          "humanResources2coursecourseContent6Desc1",
          "humanResources2coursecourseContent6Desc2",
          "humanResources2coursecourseContent6Desc3",
          "humanResources2coursecourseContent6Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent7Title",
        contents: [
          "humanResources2coursecourseContent7Desc1",
          "humanResources2coursecourseContent7Desc2",
          "humanResources2coursecourseContent7Desc3",
          "humanResources2coursecourseContent7Desc4",
          "humanResources2coursecourseContent7Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent8Title",
        contents: [
          "humanResources2coursecourseContent8Desc1",
          "humanResources2coursecourseContent8Desc2",
          "humanResources2coursecourseContent8Desc3",
          "humanResources2coursecourseContent8Desc4",
          "humanResources2coursecourseContent8Desc5",
        ],
      },
      {
        title: "humanResources2coursecourseContent9Title",
        contents: [
          "humanResources2coursecourseContent9Desc1",
          "humanResources2coursecourseContent9Desc2",
          "humanResources2coursecourseContent9Desc3",
          "humanResources2coursecourseContent9Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent10Title",
        contents: [
          "humanResources2coursecourseContent10Desc1",
          "humanResources2coursecourseContent10Desc2",
          "humanResources2coursecourseContent10Desc3",
          "humanResources2coursecourseContent10Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent11Title",
        contents: [
          "humanResources2coursecourseContent11Desc1",
          "humanResources2coursecourseContent11Desc2",
          "humanResources2coursecourseContent11Desc3",
          "humanResources2coursecourseContent11Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent12Title",
        contents: [
          "humanResources2coursecourseContent12Desc1",
          "humanResources2coursecourseContent12Desc2",
          "humanResources2coursecourseContent12Desc3",
          "humanResources2coursecourseContent12Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent13Title",
        contents: [
          "humanResources2coursecourseContent13Desc1",
          "humanResources2coursecourseContent13Desc2",
          "humanResources2coursecourseContent13Desc3",
          "humanResources2coursecourseContent13Desc4",
        ],
      },
      {
        title: "humanResources2coursecourseContent14Title",
        contents: [
          "humanResources2coursecourseContent14Desc1",
          "humanResources2coursecourseContent14Desc2",
          "humanResources2coursecourseContent14Desc3",
          "humanResources2coursecourseContent14Desc4",
        ],
      },
    ],
    courseFess: 7940,
    category: "Human Resources",
    courseCode: "HR02",
  },
  {
    id: 52,
    image: HumanResourse3,
    title: "humanResources3Title",
    desc: "humanResources3Desc",
    WhoShouldAttend: [
      "humanResources3WhoShouldAttend1",
      "humanResources3WhoShouldAttend2",
      "humanResources3WhoShouldAttend3",
      "humanResources3WhoShouldAttend4",
      "humanResources3WhoShouldAttend5",
    ],
    courseExpectation: [
      "humanResources3courseExpectation1",
      "humanResources3courseExpectation2",
      "humanResources3courseExpectation3",
      "humanResources3courseExpectation4",
      "humanResources3courseExpectation5",
      "humanResources3courseExpectation6",
      "humanResources3courseExpectation7",
    ],
    courseContent: [
      {
        title: "humanResources3coursecourseContent1Title",
        contents: [
          "humanResources3coursecourseContent1Desc1",
          "humanResources3coursecourseContent1Desc2",
          "humanResources3coursecourseContent1Desc3",
          "humanResources3coursecourseContent1Desc4",
          "humanResources3coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources3coursecourseContent2Title",
        contents: [
          "humanResources3coursecourseContent2Desc1",
          "humanResources3coursecourseContent2Desc2",
          "humanResources3coursecourseContent2Desc3",
          "humanResources3coursecourseContent2Desc4",
          "humanResources3coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources3coursecourseContent3Title",
        contents: [
          "humanResources3coursecourseContent3Desc1",
          "humanResources3coursecourseContent3Desc2",
          "humanResources3coursecourseContent3Desc3",
          "humanResources3coursecourseContent3Desc4",
          "humanResources3coursecourseContent3Desc5",
        ],
      },
      {
        title: "humanResources3coursecourseContent4Title",
        contents: [
          "humanResources3coursecourseContent4Desc1",
          "humanResources3coursecourseContent4Desc2",
          "humanResources3coursecourseContent4Desc3",
          "humanResources3coursecourseContent4Desc4",
          "humanResources3coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources3coursecourseContent5Title",
        contents: [
          "humanResources3coursecourseContent5Desc1",
          "humanResources3coursecourseContent5Desc2",
          "humanResources3coursecourseContent5Desc3",
          "humanResources3coursecourseContent5Desc4",
          "humanResources3coursecourseContent5Desc5",
        ],
      },
      {
        title: "humanResources3coursecourseContent6Title",
        contents: [
          "humanResources3coursecourseContent6Desc1",
          "humanResources3coursecourseContent6Desc2",
          "humanResources3coursecourseContent6Desc3",
          "humanResources3coursecourseContent6Desc4",
        ],
      },
      {
        title: "humanResources3coursecourseContent7Title",
        contents: [
          "humanResources3coursecourseContent7Desc1",
          "humanResources3coursecourseContent7Desc2",
          "humanResources3coursecourseContent7Desc3",
          "humanResources3coursecourseContent7Desc4",
          "humanResources3coursecourseContent7Desc5",
        ],
      },
      {
        title: "humanResources3coursecourseContent8Title",
        contents: [
          "humanResources3coursecourseContent8Desc1",
          "humanResources3coursecourseContent8Desc2",
          "humanResources3coursecourseContent8Desc3",
          "humanResources3coursecourseContent8Desc4",
          "humanResources3coursecourseContent8Desc5",
        ],
      },
      {
        title: "humanResources3coursecourseContent9Title",
        contents: [
          "humanResources3coursecourseContent9Desc1",
          "humanResources3coursecourseContent9Desc2",
          "humanResources3coursecourseContent9Desc3",
          "humanResources3coursecourseContent9Desc4",
        ],
      },
      {
        title: "humanResources3coursecourseContent10Title",
        contents: [
          "humanResources3coursecourseContent10Desc1",
          "humanResources3coursecourseContent10Desc2",
          "humanResources3coursecourseContent10Desc3",
          "humanResources3coursecourseContent10Desc4",
        ],
      },
      {
        title: "humanResources3coursecourseContent11Title",
        contents: [
          "humanResources3coursecourseContent11Desc1",
          "humanResources3coursecourseContent11Desc2",
          "humanResources3coursecourseContent11Desc3",
          "humanResources3coursecourseContent11Desc4",
        ],
      },
      {
        title: "humanResources3coursecourseContent12Title",
        contents: [
          "humanResources3coursecourseContent12Desc1",
          "humanResources3coursecourseContent12Desc2",
          "humanResources3coursecourseContent12Desc3",
          "humanResources3coursecourseContent12Desc4",
        ],
      },
      {
        title: "humanResources3coursecourseContent13Title",
        contents: [
          "humanResources3coursecourseContent13Desc1",
          "humanResources3coursecourseContent13Desc2",
          "humanResources3coursecourseContent13Desc3",
          "humanResources3coursecourseContent13Desc4",
        ],
      },
      {
        title: "humanResources3coursecourseContent14Title",
        contents: [
          "humanResources3coursecourseContent14Desc1",
          "humanResources3coursecourseContent14Desc2",
          "humanResources3coursecourseContent14Desc3",
          "humanResources3coursecourseContent14Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Human Resources",
    courseCode: "HR03",
  },
  {
    id: 53,
    image: HumanResourse4,
    title: "humanResources4Title",
    desc: "humanResources4Desc",
    WhoShouldAttend: [
      "humanResources4WhoShouldAttend1",
      "humanResources4WhoShouldAttend2",
      "humanResources4WhoShouldAttend3",
    ],
    courseExpectation: [
      "humanResources4courseExpectation1",
      "humanResources4courseExpectation2",
      "humanResources4courseExpectation3",
      "humanResources4courseExpectation4",
      "humanResources4courseExpectation5",
      "humanResources4courseExpectation6",
      "humanResources4courseExpectation7",
    ],
    courseContent: [
      {
        title: "humanResources4coursecourseContent1Title",
        contents: [
          "humanResources4coursecourseContent1Desc1",
          "humanResources4coursecourseContent1Desc2",
          "humanResources4coursecourseContent1Desc3",
          "humanResources4coursecourseContent1Desc4",
          "humanResources4coursecourseContent1Desc5",
          "humanResources4coursecourseContent1Desc6",
        ],
      },
      {
        title: "humanResources4coursecourseContent2Title",
        contents: [
          "humanResources4coursecourseContent2Desc1",
          "humanResources4coursecourseContent2Desc2",
          "humanResources4coursecourseContent2Desc3",
          "humanResources4coursecourseContent2Desc4",
          "humanResources4coursecourseContent2Desc5",
          "humanResources4coursecourseContent2Desc6",
        ],
      },
      {
        title: "humanResources4coursecourseContent3Title",
        contents: [
          "humanResources4coursecourseContent3Desc1",
          "humanResources4coursecourseContent3Desc2",
          "humanResources4coursecourseContent3Desc3",
          "humanResources4coursecourseContent3Desc4",
        ],
      },
      {
        title: "humanResources4coursecourseContent4Title",
        contents: [
          "humanResources4coursecourseContent4Desc1",
          "humanResources4coursecourseContent4Desc2",
          "humanResources4coursecourseContent4Desc3",
          "humanResources4coursecourseContent4Desc4",
          "humanResources4coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources4coursecourseContent5Title",
        contents: [
          "humanResources4coursecourseContent5Desc1",
          "humanResources4coursecourseContent5Desc2",
          "humanResources4coursecourseContent5Desc3",
          "humanResources4coursecourseContent5Desc4",
          "humanResources4coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR04",
  },
  {
    id: 54,
    image: HumanResourse5,
    title: "humanResources5Title",
    desc: "humanResources5Desc",
    WhoShouldAttend: [
      "humanResources5WhoShouldAttend1",
      "humanResources5WhoShouldAttend2",
      "humanResources5WhoShouldAttend3",
    ],
    courseExpectation: [
      "humanResources5courseExpectation1",
      "humanResources5courseExpectation2",
      "humanResources5courseExpectation3",
      "humanResources5courseExpectation4",
      "humanResources5courseExpectation5",
    ],
    courseContent: [
      {
        title: "humanResources5coursecourseContent1Title",
        contents: [
          "humanResources5coursecourseContent1Desc1",
          "humanResources5coursecourseContent1Desc2",
          "humanResources5coursecourseContent1Desc3",
          "humanResources5coursecourseContent1Desc4",
          "humanResources5coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources5coursecourseContent2Title",
        contents: [
          "humanResources5coursecourseContent2Desc1",
          "humanResources5coursecourseContent2Desc2",
          "humanResources5coursecourseContent2Desc3",
          "humanResources5coursecourseContent2Desc4",
          "humanResources5coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources5coursecourseContent3Title",
        contents: [
          "humanResources5coursecourseContent3Desc1",
          "humanResources5coursecourseContent3Desc2",
          "humanResources5coursecourseContent3Desc3",
          "humanResources5coursecourseContent3Desc4",
          "humanResources5coursecourseContent3Desc5",
        ],
      },
      {
        title: "humanResources5coursecourseContent4Title",
        contents: [
          "humanResources5coursecourseContent4Desc1",
          "humanResources5coursecourseContent4Desc2",
          "humanResources5coursecourseContent4Desc3",
          "humanResources5coursecourseContent4Desc4",
          "humanResources5coursecourseContent4Desc5",
          "humanResources5coursecourseContent4Desc6",
          "humanResources5coursecourseContent4Desc7",
        ],
      },
      {
        title: "humanResources5coursecourseContent5Title",
        contents: [
          "humanResources5coursecourseContent5Desc1",
          "humanResources5coursecourseContent5Desc2",
          "humanResources5coursecourseContent5Desc3",
          "humanResources5coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR05",
  },
  {
    id: 55,
    image: HumanResourse6,
    title: "humanResources6Title",
    desc: "humanResources6Desc",
    WhoShouldAttend: [
      "humanResources6WhoShouldAttend1",
      "humanResources6WhoShouldAttend2",
      "humanResources6WhoShouldAttend3",
    ],
    courseExpectation: [
      "humanResources6courseExpectation1",
      "humanResources6courseExpectation2",
      "humanResources6courseExpectation3",
      "humanResources6courseExpectation4",
      "humanResources6courseExpectation5",
      "humanResources6courseExpectation6",
      "humanResources6courseExpectation7",
    ],
    courseContent: [
      {
        title: "humanResources6coursecourseContent1Title",
        contents: [
          "humanResources6coursecourseContent1Desc1",
          "humanResources6coursecourseContent1Desc2",
          "humanResources6coursecourseContent1Desc3",
          "humanResources6coursecourseContent1Desc4",
          "humanResources6coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources6coursecourseContent2Title",
        contents: [
          "humanResources6coursecourseContent2Desc1",
          "humanResources6coursecourseContent2Desc2",
          "humanResources6coursecourseContent2Desc3",
          "humanResources6coursecourseContent2Desc4",
          "humanResources6coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources6coursecourseContent3Title",
        contents: [
          "humanResources6coursecourseContent3Desc1",
          "humanResources6coursecourseContent3Desc2",
          "humanResources6coursecourseContent3Desc3",
          "humanResources6coursecourseContent3Desc4",
        ],
      },
      {
        title: "humanResources6coursecourseContent4Title",
        contents: [
          "humanResources6coursecourseContent4Desc1",
          "humanResources6coursecourseContent4Desc2",
          "humanResources6coursecourseContent4Desc3",
          "humanResources6coursecourseContent4Desc4",
          "humanResources6coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources6coursecourseContent5Title",
        contents: [
          "humanResources6coursecourseContent5Desc1",
          "humanResources6coursecourseContent5Desc2",
          "humanResources6coursecourseContent5Desc3",
          "humanResources6coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR06",
  },
  {
    id: 56,
    image: HumanResourse7,
    title: "humanResources7Title",
    desc: "humanResources7Desc",
    WhoShouldAttend: [
      "humanResources7WhoShouldAttend1",
      "humanResources7WhoShouldAttend2",
      "humanResources7WhoShouldAttend3",
      "humanResources7WhoShouldAttend4",
    ],
    courseExpectation: [
      "humanResources7courseExpectation1",
      "humanResources7courseExpectation2",
      "humanResources7courseExpectation3",
      "humanResources7courseExpectation4",
    ],
    courseContent: [
      {
        title: "humanResources7coursecourseContent1Title",
        contents: [
          "humanResources7coursecourseContent1Desc1",
          "humanResources7coursecourseContent1Desc2",
          "humanResources7coursecourseContent1Desc3",
          "humanResources7coursecourseContent1Desc4",
        ],
      },
      {
        title: "humanResources7coursecourseContent2Title",
        contents: [
          "humanResources7coursecourseContent2Desc1",
          "humanResources7coursecourseContent2Desc2",
          "humanResources7coursecourseContent2Desc3",
          "humanResources7coursecourseContent2Desc4",
          "humanResources7coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources7coursecourseContent3Title",
        contents: [
          "humanResources7coursecourseContent3Desc1",
          "humanResources7coursecourseContent3Desc2",
          "humanResources7coursecourseContent3Desc3",
          "humanResources7coursecourseContent3Desc4",
          "humanResources7coursecourseContent3Desc5",
          "humanResources7coursecourseContent3Desc6",
        ],
      },
      {
        title: "humanResources7coursecourseContent4Title",
        contents: [
          "humanResources7coursecourseContent4Desc1",
          "humanResources7coursecourseContent4Desc2",
          "humanResources7coursecourseContent4Desc3",
          "humanResources7coursecourseContent4Desc4",
          "humanResources7coursecourseContent4Desc5",
          "humanResources7coursecourseContent4Desc6",
        ],
      },
      {
        title: "humanResources7coursecourseContent5Title",
        contents: [
          "humanResources7coursecourseContent5Desc1",
          "humanResources7coursecourseContent5Desc2",
          "humanResources7coursecourseContent5Desc3",
          "humanResources7coursecourseContent5Desc4",
          "humanResources7coursecourseContent5Desc5",
          "humanResources7coursecourseContent5Desc6",
          "humanResources7coursecourseContent5Desc7",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR07",
  },
  {
    id: 57,
    image: HumanResourse8,
    title: "humanResources8Title",
    desc: "humanResources8Desc",
    WhoShouldAttend: ["humanResources8WhoShouldAttend1"],
    courseExpectation: [
      "humanResources8courseExpectation1",
      "humanResources8courseExpectation2",
      "humanResources8courseExpectation3",
      "humanResources8courseExpectation4",
    ],
    courseContent: [
      {
        title: "humanResources8coursecourseContent1Title",
        contents: [
          "humanResources8coursecourseContent1Desc1",
          "humanResources8coursecourseContent1Desc2",
          "humanResources8coursecourseContent1Desc3",
          "humanResources8coursecourseContent1Desc4",
          "humanResources8coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources8coursecourseContent2Title",
        contents: [
          "humanResources8coursecourseContent2Desc1",
          "humanResources8coursecourseContent2Desc2",
          "humanResources8coursecourseContent2Desc3",
          "humanResources8coursecourseContent2Desc4",
          "humanResources8coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources8coursecourseContent3Title",
        contents: [
          "humanResources8coursecourseContent3Desc1",
          "humanResources8coursecourseContent3Desc2",
          "humanResources8coursecourseContent3Desc3",
          "humanResources8coursecourseContent3Desc4",
          "humanResources8coursecourseContent3Desc5",
        ],
      },
      {
        title: "humanResources8coursecourseContent4Title",
        contents: [
          "humanResources8coursecourseContent4Desc1",
          "humanResources8coursecourseContent4Desc2",
          "humanResources8coursecourseContent4Desc3",
          "humanResources8coursecourseContent4Desc4",
          "humanResources8coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources8coursecourseContent5Title",
        contents: [
          "humanResources8coursecourseContent5Desc1",
          "humanResources8coursecourseContent5Desc2",
          "humanResources8coursecourseContent5Desc3",
          "humanResources8coursecourseContent5Desc4",
          "humanResources8coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR08",
  },
  {
    id: 58,
    image: HumanResourse9,
    title: "humanResources9Title",
    desc: "humanResources9Desc",
    WhoShouldAttend: [
      "humanResources9WhoShouldAttend1",
      "humanResources9WhoShouldAttend2",
    ],
    courseExpectation: [
      "humanResources9courseExpectation1",
      "humanResources9courseExpectation2",
      "humanResources9courseExpectation3",
      "humanResources9courseExpectation4",
    ],
    courseContent: [
      {
        title: "humanResources9coursecourseContent1Title",
        contents: [
          "humanResources9coursecourseContent1Desc1",
          "humanResources9coursecourseContent1Desc2",
          "humanResources9coursecourseContent1Desc3",
          "humanResources9coursecourseContent1Desc4",
          "humanResources9coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources9coursecourseContent2Title",
        contents: [
          "humanResources9coursecourseContent2Desc1",
          "humanResources9coursecourseContent2Desc2",
          "humanResources9coursecourseContent2Desc3",
        ],
      },
      {
        title: "humanResources9coursecourseContent3Title",
        contents: [
          "humanResources9coursecourseContent3Desc1",
          "humanResources9coursecourseContent3Desc2",
          "humanResources9coursecourseContent3Desc3",
          "humanResources9coursecourseContent3Desc4",
          "humanResources9coursecourseContent3Desc5",
        ],
      },
      {
        title: "humanResources9coursecourseContent4Title",
        contents: [
          "humanResources9coursecourseContent4Desc1",
          "humanResources9coursecourseContent4Desc2",
          "humanResources9coursecourseContent4Desc3",
          "humanResources9coursecourseContent4Desc4",
          "humanResources9coursecourseContent4Desc5",
        ],
      },
      {
        title: "humanResources9coursecourseContent5Title",
        contents: [
          "humanResources9coursecourseContent5Desc1",
          "humanResources9coursecourseContent5Desc2",
          "humanResources9coursecourseContent5Desc3",
          "humanResources9coursecourseContent5Desc4",
          "humanResources9coursecourseContent5Desc5",
          "humanResources9coursecourseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR09",
  },
  {
    id: 59,
    image: HumanResourse10,
    title: "humanResources10Title",
    desc: "humanResources10Desc",
    WhoShouldAttend: ["humanResources10WhoShouldAttend1"],
    courseExpectation: [
      "humanResources10courseExpectation1",
      "humanResources10courseExpectation2",
      "humanResources10courseExpectation3",
      "humanResources10courseExpectation4",
      "humanResources10courseExpectation5",
    ],
    courseContent: [
      {
        title: "humanResources10coursecourseContent1Title",
        contents: [
          "humanResources10coursecourseContent1Desc1",
          "humanResources10coursecourseContent1Desc2",
          "humanResources10coursecourseContent1Desc3",
        ],
      },
      {
        title: "humanResources10coursecourseContent2Title",
        contents: [
          "humanResources10coursecourseContent2Desc1",
          "humanResources10coursecourseContent2Desc2",
          "humanResources10coursecourseContent2Desc3",
          "humanResources10coursecourseContent2Desc4",
          "humanResources10coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources10coursecourseContent3Title",
        contents: [
          "humanResources10coursecourseContent3Desc1",
          "humanResources10coursecourseContent3Desc2",
          "humanResources10coursecourseContent3Desc3",
          "humanResources10coursecourseContent3Desc4",
        ],
      },
      {
        title: "humanResources10coursecourseContent4Title",
        contents: [
          "humanResources10coursecourseContent4Desc1",
          "humanResources10coursecourseContent4Desc2",
          "humanResources10coursecourseContent4Desc3",
          "humanResources10coursecourseContent4Desc4",
        ],
      },
      {
        title: "humanResources10coursecourseContent5Title",
        contents: [
          "humanResources10coursecourseContent5Desc1",
          "humanResources10coursecourseContent5Desc2",
          "humanResources10coursecourseContent5Desc3",
          "humanResources10coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR10",
  },
  {
    id: 60,
    image: HumanResourse11,
    title: "humanResources11Title",
    desc: "humanResources11Desc",
    WhoShouldAttend: [
      "humanResources11WhoShouldAttend1",
      "humanResources11WhoShouldAttend2",
      "humanResources11WhoShouldAttend3",
    ],
    courseExpectation: [
      "humanResources11courseExpectation1",
      "humanResources11courseExpectation2",
      "humanResources11courseExpectation3",
      "humanResources11courseExpectation4",
    ],
    courseContent: [
      {
        title: "humanResources11coursecourseContent1Title",
        contents: [
          "humanResources11coursecourseContent1Desc1",
          "humanResources11coursecourseContent1Desc2",
          "humanResources11coursecourseContent1Desc3",
          "humanResources11coursecourseContent1Desc4",
          "humanResources11coursecourseContent1Desc5",
          "humanResources11coursecourseContent1Desc6",
        ],
      },
      {
        title: "humanResources11coursecourseContent2Title",
        contents: [
          "humanResources11coursecourseContent2Desc1",
          "humanResources11coursecourseContent2Desc2",
          "humanResources11coursecourseContent2Desc3",
          "humanResources11coursecourseContent2Desc4",
          "humanResources11coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources11coursecourseContent3Title",
        contents: [
          "humanResources11coursecourseContent3Desc1",
          "humanResources11coursecourseContent3Desc2",
          "humanResources11coursecourseContent3Desc3",
          "humanResources11coursecourseContent3Desc4",
          "humanResources11coursecourseContent3Desc5",
        ],
      },
      {
        title: "humanResources11coursecourseContent4Title",
        contents: [
          "humanResources11coursecourseContent4Desc1",
          "humanResources11coursecourseContent4Desc2",
          "humanResources11coursecourseContent4Desc3",
        ],
      },
      {
        title: "humanResources11coursecourseContent5Title",
        contents: [
          "humanResources11coursecourseContent5Desc1",
          "humanResources11coursecourseContent5Desc2",
          "humanResources11coursecourseContent5Desc3",
          "humanResources11coursecourseContent5Desc4",
          "humanResources11coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR11",
  },
  {
    id: 61,
    image: HumanResourse12,
    title: "humanResources12Title",
    desc: "humanResources12Desc",
    WhoShouldAttend: [
      "humanResources12WhoShouldAttend1",
      "humanResources12WhoShouldAttend2",
      "humanResources12WhoShouldAttend3",
    ],
    courseExpectation: [
      "humanResources12courseExpectation1",
      "humanResources12courseExpectation2",
      "humanResources12courseExpectation3",
      "humanResources12courseExpectation4",
      "humanResources12courseExpectation5",
    ],
    courseContent: [
      {
        title: "humanResources12coursecourseContent1Title",
        contents: [
          "humanResources12coursecourseContent1Desc1",
          "humanResources12coursecourseContent1Desc2",
          "humanResources12coursecourseContent1Desc3",
          "humanResources12coursecourseContent1Desc4",
          "humanResources12coursecourseContent1Desc5",
        ],
      },
      {
        title: "humanResources12coursecourseContent2Title",
        contents: [
          "humanResources12coursecourseContent2Desc1",
          "humanResources12coursecourseContent2Desc2",
          "humanResources12coursecourseContent2Desc3",
          "humanResources12coursecourseContent2Desc4",
          "humanResources12coursecourseContent2Desc5",
        ],
      },
      {
        title: "humanResources12coursecourseContent3Title",
        contents: [
          "humanResources12coursecourseContent3Desc1",
          "humanResources12coursecourseContent3Desc2",
          "humanResources12coursecourseContent3Desc3",
          "humanResources12coursecourseContent3Desc4",
          "humanResources12coursecourseContent3Desc5",
          "humanResources12coursecourseContent3Desc6",
        ],
      },
      {
        title: "humanResources12coursecourseContent4Title",
        contents: [
          "humanResources12coursecourseContent4Desc1",
          "humanResources12coursecourseContent4Desc2",
          "humanResources12coursecourseContent4Desc3",
          "humanResources12coursecourseContent4Desc4",
          "humanResources12coursecourseContent4Desc5",
          "humanResources12coursecourseContent4Desc6",
          "humanResources12coursecourseContent4Desc7",
        ],
      },
      {
        title: "humanResources12coursecourseContent5Title",
        contents: [
          "humanResources12coursecourseContent5Desc1",
          "humanResources12coursecourseContent5Desc2",
          "humanResources12coursecourseContent5Desc3",
          "humanResources12coursecourseContent5Desc4",
          "humanResources12coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR12",
  },
  // Human Resources

  // Law
  {
    id: 62,
    image: Law1,
    title: "Law1Title",
    desc: "Law1Desc",
    WhoShouldAttend: [
      "Law1WhoShouldAttend1",
      "Law1WhoShouldAttend2",
      "Law1WhoShouldAttend3",
      "Law1WhoShouldAttend4",
      "Law1WhoShouldAttend5",
      "Law1WhoShouldAttend6",
    ],
    courseExpectation: [
      "Law1courseExpectation1",
      "Law1courseExpectation2",
      "Law1courseExpectation3",
      "Law1courseExpectation4",
      "Law1courseExpectation5",
      "Law1courseExpectation6",
      "Law1courseExpectation7",
      "Law1courseExpectation8",
      "Law1courseExpectation9",
    ],
    courseContent: [
      {
        title: "Law1coursecourseContent1Title",
        contents: [
          "Law1coursecourseContent1Desc1",
          "Law1coursecourseContent1Desc2",
          "Law1coursecourseContent1Desc3",
          "Law1coursecourseContent1Desc4",
          "Law1coursecourseContent1Desc5",
          "Law1coursecourseContent1Desc6",
          "Law1coursecourseContent1Desc7",
        ],
      },
      {
        title: "Law1coursecourseContent2Title",
        contents: [
          "Law1coursecourseContent2Desc1",
          "Law1coursecourseContent2Desc2",
          "Law1coursecourseContent2Desc3",
          "Law1coursecourseContent2Desc4",
          "Law1coursecourseContent2Desc5",
          "Law1coursecourseContent2Desc6",
          "Law1coursecourseContent2Desc7",
          "Law1coursecourseContent2Desc8",
          "Law1coursecourseContent2Desc9",
        ],
      },
      {
        title: "Law1coursecourseContent3Title",
        contents: [
          "Law1coursecourseContent3Desc1",
          "Law1coursecourseContent3Desc2",
          "Law1coursecourseContent3Desc3",
          "Law1coursecourseContent3Desc4",
          "Law1coursecourseContent3Desc5",
          "Law1coursecourseContent3Desc6",
          "Law1coursecourseContent3Desc7",
        ],
      },
      {
        title: "Law1coursecourseContent4Title",
        contents: [
          "Law1coursecourseContent4Desc1",
          "Law1coursecourseContent4Desc2",
          "Law1coursecourseContent4Desc3",
          "Law1coursecourseContent4Desc4",
          "Law1coursecourseContent4Desc5",
          "Law1coursecourseContent4Desc6",
          "Law1coursecourseContent4Desc7",
        ],
      },
      {
        title: "Law1coursecourseContent5Title",
        contents: [
          "Law1coursecourseContent5Desc1",
          "Law1coursecourseContent5Desc2",
          "Law1coursecourseContent5Desc3",
          "Law1coursecourseContent5Desc4",
          "Law1coursecourseContent5Desc5",
          "Law1coursecourseContent5Desc6",
          "Law1coursecourseContent5Desc7",
          "Law1coursecourseContent5Desc8",
        ],
      },
      {
        title: "Law1coursecourseContent6Title",
        contents: [
          "Law1coursecourseContent6Desc1",
          "Law1coursecourseContent6Desc2",
          "Law1coursecourseContent6Desc3",
          "Law1coursecourseContent6Desc4",
          "Law1coursecourseContent6Desc5",
          "Law1coursecourseContent6Desc6",
          "Law1coursecourseContent6Desc7",
          "Law1coursecourseContent6Desc8",
          "Law1coursecourseContent6Desc9",
          "Law1coursecourseContent6Desc10",
          "Law1coursecourseContent6Desc11",
        ],
      },
      {
        title: "Law1coursecourseContent7Title",
        contents: [
          "Law1coursecourseContent7Desc1",
          "Law1coursecourseContent7Desc2",
          "Law1coursecourseContent7Desc3",
          "Law1coursecourseContent7Desc4",
          "Law1coursecourseContent7Desc5",
          "Law1coursecourseContent7Desc6",
          "Law1coursecourseContent7Desc7",
          "Law1coursecourseContent7Desc8",
          "Law1coursecourseContent7Desc9",
        ],
      },
      {
        title: "Law1coursecourseContent8Title",
        contents: [
          "Law1coursecourseContent8Desc1",
          "Law1coursecourseContent8Desc2",
          "Law1coursecourseContent8Desc3",
          "Law1coursecourseContent8Desc4",
          "Law1coursecourseContent8Desc5",
          "Law1coursecourseContent8Desc6",
          "Law1coursecourseContent8Desc7",
          "Law1coursecourseContent8Desc8",
        ],
      },
      {
        title: "Law1coursecourseContent9Title",
        contents: [
          "Law1coursecourseContent9Desc1",
          "Law1coursecourseContent9Desc2",
          "Law1coursecourseContent9Desc3",
          "Law1coursecourseContent9Desc4",
          "Law1coursecourseContent9Desc5",
          "Law1coursecourseContent9Desc6",
          "Law1coursecourseContent9Desc7",
          "Law1coursecourseContent9Desc8",
        ],
      },
    ],
    courseFess: 6080,
    category: "Law",
    courseCode: "LW01",
  },
  {
    id: 63,
    image: Law2,
    title: "Law2Title",
    desc: "Law2Desc",
    WhoShouldAttend: [
      "Law2WhoShouldAttend1",
      "Law2WhoShouldAttend2",
      "Law2WhoShouldAttend3",
      "Law2WhoShouldAttend4",
      "Law2WhoShouldAttend5",
      "Law2WhoShouldAttend6",
      "Law2WhoShouldAttend7",
      "Law2WhoShouldAttend8",
      "Law2WhoShouldAttend9",
    ],
    courseExpectation: [
      "Law2courseExpectation1",
      "Law2courseExpectation2",
      "Law2courseExpectation3",
      "Law2courseExpectation4",
      "Law2courseExpectation5",
      "Law2courseExpectation6",
      "Law2courseExpectation7",
      "Law2courseExpectation8",
    ],
    courseContent: [
      {
        title: "Law2coursecourseContent1Title",
        contents: [
          "Law2coursecourseContent1Desc1",
          "Law2coursecourseContent1Desc2",
          "Law2coursecourseContent1Desc3",
          "Law2coursecourseContent1Desc4",
          "Law2coursecourseContent1Desc5",
          "Law2coursecourseContent1Desc6",
        ],
      },
      {
        title: "Law2coursecourseContent2Title",
        contents: [
          "Law2coursecourseContent2Desc1",
          "Law2coursecourseContent2Desc2",
          "Law2coursecourseContent2Desc3",
          "Law2coursecourseContent2Desc4",
          "Law2coursecourseContent2Desc5",
        ],
      },
      {
        title: "Law2coursecourseContent3Title",
        contents: [
          "Law2coursecourseContent3Desc1",
          "Law2coursecourseContent3Desc2",
          "Law2coursecourseContent3Desc3",
          "Law2coursecourseContent3Desc4",
          "Law2coursecourseContent3Desc5",
        ],
      },
      {
        title: "Law2coursecourseContent4Title",
        contents: [
          "Law2coursecourseContent4Desc1",
          "Law2coursecourseContent4Desc2",
          "Law2coursecourseContent4Desc3",
          "Law2coursecourseContent4Desc4",
          "Law2coursecourseContent4Desc5",
        ],
      },
      {
        title: "Law2coursecourseContent5Title",
        contents: [
          "Law2coursecourseContent5Desc1",
          "Law2coursecourseContent5Desc2",
          "Law2coursecourseContent5Desc3",
          "Law2coursecourseContent5Desc4",
          "Law2coursecourseContent5Desc5",
          "Law2coursecourseContent5Desc6",
        ],
      },
      {
        title: "Law2coursecourseContent6Title",
        contents: [
          "Law2coursecourseContent6Desc1",
          "Law2coursecourseContent6Desc2",
          "Law2coursecourseContent6Desc3",
          "Law2coursecourseContent6Desc4",
        ],
      },
      {
        title: "Law2coursecourseContent7Title",
        contents: [
          "Law2coursecourseContent7Desc1",
          "Law2coursecourseContent7Desc2",
          "Law2coursecourseContent7Desc3",
          "Law2coursecourseContent7Desc4",
        ],
      },
      {
        title: "Law2coursecourseContent8Title",
        contents: [
          "Law2coursecourseContent8Desc1",
          "Law2coursecourseContent8Desc2",
          "Law2coursecourseContent8Desc3",
          "Law2coursecourseContent8Desc4",
          "Law2coursecourseContent8Desc5",
        ],
      },
      {
        title: "Law2coursecourseContent9Title",
        contents: [
          "Law2coursecourseContent9Desc1",
          "Law2coursecourseContent9Desc2",
          "Law2coursecourseContent9Desc3",
          "Law2coursecourseContent9Desc4",
          "Law2coursecourseContent9Desc5",
          "Law2coursecourseContent9Desc6",
        ],
      },
    ],
    courseFess: 6080,
    category: "Law",
    courseCode: "LW02",
  },
  {
    id: 64,
    image: Law3,
    title: "Law3Title",
    desc: "Law3Desc",
    WhoShouldAttend: [
      "Law3WhoShouldAttend1",
      "Law3WhoShouldAttend2",
      "Law3WhoShouldAttend3",
      "Law3WhoShouldAttend4",
      "Law3WhoShouldAttend5",
      "Law3WhoShouldAttend6",
      "Law3WhoShouldAttend7",
    ],
    courseExpectation: [
      "Law3courseExpectation1",
      "Law3courseExpectation2",
      "Law3courseExpectation3",
      "Law3courseExpectation4",
      "Law3courseExpectation5",
      "Law3courseExpectation6",
      "Law3courseExpectation7",
      "Law3courseExpectation8",
      "Law3courseExpectation9",
      "Law3courseExpectation10",
    ],
    courseContent: [
      {
        title: "Law3coursecourseContent1Title",
        contents: [
          "Law3coursecourseContent1Desc1",
          "Law3coursecourseContent1Desc2",
          "Law3coursecourseContent1Desc3",
          "Law3coursecourseContent1Desc4",
          "Law3coursecourseContent1Desc5",
          "Law3coursecourseContent1Desc6",
          "Law3coursecourseContent1Desc7",
        ],
      },
      {
        title: "Law3coursecourseContent2Title",
        contents: [
          "Law3coursecourseContent2Desc1",
          "Law3coursecourseContent2Desc2",
          "Law3coursecourseContent2Desc3",
          "Law3coursecourseContent2Desc4",
          "Law3coursecourseContent2Desc5",
          "Law3coursecourseContent2Desc6",
          "Law3coursecourseContent2Desc7",
          "Law3coursecourseContent2Desc8",
        ],
      },
      {
        title: "Law3coursecourseContent3Title",
        contents: [
          "Law3coursecourseContent3Desc1",
          "Law3coursecourseContent3Desc2",
          "Law3coursecourseContent3Desc3",
          "Law3coursecourseContent3Desc4",
          "Law3coursecourseContent3Desc5",
          "Law3coursecourseContent3Desc6",
          "Law3coursecourseContent3Desc7",
        ],
      },
      {
        title: "Law3coursecourseContent4Title",
        contents: [
          "Law3coursecourseContent4Desc1",
          "Law3coursecourseContent4Desc2",
          "Law3coursecourseContent4Desc3",
          "Law3coursecourseContent4Desc4",
          "Law3coursecourseContent4Desc5",
          "Law3coursecourseContent4Desc6",
        ],
      },
      {
        title: "Law3coursecourseContent5Title",
        contents: [
          "Law3coursecourseContent5Desc1",
          "Law3coursecourseContent5Desc2",
          "Law3coursecourseContent5Desc3",
          "Law3coursecourseContent5Desc4",
          "Law3coursecourseContent5Desc5",
          "Law3coursecourseContent5Desc6",
          "Law3coursecourseContent5Desc7",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW03",
  },
  {
    id: 65,
    image: Law4,
    title: "Law4Title",
    desc: "Law4Desc",
    WhoShouldAttend: [
      "Law4WhoShouldAttend1",
      "Law4WhoShouldAttend2",
      "Law4WhoShouldAttend3",
      "Law4WhoShouldAttend4",
      "Law4WhoShouldAttend5",
    ],
    courseExpectation: [
      "Law4courseExpectation1",
      "Law4courseExpectation2",
      "Law4courseExpectation3",
      "Law4courseExpectation4",
      "Law4courseExpectation5",
      "Law4courseExpectation6",
      "Law4courseExpectation7",
    ],
    courseContent: [
      {
        title: "Law4coursecourseContent1Title",
        contents: [
          "Law4coursecourseContent1Desc1",
          "Law4coursecourseContent1Desc2",
          "Law4coursecourseContent1Desc3",
          "Law4coursecourseContent1Desc4",
          "Law4coursecourseContent1Desc5",
        ],
      },
      {
        title: "Law4coursecourseContent2Title",
        contents: [
          "Law4coursecourseContent2Desc1",
          "Law4coursecourseContent2Desc2",
          "Law4coursecourseContent2Desc3",
          "Law4coursecourseContent2Desc4",
        ],
      },
      {
        title: "Law4coursecourseContent3Title",
        contents: [
          "Law4coursecourseContent3Desc1",
          "Law4coursecourseContent3Desc2",
          "Law4coursecourseContent3Desc3",
          "Law4coursecourseContent3Desc4",
        ],
      },
      {
        title: "Law4coursecourseContent4Title",
        contents: [
          "Law4coursecourseContent4Desc1",
          "Law4coursecourseContent4Desc2",
          "Law4coursecourseContent4Desc3",
          "Law4coursecourseContent4Desc4",
        ],
      },
      {
        title: "Law4coursecourseContent5Title",
        contents: [
          "Law4coursecourseContent5Desc1",
          "Law4coursecourseContent5Desc2",
          "Law4coursecourseContent5Desc3",
          "Law4coursecourseContent5Desc4",
          "Law4coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW04",
  },
  {
    id: 66,
    image: Law5,
    title: "Law5Title",
    desc: "Law5Desc",
    WhoShouldAttend: ["Law5WhoShouldAttend1"],
    courseExpectation: [
      "Law5courseExpectation1",
      "Law5courseExpectation2",
      "Law5courseExpectation3",
      "Law5courseExpectation4",
      "Law5courseExpectation5",
    ],
    courseContent: [
      {
        title: "Law5coursecourseContent1Title",
        contents: [
          "Law5coursecourseContent1Desc1",
          "Law5coursecourseContent1Desc2",
          "Law5coursecourseContent1Desc3",
          "Law5coursecourseContent1Desc4",
        ],
      },
      {
        title: "Law5coursecourseContent2Title",
        contents: [
          "Law5coursecourseContent2Desc1",
          "Law5coursecourseContent2Desc2",
          "Law5coursecourseContent2Desc3",
          "Law5coursecourseContent2Desc4",
        ],
      },
      {
        title: "Law5coursecourseContent3Title",
        contents: [
          "Law5coursecourseContent3Desc1",
          "Law5coursecourseContent3Desc2",
          "Law5coursecourseContent3Desc3",
          "Law5coursecourseContent3Desc4",
        ],
      },
      {
        title: "Law5coursecourseContent4Title",
        contents: [
          "Law5coursecourseContent4Desc1",
          "Law5coursecourseContent4Desc2",
          "Law5coursecourseContent4Desc3",
          "Law5coursecourseContent4Desc4",
        ],
      },
      {
        title: "Law5coursecourseContent5Title",
        contents: [
          "Law5coursecourseContent5Desc1",
          "Law5coursecourseContent5Desc2",
          "Law5coursecourseContent5Desc3",
          "Law5coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW05",
  },
  {
    id: 67,
    image: Law6,
    title: "Law6Title",
    desc: "Law6Desc",
    WhoShouldAttend: [
      "Law6WhoShouldAttend1",
      "Law6WhoShouldAttend2",
      "Law6WhoShouldAttend3",
      "Law6WhoShouldAttend4",
      "Law6WhoShouldAttend5",
      "Law6WhoShouldAttend6",
      "Law6WhoShouldAttend7",
    ],
    courseExpectation: [
      "Law6courseExpectation1",
      "Law6courseExpectation2",
      "Law6courseExpectation3",
      "Law6courseExpectation4",
      "Law6courseExpectation5",
      "Law6courseExpectation6",
      "Law6courseExpectation7",
    ],
    courseContent: [
      {
        title: "Law6coursecourseContent1Title",
        contents: [
          "Law6coursecourseContent1Desc1",
          "Law6coursecourseContent1Desc2",
          "Law6coursecourseContent1Desc3",
          "Law6coursecourseContent1Desc4",
          "Law6coursecourseContent1Desc5",
          "Law6coursecourseContent1Desc6",
        ],
      },
      {
        title: "Law6coursecourseContent2Title",
        contents: [
          "Law6coursecourseContent2Desc1",
          "Law6coursecourseContent2Desc2",
          "Law6coursecourseContent2Desc3",
          "Law6coursecourseContent2Desc4",
          "Law6coursecourseContent2Desc5",
          "Law6coursecourseContent2Desc6",
          "Law6coursecourseContent2Desc7",
        ],
      },
      {
        title: "Law6coursecourseContent3Title",
        contents: [
          "Law6coursecourseContent3Desc1",
          "Law6coursecourseContent3Desc2",
          "Law6coursecourseContent3Desc3",
          "Law6coursecourseContent3Desc4",
          "Law6coursecourseContent3Desc5",
          "Law6coursecourseContent3Desc6",
        ],
      },
      {
        title: "Law6coursecourseContent4Title",
        contents: [
          "Law6coursecourseContent4Desc1",
          "Law6coursecourseContent4Desc2",
          "Law6coursecourseContent4Desc3",
          "Law6coursecourseContent4Desc4",
          "Law6coursecourseContent4Desc5",
          "Law6coursecourseContent4Desc6",
        ],
      },
      {
        title: "Law6coursecourseContent5Title",
        contents: [
          "Law6coursecourseContent5Desc1",
          "Law6coursecourseContent5Desc2",
          "Law6coursecourseContent5Desc3",
          "Law6coursecourseContent5Desc4",
          "Law6coursecourseContent5Desc5",
        ],
      },
      {
        title: "Law6coursecourseContent6Title",
        contents: [
          "Law6coursecourseContent6Desc1",
          "Law6coursecourseContent6Desc2",
          "Law6coursecourseContent6Desc3",
          "Law6coursecourseContent6Desc4",
          "Law6coursecourseContent6Desc5",
          "Law6coursecourseContent6Desc6",
        ],
      },
      {
        title: "Law6coursecourseContent7Title",
        contents: [
          "Law6coursecourseContent7Desc1",
          "Law6coursecourseContent7Desc2",
          "Law6coursecourseContent7Desc3",
          "Law6coursecourseContent7Desc4",
          "Law6coursecourseContent7Desc5",
          "Law6coursecourseContent7Desc6",
          "Law6coursecourseContent7Desc7",
        ],
      },
      {
        title: "Law6coursecourseContent8Title",
        contents: [
          "Law6coursecourseContent8Desc1",
          "Law6coursecourseContent8Desc2",
          "Law6coursecourseContent8Desc3",
          "Law6coursecourseContent8Desc4",
          "Law6coursecourseContent8Desc5",
          "Law6coursecourseContent8Desc6",
        ],
      },
      {
        title: "Law6coursecourseContent9Title",
        contents: [
          "Law6coursecourseContent9Desc1",
          "Law6coursecourseContent9Desc2",
          "Law6coursecourseContent9Desc3",
          "Law6coursecourseContent9Desc4",
          "Law6coursecourseContent9Desc5",
          "Law6coursecourseContent9Desc6",
        ],
      },
    ],
    courseFess: 6080,
    category: "Law",
    courseCode: "LW06",
  },
  {
    id: 68,
    image: Law7,
    title: "Law7Title",
    desc: "Law7Desc",
    WhoShouldAttend: [
      "Law7WhoShouldAttend1",
      "Law7WhoShouldAttend2",
      "Law7WhoShouldAttend3",
      "Law7WhoShouldAttend4",
      "Law7WhoShouldAttend5",
      "Law7WhoShouldAttend6",
      "Law7WhoShouldAttend7",
      "Law7WhoShouldAttend8",
    ],
    courseExpectation: [
      "Law7courseExpectation1",
      "Law7courseExpectation2",
      "Law7courseExpectation3",
      "Law7courseExpectation4",
    ],
    courseContent: [
      {
        title: "Law7coursecourseContent1Title",
        contents: [
          "Law7coursecourseContent1Desc1",
          "Law7coursecourseContent1Desc2",
          "Law7coursecourseContent1Desc3",
          "Law7coursecourseContent1Desc4",
          "Law7coursecourseContent1Desc5",
          "Law7coursecourseContent1Desc6",
        ],
      },
      {
        title: "Law7coursecourseContent2Title",
        contents: [
          "Law7coursecourseContent2Desc1",
          "Law7coursecourseContent2Desc2",
          "Law7coursecourseContent2Desc3",
          "Law7coursecourseContent2Desc4",
          "Law7coursecourseContent2Desc5",
          "Law7coursecourseContent2Desc6",
          "Law7coursecourseContent2Desc7",
        ],
      },
      {
        title: "Law7coursecourseContent3Title",
        contents: [
          "Law7coursecourseContent3Desc1",
          "Law7coursecourseContent3Desc2",
          "Law7coursecourseContent3Desc3",
          "Law7coursecourseContent3Desc4",
          "Law7coursecourseContent3Desc5",
          "Law7coursecourseContent3Desc6",
        ],
      },
      {
        title: "Law7coursecourseContent4Title",
        contents: [
          "Law7coursecourseContent4Desc1",
          "Law7coursecourseContent4Desc2",
          "Law7coursecourseContent4Desc3",
          "Law7coursecourseContent4Desc4",
          "Law7coursecourseContent4Desc5",
          "Law7coursecourseContent4Desc6",
        ],
      },
      {
        title: "Law7coursecourseContent5Title",
        contents: [
          "Law7coursecourseContent5Desc1",
          "Law7coursecourseContent5Desc2",
          "Law7coursecourseContent5Desc3",
          "Law7coursecourseContent5Desc4",
          "Law7coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW07",
  },
  {
    id: 69,
    image: Law8,
    title: "Law8Title",
    desc: "Law8Desc",
    WhoShouldAttend: [
      "Law8WhoShouldAttend1",
      "Law8WhoShouldAttend2",
      "Law8WhoShouldAttend3",
      "Law8WhoShouldAttend4",
      "Law8WhoShouldAttend5",
    ],
    courseExpectation: [
      "Law8courseExpectation1",
      "Law8courseExpectation2",
      "Law8courseExpectation3",
      "Law8courseExpectation4",
      "Law8courseExpectation5",
    ],
    courseContent: [
      {
        title: "Law8coursecourseContent1Title",
        contents: [
          "Law8coursecourseContent1Desc1",
          "Law8coursecourseContent1Desc2",
          "Law8coursecourseContent1Desc3",
          "Law8coursecourseContent1Desc4",
          "Law8coursecourseContent1Desc5",
          "Law8coursecourseContent1Desc6",
        ],
      },
      {
        title: "Law8coursecourseContent2Title",
        contents: [
          "Law8coursecourseContent2Desc1",
          "Law8coursecourseContent2Desc2",
          "Law8coursecourseContent2Desc3",
          "Law8coursecourseContent2Desc4",
          "Law8coursecourseContent2Desc5",
          "Law8coursecourseContent2Desc6",
          "Law8coursecourseContent2Desc7",
        ],
      },
      {
        title: "Law8coursecourseContent3Title",
        contents: [
          "Law8coursecourseContent3Desc1",
          "Law8coursecourseContent3Desc2",
          "Law8coursecourseContent3Desc3",
          "Law8coursecourseContent3Desc4",
          "Law8coursecourseContent3Desc5",
        ],
      },
      {
        title: "Law8coursecourseContent4Title",
        contents: [
          "Law8coursecourseContent4Desc1",
          "Law8coursecourseContent4Desc2",
          "Law8coursecourseContent4Desc3",
          "Law8coursecourseContent4Desc4",
          "Law8coursecourseContent4Desc5",
          "Law8coursecourseContent4Desc6",
        ],
      },
      {
        title: "Law8coursecourseContent5Title",
        contents: [
          "Law8coursecourseContent5Desc1",
          "Law8coursecourseContent5Desc2",
          "Law8coursecourseContent5Desc3",
          "Law8coursecourseContent5Desc4",
          "Law8coursecourseContent5Desc5",
          "Law8coursecourseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW08",
  },
  {
    id: 70,
    image: Law9,
    title: "Law9Title",
    desc: "Law9Desc",
    WhoShouldAttend: [
      "Law9WhoShouldAttend1",
      "Law9WhoShouldAttend2",
      "Law9WhoShouldAttend3",
      "Law9WhoShouldAttend4",
      "Law9WhoShouldAttend5",
      "Law9WhoShouldAttend6",
      "Law9WhoShouldAttend7",
      "Law9WhoShouldAttend8",
    ],
    courseExpectation: [
      "Law9courseExpectation1",
      "Law9courseExpectation2",
      "Law9courseExpectation3",
      "Law9courseExpectation4",
      "Law9courseExpectation5",
    ],
    courseContent: [
      {
        title: "Law9coursecourseContent1Title",
        contents: [
          "Law9coursecourseContent1Desc1",
          "Law9coursecourseContent1Desc2",
          "Law9coursecourseContent1Desc3",
          "Law9coursecourseContent1Desc4",
          "Law9coursecourseContent1Desc5",
          "Law9coursecourseContent1Desc6",
        ],
      },
      {
        title: "Law9coursecourseContent2Title",
        contents: [
          "Law9coursecourseContent2Desc1",
          "Law9coursecourseContent2Desc2",
          "Law9coursecourseContent2Desc3",
          "Law9coursecourseContent2Desc4",
          "Law9coursecourseContent2Desc5",
          "Law9coursecourseContent2Desc6",
          "Law9coursecourseContent2Desc7",
          "Law9coursecourseContent2Desc8",
          "Law9coursecourseContent2Desc9",
          "Law9coursecourseContent2Desc10",
          "Law9coursecourseContent2Desc11",
          "Law9coursecourseContent2Desc12",
        ],
      },
      {
        title: "Law9coursecourseContent3Title",
        contents: [
          "Law9coursecourseContent3Desc1",
          "Law9coursecourseContent3Desc2",
          "Law9coursecourseContent3Desc3",
          "Law9coursecourseContent3Desc4",
          "Law9coursecourseContent3Desc5",
          "Law9coursecourseContent3Desc6",
          "Law9coursecourseContent3Desc7",
          "Law9coursecourseContent3Desc8",
          "Law9coursecourseContent3Desc9",
        ],
      },
      {
        title: "Law9coursecourseContent4Title",
        contents: [
          "Law9coursecourseContent4Desc1",
          "Law9coursecourseContent4Desc2",
          "Law9coursecourseContent4Desc3",
          "Law9coursecourseContent4Desc4",
          "Law9coursecourseContent4Desc5",
          "Law9coursecourseContent4Desc6",
          "Law9coursecourseContent4Desc7",
          "Law9coursecourseContent4Desc8",
        ],
      },
      {
        title: "Law9coursecourseContent5Title",
        contents: [
          "Law9coursecourseContent5Desc1",
          "Law9coursecourseContent5Desc2",
          "Law9coursecourseContent5Desc3",
          "Law9coursecourseContent5Desc4",
          "Law9coursecourseContent5Desc5",
          "Law9coursecourseContent5Desc6",
          "Law9coursecourseContent5Desc7",
          "Law9coursecourseContent5Desc8",
          "Law9coursecourseContent5Desc9",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW09",
  },
  // Law

  // Operations Management
  {
    id: 79,
    image: OperationM1,
    title: "operationsManagement1Title",
    desc: "operationsManagement1Desc",
    WhoShouldAttend: ["operationsManagement1WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement1courseExpectation1",
      "operationsManagement1courseExpectation2",
      "operationsManagement1courseExpectation3",
      "operationsManagement1courseExpectation4",
      "operationsManagement1courseExpectation5",
      "operationsManagement1courseExpectation6",
    ],
    courseContent: [
      {
        title: "operationsManagement1coursecourseContent1Title",
        contents: [
          "operationsManagement1coursecourseContent1Desc1",
          "operationsManagement1coursecourseContent1Desc2",
          "operationsManagement1coursecourseContent1Desc3",
          "operationsManagement1coursecourseContent1Desc4",
          "operationsManagement1coursecourseContent1Desc5",
          "operationsManagement1coursecourseContent1Desc6",
          "operationsManagement1coursecourseContent1Desc7",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent2Title",
        contents: [
          "operationsManagement1coursecourseContent2Desc1",
          "operationsManagement1coursecourseContent2Desc2",
          "operationsManagement1coursecourseContent2Desc3",
          "operationsManagement1coursecourseContent2Desc4",
          "operationsManagement1coursecourseContent2Desc5",
          "operationsManagement1coursecourseContent2Desc6",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent3Title",
        contents: [
          "operationsManagement1coursecourseContent3Desc1",
          "operationsManagement1coursecourseContent3Desc2",
          "operationsManagement1coursecourseContent3Desc3",
          "operationsManagement1coursecourseContent3Desc4",
          "operationsManagement1coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent4Title",
        contents: [
          "operationsManagement1coursecourseContent4Desc1",
          "operationsManagement1coursecourseContent4Desc2",
          "operationsManagement1coursecourseContent4Desc3",
          "operationsManagement1coursecourseContent4Desc4",
          "operationsManagement1coursecourseContent4Desc5",
          "operationsManagement1coursecourseContent4Desc6",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent5Title",
        contents: [
          "operationsManagement1coursecourseContent5Desc1",
          "operationsManagement1coursecourseContent5Desc2",
          "operationsManagement1coursecourseContent5Desc3",
          "operationsManagement1coursecourseContent5Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent6Title",
        contents: [
          "operationsManagement1coursecourseContent6Desc1",
          "operationsManagement1coursecourseContent6Desc2",
          "operationsManagement1coursecourseContent6Desc3",
          "operationsManagement1coursecourseContent6Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent7Title",
        contents: [
          "operationsManagement1coursecourseContent7Desc1",
          "operationsManagement1coursecourseContent7Desc2",
          "operationsManagement1coursecourseContent7Desc3",
          "operationsManagement1coursecourseContent7Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent8Title",
        contents: [
          "operationsManagement1coursecourseContent8Desc1",
          "operationsManagement1coursecourseContent8Desc2",
          "operationsManagement1coursecourseContent8Desc3",
          "operationsManagement1coursecourseContent8Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent9Title",
        contents: [
          "operationsManagement1coursecourseContent9Desc1",
          "operationsManagement1coursecourseContent9Desc2",
          "operationsManagement1coursecourseContent9Desc3",
          "operationsManagement1coursecourseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM01",
  },
  {
    id: 80,
    image: OperationM2,
    title: "operationsManagement2Title",
    desc: "operationsManagement2Desc",
    WhoShouldAttend: [
      "operationsManagement2WhoShouldAttend1",
      "operationsManagement2WhoShouldAttend2",
      "operationsManagement2WhoShouldAttend3",
      "operationsManagement2WhoShouldAttend4",
      "operationsManagement2WhoShouldAttend5",
      "operationsManagement2WhoShouldAttend6",
      "operationsManagement2WhoShouldAttend7",
      "operationsManagement2WhoShouldAttend8",
      "operationsManagement2WhoShouldAttend9",
    ],
    courseExpectation: [
      "operationsManagement2courseExpectation1",
      "operationsManagement2courseExpectation2",
      "operationsManagement2courseExpectation3",
      "operationsManagement2courseExpectation4",
      "operationsManagement2courseExpectation5",
      "operationsManagement2courseExpectation6",
      "operationsManagement2courseExpectation7",
      "operationsManagement2courseExpectation8",
      "operationsManagement2courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement2coursecourseContent1Title",
        contents: [
          "operationsManagement2coursecourseContent1Desc1",
          "operationsManagement2coursecourseContent1Desc2",
          "operationsManagement2coursecourseContent1Desc3",
          "operationsManagement2coursecourseContent1Desc4",
          "operationsManagement2coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent2Title",
        contents: [
          "operationsManagement2coursecourseContent2Desc1",
          "operationsManagement2coursecourseContent2Desc2",
          "operationsManagement2coursecourseContent2Desc3",
          "operationsManagement2coursecourseContent2Desc4",
          "operationsManagement2coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent3Title",
        contents: [
          "operationsManagement2coursecourseContent3Desc1",
          "operationsManagement2coursecourseContent3Desc2",
          "operationsManagement2coursecourseContent3Desc3",
          "operationsManagement2coursecourseContent3Desc4",
          "operationsManagement2coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent4Title",
        contents: [
          "operationsManagement2coursecourseContent4Desc1",
          "operationsManagement2coursecourseContent4Desc2",
          "operationsManagement2coursecourseContent4Desc3",
          "operationsManagement2coursecourseContent4Desc4",
          "operationsManagement2coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent5Title",
        contents: [
          "operationsManagement2coursecourseContent5Desc1",
          "operationsManagement2coursecourseContent5Desc2",
          "operationsManagement2coursecourseContent5Desc3",
          "operationsManagement2coursecourseContent5Desc4",
          "operationsManagement2coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent6Title",
        contents: [
          "operationsManagement2coursecourseContent6Desc1",
          "operationsManagement2coursecourseContent6Desc2",
          "operationsManagement2coursecourseContent6Desc3",
          "operationsManagement2coursecourseContent6Desc4",
          "operationsManagement2coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent7Title",
        contents: [
          "operationsManagement2coursecourseContent7Desc1",
          "operationsManagement2coursecourseContent7Desc2",
          "operationsManagement2coursecourseContent7Desc3",
          "operationsManagement2coursecourseContent7Desc4",
          "operationsManagement2coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent8Title",
        contents: [
          "operationsManagement2coursecourseContent8Desc1",
          "operationsManagement2coursecourseContent8Desc2",
          "operationsManagement2coursecourseContent8Desc3",
          "operationsManagement2coursecourseContent8Desc4",
          "operationsManagement2coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent9Title",
        contents: [
          "operationsManagement2coursecourseContent9Desc1",
          "operationsManagement2coursecourseContent9Desc2",
          "operationsManagement2coursecourseContent9Desc3",
          "operationsManagement2coursecourseContent9Desc4",
          "operationsManagement2coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM02",
  },
  {
    id: 81,
    image: OperationM3,
    title: "operationsManagement3Title",
    desc: "operationsManagement3Desc",
    WhoShouldAttend: [
      "operationsManagement3WhoShouldAttend1",
      "operationsManagement3WhoShouldAttend2",
      "operationsManagement3WhoShouldAttend3",
      "operationsManagement3WhoShouldAttend4",
      "operationsManagement3WhoShouldAttend5",
      "operationsManagement3WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement3courseExpectation1",
      "operationsManagement3courseExpectation2",
      "operationsManagement3courseExpectation3",
      "operationsManagement3courseExpectation4",
      "operationsManagement3courseExpectation5",
      "operationsManagement3courseExpectation6",
      "operationsManagement3courseExpectation7",
      "operationsManagement3courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement3coursecourseContent1Title",
        contents: [
          "operationsManagement3coursecourseContent1Desc1",
          "operationsManagement3coursecourseContent1Desc2",
          "operationsManagement3coursecourseContent1Desc3",
          "operationsManagement3coursecourseContent1Desc4",
          "operationsManagement3coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent2Title",
        contents: [
          "operationsManagement3coursecourseContent2Desc1",
          "operationsManagement3coursecourseContent2Desc2",
          "operationsManagement3coursecourseContent2Desc3",
          "operationsManagement3coursecourseContent2Desc4",
          "operationsManagement3coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent3Title",
        contents: [
          "operationsManagement3coursecourseContent3Desc1",
          "operationsManagement3coursecourseContent3Desc2",
          "operationsManagement3coursecourseContent3Desc3",
          "operationsManagement3coursecourseContent3Desc4",
          "operationsManagement3coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent4Title",
        contents: [
          "operationsManagement3coursecourseContent4Desc1",
          "operationsManagement3coursecourseContent4Desc2",
          "operationsManagement3coursecourseContent4Desc3",
          "operationsManagement3coursecourseContent4Desc4",
          "operationsManagement3coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent5Title",
        contents: [
          "operationsManagement3coursecourseContent5Desc1",
          "operationsManagement3coursecourseContent5Desc2",
          "operationsManagement3coursecourseContent5Desc3",
          "operationsManagement3coursecourseContent5Desc4",
          "operationsManagement3coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent6Title",
        contents: [
          "operationsManagement3coursecourseContent6Desc1",
          "operationsManagement3coursecourseContent6Desc2",
          "operationsManagement3coursecourseContent6Desc3",
          "operationsManagement3coursecourseContent6Desc4",
          "operationsManagement3coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent7Title",
        contents: [
          "operationsManagement3coursecourseContent7Desc1",
          "operationsManagement3coursecourseContent7Desc2",
          "operationsManagement3coursecourseContent7Desc3",
          "operationsManagement3coursecourseContent7Desc4",
          "operationsManagement3coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent8Title",
        contents: [
          "operationsManagement3coursecourseContent8Desc1",
          "operationsManagement3coursecourseContent8Desc2",
          "operationsManagement3coursecourseContent8Desc3",
          "operationsManagement3coursecourseContent8Desc4",
          "operationsManagement3coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent9Title",
        contents: [
          "operationsManagement3coursecourseContent9Desc1",
          "operationsManagement3coursecourseContent9Desc2",
          "operationsManagement3coursecourseContent9Desc3",
          "operationsManagement3coursecourseContent9Desc4",
          "operationsManagement3coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM03",
  },
  {
    id: 82,
    image: OperationM4,
    title: "operationsManagement4Title",
    desc: "",
    WhoShouldAttend: [
      "operationsManagement4WhoShouldAttend1",
      "operationsManagement4WhoShouldAttend2",
      "operationsManagement4WhoShouldAttend3",
      "operationsManagement4WhoShouldAttend4",
      "operationsManagement4WhoShouldAttend5",
      "operationsManagement4WhoShouldAttend6",
      "operationsManagement4WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement4courseExpectation1",
      "operationsManagement4courseExpectation2",
      "operationsManagement4courseExpectation3",
      "operationsManagement4courseExpectation4",
      "operationsManagement4courseExpectation5",
      "operationsManagement4courseExpectation6",
      "operationsManagement4courseExpectation7",
      "operationsManagement4courseExpectation8",
      "operationsManagement4courseExpectation9",
      "operationsManagement4courseExpectation10",
      "operationsManagement4courseExpectation11",
      "operationsManagement4courseExpectation12",
      "operationsManagement4courseExpectation13",
      "operationsManagement4courseExpectation14",
    ],
    courseContent: [
      {
        title: "operationsManagement4coursecourseContent1Title",
        contents: [
          "operationsManagement4coursecourseContent1Desc1",
          "operationsManagement4coursecourseContent1Desc2",
          "operationsManagement4coursecourseContent1Desc3",
          "operationsManagement4coursecourseContent1Desc4",
          "operationsManagement4coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent2Title",
        contents: [
          "operationsManagement4coursecourseContent2Desc1",
          "operationsManagement4coursecourseContent2Desc2",
          "operationsManagement4coursecourseContent2Desc3",
          "operationsManagement4coursecourseContent2Desc3",
          "operationsManagement4coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent3Title",
        contents: [
          "operationsManagement4coursecourseContent3Desc1",
          "operationsManagement4coursecourseContent3Desc2",
          "operationsManagement4coursecourseContent3Desc3",
          "operationsManagement4coursecourseContent3Desc4",
          "operationsManagement4coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent4Title",
        contents: [
          "operationsManagement4coursecourseContent4Desc1",
          "operationsManagement4coursecourseContent4Desc2",
          "operationsManagement4coursecourseContent4Desc3",
          "operationsManagement4coursecourseContent4Desc4",
          "operationsManagement4coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent5Title",
        contents: [
          "operationsManagement4coursecourseContent5Desc1",
          "operationsManagement4coursecourseContent5Desc2",
          "operationsManagement4coursecourseContent5Desc3",
          "operationsManagement4coursecourseContent5Desc4",
          "operationsManagement4coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent6Title",
        contents: [
          "operationsManagement4coursecourseContent6Desc1",
          "operationsManagement4coursecourseContent6Desc2",
          "operationsManagement4coursecourseContent6Desc3",
          "operationsManagement4coursecourseContent6Desc4",
          "operationsManagement4coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent7Title",
        contents: [
          "operationsManagement4coursecourseContent7Desc1",
          "operationsManagement4coursecourseContent7Desc2",
          "operationsManagement4coursecourseContent7Desc3",
          "operationsManagement4coursecourseContent7Desc4",
          "operationsManagement4coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent8Title",
        contents: [
          "operationsManagement4coursecourseContent8Desc1",
          "operationsManagement4coursecourseContent8Desc2",
          "operationsManagement4coursecourseContent8Desc3",
          "operationsManagement4coursecourseContent8Desc4",
          "operationsManagement4coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent9Title",
        contents: [
          "operationsManagement4coursecourseContent9Desc1",
          "operationsManagement4coursecourseContent9Desc2",
          "operationsManagement4coursecourseContent9Desc3",
          "operationsManagement4coursecourseContent9Desc4",
          "operationsManagement4coursecourseContent9Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent10Title",
        contents: [
          "operationsManagement4coursecourseContent10Desc1",
          "operationsManagement4coursecourseContent10Desc2",
          "operationsManagement4coursecourseContent10Desc3",
          "operationsManagement4coursecourseContent10Desc4",
          "operationsManagement4coursecourseContent10Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent11Title",
        contents: [
          "operationsManagement4coursecourseContent11Desc1",
          "operationsManagement4coursecourseContent11Desc2",
          "operationsManagement4coursecourseContent11Desc3",
          "operationsManagement4coursecourseContent11Desc4",
          "operationsManagement4coursecourseContent11Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent12Title",
        contents: [
          "operationsManagement4coursecourseContent12Desc1",
          "operationsManagement4coursecourseContent12Desc2",
          "operationsManagement4coursecourseContent12Desc3",
          "operationsManagement4coursecourseContent12Desc4",
          "operationsManagement4coursecourseContent12Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent13Title",
        contents: [
          "operationsManagement4coursecourseContent13Desc1",
          "operationsManagement4coursecourseContent13Desc2",
          "operationsManagement4coursecourseContent13Desc3",
          "operationsManagement4coursecourseContent13Desc4",
          "operationsManagement4coursecourseContent13Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent14Title",
        contents: [
          "operationsManagement4coursecourseContent14Desc1",
          "operationsManagement4coursecourseContent14Desc2",
          "operationsManagement4coursecourseContent14Desc3",
          "operationsManagement4coursecourseContent14Desc4",
          "operationsManagement4coursecourseContent14Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM04",
  },
  {
    id: 83,
    image: OperationM5,
    title: "operationsManagement5Title",
    desc: "operationsManagement5Desc",
    WhoShouldAttend: [
      "operationsManagement5WhoShouldAttend1",
      "operationsManagement5WhoShouldAttend2",
      "operationsManagement5WhoShouldAttend3",
      "operationsManagement5WhoShouldAttend4",
      "operationsManagement5WhoShouldAttend5",
    ],
    courseExpectation: [
      "operationsManagement5courseExpectation1",
      "operationsManagement5courseExpectation2",
      "operationsManagement5courseExpectation3",
      "operationsManagement5courseExpectation4",
      "operationsManagement5courseExpectation5",
      "operationsManagement5courseExpectation6",
      "operationsManagement5courseExpectation7",
      "operationsManagement5courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement5coursecourseContent1Title",
        contents: [
          "operationsManagement5coursecourseContent1Desc1",
          "operationsManagement5coursecourseContent1Desc2",
          "operationsManagement5coursecourseContent1Desc3",
          "operationsManagement5coursecourseContent1Desc4",
          "operationsManagement5coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent2Title",
        contents: [
          "operationsManagement5coursecourseContent2Desc1",
          "operationsManagement5coursecourseContent2Desc2",
          "operationsManagement5coursecourseContent2Desc3",
          "operationsManagement5coursecourseContent2Desc4",
          "operationsManagement5coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent3Title",
        contents: [
          "operationsManagement5coursecourseContent3Desc1",
          "operationsManagement5coursecourseContent3Desc2",
          "operationsManagement5coursecourseContent3Desc3",
          "operationsManagement5coursecourseContent3Desc4",
          "operationsManagement5coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent4Title",
        contents: [
          "operationsManagement5coursecourseContent4Desc1",
          "operationsManagement5coursecourseContent4Desc2",
          "operationsManagement5coursecourseContent4Desc3",
          "operationsManagement5coursecourseContent4Desc4",
          "operationsManagement5coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent5Title",
        contents: [
          "operationsManagement5coursecourseContent5Desc1",
          "operationsManagement5coursecourseContent5Desc2",
          "operationsManagement5coursecourseContent5Desc3",
          "operationsManagement5coursecourseContent5Desc4",
          "operationsManagement5coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent6Title",
        contents: [
          "operationsManagement5coursecourseContent6Desc1",
          "operationsManagement5coursecourseContent6Desc2",
          "operationsManagement5coursecourseContent6Desc3",
          "operationsManagement5coursecourseContent6Desc4",
          "operationsManagement5coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent7Title",
        contents: [
          "operationsManagement5coursecourseContent7Desc1",
          "operationsManagement5coursecourseContent7Desc2",
          "operationsManagement5coursecourseContent7Desc3",
          "operationsManagement5coursecourseContent7Desc4",
          "operationsManagement5coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent8Title",
        contents: [
          "operationsManagement5coursecourseContent8Desc1",
          "operationsManagement5coursecourseContent8Desc2",
          "operationsManagement5coursecourseContent8Desc3",
          "operationsManagement5coursecourseContent8Desc4",
          "operationsManagement5coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent9Title",
        contents: [
          "operationsManagement5coursecourseContent9Desc1",
          "operationsManagement5coursecourseContent9Desc2",
          "operationsManagement5coursecourseContent9Desc3",
          "operationsManagement5coursecourseContent9Desc4",
          "operationsManagement5coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM05",
  },
  {
    id: 84,
    image: OperationM6,
    title: "operationsManagement6Title",
    desc: "operationsManagement6Desc",
    WhoShouldAttend: [
      "operationsManagement6WhoShouldAttend1",
      "operationsManagement6WhoShouldAttend2",
      "operationsManagement6WhoShouldAttend3",
      "operationsManagement6WhoShouldAttend4",
      "operationsManagement6WhoShouldAttend5",
      "operationsManagement6WhoShouldAttend6",
      "operationsManagement6WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement6courseExpectation1",
      "operationsManagement6courseExpectation2",
      "operationsManagement6courseExpectation3",
      "operationsManagement6courseExpectation4",
      "operationsManagement6courseExpectation5",
      "operationsManagement6courseExpectation6",
      "operationsManagement6courseExpectation7",
      "operationsManagement6courseExpectation8",
      "operationsManagement6courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement6coursecourseContent1Title",
        contents: [
          "operationsManagement6coursecourseContent1Desc1",
          "operationsManagement6coursecourseContent1Desc2",
          "operationsManagement6coursecourseContent1Desc3",
          "operationsManagement6coursecourseContent1Desc4",
          "operationsManagement6coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent2Title",
        contents: [
          "operationsManagement6coursecourseContent2Desc1",
          "operationsManagement6coursecourseContent2Desc2",
          "operationsManagement6coursecourseContent2Desc3",
          "operationsManagement6coursecourseContent2Desc4",
          "operationsManagement6coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent3Title",
        contents: [
          "operationsManagement6coursecourseContent3Desc1",
          "operationsManagement6coursecourseContent3Desc2",
          "operationsManagement6coursecourseContent3Desc3",
          "operationsManagement6coursecourseContent3Desc4",
          "operationsManagement6coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent4Title",
        contents: [
          "operationsManagement6coursecourseContent4Desc1",
          "operationsManagement6coursecourseContent4Desc2",
          "operationsManagement6coursecourseContent4Desc3",
          "operationsManagement6coursecourseContent4Desc4",
          "operationsManagement6coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent5Title",
        contents: [
          "operationsManagement6coursecourseContent5Desc1",
          "operationsManagement6coursecourseContent5Desc2",
          "operationsManagement6coursecourseContent5Desc3",
          "operationsManagement6coursecourseContent5Desc4",
          "operationsManagement6coursecourseContent5Desc5",
          "operationsManagement6coursecourseContent5Desc6",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent6Title",
        contents: [
          "operationsManagement6coursecourseContent6Desc1",
          "operationsManagement6coursecourseContent6Desc2",
          "operationsManagement6coursecourseContent6Desc3",
          "operationsManagement6coursecourseContent6Desc4",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent7Title",
        contents: [
          "operationsManagement6coursecourseContent7Desc1",
          "operationsManagement6coursecourseContent7Desc2",
          "operationsManagement6coursecourseContent7Desc3",
          "operationsManagement6coursecourseContent7Desc4",
          "operationsManagement6coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent8Title",
        contents: [
          "operationsManagement6coursecourseContent8Desc1",
          "operationsManagement6coursecourseContent8Desc2",
          "operationsManagement6coursecourseContent8Desc3",
          "operationsManagement6coursecourseContent8Desc4",
          "operationsManagement6coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent9Title",
        contents: [
          "operationsManagement6coursecourseContent9Desc1",
          "operationsManagement6coursecourseContent9Desc2",
          "operationsManagement6coursecourseContent9Desc3",
          "operationsManagement6coursecourseContent9Desc4",
          "operationsManagement6coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM06",
  },
  {
    id: 85,
    image: OperationM7,
    title: "operationsManagement7Title",
    desc: "operationsManagement7Desc",
    WhoShouldAttend: ["operationsManagement7WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement7courseExpectation1",
      "operationsManagement7courseExpectation2",
      "operationsManagement7courseExpectation3",
      "operationsManagement7courseExpectation4",
      "operationsManagement7courseExpectation5",
      "operationsManagement7courseExpectation6",
      "operationsManagement7courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement7coursecourseContent1Title",
        contents: [
          "operationsManagement7coursecourseContent1Desc1",
          "operationsManagement7coursecourseContent1Desc2",
          "operationsManagement7coursecourseContent1Desc3",
          "operationsManagement7coursecourseContent1Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent2Title",
        contents: [
          "operationsManagement7coursecourseContent2Desc1",
          "operationsManagement7coursecourseContent2Desc2",
          "operationsManagement7coursecourseContent2Desc3",
          "operationsManagement7coursecourseContent2Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent3Title",
        contents: [
          "operationsManagement7coursecourseContent3Desc1",
          "operationsManagement7coursecourseContent3Desc2",
          "operationsManagement7coursecourseContent3Desc3",
          "operationsManagement7coursecourseContent3Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent4Title",
        contents: [
          "operationsManagement7coursecourseContent4Desc1",
          "operationsManagement7coursecourseContent4Desc2",
          "operationsManagement7coursecourseContent4Desc3",
          "operationsManagement7coursecourseContent4Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent5Title",
        contents: [
          "operationsManagement7coursecourseContent5Desc1",
          "operationsManagement7coursecourseContent5Desc2",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM07",
  },
  {
    id: 86,
    image: OperationM8,
    title: "operationsManagement8Title",
    desc: "operationsManagement8Desc",
    WhoShouldAttend: [
      "operationsManagement8WhoShouldAttend1",
      "operationsManagement8WhoShouldAttend2",
      "operationsManagement8WhoShouldAttend3",
      "operationsManagement8WhoShouldAttend4",
      "operationsManagement8WhoShouldAttend5",
      "operationsManagement8WhoShouldAttend6",
      "operationsManagement8WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement8courseExpectation1",
      "operationsManagement8courseExpectation2",
      "operationsManagement8courseExpectation3",
      "operationsManagement8courseExpectation4",
      "operationsManagement8courseExpectation5",
      "operationsManagement8courseExpectation6",
      "operationsManagement8courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement8coursecourseContent1Title",
        contents: [
          "operationsManagement8coursecourseContent1Desc1",
          "operationsManagement8coursecourseContent1Desc2",
          "operationsManagement8coursecourseContent1Desc3",
          "operationsManagement8coursecourseContent1Desc4",
          "operationsManagement8coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent2Title",
        contents: [
          "operationsManagement8coursecourseContent2Desc1",
          "operationsManagement8coursecourseContent2Desc2",
          "operationsManagement8coursecourseContent2Desc3",
          "operationsManagement8coursecourseContent2Desc4",
          "operationsManagement8coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent3Title",
        contents: [
          "operationsManagement8coursecourseContent3Desc1",
          "operationsManagement8coursecourseContent3Desc2",
          "operationsManagement8coursecourseContent3Desc3",
          "operationsManagement8coursecourseContent3Desc4",
          "operationsManagement8coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent4Title",
        contents: [
          "operationsManagement8coursecourseContent4Desc1",
          "operationsManagement8coursecourseContent4Desc2",
          "operationsManagement8coursecourseContent4Desc3",
          "operationsManagement8coursecourseContent4Desc4",
          "operationsManagement8coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent5Title",
        contents: [
          "operationsManagement8coursecourseContent5Desc1",
          "operationsManagement8coursecourseContent5Desc2",
          "operationsManagement8coursecourseContent5Desc3",
          "operationsManagement8coursecourseContent5Desc4",
          "operationsManagement8coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM08",
  },
  {
    id: 87,
    image: OperationM9,
    title: "operationsManagement9Title",
    desc: "operationsManagement9Desc",
    WhoShouldAttend: ["operationsManagement9WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement9courseExpectation1",
      "operationsManagement9courseExpectation2",
      "operationsManagement9courseExpectation3",
      "operationsManagement9courseExpectation4",
      "operationsManagement9courseExpectation5",
      "operationsManagement9courseExpectation6",
      "operationsManagement9courseExpectation7",
      "operationsManagement9courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement9coursecourseContent1Title",
        contents: [
          "operationsManagement9coursecourseContent1Desc1",
          "operationsManagement9coursecourseContent1Desc2",
          "operationsManagement9coursecourseContent1Desc3",
          "operationsManagement9coursecourseContent1Desc4",
          "operationsManagement9coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent2Title",
        contents: [
          "operationsManagement9coursecourseContent2Desc1",
          "operationsManagement9coursecourseContent2Desc2",
          "operationsManagement9coursecourseContent2Desc3",
          "operationsManagement9coursecourseContent2Desc4",
          "operationsManagement9coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent3Title",
        contents: [
          "operationsManagement9coursecourseContent3Desc1",
          "operationsManagement9coursecourseContent3Desc2",
          "operationsManagement9coursecourseContent3Desc3",
          "operationsManagement9coursecourseContent3Desc4",
          "operationsManagement9coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent4Title",
        contents: [
          "operationsManagement9coursecourseContent4Desc1",
          "operationsManagement9coursecourseContent4Desc2",
          "operationsManagement9coursecourseContent4Desc3",
          "operationsManagement9coursecourseContent4Desc4",
          "operationsManagement9coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent5Title",
        contents: [
          "operationsManagement9coursecourseContent5Desc1",
          "operationsManagement9coursecourseContent5Desc2",
          "operationsManagement9coursecourseContent5Desc3",
          "operationsManagement9coursecourseContent5Desc4",
          "operationsManagement9coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM09",
  },
  {
    id: 88,
    image: OperationM10,
    title: "operationsManagement10Title",
    desc: "operationsManagement10Desc",
    WhoShouldAttend: ["operationsManagement10WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement10courseExpectation1",
      "operationsManagement10courseExpectation2",
      "operationsManagement10courseExpectation3",
      "operationsManagement10courseExpectation4",
      "operationsManagement10courseExpectation5",
      "operationsManagement10courseExpectation6",
      "operationsManagement10courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement10coursecourseContent1Title",
        contents: [
          "operationsManagement10coursecourseContent1Desc1",
          "operationsManagement10coursecourseContent1Desc2",
          "operationsManagement10coursecourseContent1Desc3",
          "operationsManagement10coursecourseContent1Desc4",
          "operationsManagement10coursecourseContent1Desc5",
          "operationsManagement10coursecourseContent1Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent2Title",
        contents: [
          "operationsManagement10coursecourseContent2Desc1",
          "operationsManagement10coursecourseContent2Desc2",
          "operationsManagement10coursecourseContent2Desc3",
          "operationsManagement10coursecourseContent2Desc4",
          "operationsManagement10coursecourseContent2Desc5",
          "operationsManagement10coursecourseContent2Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent3Title",
        contents: [
          "operationsManagement10coursecourseContent3Desc1",
          "operationsManagement10coursecourseContent3Desc2",
          "operationsManagement10coursecourseContent3Desc3",
          "operationsManagement10coursecourseContent3Desc4",
          "operationsManagement10coursecourseContent3Desc5",
          "operationsManagement10coursecourseContent3Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent4Title",
        contents: [
          "operationsManagement10coursecourseContent4Desc1",
          "operationsManagement10coursecourseContent4Desc2",
          "operationsManagement10coursecourseContent4Desc3",
          "operationsManagement10coursecourseContent4Desc4",
          "operationsManagement10coursecourseContent4Desc5",
          "operationsManagement10coursecourseContent4Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent5Title",
        contents: [
          "operationsManagement10coursecourseContent5Desc1",
          "operationsManagement10coursecourseContent5Desc2",
          "operationsManagement10coursecourseContent5Desc3",
          "operationsManagement10coursecourseContent5Desc4",
          "operationsManagement10coursecourseContent5Desc5",
          "operationsManagement10coursecourseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM10",
  },
  {
    id: 89,
    image: OperationM11,
    title: "operationsManagement11Title",
    desc: "operationsManagement11Desc",
    WhoShouldAttend: [
      "operationsManagement11WhoShouldAttend1",
      "operationsManagement11WhoShouldAttend2",
      "operationsManagement11WhoShouldAttend3",
      "operationsManagement11WhoShouldAttend4",
      "operationsManagement11WhoShouldAttend5",
      "operationsManagement11WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement11courseExpectation1",
      "operationsManagement11courseExpectation2",
      "operationsManagement11courseExpectation3",
      "operationsManagement11courseExpectation4",
      "operationsManagement11courseExpectation5",
      "operationsManagement11courseExpectation6",
      "operationsManagement11courseExpectation7",
      "operationsManagement11courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement11coursecourseContent1Title",
        contents: [
          "operationsManagement11coursecourseContent1Desc1",
          "operationsManagement11coursecourseContent1Desc2",
          "operationsManagement11coursecourseContent1Desc3",
          "operationsManagement11coursecourseContent1Desc4",
          "operationsManagement11coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent2Title",
        contents: [
          "operationsManagement11coursecourseContent2Desc1",
          "operationsManagement11coursecourseContent2Desc2",
          "operationsManagement11coursecourseContent2Desc3",
          "operationsManagement11coursecourseContent2Desc4",
          "operationsManagement11coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent3Title",
        contents: [
          "operationsManagement11coursecourseContent3Desc1",
          "operationsManagement11coursecourseContent3Desc2",
          "operationsManagement11coursecourseContent3Desc3",
          "operationsManagement11coursecourseContent3Desc4",
          "operationsManagement11coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent4Title",
        contents: [
          "operationsManagement11coursecourseContent4Desc1",
          "operationsManagement11coursecourseContent4Desc2",
          "operationsManagement11coursecourseContent4Desc3",
          "operationsManagement11coursecourseContent4Desc4",
          "operationsManagement11coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent5Title",
        contents: [
          "operationsManagement11coursecourseContent5Desc1",
          "operationsManagement11coursecourseContent5Desc2",
          "operationsManagement11coursecourseContent5Desc3",
          "operationsManagement11coursecourseContent5Desc4",
          "operationsManagement11coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM11",
  },
  {
    id: 90,
    image: OperationM12,
    title: "operationsManagement12Title",
    desc: "operationsManagement12Desc",
    WhoShouldAttend: [
      "operationsManagement12WhoShouldAttend1",
      "operationsManagement12WhoShouldAttend2",
      "operationsManagement12WhoShouldAttend3",
      "operationsManagement12WhoShouldAttend4",
      "operationsManagement12WhoShouldAttend5",
      "operationsManagement12WhoShouldAttend6",
      "operationsManagement12WhoShouldAttend7",
      "operationsManagement12WhoShouldAttend8",
      "operationsManagement12WhoShouldAttend9",
    ],
    courseExpectation: [
      "operationsManagement12courseExpectation1",
      "operationsManagement12courseExpectation2",
      "operationsManagement12courseExpectation3",
      "operationsManagement12courseExpectation4",
      "operationsManagement12courseExpectation5",
      "operationsManagement12courseExpectation6",
      "operationsManagement12courseExpectation7",
      "operationsManagement12courseExpectation8",
      "operationsManagement12courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement12coursecourseContent1Title",
        contents: [
          "operationsManagement12coursecourseContent1Desc1",
          "operationsManagement12coursecourseContent1Desc2",
          "operationsManagement12coursecourseContent1Desc3",
          "operationsManagement12coursecourseContent1Desc4",
          "operationsManagement12coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent2Title",
        contents: [
          "operationsManagement12coursecourseContent2Desc1",
          "operationsManagement12coursecourseContent2Desc2",
          "operationsManagement12coursecourseContent2Desc3",
          "operationsManagement12coursecourseContent2Desc4",
          "operationsManagement12coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent3Title",
        contents: [
          "operationsManagement12coursecourseContent3Desc1",
          "operationsManagement12coursecourseContent3Desc2",
          "operationsManagement12coursecourseContent3Desc3",
          "operationsManagement12coursecourseContent3Desc4",
          "operationsManagement12coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent4Title",
        contents: [
          "operationsManagement12coursecourseContent4Desc1",
          "operationsManagement12coursecourseContent4Desc2",
          "operationsManagement12coursecourseContent4Desc3",
          "operationsManagement12coursecourseContent4Desc4",
          "operationsManagement12coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent5Title",
        contents: [
          "operationsManagement12coursecourseContent5Desc1",
          "operationsManagement12coursecourseContent5Desc2",
          "operationsManagement12coursecourseContent5Desc3",
          "operationsManagement12coursecourseContent5Desc4",
          "operationsManagement12coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM12",
  },
  {
    id: 91,
    image: OperationM13,
    title: "operationsManagement13Title",
    desc: "operationsManagement13Desc",
    WhoShouldAttend: ["operationsManagement13WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement13courseExpectation1",
      "operationsManagement13courseExpectation2",
      "operationsManagement13courseExpectation3",
      "operationsManagement13courseExpectation4",
      "operationsManagement13courseExpectation5",
    ],
    courseContent: [
      {
        title: "operationsManagement13coursecourseContent1Title",
        contents: [
          "operationsManagement13coursecourseContent1Desc1",
          "operationsManagement13coursecourseContent1Desc2",
          "operationsManagement13coursecourseContent1Desc3",
          "operationsManagement13coursecourseContent1Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent2Title",
        contents: [
          "operationsManagement13coursecourseContent2Desc1",
          "operationsManagement13coursecourseContent2Desc2",
          "operationsManagement13coursecourseContent2Desc3",
          "operationsManagement13coursecourseContent2Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent3Title",
        contents: [
          "operationsManagement13coursecourseContent3Desc1",
          "operationsManagement13coursecourseContent3Desc2",
          "operationsManagement13coursecourseContent3Desc3",
          "operationsManagement13coursecourseContent3Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent4Title",
        contents: [
          "operationsManagement13coursecourseContent4Desc1",
          "operationsManagement13coursecourseContent4Desc2",
          "operationsManagement13coursecourseContent4Desc3",
          "operationsManagement13coursecourseContent4Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent5Title",
        contents: [
          "operationsManagement13coursecourseContent5Desc1",
          "operationsManagement13coursecourseContent5Desc2",
          "operationsManagement13coursecourseContent5Desc3",
          "operationsManagement13coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM13",
  },
  {
    id: 92,
    image: OperationM14,
    title: "operationsManagement14Title",
    desc: "operationsManagement14Desc",
    WhoShouldAttend: [
      "operationsManagement14WhoShouldAttend1",
      "operationsManagement14WhoShouldAttend2",
      "operationsManagement14WhoShouldAttend3",
      "operationsManagement14WhoShouldAttend4",
      "operationsManagement14WhoShouldAttend5",
    ],
    courseExpectation: [
      "operationsManagement14courseContent1",
      "operationsManagement14courseContent2",
      "operationsManagement14courseContent3",
      "operationsManagement14courseContent4",
      "operationsManagement14courseContent5",
      "operationsManagement14courseContent6",
      "operationsManagement14courseContent7",
      "operationsManagement14courseContent8",
    ],
    courseContent: [
      {
        title: "operationsManagement14coursecourseContent1Title",
        contents: [
          "operationsManagement14coursecourseContent1Desc1",
          "operationsManagement14coursecourseContent1Desc2",
          "operationsManagement14coursecourseContent1Desc3",
          "operationsManagement14coursecourseContent1Desc4",
          "operationsManagement14coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent2Title",
        contents: [
          "operationsManagement14coursecourseContent2Desc1",
          "operationsManagement14coursecourseContent2Desc2",
          "operationsManagement14coursecourseContent2Desc3",
          "operationsManagement14coursecourseContent2Desc4",
          "operationsManagement14coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent3Title",
        contents: [
          "operationsManagement14coursecourseContent3Desc1",
          "operationsManagement14coursecourseContent3Desc2",
          "operationsManagement14coursecourseContent3Desc3",
          "operationsManagement14coursecourseContent3Desc4",
          "operationsManagement14coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent4Title",
        contents: [
          "operationsManagement14coursecourseContent4Desc1",
          "operationsManagement14coursecourseContent4Desc2",
          "operationsManagement14coursecourseContent4Desc3",
          "operationsManagement14coursecourseContent4Desc4",
          "operationsManagement14coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent5Title",
        contents: [
          "operationsManagement14coursecourseContent5Desc1",
          "operationsManagement14coursecourseContent5Desc2",
          "operationsManagement14coursecourseContent5Desc3",
          "operationsManagement14coursecourseContent5Desc4",
          "operationsManagement14coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM14",
  },
  {
    id: 93,
    image: OperationM15,
    title: "operationsManagement15Title",
    desc: "operationsManagement15Desc",
    WhoShouldAttend: [
      "operationsManagement15WhoShouldAttend1",
      "operationsManagement15WhoShouldAttend2",
      "operationsManagement15WhoShouldAttend3",
      "operationsManagement15WhoShouldAttend4",
      "operationsManagement15WhoShouldAttend5",
      "operationsManagement15WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement15courseExpectation1",
      "operationsManagement15courseExpectation2",
      "operationsManagement15courseExpectation3",
      "operationsManagement15courseExpectation4",
      "operationsManagement15courseExpectation5",
      "operationsManagement15courseExpectation6",
      "operationsManagement15courseExpectation7",
      "operationsManagement15courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement15coursecourseContent1Title",
        contents: [
          "operationsManagement15coursecourseContent1Desc1",
          "operationsManagement15coursecourseContent1Desc2",
          "operationsManagement15coursecourseContent1Desc3",
          "operationsManagement15coursecourseContent1Desc4",
          "operationsManagement15coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent2Title",
        contents: [
          "operationsManagement15coursecourseContent2Desc1",
          "operationsManagement15coursecourseContent2Desc2",
          "operationsManagement15coursecourseContent2Desc3",
          "operationsManagement15coursecourseContent2Desc4",
          "operationsManagement15coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent3Title",
        contents: [
          "operationsManagement15coursecourseContent3Desc1",
          "operationsManagement15coursecourseContent3Desc2",
          "operationsManagement15coursecourseContent3Desc3",
          "operationsManagement15coursecourseContent3Desc4",
          "operationsManagement15coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent4Title",
        contents: [
          "operationsManagement15coursecourseContent4Desc1",
          "operationsManagement15coursecourseContent4Desc2",
          "operationsManagement15coursecourseContent4Desc3",
          "operationsManagement15coursecourseContent4Desc4",
          "operationsManagement15coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent5Title",
        contents: [
          "operationsManagement15coursecourseContent5Desc1",
          "operationsManagement15coursecourseContent5Desc2",
          "operationsManagement15coursecourseContent5Desc3",
          "operationsManagement15coursecourseContent5Desc4",
          "operationsManagement15coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM15",
  },
  {
    id: 94,
    image: OperationM16,
    title: "operationsManagement16Title",
    desc: "operationsManagement16Desc",
    WhoShouldAttend: [
      "operationsManagement16WhoShouldAttend1",
      "operationsManagement16WhoShouldAttend2",
      "operationsManagement16WhoShouldAttend3",
      "operationsManagement16WhoShouldAttend4",
      "operationsManagement16WhoShouldAttend5",
      "operationsManagement16WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement16courseExpectation1",
      "operationsManagement16courseExpectation2",
      "operationsManagement16courseExpectation3",
      "operationsManagement16courseExpectation4",
      "operationsManagement16courseExpectation5",
      "operationsManagement16courseExpectation6",
      "operationsManagement16courseExpectation7",
      "operationsManagement16courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement16coursecourseContent1Title",
        contents: [
          "operationsManagement16coursecourseContent1Desc1",
          "operationsManagement16coursecourseContent1Desc2",
          "operationsManagement16coursecourseContent1Desc3",
          "operationsManagement16coursecourseContent1Desc4",
          "operationsManagement16coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent2Title",
        contents: [
          "operationsManagement16coursecourseContent2Desc1",
          "operationsManagement16coursecourseContent2Desc2",
          "operationsManagement16coursecourseContent2Desc3",
          "operationsManagement16coursecourseContent2Desc4",
          "operationsManagement16coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent3Title",
        contents: [
          "operationsManagement16coursecourseContent3Desc1",
          "operationsManagement16coursecourseContent3Desc2",
          "operationsManagement16coursecourseContent3Desc3",
          "operationsManagement16coursecourseContent3Desc4",
          "operationsManagement16coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent4Title",
        contents: [
          "operationsManagement16coursecourseContent4Desc1",
          "operationsManagement16coursecourseContent4Desc2",
          "operationsManagement16coursecourseContent4Desc3",
          "operationsManagement16coursecourseContent4Desc4",
          "operationsManagement16coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent5Title",
        contents: [
          "operationsManagement16coursecourseContent5Desc1",
          "operationsManagement16coursecourseContent5Desc2",
          "operationsManagement16coursecourseContent5Desc3",
          "operationsManagement16coursecourseContent5Desc4",
          "operationsManagement16coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM16",
  },
  {
    id: 95,
    image: OperationM17,
    title: "operationsManagement17Title",
    desc: "operationsManagement17Desc",
    WhoShouldAttend: [
      "operationsManagement17WhoShouldAttend1",
      "operationsManagement17WhoShouldAttend2",
      "operationsManagement17WhoShouldAttend3",
      "operationsManagement17WhoShouldAttend4",
      "operationsManagement17WhoShouldAttend5",
    ],
    courseExpectation: [
      "operationsManagement17courseExpectation1",
      "operationsManagement17courseExpectation2",
      "operationsManagement17courseExpectation3",
      "operationsManagement17courseExpectation4",
      "operationsManagement17courseExpectation5",
      "operationsManagement17courseExpectation6",
      "operationsManagement17courseExpectation7",
      "operationsManagement17courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement17coursecourseContent1Title",
        contents: [
          "operationsManagement17coursecourseContent1Desc1",
          "operationsManagement17coursecourseContent1Desc2",
          "operationsManagement17coursecourseContent1Desc3",
          "operationsManagement17coursecourseContent1Desc4",
          "operationsManagement17coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent2Title",
        contents: [
          "operationsManagement17coursecourseContent2Desc1",
          "operationsManagement17coursecourseContent2Desc2",
          "operationsManagement17coursecourseContent2Desc3",
          "operationsManagement17coursecourseContent2Desc4",
          "operationsManagement17coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent3Title",
        contents: [
          "operationsManagement17coursecourseContent3Desc1",
          "operationsManagement17coursecourseContent3Desc2",
          "operationsManagement17coursecourseContent3Desc3",
          "operationsManagement17coursecourseContent3Desc4",
          "operationsManagement17coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent4Title",
        contents: [
          "operationsManagement17coursecourseContent4Desc1",
          "operationsManagement17coursecourseContent4Desc2",
          "operationsManagement17coursecourseContent4Desc3",
          "operationsManagement17coursecourseContent4Desc4",
          "operationsManagement17coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent5Title",
        contents: [
          "operationsManagement17coursecourseContent5Desc1",
          "operationsManagement17coursecourseContent5Desc2",
          "operationsManagement17coursecourseContent5Desc3",
          "operationsManagement17coursecourseContent5Desc4",
          "operationsManagement17coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM17",
  },
  {
    id: 96,
    image: OperationM18,
    title: "operationsManagement18Title",
    desc: "operationsManagement18Desc",
    WhoShouldAttend: [
      "operationsManagement18WhoShouldAttend1",
      "operationsManagement18WhoShouldAttend2",
      "operationsManagement18WhoShouldAttend3",
      "operationsManagement18WhoShouldAttend4",
      "operationsManagement18WhoShouldAttend5",
      "operationsManagement18WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement18courseExpectation1",
      "operationsManagement18courseExpectation2",
      "operationsManagement18courseExpectation3",
      "operationsManagement18courseExpectation4",
      "operationsManagement18courseExpectation5",
      "operationsManagement18courseExpectation6",
      "operationsManagement18courseExpectation7",
      "operationsManagement18courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement18coursecourseContent1Title",
        contents: [
          "operationsManagement18coursecourseContent1Desc1",
          "operationsManagement18coursecourseContent1Desc2",
          "operationsManagement18coursecourseContent1Desc3",
          "operationsManagement18coursecourseContent1Desc4",
          "operationsManagement18coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent2Title",
        contents: [
          "operationsManagement18coursecourseContent2Desc1",
          "operationsManagement18coursecourseContent2Desc2",
          "operationsManagement18coursecourseContent2Desc3",
          "operationsManagement18coursecourseContent2Desc4",
          "operationsManagement18coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent3Title",
        contents: [
          "operationsManagement18coursecourseContent3Desc1",
          "operationsManagement18coursecourseContent3Desc2",
          "operationsManagement18coursecourseContent3Desc3",
          "operationsManagement18coursecourseContent3Desc4",
          "operationsManagement18coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent4Title",
        contents: [
          "operationsManagement18coursecourseContent4Desc1",
          "operationsManagement18coursecourseContent4Desc2",
          "operationsManagement18coursecourseContent4Desc3",
          "operationsManagement18coursecourseContent4Desc4",
          "operationsManagement18coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent5Title",
        contents: [
          "operationsManagement18coursecourseContent5Desc1",
          "operationsManagement18coursecourseContent5Desc2",
          "operationsManagement18coursecourseContent5Desc3",
          "operationsManagement18coursecourseContent5Desc4",
          "operationsManagement18coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM18",
  },
  {
    id: 97,
    image: OperationM19,
    title: "operationsManagement19Title",
    desc: "operationsManagement19Desc",
    WhoShouldAttend: [
      "operationsManagement19WhoShouldAttend1",
      "operationsManagement19WhoShouldAttend2",
      "operationsManagement19WhoShouldAttend3",
      "operationsManagement19WhoShouldAttend4",
      "operationsManagement19WhoShouldAttend5",
      "operationsManagement19WhoShouldAttend6",
      "operationsManagement19WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement19courseExpectation1",
      "operationsManagement19courseExpectation2",
      "operationsManagement19courseExpectation3",
      "operationsManagement19courseExpectation4",
      "operationsManagement19courseExpectation5",
      "operationsManagement19courseExpectation6",
      "operationsManagement19courseExpectation7",
      "operationsManagement19courseExpectation8",
      "operationsManagement19courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement19coursecourseContent1Title",
        contents: [
          "operationsManagement19coursecourseContent1Desc1",
          "operationsManagement19coursecourseContent1Desc2",
          "operationsManagement19coursecourseContent1Desc3",
          "operationsManagement19coursecourseContent1Desc4",
          "operationsManagement19coursecourseContent1Desc5",
          "operationsManagement19coursecourseContent1Desc6",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent2Title",
        contents: [
          "operationsManagement19coursecourseContent2Desc1",
          "operationsManagement19coursecourseContent2Desc2",
          "operationsManagement19coursecourseContent2Desc3",
          "operationsManagement19coursecourseContent2Desc4",
          "operationsManagement19coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent3Title",
        contents: [
          "operationsManagement19coursecourseContent3Desc1",
          "operationsManagement19coursecourseContent3Desc2",
          "operationsManagement19coursecourseContent3Desc3",
          "operationsManagement19coursecourseContent3Desc4",
          "operationsManagement19coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent4Title",
        contents: [
          "operationsManagement19coursecourseContent4Desc1",
          "operationsManagement19coursecourseContent4Desc2",
          "operationsManagement19coursecourseContent4Desc3",
          "operationsManagement19coursecourseContent4Desc4",
          "operationsManagement19coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent5Title",
        contents: [
          "operationsManagement19coursecourseContent5Desc1",
          "operationsManagement19coursecourseContent5Desc2",
          "operationsManagement19coursecourseContent5Desc3",
          "operationsManagement19coursecourseContent5Desc4",
          "operationsManagement19coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM19",
  },
  {
    id: 98,
    image: OperationM20,
    title: "operationsManagement20Title",
    desc: "operationsManagement20Desc",
    WhoShouldAttend: [
      "operationsManagement20WhoShouldAttend1",
      "operationsManagement20WhoShouldAttend2",
      "operationsManagement20WhoShouldAttend3",
      "operationsManagement20WhoShouldAttend4",
      "operationsManagement20WhoShouldAttend5",
      "operationsManagement20WhoShouldAttend6",
      "operationsManagement20WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement20courseExpectation1",
      "operationsManagement20courseExpectation2",
      "operationsManagement20courseExpectation3",
      "operationsManagement20courseExpectation4",
      "operationsManagement20courseExpectation5",
      "operationsManagement20courseExpectation6",
      "operationsManagement20courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement20coursecourseContent1Title",
        contents: [
          "operationsManagement20coursecourseContent1Desc1",
          "operationsManagement20coursecourseContent1Desc2",
          "operationsManagement20coursecourseContent1Desc3",
          "operationsManagement20coursecourseContent1Desc4",
          "operationsManagement20coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent2Title",
        contents: [
          "operationsManagement20coursecourseContent2Desc1",
          "operationsManagement20coursecourseContent2Desc2",
          "operationsManagement20coursecourseContent2Desc3",
          "operationsManagement20coursecourseContent2Desc4",
          "operationsManagement20coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent3Title",
        contents: [
          "operationsManagement20coursecourseContent3Desc1",
          "operationsManagement20coursecourseContent3Desc2",
          "operationsManagement20coursecourseContent3Desc3",
          "operationsManagement20coursecourseContent3Desc4",
          "operationsManagement20coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent4Title",
        contents: [
          "operationsManagement20coursecourseContent4Desc1",
          "operationsManagement20coursecourseContent4Desc2",
          "operationsManagement20coursecourseContent4Desc3",
          "operationsManagement20coursecourseContent4Desc4",
          "operationsManagement20coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent5Title",
        contents: [
          "operationsManagement20coursecourseContent5Desc1",
          "operationsManagement20coursecourseContent5Desc2",
          "operationsManagement20coursecourseContent5Desc3",
          "operationsManagement20coursecourseContent5Desc4",
          "operationsManagement20coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM20",
  },
  // Operations Management

  // Public Relations
  {
    id: 99,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations1Title",
    desc: "publicRelations1Desc",
    WhoShouldAttend: [
      "publicRelations1WhoShouldAttend1",
      "publicRelations1WhoShouldAttend2",
      "publicRelations1WhoShouldAttend3",
      "publicRelations1WhoShouldAttend4",
      "publicRelations1WhoShouldAttend5",
      "publicRelations1WhoShouldAttend6",
      "publicRelations1WhoShouldAttend7",
      "publicRelations1WhoShouldAttend8",
      "publicRelations1WhoShouldAttend9",
      "publicRelations1WhoShouldAttend10",
      "publicRelations1WhoShouldAttend11",
    ],
    courseExpectation: [
      "publicRelations1courseExpectation1",
      "publicRelations1courseExpectation2",
      "publicRelations1courseExpectation3",
      "publicRelations1courseExpectation4",
      "publicRelations1courseExpectation5",
      "publicRelations1courseExpectation6",
      "publicRelations1courseExpectation7",
    ],
    courseContent: [
      {
        title: "publicRelations1coursecourseContent1Title",
        contents: [
          "publicRelations1coursecourseContent1Desc1",
          "publicRelations1coursecourseContent1Desc2",
          "publicRelations1coursecourseContent1Desc3",
          "publicRelations1coursecourseContent1Desc4",
          "publicRelations1coursecourseContent1Desc5",
          "publicRelations1coursecourseContent1Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent2Title",
        contents: [
          "publicRelations1coursecourseContent2Desc1",
          "publicRelations1coursecourseContent2Desc2",
          "publicRelations1coursecourseContent2Desc3",
          "publicRelations1coursecourseContent2Desc4",
          "publicRelations1coursecourseContent2Desc5",
          "publicRelations1coursecourseContent2Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent3Title",
        contents: [
          "publicRelations1coursecourseContent3Desc1",
          "publicRelations1coursecourseContent3Desc2",
          "publicRelations1coursecourseContent3Desc3",
          "publicRelations1coursecourseContent3Desc4",
          "publicRelations1coursecourseContent3Desc5",
          "publicRelations1coursecourseContent3Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent4Title",
        contents: [
          "publicRelations1coursecourseContent4Desc1",
          "publicRelations1coursecourseContent4Desc2",
          "publicRelations1coursecourseContent4Desc3",
          "publicRelations1coursecourseContent4Desc4",
          "publicRelations1coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations1coursecourseContent5Title",
        contents: [
          "publicRelations1coursecourseContent5Desc1",
          "publicRelations1coursecourseContent5Desc2",
          "publicRelations1coursecourseContent5Desc3",
          "publicRelations1coursecourseContent5Desc4",
          "publicRelations1coursecourseContent5Desc5",
          "publicRelations1coursecourseContent5Desc6",
          "publicRelations1coursecourseContent5Desc7",
        ],
      },
      {
        title: "publicRelations1coursecourseContent6Title",
        contents: [
          "publicRelations1coursecourseContent6Desc1",
          "publicRelations1coursecourseContent6Desc2",
          "publicRelations1coursecourseContent6Desc3",
          "publicRelations1coursecourseContent6Desc4",
          "publicRelations1coursecourseContent6Desc5",
          "publicRelations1coursecourseContent6Desc6",
          "publicRelations1coursecourseContent6Desc7",
        ],
      },
      {
        title: "publicRelations1coursecourseContent7Title",
        contents: [
          "publicRelations1coursecourseContent7Desc1",
          "publicRelations1coursecourseContent7Desc2",
          "publicRelations1coursecourseContent7Desc3",
          "publicRelations1coursecourseContent7Desc4",
          "publicRelations1coursecourseContent7Desc5",
          "publicRelations1coursecourseContent7Desc6",
        ],
      },
      {
        title: "publicRelations1coursecourseContent8Title",
        contents: [
          "publicRelations1coursecourseContent8Desc1",
          "publicRelations1coursecourseContent8Desc2",
          "publicRelations1coursecourseContent8Desc3",
          "publicRelations1coursecourseContent8Desc4",
          "publicRelations1coursecourseContent8Desc5",
          "publicRelations1coursecourseContent8Desc6",
          "publicRelations1coursecourseContent8Desc7",
        ],
      },
      {
        title: "publicRelations1coursecourseContent9Title",
        contents: [
          "publicRelations1coursecourseContent9Desc1",
          "publicRelations1coursecourseContent9Desc2",
          "publicRelations1coursecourseContent9Desc3",
          "publicRelations1coursecourseContent9Desc4",
          "publicRelations1coursecourseContent9Desc5",
          "publicRelations1coursecourseContent9Desc6",
          "publicRelations1coursecourseContent9Desc7",
        ],
      },
    ],
    courseFess: 5580,
    category: "Public Relations",
    courseCode: "PR01",
  },
  {
    id: 101,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations2Title",
    desc: "publicRelations2Desc",
    WhoShouldAttend: [
      "publicRelations2WhoShouldAttend1",
      "publicRelations2WhoShouldAttend2",
      "publicRelations2WhoShouldAttend3",
      "publicRelations2WhoShouldAttend4",
      "publicRelations2WhoShouldAttend5",
    ],
    courseExpectation: [
      "publicRelations2courseExpectation1",
      "publicRelations2courseExpectation2",
      "publicRelations2courseExpectation3",
      "publicRelations2courseExpectation4",
      "publicRelations2courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations2coursecourseContent1Title",
        contents: [
          "publicRelations2coursecourseContent1Desc1",
          "publicRelations2coursecourseContent1Desc2",
          "publicRelations2coursecourseContent1Desc3",
          "publicRelations2coursecourseContent1Desc4",
          "publicRelations2coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations2coursecourseContent2Title",
        contents: [
          "publicRelations2coursecourseContent2Desc1",
          "publicRelations2coursecourseContent2Desc2",
          "publicRelations2coursecourseContent2Desc3",
          "publicRelations2coursecourseContent2Desc4",
          "publicRelations2coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations2coursecourseContent3Title",
        contents: [
          "publicRelations2coursecourseContent3Desc1",
          "publicRelations2coursecourseContent3Desc2",
          "publicRelations2coursecourseContent3Desc3",
          "publicRelations2coursecourseContent3Desc4",
          "publicRelations2coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations2coursecourseContent4Title",
        contents: [
          "publicRelations2coursecourseContent4Desc1",
          "publicRelations2coursecourseContent4Desc2",
          "publicRelations2coursecourseContent4Desc3",
          "publicRelations2coursecourseContent4Desc4",
        ],
      },
      {
        title: "publicRelations2coursecourseContent5Title",
        contents: [
          "publicRelations2coursecourseContent5Desc1",
          "publicRelations2coursecourseContent5Desc2",
          "publicRelations2coursecourseContent5Desc3",
          "publicRelations2coursecourseContent5Desc4",
          "publicRelations2coursecourseContent5Desc5",
        ],
      },
      {
        title: "publicRelations2coursecourseContent6Title",
        contents: [
          "publicRelations2coursecourseContent6Desc1",
          "publicRelations2coursecourseContent6Desc2",
          "publicRelations2coursecourseContent6Desc3",
          "publicRelations2coursecourseContent6Desc4",
          "publicRelations2coursecourseContent6Desc5",
        ],
      },
      {
        title: "publicRelations2coursecourseContent7Title",
        contents: [
          "publicRelations2coursecourseContent7Desc1",
          "publicRelations2coursecourseContent7Desc2",
          "publicRelations2coursecourseContent7Desc3",
          "publicRelations2coursecourseContent7Desc4",
          "publicRelations2coursecourseContent7Desc5",
        ],
      },
      {
        title: "publicRelations2coursecourseContent8Title",
        contents: [
          "publicRelations2coursecourseContent8Desc1",
          "publicRelations2coursecourseContent8Desc2",
          "publicRelations2coursecourseContent8Desc3",
          "publicRelations2coursecourseContent8Desc4",
          "publicRelations2coursecourseContent8Desc5",
        ],
      },
      {
        title: "publicRelations2coursecourseContent9Title",
        contents: [
          "publicRelations2coursecourseContent9Desc1",
          "publicRelations2coursecourseContent9Desc2",
          "publicRelations2coursecourseContent9Desc3",
          "publicRelations2coursecourseContent9Desc4",
        ],
      },
    ],
    courseFess: 5580,
    category: "Public Relations",
    courseCode: "PR02",
  },
  {
    id: 102,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations3Title",
    desc: "publicRelations3Desc",
    WhoShouldAttend: [
      "publicRelations3WhoShouldAttend1",
      "publicRelations3WhoShouldAttend2",
      "publicRelations3WhoShouldAttend3",
      "publicRelations3WhoShouldAttend4",
      "publicRelations3WhoShouldAttend5",
    ],
    courseExpectation: [
      "publicRelations3courseExpectation1",
      "publicRelations3courseExpectation2",
      "publicRelations3courseExpectation3",
      "publicRelations3courseExpectation4",
      "publicRelations3courseExpectation5",
      "publicRelations3courseExpectation6",
      "publicRelations3courseExpectation7",
    ],
    courseContent: [
      {
        title: "publicRelations3coursecourseContent1Title",
        contents: [
          "publicRelations3coursecourseContent1Desc1",
          "publicRelations3coursecourseContent1Desc2",
          "publicRelations3coursecourseContent1Desc3",
          "publicRelations3coursecourseContent1Desc4",
          "publicRelations3coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations3coursecourseContent2Title",
        contents: [
          "publicRelations3coursecourseContent2Desc1",
          "publicRelations3coursecourseContent2Desc2",
          "publicRelations3coursecourseContent2Desc3",
          "publicRelations3coursecourseContent2Desc4",
          "publicRelations3coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations3coursecourseContent3Title",
        contents: [
          "publicRelations3coursecourseContent3Desc1",
          "publicRelations3coursecourseContent3Desc2",
          "publicRelations3coursecourseContent3Desc3",
          "publicRelations3coursecourseContent3Desc4",
          "publicRelations3coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations3coursecourseContent4Title",
        contents: [
          "publicRelations3coursecourseContent4Desc1",
          "publicRelations3coursecourseContent4Desc2",
          "publicRelations3coursecourseContent4Desc3",
          "publicRelations3coursecourseContent4Desc4",
          "publicRelations3coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations3coursecourseContent5Title",
        contents: [
          "publicRelations3coursecourseContent5Desc1",
          "publicRelations3coursecourseContent5Desc2",
          "publicRelations3coursecourseContent5Desc3",
          "publicRelations3coursecourseContent5Desc4",
          "publicRelations3coursecourseContent5Desc5",
        ],
      },
      {
        title: "publicRelations3coursecourseContent6Title",
        contents: [
          "publicRelations3coursecourseContent6Desc1",
          "publicRelations3coursecourseContent6Desc2",
          "publicRelations3coursecourseContent6Desc3",
          "publicRelations3coursecourseContent6Desc4",
          "publicRelations3coursecourseContent6Desc5",
        ],
      },
      {
        title: "publicRelations3coursecourseContent7Title",
        contents: [
          "publicRelations3coursecourseContent7Desc1",
          "publicRelations3coursecourseContent7Desc2",
          "publicRelations3coursecourseContent7Desc3",
          "publicRelations3coursecourseContent7Desc4",
          "publicRelations3coursecourseContent7Desc5",
        ],
      },
      {
        title: "publicRelations3coursecourseContent8Title",
        contents: [
          "publicRelations3coursecourseContent8Desc1",
          "publicRelations3coursecourseContent8Desc2",
          "publicRelations3coursecourseContent8Desc3",
          "publicRelations3coursecourseContent8Desc4",
        ],
      },
      {
        title: "publicRelations3coursecourseContent9Title",
        contents: [
          "publicRelations3coursecourseContent9Desc1",
          "publicRelations3coursecourseContent9Desc2",
          "publicRelations3coursecourseContent9Desc3",
          "publicRelations3coursecourseContent9Desc4",
          "publicRelations3coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 5580,
    category: "Public Relations",
    courseCode: "PR03",
  },
  {
    id: 103,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations4Title",
    desc: "publicRelations4Desc",
    WhoShouldAttend: [
      "publicRelations4WhoShouldAttend1",
      "publicRelations4WhoShouldAttend2",
      "publicRelations4WhoShouldAttend3",
      "publicRelations4WhoShouldAttend4",
      "publicRelations4WhoShouldAttend5",
      "publicRelations4WhoShouldAttend6",
      "publicRelations4WhoShouldAttend7",
      "publicRelations4WhoShouldAttend8",
      "publicRelations4WhoShouldAttend9",
      "publicRelations4WhoShouldAttend10",
      "publicRelations4WhoShouldAttend11",
    ],
    courseExpectation: [
      "publicRelations4courseExpectation1",
      "publicRelations4courseExpectation2",
      "publicRelations4courseExpectation3",
      "publicRelations4courseExpectation4",
      "publicRelations4courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations4coursecourseContent1Title",
        contents: [
          "publicRelations4coursecourseContent1Desc1",
          "publicRelations4coursecourseContent1Desc2",
          "publicRelations4coursecourseContent1Desc3",
          "publicRelations4coursecourseContent1Desc4",
          "publicRelations4coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent2Title",
        contents: [
          "publicRelations4coursecourseContent2Desc1",
          "publicRelations4coursecourseContent2Desc2",
          "publicRelations4coursecourseContent2Desc3",
          "publicRelations4coursecourseContent2Desc4",
          "publicRelations4coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent3Title",
        contents: [
          "publicRelations4coursecourseContent3Desc1",
          "publicRelations4coursecourseContent3Desc2",
          "publicRelations4coursecourseContent3Desc3",
          "publicRelations4coursecourseContent3Desc4",
          "publicRelations4coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent4Title",
        contents: [
          "publicRelations4coursecourseContent4Desc1",
          "publicRelations4coursecourseContent4Desc2",
          "publicRelations4coursecourseContent4Desc3",
          "publicRelations4coursecourseContent4Desc4",
          "publicRelations4coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent5Title",
        contents: [
          "publicRelations4coursecourseContent5Desc1",
          "publicRelations4coursecourseContent5Desc2",
          "publicRelations4coursecourseContent5Desc3",
          "publicRelations4coursecourseContent5Desc4",
          "publicRelations4coursecourseContent5Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent6Title",
        contents: [
          "publicRelations4coursecourseContent6Desc1",
          "publicRelations4coursecourseContent6Desc2",
          "publicRelations4coursecourseContent6Desc3",
          "publicRelations4coursecourseContent6Desc4",
          "publicRelations4coursecourseContent6Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent7Title",
        contents: [
          "publicRelations4coursecourseContent7Desc1",
          "publicRelations4coursecourseContent7Desc2",
          "publicRelations4coursecourseContent7Desc3",
          "publicRelations4coursecourseContent7Desc4",
          "publicRelations4coursecourseContent7Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent8Title",
        contents: [
          "publicRelations4coursecourseContent8Desc1",
          "publicRelations4coursecourseContent8Desc2",
          "publicRelations4coursecourseContent8Desc3",
          "publicRelations4coursecourseContent8Desc4",
          "publicRelations4coursecourseContent8Desc5",
        ],
      },
      {
        title: "publicRelations4coursecourseContent9Title",
        contents: [
          "publicRelations4coursecourseContent9Desc1",
          "publicRelations4coursecourseContent9Desc2",
          "publicRelations4coursecourseContent9Desc3",
          "publicRelations4coursecourseContent9Desc4",
          "publicRelations4coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 5580,
    category: "Public Relations",
    courseCode: "PR04",
  },
  {
    id: 104,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations5Title",
    desc: "publicRelations5Desc",
    WhoShouldAttend: [
      "publicRelations5WhoShouldAttend1",
      "publicRelations5WhoShouldAttend2",
      "publicRelations5WhoShouldAttend3",
      "publicRelations5WhoShouldAttend4",
      "publicRelations5WhoShouldAttend5",
    ],
    courseExpectation: [
      "publicRelations5courseExpectation1",
      "publicRelations5courseExpectation2",
      "publicRelations5courseExpectation3",
      "publicRelations5courseExpectation4",
      "publicRelations5courseExpectation5",
      "publicRelations5courseExpectation6",
      "publicRelations5courseExpectation7",
    ],
    courseContent: [
      {
        title: "publicRelations5coursecourseContent1Title",
        contents: [
          "publicRelations5coursecourseContent1Desc1",
          "publicRelations5coursecourseContent1Desc2",
          "publicRelations5coursecourseContent1Desc3",
          "publicRelations5coursecourseContent1Desc4",
          "publicRelations5coursecourseContent1Desc5",
          "publicRelations5coursecourseContent1Desc6",
        ],
      },
      {
        title: "publicRelations5coursecourseContent2Title",
        contents: [
          "publicRelations5coursecourseContent2Desc1",
          "publicRelations5coursecourseContent2Desc2",
          "publicRelations5coursecourseContent2Desc3",
          "publicRelations5coursecourseContent2Desc4",
          "publicRelations5coursecourseContent2Desc5",
          "publicRelations5coursecourseContent2Desc6",
        ],
      },
      {
        title: "publicRelations5coursecourseContent3Title",
        contents: [
          "publicRelations5coursecourseContent3Desc1",
          "publicRelations5coursecourseContent3Desc2",
          "publicRelations5coursecourseContent3Desc3",
          "publicRelations5coursecourseContent3Desc4",
          "publicRelations5coursecourseContent3Desc5",
          "publicRelations5coursecourseContent3Desc6",
        ],
      },
      {
        title: "publicRelations5coursecourseContent4Title",
        contents: [
          "publicRelations5coursecourseContent4Desc1",
          "publicRelations5coursecourseContent4Desc2",
          "publicRelations5coursecourseContent4Desc3",
          "publicRelations5coursecourseContent4Desc4",
          "publicRelations5coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations5coursecourseContent5Title",
        contents: [
          "publicRelations5coursecourseContent5Desc1",
          "publicRelations5coursecourseContent5Desc2",
          "publicRelations5coursecourseContent5Desc3",
          "publicRelations5coursecourseContent5Desc4",
          "publicRelations5coursecourseContent5Desc5",
          "publicRelations5coursecourseContent5Desc6",
          "publicRelations5coursecourseContent5Desc7",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR05",
  },
  {
    id: 105,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations6Title",
    desc: "publicRelations6Desc",
    WhoShouldAttend: [
      "publicRelations6WhoShouldAttend1",
      "publicRelations6WhoShouldAttend2",
      "publicRelations6WhoShouldAttend3",
      "publicRelations6WhoShouldAttend4",
      "publicRelations6WhoShouldAttend5",
      "publicRelations6WhoShouldAttend6",
      "publicRelations6WhoShouldAttend7",
    ],
    courseExpectation: [
      "publicRelations6courseExpectation1",
      "publicRelations6courseExpectation2",
      "publicRelations6courseExpectation3",
      "publicRelations6courseExpectation4",
      "publicRelations6courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations6coursecourseContent1Title",
        contents: [
          "publicRelations6coursecourseContent1Desc1",
          "publicRelations6coursecourseContent1Desc2",
          "publicRelations6coursecourseContent1Desc3",
          "publicRelations6coursecourseContent1Desc4",
          "publicRelations6coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations6coursecourseContent2Title",
        contents: [
          "publicRelations6coursecourseContent2Desc1",
          "publicRelations6coursecourseContent2Desc2",
          "publicRelations6coursecourseContent2Desc3",
          "publicRelations6coursecourseContent2Desc4",
          "publicRelations6coursecourseContent2Desc5",
          "publicRelations6coursecourseContent2Desc6",
          "publicRelations6coursecourseContent2Desc7",
        ],
      },
      {
        title: "publicRelations6coursecourseContent3Title",
        contents: [
          "publicRelations6coursecourseContent3Desc1",
          "publicRelations6coursecourseContent3Desc2",
          "publicRelations6coursecourseContent3Desc3",
          "publicRelations6coursecourseContent3Desc4",
          "publicRelations6coursecourseContent3Desc5",
          "publicRelations6coursecourseContent3Desc6",
        ],
      },
      {
        title: "publicRelations6coursecourseContent4Title",
        contents: [
          "publicRelations6coursecourseContent4Desc1",
          "publicRelations6coursecourseContent4Desc2",
          "publicRelations6coursecourseContent4Desc3",
          "publicRelations6coursecourseContent4Desc4",
          "publicRelations6coursecourseContent4Desc5",
          "publicRelations6coursecourseContent4Desc6",
          "publicRelations6coursecourseContent4Desc7",
        ],
      },
      {
        title: "publicRelations6coursecourseContent5Title",
        contents: [
          "publicRelations6coursecourseContent5Desc1",
          "publicRelations6coursecourseContent5Desc2",
          "publicRelations6coursecourseContent5Desc3",
          "publicRelations6coursecourseContent5Desc4",
          "publicRelations6coursecourseContent5Desc5",
          "publicRelations6coursecourseContent5Desc6",
          "publicRelations6coursecourseContent5Desc7",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR06",
  },
  {
    id: 107,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations7Title",
    desc: "publicRelations7Desc",
    WhoShouldAttend: [
      "publicRelations7WhoShouldAttend1",
      "publicRelations7WhoShouldAttend2",
      "publicRelations7WhoShouldAttend3",
      "publicRelations7WhoShouldAttend4",
      "publicRelations7WhoShouldAttend5",
    ],
    courseExpectation: [
      "publicRelations7courseExpectation1",
      "publicRelations7courseExpectation2",
      "publicRelations7courseExpectation3",
      "publicRelations7courseExpectation4",
      "publicRelations7courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations7coursecourseContent1Title",
        contents: [
          "publicRelations7coursecourseContent1Desc1",
          "publicRelations7coursecourseContent1Desc2",
          "publicRelations7coursecourseContent1Desc3",
          "publicRelations7coursecourseContent1Desc4",
          "publicRelations7coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations7coursecourseContent2Title",
        contents: [
          "publicRelations7coursecourseContent2Desc1",
          "publicRelations7coursecourseContent2Desc2",
          "publicRelations7coursecourseContent2Desc3",
          "publicRelations7coursecourseContent2Desc4",
          "publicRelations7coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations7coursecourseContent3Title",
        contents: [
          "publicRelations7coursecourseContent3Desc1",
          "publicRelations7coursecourseContent3Desc2",
          "publicRelations7coursecourseContent3Desc3",
          "publicRelations7coursecourseContent3Desc4",
          "publicRelations7coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations7coursecourseContent4Title",
        contents: [
          "publicRelations7coursecourseContent4Desc1",
          "publicRelations7coursecourseContent4Desc2",
          "publicRelations7coursecourseContent4Desc3",
          "publicRelations7coursecourseContent4Desc4",
          "publicRelations7coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations7coursecourseContent5Title",
        contents: [
          "publicRelations7coursecourseContent5Desc1",
          "publicRelations7coursecourseContent5Desc2",
          "publicRelations7coursecourseContent5Desc3",
          "publicRelations7coursecourseContent5Desc4",
          "publicRelations7coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR07",
  },
  {
    id: 108,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations8Title",
    desc: "publicRelations8Desc",
    WhoShouldAttend: [
      "publicRelations8WhoShouldAttend1",
      "publicRelations8WhoShouldAttend2",
      "publicRelations8WhoShouldAttend3",
      "publicRelations8WhoShouldAttend4",
      "publicRelations8WhoShouldAttend5",
    ],
    courseExpectation: [
      "publicRelations8courseExpectation1",
      "publicRelations8courseExpectation2",
      "publicRelations8courseExpectation3",
      "publicRelations8courseExpectation4",
      "publicRelations8courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations8coursecourseContent1Title",
        contents: [
          "publicRelations8coursecourseContent1Desc1",
          "publicRelations8coursecourseContent1Desc2",
          "publicRelations8coursecourseContent1Desc3",
          "publicRelations8coursecourseContent1Desc4",
          "publicRelations8coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations8coursecourseContent2Title",
        contents: [
          "publicRelations8coursecourseContent2Desc1",
          "publicRelations8coursecourseContent2Desc2",
          "publicRelations8coursecourseContent2Desc3",
          "publicRelations8coursecourseContent2Desc4",
          "publicRelations8coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations8coursecourseContent3Title",
        contents: [
          "publicRelations8coursecourseContent3Desc1",
          "publicRelations8coursecourseContent3Desc2",
          "publicRelations8coursecourseContent3Desc3",
          "publicRelations8coursecourseContent3Desc4",
          "publicRelations8coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations8coursecourseContent4Title",
        contents: [
          "publicRelations8coursecourseContent4Desc1",
          "publicRelations8coursecourseContent4Desc2",
          "publicRelations8coursecourseContent4Desc3",
          "publicRelations8coursecourseContent4Desc4",
          "publicRelations8coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations8coursecourseContent5Title",
        contents: [
          "publicRelations8coursecourseContent5Desc1",
          "publicRelations8coursecourseContent5Desc2",
          "publicRelations8coursecourseContent5Desc3",
          "publicRelations8coursecourseContent5Desc4",
          "publicRelations8coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR08",
  },
  {
    id: 109,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations9Title",
    desc: "publicRelations9Desc",
    WhoShouldAttend: [
      "publicRelations9WhoShouldAttend1",
      "publicRelations9WhoShouldAttend2",
      "publicRelations9WhoShouldAttend3",
      "publicRelations9WhoShouldAttend4",
      "publicRelations9WhoShouldAttend5",
    ],
    courseExpectation: [
      "publicRelations9courseExpectation1",
      "publicRelations9courseExpectation2",
      "publicRelations9courseExpectation3",
      "publicRelations9courseExpectation4",
      "publicRelations9courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations9coursecourseContent1Title",
        contents: [
          "publicRelations9coursecourseContent1Desc1",
          "publicRelations9coursecourseContent1Desc2",
          "publicRelations9coursecourseContent1Desc3",
          "publicRelations9coursecourseContent1Desc4",
          "publicRelations9coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations9coursecourseContent2Title",
        contents: [
          "publicRelations9coursecourseContent2Desc1",
          "publicRelations9coursecourseContent2Desc2",
          "publicRelations9coursecourseContent2Desc3",
          "publicRelations9coursecourseContent2Desc4",
          "publicRelations9coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations9coursecourseContent3Title",
        contents: [
          "publicRelations9coursecourseContent3Desc1",
          "publicRelations9coursecourseContent3Desc2",
          "publicRelations9coursecourseContent3Desc3",
          "publicRelations9coursecourseContent3Desc4",
          "publicRelations9coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations9coursecourseContent4Title",
        contents: [
          "publicRelations9coursecourseContent4Desc1",
          "publicRelations9coursecourseContent4Desc2",
          "publicRelations9coursecourseContent4Desc3",
          "publicRelations9coursecourseContent4Desc4",
          "publicRelations9coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations9coursecourseContent5Title",
        contents: [
          "publicRelations9coursecourseContent5Desc1",
          "publicRelations9coursecourseContent5Desc2",
          "publicRelations9coursecourseContent5Desc3",
          "publicRelations9coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR09",
  },
  {
    id: 110,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations10Title",
    desc: "publicRelations10Desc",
    WhoShouldAttend: [
      "publicRelations10WhoShouldAttend1",
      "publicRelations10WhoShouldAttend2",
      "publicRelations10WhoShouldAttend3",
      "publicRelations10WhoShouldAttend4",
      "publicRelations10WhoShouldAttend5",
      "publicRelations10WhoShouldAttend6",
      "publicRelations10WhoShouldAttend7",
    ],
    courseExpectation: [
      "publicRelations10courseExpectation1",
      "publicRelations10courseExpectation2",
      "publicRelations10courseExpectation3",
      "publicRelations10courseExpectation4",
      "publicRelations10courseExpectation5",
      "publicRelations10courseExpectation6",
    ],
    courseContent: [
      {
        title: "publicRelations10coursecourseContent1Title",
        contents: [
          "publicRelations10coursecourseContent1Desc1",
          "publicRelations10coursecourseContent1Desc2",
          "publicRelations10coursecourseContent1Desc3",
          "publicRelations10coursecourseContent1Desc4",
          "publicRelations10coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations10coursecourseContent2Title",
        contents: [
          "publicRelations10coursecourseContent2Desc1",
          "publicRelations10coursecourseContent2Desc2",
          "publicRelations10coursecourseContent2Desc3",
          "publicRelations10coursecourseContent2Desc4",
          "publicRelations10coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations10coursecourseContent3Title",
        contents: [
          "publicRelations10coursecourseContent3Desc1",
          "publicRelations10coursecourseContent3Desc2",
          "publicRelations10coursecourseContent3Desc3",
          "publicRelations10coursecourseContent3Desc4",
          "publicRelations10coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations10coursecourseContent4Title",
        contents: [
          "publicRelations10coursecourseContent4Desc1",
          "publicRelations10coursecourseContent4Desc2",
          "publicRelations10coursecourseContent4Desc3",
          "publicRelations10coursecourseContent4Desc4",
          "publicRelations10coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations10coursecourseContent5Title",
        contents: [
          "publicRelations10coursecourseContent5Desc1",
          "publicRelations10coursecourseContent5Desc2",
          "publicRelations10coursecourseContent5Desc3",
          "publicRelations10coursecourseContent5Desc4",
          "publicRelations10coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR10",
  },
  {
    id: 111,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations11Title",
    desc: "publicRelations11Desc",
    WhoShouldAttend: [
      "publicRelations11WhoShouldAttend1",
      "publicRelations11WhoShouldAttend2",
      "publicRelations11WhoShouldAttend3",
      "publicRelations11WhoShouldAttend4",
      "publicRelations11WhoShouldAttend5",
      "publicRelations11WhoShouldAttend6",
      "publicRelations11WhoShouldAttend7",
    ],
    courseExpectation: [
      "publicRelations11courseExpectation1",
      "publicRelations11courseExpectation2",
      "publicRelations11courseExpectation3",
      "publicRelations11courseExpectation4",
    ],
    courseContent: [
      {
        title: "publicRelations11coursecourseContent1Title",
        contents: [
          "publicRelations11coursecourseContent1Desc1",
          "publicRelations11coursecourseContent1Desc2",
          "publicRelations11coursecourseContent1Desc3",
          "publicRelations11coursecourseContent1Desc4",
          "publicRelations11coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations11coursecourseContent2Title",
        contents: [
          "publicRelations11coursecourseContent2Desc1",
          "publicRelations11coursecourseContent2Desc2",
          "publicRelations11coursecourseContent2Desc3",
          "publicRelations11coursecourseContent2Desc4",
          "publicRelations11coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations11coursecourseContent3Title",
        contents: [
          "publicRelations11coursecourseContent3Desc1",
          "publicRelations11coursecourseContent3Desc2",
          "publicRelations11coursecourseContent3Desc3",
          "publicRelations11coursecourseContent3Desc4",
          "publicRelations11coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations11coursecourseContent4Title",
        contents: [
          "publicRelations11coursecourseContent4Desc1",
          "publicRelations11coursecourseContent4Desc2",
          "publicRelations11coursecourseContent4Desc3",
          "publicRelations11coursecourseContent4Desc4",
        ],
      },
      {
        title: "publicRelations11coursecourseContent5Title",
        contents: [
          "publicRelations11coursecourseContent5Desc1",
          "publicRelations11coursecourseContent5Desc2",
          "publicRelations11coursecourseContent5Desc3",
          "publicRelations11coursecourseContent5Desc4",
          "publicRelations11coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR11",
  },
  {
    id: 112,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations12Title",
    desc: "publicRelations12Desc",
    WhoShouldAttend: [
      "publicRelations12WhoShouldAttend1",
      "publicRelations12WhoShouldAttend2",
      "publicRelations12WhoShouldAttend3",
      "publicRelations12WhoShouldAttend4",
      "publicRelations12WhoShouldAttend5",
      "publicRelations12WhoShouldAttend6",
      "publicRelations12WhoShouldAttend7",
      "publicRelations12WhoShouldAttend8",
      "publicRelations12WhoShouldAttend9",
      "publicRelations12WhoShouldAttend10",
    ],
    courseExpectation: [
      "publicRelations12courseExpectation1",
      "publicRelations12courseExpectation2",
      "publicRelations12courseExpectation3",
      "publicRelations12courseExpectation4",
      "publicRelations12courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations12coursecourseContent1Title",
        contents: [
          "publicRelations12coursecourseContent1Desc1",
          "publicRelations12coursecourseContent1Desc2",
          "publicRelations12coursecourseContent1Desc3",
          "publicRelations12coursecourseContent1Desc4",
          "publicRelations12coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations12coursecourseContent2Title",
        contents: [
          "publicRelations12coursecourseContent2Desc1",
          "publicRelations12coursecourseContent2Desc2",
          "publicRelations12coursecourseContent2Desc3",
          "publicRelations12coursecourseContent2Desc4",
          "publicRelations12coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations12coursecourseContent3Title",
        contents: [
          "publicRelations12coursecourseContent3Desc1",
          "publicRelations12coursecourseContent3Desc2",
          "publicRelations12coursecourseContent3Desc3",
          "publicRelations12coursecourseContent3Desc4",
          "publicRelations12coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations12coursecourseContent4Title",
        contents: [
          "publicRelations12coursecourseContent4Desc1",
          "publicRelations12coursecourseContent4Desc2",
          "publicRelations12coursecourseContent4Desc3",
          "publicRelations12coursecourseContent4Desc4",
          "publicRelations12coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations12coursecourseContent5Title",
        contents: [
          "publicRelations12coursecourseContent5Desc1",
          "publicRelations12coursecourseContent5Desc2",
          "publicRelations12coursecourseContent5Desc3",
          "publicRelations12coursecourseContent5Desc4",
          "publicRelations12coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR12",
  },
  {
    id: 113,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations13Title",
    desc: "publicRelations13Desc",
    WhoShouldAttend: [
      "publicRelations13WhoShouldAttend1",
      "publicRelations13WhoShouldAttend2",
      "publicRelations13WhoShouldAttend3",
      "publicRelations13WhoShouldAttend4",
      "publicRelations13WhoShouldAttend5",
      "publicRelations13WhoShouldAttend6",
      "publicRelations13WhoShouldAttend7",
      "publicRelations13WhoShouldAttend8",
      "publicRelations13WhoShouldAttend9",
      "publicRelations13WhoShouldAttend10",
      "publicRelations13WhoShouldAttend11",
    ],
    courseExpectation: [
      "publicRelations13courseExpectation1",
      "publicRelations13courseExpectation2",
      "publicRelations13courseExpectation3",
      "publicRelations13courseExpectation4",
      "publicRelations13courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations13coursecourseContent1Title",
        contents: [
          "publicRelations13coursecourseContent1Desc1",
          "publicRelations13coursecourseContent1Desc2",
          "publicRelations13coursecourseContent1Desc3",
          "publicRelations13coursecourseContent1Desc4",
          "publicRelations13coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations13coursecourseContent2Title",
        contents: [
          "publicRelations13coursecourseContent2Desc1",
          "publicRelations13coursecourseContent2Desc2",
          "publicRelations13coursecourseContent2Desc3",
          "publicRelations13coursecourseContent2Desc4",
          "publicRelations13coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations13coursecourseContent3Title",
        contents: [
          "publicRelations13coursecourseContent3Desc1",
          "publicRelations13coursecourseContent3Desc2",
          "publicRelations13coursecourseContent3Desc3",
          "publicRelations13coursecourseContent3Desc4",
          "publicRelations13coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations13coursecourseContent4Title",
        contents: [
          "publicRelations13coursecourseContent4Desc1",
          "publicRelations13coursecourseContent4Desc2",
          "publicRelations13coursecourseContent4Desc3",
          "publicRelations13coursecourseContent4Desc4",
          "publicRelations13coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations13coursecourseContent5Title",
        contents: [
          "publicRelations13coursecourseContent5Desc1",
          "publicRelations13coursecourseContent5Desc2",
          "publicRelations13coursecourseContent5Desc3",
          "publicRelations13coursecourseContent5Desc4",
          "publicRelations13coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR13",
  },
  {
    id: 114,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations14Title",
    desc: "publicRelations14Desc",
    WhoShouldAttend: [
      "publicRelations14WhoShouldAttend1",
      "publicRelations14WhoShouldAttend2",
      "publicRelations14WhoShouldAttend3",
      "publicRelations14WhoShouldAttend4",
      "publicRelations14WhoShouldAttend5",
      "publicRelations14WhoShouldAttend6",
      "publicRelations14WhoShouldAttend7",
      "publicRelations14WhoShouldAttend8",
      "publicRelations14WhoShouldAttend9",
      "publicRelations14WhoShouldAttend10",
    ],
    courseExpectation: [
      "publicRelations14courseExpectation1",
      "publicRelations14courseExpectation2",
      "publicRelations14courseExpectation3",
      "publicRelations14courseExpectation4",
      "publicRelations14courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations14coursecourseContent1Title",
        contents: [
          "publicRelations14coursecourseContent1Desc1",
          "publicRelations14coursecourseContent1Desc2",
          "publicRelations14coursecourseContent1Desc3",
          "publicRelations14coursecourseContent1Desc4",
          "publicRelations14coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations14coursecourseContent2Title",
        contents: [
          "publicRelations14coursecourseContent2Desc1",
          "publicRelations14coursecourseContent2Desc2",
          "publicRelations14coursecourseContent2Desc3",
          "publicRelations14coursecourseContent2Desc4",
          "publicRelations14coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations14coursecourseContent3Title",
        contents: [
          "publicRelations14coursecourseContent3Desc1",
          "publicRelations14coursecourseContent3Desc2",
          "publicRelations14coursecourseContent3Desc3",
          "publicRelations14coursecourseContent3Desc4",
          "publicRelations14coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations14coursecourseContent4Title",
        contents: [
          "publicRelations14coursecourseContent4Desc1",
          "publicRelations14coursecourseContent4Desc2",
          "publicRelations14coursecourseContent4Desc3",
          "publicRelations14coursecourseContent4Desc4",
          "publicRelations14coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations14coursecourseContent5Title",
        contents: [
          "publicRelations14coursecourseContent5Desc1",
          "publicRelations14coursecourseContent5Desc2",
          "publicRelations14coursecourseContent5Desc3",
          "publicRelations14coursecourseContent5Desc4",
          "publicRelations14coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR14",
  },
  {
    id: 116,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations15Title",
    desc: "publicRelations15Desc",
    WhoShouldAttend: [
      "publicRelations15WhoShouldAttend1",
      "publicRelations15WhoShouldAttend2",
      "publicRelations15WhoShouldAttend3",
      "publicRelations15WhoShouldAttend4",
      "publicRelations15WhoShouldAttend5",
      "publicRelations15WhoShouldAttend6",
      "publicRelations15WhoShouldAttend7",
      "publicRelations15WhoShouldAttend8",
      "publicRelations15WhoShouldAttend9",
      "publicRelations15WhoShouldAttend10",
      "publicRelations15WhoShouldAttend11",
      "publicRelations15WhoShouldAttend12",
    ],
    courseExpectation: [
      "publicRelations15courseExpectation1",
      "publicRelations15courseExpectation2",
      "publicRelations15courseExpectation3",
      "publicRelations15courseExpectation4",
      "publicRelations15courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations15coursecourseContent1Title",
        contents: [
          "publicRelations15coursecourseContent1Desc1",
          "publicRelations15coursecourseContent1Desc2",
          "publicRelations15coursecourseContent1Desc3",
          "publicRelations15coursecourseContent1Desc4",
          "publicRelations15coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations15coursecourseContent2Title",
        contents: [
          "publicRelations15coursecourseContent2Desc1",
          "publicRelations15coursecourseContent2Desc2",
          "publicRelations15coursecourseContent2Desc3",
          "publicRelations15coursecourseContent2Desc4",
          "publicRelations15coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations15coursecourseContent3Title",
        contents: [
          "publicRelations15coursecourseContent3Desc1",
          "publicRelations15coursecourseContent3Desc2",
          "publicRelations15coursecourseContent3Desc3",
          "publicRelations15coursecourseContent3Desc4",
          "publicRelations15coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations15coursecourseContent4Title",
        contents: [
          "publicRelations15coursecourseContent4Desc1",
          "publicRelations15coursecourseContent4Desc2",
          "publicRelations15coursecourseContent4Desc3",
          "publicRelations15coursecourseContent4Desc4",
          "publicRelations15coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations15coursecourseContent5Title",
        contents: [
          "publicRelations15coursecourseContent5Desc1",
          "publicRelations15coursecourseContent5Desc2",
          "publicRelations15coursecourseContent5Desc3",
          "publicRelations15coursecourseContent5Desc4",
          "publicRelations15coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Public Relations",
    courseCode: "PR16",
  },
  {
    id: 117,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations16Title",
    desc: "publicRelations16Desc",
    WhoShouldAttend: [
      "publicRelations16WhoShouldAttend1",
      "publicRelations16WhoShouldAttend2",
      "publicRelations16WhoShouldAttend3",
      "publicRelations16WhoShouldAttend4",
      "publicRelations16WhoShouldAttend5",
      "publicRelations16WhoShouldAttend6",
      "publicRelations16WhoShouldAttend7",
      "publicRelations16WhoShouldAttend8",
      "publicRelations16WhoShouldAttend9",
    ],
    courseExpectation: [
      "publicRelations16courseExpectation1",
      "publicRelations16courseExpectation2",
      "publicRelations16courseExpectation3",
      "publicRelations16courseExpectation4",
      "publicRelations16courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations16coursecourseContent1Title",
        contents: [
          "publicRelations16coursecourseContent1Desc1",
          "publicRelations16coursecourseContent1Desc2",
          "publicRelations16coursecourseContent1Desc3",
          "publicRelations16coursecourseContent1Desc4",
          "publicRelations16coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent2Title",
        contents: [
          "publicRelations16coursecourseContent2Desc1",
          "publicRelations16coursecourseContent2Desc2",
          "publicRelations16coursecourseContent2Desc3",
          "publicRelations16coursecourseContent2Desc4",
          "publicRelations16coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent3Title",
        contents: [
          "publicRelations16coursecourseContent3Desc1",
          "publicRelations16coursecourseContent3Desc2",
          "publicRelations16coursecourseContent3Desc3",
          "publicRelations16coursecourseContent3Desc4",
          "publicRelations16coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent4Title",
        contents: [
          "publicRelations16coursecourseContent4Desc1",
          "publicRelations16coursecourseContent4Desc2",
          "publicRelations16coursecourseContent4Desc3",
          "publicRelations16coursecourseContent4Desc4",
          "publicRelations16coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent5Title",
        contents: [
          "publicRelations16coursecourseContent5Desc1",
          "publicRelations16coursecourseContent5Desc2",
          "publicRelations16coursecourseContent5Desc3",
          "publicRelations16coursecourseContent5Desc4",
          "publicRelations16coursecourseContent5Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent6Title",
        contents: [
          "publicRelations16coursecourseContent6Desc1",
          "publicRelations16coursecourseContent6Desc2",
          "publicRelations16coursecourseContent6Desc3",
          "publicRelations16coursecourseContent6Desc4",
          "publicRelations16coursecourseContent6Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent7Title",
        contents: [
          "publicRelations16coursecourseContent7Desc1",
          "publicRelations16coursecourseContent7Desc2",
          "publicRelations16coursecourseContent7Desc3",
          "publicRelations16coursecourseContent7Desc4",
          "publicRelations16coursecourseContent7Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent8Title",
        contents: [
          "publicRelations16coursecourseContent8Desc1",
          "publicRelations16coursecourseContent8Desc2",
          "publicRelations16coursecourseContent8Desc3",
          "publicRelations16coursecourseContent8Desc4",
          "publicRelations16coursecourseContent8Desc5",
        ],
      },
      {
        title: "publicRelations16coursecourseContent9Title",
        contents: [
          "publicRelations16coursecourseContent9Desc1",
          "publicRelations16coursecourseContent9Desc2",
          "publicRelations16coursecourseContent9Desc3",
          "publicRelations16coursecourseContent9Desc4",
          "publicRelations16coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Public Relations",
    courseCode: "PR17",
  },
  {
    id: 118,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "publicRelations17Title",
    desc: "publicRelations17Desc",
    WhoShouldAttend: [
      "publicRelations17WhoShouldAttend1",
      "publicRelations17WhoShouldAttend2",
      "publicRelations17WhoShouldAttend3",
      "publicRelations17WhoShouldAttend4",
      "publicRelations17WhoShouldAttend5",
      "publicRelations17WhoShouldAttend6",
      "publicRelations17WhoShouldAttend7",
      "publicRelations17WhoShouldAttend8",
      "publicRelations17WhoShouldAttend9",
      "publicRelations17WhoShouldAttend10",
    ],
    courseExpectation: [
      "publicRelations17courseExpectation1",
      "publicRelations17courseExpectation2",
      "publicRelations17courseExpectation3",
      "publicRelations17courseExpectation4",
      "publicRelations17courseExpectation5",
    ],
    courseContent: [
      {
        title: "publicRelations17coursecourseContent1Title",
        contents: [
          "publicRelations17coursecourseContent1Desc1",
          "publicRelations17coursecourseContent1Desc2",
          "publicRelations17coursecourseContent1Desc3",
          "publicRelations17coursecourseContent1Desc4",
          "publicRelations17coursecourseContent1Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent2Title",
        contents: [
          "publicRelations17coursecourseContent2Desc1",
          "publicRelations17coursecourseContent2Desc2",
          "publicRelations17coursecourseContent2Desc3",
          "publicRelations17coursecourseContent2Desc4",
          "publicRelations17coursecourseContent2Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent3Title",
        contents: [
          "publicRelations17coursecourseContent3Desc1",
          "publicRelations17coursecourseContent3Desc2",
          "publicRelations17coursecourseContent3Desc3",
          "publicRelations17coursecourseContent3Desc4",
          "publicRelations17coursecourseContent3Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent4Title",
        contents: [
          "publicRelations17coursecourseContent4Desc1",
          "publicRelations17coursecourseContent4Desc2",
          "publicRelations17coursecourseContent4Desc3",
          "publicRelations17coursecourseContent4Desc4",
          "publicRelations17coursecourseContent4Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent5Title",
        contents: [
          "publicRelations17coursecourseContent5Desc1",
          "publicRelations17coursecourseContent5Desc2",
          "publicRelations17coursecourseContent5Desc3",
          "publicRelations17coursecourseContent5Desc4",
          "publicRelations17coursecourseContent5Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent6Title",
        contents: [
          "publicRelations17coursecourseContent6Desc1",
          "publicRelations17coursecourseContent6Desc2",
          "publicRelations17coursecourseContent6Desc3",
          "publicRelations17coursecourseContent6Desc4",
          "publicRelations17coursecourseContent6Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent7Title",
        contents: [
          "publicRelations17coursecourseContent7Desc1",
          "publicRelations17coursecourseContent7Desc2",
          "publicRelations17coursecourseContent7Desc3",
          "publicRelations17coursecourseContent7Desc4",
          "publicRelations17coursecourseContent7Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent8Title",
        contents: [
          "publicRelations17coursecourseContent8Desc1",
          "publicRelations17coursecourseContent8Desc2",
          "publicRelations17coursecourseContent8Desc3",
          "publicRelations17coursecourseContent8Desc4",
          "publicRelations17coursecourseContent8Desc5",
        ],
      },
      {
        title: "publicRelations17coursecourseContent9Title",
        contents: [
          "publicRelations17coursecourseContent9Desc1",
          "publicRelations17coursecourseContent9Desc2",
          "publicRelations17coursecourseContent9Desc3",
          "publicRelations17coursecourseContent9Desc4",
          "publicRelations17coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Public Relations",
    courseCode: "PR18",
  },
  // Public Relations

  // Sales and Marketing
  {
    id: 119,
    image: Sales1,
    title: "salesAndMarketing1Title",
    desc: "salesAndMarketing1Desc",
    WhoShouldAttend: [
      "salesAndMarketing1WhoShouldAttend1",
      "salesAndMarketing1WhoShouldAttend2",
      "salesAndMarketing1WhoShouldAttend3",
      "salesAndMarketing1WhoShouldAttend4",
    ],
    courseExpectation: [
      "salesAndMarketing1courseExpectation1",
      "salesAndMarketing1courseExpectation2",
      "salesAndMarketing1courseExpectation3",
      "salesAndMarketing1courseExpectation4",
      "salesAndMarketing1courseExpectation5",
      "salesAndMarketing1courseExpectation6",
      "salesAndMarketing1courseExpectation7",
      "salesAndMarketing1courseExpectation8",
      "salesAndMarketing1courseExpectation9",
      "salesAndMarketing1courseExpectation10",
      "salesAndMarketing1courseExpectation11",
      "salesAndMarketing1courseExpectation12",
      "salesAndMarketing1courseExpectation13",
    ],
    courseContent: [
      {
        title: "salesAndMarketing1coursecourseContent1Title",
        contents: [
          "salesAndMarketing1coursecourseContent1Desc1",
          "salesAndMarketing1coursecourseContent1Desc2",
          "salesAndMarketing1coursecourseContent1Desc3",
          "salesAndMarketing1coursecourseContent1Desc4",
          "salesAndMarketing1coursecourseContent1Desc5",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent2Title",
        contents: [
          "salesAndMarketing1coursecourseContent2Desc1",
          "salesAndMarketing1coursecourseContent2Desc2",
          "salesAndMarketing1coursecourseContent2Desc3",
          "salesAndMarketing1coursecourseContent2Desc4",
          "salesAndMarketing1coursecourseContent2Desc5",
          "salesAndMarketing1coursecourseContent2Desc6",
          "salesAndMarketing1coursecourseContent2Desc7",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent3Title",
        contents: [
          "salesAndMarketing1coursecourseContent3Desc1",
          "salesAndMarketing1coursecourseContent3Desc2",
          "salesAndMarketing1coursecourseContent3Desc3",
          "salesAndMarketing1coursecourseContent3Desc4",
          "salesAndMarketing1coursecourseContent3Desc5",
          "salesAndMarketing1coursecourseContent3Desc6",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent4Title",
        contents: [
          "salesAndMarketing1coursecourseContent4Desc1",
          "salesAndMarketing1coursecourseContent4Desc2",
          "salesAndMarketing1coursecourseContent4Desc3",
          "salesAndMarketing1coursecourseContent4Desc4",
          "salesAndMarketing1coursecourseContent4Desc5",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent5Title",
        contents: [
          "salesAndMarketing1coursecourseContent5Desc1",
          "salesAndMarketing1coursecourseContent5Desc2",
          "salesAndMarketing1coursecourseContent5Desc3",
          "salesAndMarketing1coursecourseContent5Desc4",
          "salesAndMarketing1coursecourseContent5Desc5",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent6Title",
        contents: [
          "salesAndMarketing1coursecourseContent6Desc1",
          "salesAndMarketing1coursecourseContent6Desc2",
          "salesAndMarketing1coursecourseContent6Desc3",
          "salesAndMarketing1coursecourseContent6Desc4",
          "salesAndMarketing1coursecourseContent6Desc5",
          "salesAndMarketing1coursecourseContent6Desc6",
          "salesAndMarketing1coursecourseContent6Desc7",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent7Title",
        contents: [
          "salesAndMarketing1coursecourseContent7Desc1",
          "salesAndMarketing1coursecourseContent7Desc2",
          "salesAndMarketing1coursecourseContent7Desc3",
          "salesAndMarketing1coursecourseContent7Desc4",
          "salesAndMarketing1coursecourseContent7Desc5",
          "salesAndMarketing1coursecourseContent7Desc6",
          "salesAndMarketing1coursecourseContent7Desc7",
          "salesAndMarketing1coursecourseContent7Desc8",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent8Title",
        contents: [
          "salesAndMarketing1coursecourseContent8Desc1",
          "salesAndMarketing1coursecourseContent8Desc2",
          "salesAndMarketing1coursecourseContent8Desc3",
          "salesAndMarketing1coursecourseContent8Desc4",
          "salesAndMarketing1coursecourseContent8Desc5",
          "salesAndMarketing1coursecourseContent8Desc6",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent9Title",
        contents: [
          "salesAndMarketing1coursecourseContent9Desc1",
          "salesAndMarketing1coursecourseContent9Desc2",
          "salesAndMarketing1coursecourseContent9Desc3",
          "salesAndMarketing1coursecourseContent9Desc4",
          "salesAndMarketing1coursecourseContent9Desc5",
          "salesAndMarketing1coursecourseContent9Desc6",
        ],
      },
    ],
    courseFess: 6080,
    category: "Sales and Marketing",
    courseCode: "SM01",
  },
  {
    id: 120,
    image: Sales2,
    title: "salesAndMarketing2Title",
    desc: "salesAndMarketing2Desc",
    WhoShouldAttend: [
      "salesAndMarketing2WhoShouldAttend1",
      "salesAndMarketing2WhoShouldAttend2",
      "salesAndMarketing2WhoShouldAttend3",
      "salesAndMarketing2WhoShouldAttend4",
      "salesAndMarketing2WhoShouldAttend5",
    ],
    courseExpectation: [
      "salesAndMarketing2courseExpectation1",
      "salesAndMarketing2courseExpectation2",
      "salesAndMarketing2courseExpectation3",
      "salesAndMarketing2courseExpectation4",
      "salesAndMarketing2courseExpectation5",
      "salesAndMarketing2courseExpectation6",
      "salesAndMarketing2courseExpectation7",
      "salesAndMarketing2courseExpectation8",
    ],
    courseContent: [
      {
        title: "salesAndMarketing2coursecourseContent1Title",
        contents: [
          "salesAndMarketing2coursecourseContent1Desc1",
          "salesAndMarketing2coursecourseContent1Desc2",
          "salesAndMarketing2coursecourseContent1Desc3",
          "salesAndMarketing2coursecourseContent1Desc4",
          "salesAndMarketing2coursecourseContent1Desc5",
        ],
      },
      {
        title: "salesAndMarketing2coursecourseContent2Title",
        contents: [
          "salesAndMarketing2coursecourseContent2Desc1",
          "salesAndMarketing2coursecourseContent2Desc2",
          "salesAndMarketing2coursecourseContent2Desc3",
          "salesAndMarketing2coursecourseContent2Desc4",
          "salesAndMarketing2coursecourseContent2Desc5",
          "salesAndMarketing2coursecourseContent2Desc6",
          "salesAndMarketing2coursecourseContent2Desc7",
        ],
      },
      {
        title: "salesAndMarketing2coursecourseContent3Title",
        contents: [
          "salesAndMarketing2coursecourseContent3Desc1",
          "salesAndMarketing2coursecourseContent3Desc2",
          "salesAndMarketing2coursecourseContent3Desc3",
          "salesAndMarketing2coursecourseContent3Desc4",
          "salesAndMarketing2coursecourseContent3Desc5",
          "salesAndMarketing2coursecourseContent3Desc6",
        ],
      },
      {
        title: "salesAndMarketing2coursecourseContent4Title",
        contents: [
          "salesAndMarketing2coursecourseContent4Desc1",
          "salesAndMarketing2coursecourseContent4Desc2",
          "salesAndMarketing2coursecourseContent4Desc3",
          "salesAndMarketing2coursecourseContent4Desc4",
          "salesAndMarketing2coursecourseContent4Desc5",
        ],
      },
      {
        title: "salesAndMarketing2coursecourseContent5Title",
        contents: [
          "salesAndMarketing2coursecourseContent5Desc1",
          "salesAndMarketing2coursecourseContent5Desc2",
          "salesAndMarketing2coursecourseContent5Desc3",
          "salesAndMarketing2coursecourseContent5Desc4",
          "salesAndMarketing2coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Sales and Marketing",
    courseCode: "SM02",
  },
  {
    id: 121,
    image: Sales3,
    title: "salesAndMarketing3Title",
    desc: "salesAndMarketing3Desc",
    WhoShouldAttend: [
      "salesAndMarketing3WhoShouldAttend1",
      "salesAndMarketing3WhoShouldAttend2",
      "salesAndMarketing3WhoShouldAttend3",
    ],
    courseExpectation: [
      "salesAndMarketing3courseExpectation1",
      "salesAndMarketing3courseExpectation2",
      "salesAndMarketing3courseExpectation3",
      "salesAndMarketing3courseExpectation4",
      "salesAndMarketing3courseExpectation5",
      "salesAndMarketing1courseExpectation11",
      "salesAndMarketing1courseExpectation12",
      "humanResources7courseExpectation3",
    ],
    courseContent: [
      {
        title: "salesAndMarketing3coursecourseContent1Title",
        contents: [
          "salesAndMarketing3coursecourseContent1Desc1",
          "salesAndMarketing3coursecourseContent1Desc2",
          "salesAndMarketing3coursecourseContent1Desc3",
          "salesAndMarketing3coursecourseContent1Desc4",
          "salesAndMarketing3coursecourseContent1Desc5",
          "salesAndMarketing3coursecourseContent1Desc6",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent6Title",
        contents: [
          "salesAndMarketing1coursecourseContent6Desc1",
          "salesAndMarketing1coursecourseContent6Desc2",
          "salesAndMarketing1coursecourseContent6Desc3",
          "salesAndMarketing1coursecourseContent6Desc4",
          "salesAndMarketing1coursecourseContent6Desc5",
          "salesAndMarketing1coursecourseContent6Desc6",
          "salesAndMarketing1coursecourseContent6Desc7",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent7Title",
        contents: [
          "salesAndMarketing1coursecourseContent7Desc1",
          "salesAndMarketing1coursecourseContent7Desc2",
          "salesAndMarketing1coursecourseContent7Desc3",
          "salesAndMarketing1coursecourseContent7Desc4",
          "salesAndMarketing1coursecourseContent7Desc5",
          "salesAndMarketing1coursecourseContent7Desc6",
          "salesAndMarketing1coursecourseContent7Desc7",
          "salesAndMarketing1coursecourseContent7Desc8",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent8Title",
        contents: [
          "salesAndMarketing1coursecourseContent8Desc1",
          "salesAndMarketing1coursecourseContent8Desc2",
          "salesAndMarketing1coursecourseContent8Desc3",
          "salesAndMarketing1coursecourseContent8Desc4",
          "salesAndMarketing1coursecourseContent8Desc5",
          "salesAndMarketing1coursecourseContent8Desc6",
        ],
      },
      {
        title: "salesAndMarketing1coursecourseContent9Title",
        contents: [
          "salesAndMarketing1coursecourseContent9Desc1",
          "salesAndMarketing1coursecourseContent9Desc2",
          "salesAndMarketing1coursecourseContent9Desc3",
          "salesAndMarketing1coursecourseContent9Desc4",
          "salesAndMarketing1coursecourseContent9Desc5",
          "salesAndMarketing1coursecourseContent9Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Sales and Marketing",
    courseCode: "SM03",
  },
  // Sales and Marketing

  // Secretarial Pratices
  {
    id: 122,
    image: Sskills1,
    title: "secretarialPratices1Title",
    desc: "secretarialPratices1Desc",
    WhoShouldAttend: ["secretarialPratices1WhoShouldAttend1"],
    courseExpectation: [
      "secretarialPratices1courseExpectation1",
      "secretarialPratices1courseExpectation2",
      "secretarialPratices1courseExpectation3",
      "secretarialPratices1courseExpectation4",
      "secretarialPratices1courseExpectation5",
      "secretarialPratices1courseExpectation6",
    ],
    courseContent: [
      {
        title: "secretarialPratices1coursecourseContent1Title",
        contents: [
          "secretarialPratices1coursecourseContent1Desc1",
          "secretarialPratices1coursecourseContent1Desc2",
          "secretarialPratices1coursecourseContent1Desc3",
          "secretarialPratices1coursecourseContent1Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent2Title",
        contents: [
          "secretarialPratices1coursecourseContent2Desc1",
          "secretarialPratices1coursecourseContent2Desc2",
          "secretarialPratices1coursecourseContent2Desc3",
          "secretarialPratices1coursecourseContent2Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent3Title",
        contents: [
          "secretarialPratices1coursecourseContent3Desc1",
          "secretarialPratices1coursecourseContent3Desc2",
          "secretarialPratices1coursecourseContent3Desc3",
          "secretarialPratices1coursecourseContent3Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent4Title",
        contents: [
          "secretarialPratices1coursecourseContent4Desc1",
          "secretarialPratices1coursecourseContent4Desc2",
          "secretarialPratices1coursecourseContent4Desc3",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent5Title",
        contents: [
          "secretarialPratices1coursecourseContent5Desc1",
          "secretarialPratices1coursecourseContent5Desc2",
          "secretarialPratices1coursecourseContent5Desc3",
          "secretarialPratices1coursecourseContent5Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent6Title",
        contents: [
          "secretarialPratices1coursecourseContent6Desc1",
          "secretarialPratices1coursecourseContent6Desc2",
          "secretarialPratices1coursecourseContent6Desc3",
          "secretarialPratices1coursecourseContent6Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent7Title",
        contents: [
          "secretarialPratices1coursecourseContent7Desc1",
          "secretarialPratices1coursecourseContent7Desc2",
          "secretarialPratices1coursecourseContent7Desc3",
          "secretarialPratices1coursecourseContent7Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent8Title",
        contents: [
          "secretarialPratices1coursecourseContent8Desc1",
          "secretarialPratices1coursecourseContent8Desc2",
          "secretarialPratices1coursecourseContent8Desc3",
          "secretarialPratices1coursecourseContent8Desc4",
        ],
      },
      {
        title: "secretarialPratices1coursecourseContent9Title",
        contents: [
          "secretarialPratices1coursecourseContent9Desc1",
          "secretarialPratices1coursecourseContent9Desc2",
          "secretarialPratices1coursecourseContent9Desc3",
          "secretarialPratices1coursecourseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Secretarial Practices",
    courseCode: "SP01",
  },
  {
    id: 123,
    image: Sskills2,
    title: "secretarialPratices2Title",
    desc: "secretarialPratices2Desc",
    WhoShouldAttend: ["secretarialPratices2WhoShouldAttend1"],
    courseExpectation: [
      "secretarialPratices2courseExpectation1",
      "secretarialPratices2courseExpectation2",
      "secretarialPratices2courseExpectation3",
      "secretarialPratices2courseExpectation4",
    ],
    courseContent: [
      {
        title: "secretarialPratices2coursecourseContent1Title",
        contents: [
          "secretarialPratices2coursecourseContent1Desc1",
          "secretarialPratices2coursecourseContent1Desc2",
          "secretarialPratices2coursecourseContent1Desc3",
          "secretarialPratices2coursecourseContent1Desc4",
        ],
      },
      {
        title: "secretarialPratices2coursecourseContent2Title",
        contents: [
          "secretarialPratices2coursecourseContent2Desc1",
          "secretarialPratices2coursecourseContent2Desc2",
          "secretarialPratices2coursecourseContent2Desc3",
          "secretarialPratices2coursecourseContent2Desc4",
        ],
      },
      {
        title: "secretarialPratices2coursecourseContent3Title",
        contents: [
          "secretarialPratices2coursecourseContent3Desc1",
          "secretarialPratices2coursecourseContent3Desc2",
          "secretarialPratices2coursecourseContent3Desc3",
          "secretarialPratices2coursecourseContent3Desc4",
        ],
      },
      {
        title: "secretarialPratices2coursecourseContent4Title",
        contents: [
          "secretarialPratices2coursecourseContent4Desc1",
          "secretarialPratices2coursecourseContent4Desc2",
          "secretarialPratices2coursecourseContent4Desc3",
        ],
      },
      {
        title: "secretarialPratices2coursecourseContent5Title",
        contents: [
          "secretarialPratices2coursecourseContent5Desc1",
          "secretarialPratices2coursecourseContent5Desc2",
          "secretarialPratices2coursecourseContent5Desc3",
          "secretarialPratices2coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Secretarial Practices",
    courseCode: "SP02",
  },
  {
    id: 124,
    image: Sskills3,
    title: "secretarialPratices3Title",
    desc: "secretarialPratices3Desc",
    WhoShouldAttend: ["secretarialPratices3WhoShouldAttend1"],
    courseExpectation: [
      "secretarialPratices3courseExpectation1",
      "secretarialPratices3courseExpectation2",
      "secretarialPratices3courseExpectation3",
      "secretarialPratices3courseExpectation4",
    ],
    courseContent: [
      {
        title: "secretarialPratices3coursecourseContent1Title",
        contents: [
          "secretarialPratices3coursecourseContent1Desc1",
          "secretarialPratices3coursecourseContent1Desc2",
          "secretarialPratices3coursecourseContent1Desc3",
          "secretarialPratices3coursecourseContent1Desc4",
        ],
      },
      {
        title: "secretarialPratices3coursecourseContent2Title",
        contents: [
          "secretarialPratices3coursecourseContent2Desc1",
          "secretarialPratices3coursecourseContent2Desc2",
          "secretarialPratices3coursecourseContent2Desc3",
          "secretarialPratices3coursecourseContent2Desc4",
        ],
      },
      {
        title: "secretarialPratices3coursecourseContent3Title",
        contents: [
          "secretarialPratices3coursecourseContent3Desc1",
          "secretarialPratices3coursecourseContent3Desc2",
          "secretarialPratices3coursecourseContent3Desc3",
          "secretarialPratices3coursecourseContent3Desc4",
          "secretarialPratices3coursecourseContent3Desc5",
        ],
      },
      {
        title: "secretarialPratices3coursecourseContent4Title",
        contents: [
          "secretarialPratices3coursecourseContent4Desc1",
          "secretarialPratices3coursecourseContent4Desc2",
          "secretarialPratices3coursecourseContent4Desc3",
          "secretarialPratices3coursecourseContent4Desc4",
        ],
      },
      {
        title: "secretarialPratices3coursecourseContent5Title",
        contents: [
          "secretarialPratices3coursecourseContent5Desc1",
          "secretarialPratices3coursecourseContent5Desc2",
          "secretarialPratices3coursecourseContent5Desc3",
          "secretarialPratices3coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Secretarial Practices",
    courseCode: "SP03",
  },
  // Secretarial Pratices
];
