import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "layout",
  initialState: {
    isLoafding: false,
    isSideNavOpen: false,
    curretRoute: null,
    selectedWorkshop: null,
    redirectPath: null,
    organisation: null,
    hasOrganisation: false,
    hasUserData: false,
  },
  reducers: {
    setIsSideNavOpen(state, action) {
      state.isSideNavOpen = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoafding = action.payload;
    },
    setSelectedWorkshop(state, action) {
      state.selectedWorkshop = action.payload;
    },
    setOrganisationData(state, action) {
      state.organisation = action.payload;
    },
    setHasOrganisationData(state, action) {
      state.hasOrganisation = action.payload;
    },
    setHasUserData(state, action) {
      state.hasUserData = action.payload;
    },
  },
});

export const myLayoutActions = mySlice.actions;

export default mySlice.reducer;
