import Eric from "../assets/constants/eric.jpg";

const ConsultantDetailsPage = () => {
  return (
    <div className="bg-slate-100 pb-10 border text-gray-900 ">
      <div className="w-full max-w-[1100px] lg:mx-auto lg:p-4 lg:py-8">
        <article className="mx-auto w-full max-w-[1100px]  format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <div className="mt-10 flex items-center mx-auto justify-center flex-col space-y-3">
            <img
              src={Eric}
              className="xw-[500px] xh-[500px] object-cover"
              alt=""
            />
            <h1 className="font-bold">Dr Erick Oluoch Manga</h1>
          </div>
          <div className="px-6">
            <div>
              <h1 className="font-semibold xmb-6 mt-10 text-[#003d8c]">
                Personal summary
              </h1>
              <span>
                I am a researcher, consultant, a lecturer and a community
                mobiliser on international development with a focus on public
                transport, institutions, informality, conflict and peace,
                education, social movements, labour relations, development
                partnership and public policy. I have long experience in
                development research; university teaching and post-graduate
                supervision; development programming; monitoring and evaluation;
                and capacity, scenario and strategy building.
              </span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                Objective:{" "}
              </h1>
              <span>
                To work in an environment which requires knowledge, skills and
                attitude to advance social transformation through development
                research, capacity building and general practice.
              </span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                Educational background{" "}
              </h1>
              <p className="font-semibold text-sm">
                2017:{" "}
                <span className="font-normal">
                  PhD Programme in Development Studies, Resident Commonwealth
                  Scholar Institute of Development Studies (IDS), University of
                  Sussex, Brighton, UK
                </span>
              </p>
              <p className="font-semibold text-sm">
                2007:{" "}
                <span className="font-normal">
                  Master of Arts in Development Studies, University of Nairobi,
                  Institute for Development Studies (IDS), Nairobi, Kenya.
                </span>
              </p>
              <p className="font-semibold text-sm">
                2001:{" "}
                <span className="font-normal">
                  Post-Graduate Diploma in Planning and Management of
                  Development Projects, Catholic University of Eastern Africa
                  (CUEA), Nairobi, Kenya.
                </span>
              </p>
              <p className="font-semibold text-sm">
                1999:{" "}
                <span className="font-normal">
                  Bachelor of Philosophy, Pontifical Urbaniana University, Rome.
                </span>
              </p>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                Additional trainings{" "}
              </h1>
              <p className="font-semibold text-sm">
                August, 23-September 1, 2023:{" "}
                <span className="font-normal">
                  Urban Logistics: Urban Logistics Summer School at the
                  University of Antwerp Belgium.
                </span>
              </p>
              <p className="font-semibold text-sm">
                25-29 October, 2021:{" "}
                <span className="font-normal">
                  Crafting early warning and political analysis. A capacity
                  building training organised by Conflict Early Warning and
                  Response Mechanism (CEWARN)- Inter Governmental Authority on
                  Development (IGAD) for partner National Research Institutes
                  (NRIs) at Imperial Victoria Hotel, Entebbe, Uganda.
                </span>
              </p>
              <p className="font-semibold text-sm">
                October 22-1 November, 2019:{" "}
                <span className="font-normal">
                  Conflict early warning and response mechanism data analysis
                  and regional scenario building. A capacity building training
                  organised by Conflict Early Warning and Response Mechanism
                  (CEWARN)- Inter Governmental Authority on Development (IGAD)
                  for partner National Research Institutes (NRIs) at Best
                  Western Hotel Entebbe, Uganda.
                </span>
              </p>
              <p className="font-semibold text-sm">
                April, 24-28, 2017:{" "}
                <span className="font-normal">
                  Leaders in urban transport program. Advance training for
                  Africa Transport Policy Programme (SSATP) Anglophone member
                  countries at Windsor Golf Hotel and Country Club, Nairobi,
                  Kenya.
                </span>
              </p>
              {/* here */}
              <p className="font-semibold text-sm">
                Sept. 2009-Sept 2010:{" "}
                <span className="font-normal">
                  Audited post graduate research courses: <br />
                  i. Social science research in a cross-cultural context <br />
                  ii. qualitative research techniques. School of Global Studies,
                  University of Sussex, Brighton, UK.
                </span>
              </p>
              <p className="font-semibold text-sm">
                2-26 June, 2008:{" "}
                <span className="font-normal">
                  Capacity building training workshop for qualitative and mixed
                  research methods. A training workshop organised by the Centre
                  for the Future State (CFS), Development Research Consortium
                  (DRC), Institute of Development Studies (IDS), Brighton, UK.
                </span>
              </p>
              <p className="font-semibold text-sm">
                18-22 June, 2007:{" "}
                <span className="font-normal">
                  Capacity building workshop for comparative research
                  techniques. A training workshop organised by the Centre for
                  the Future State (CFS), Development Research Consortium (DRC),
                  Institute of Development Studies (IDS), Brighton, UK.
                </span>
              </p>
              <p className="font-semibold text-sm">
                20-24 June 2006:{" "}
                <span className="font-normal">
                  Capacity building workshop on scientific writing and network
                  analysis. A training workshop organised by the Centre for the
                  Future State (CFS) Development Research Consortium (DRC),
                  Institute of Development Studies (IDS), Brighton, UK.
                </span>
              </p>
              <p className="font-semibold text-sm">
                1-5 October 2005:{" "}
                <span className="font-normal">
                  Strategic thinking and strategy building. A training workshop
                  organised for the facilitators of strategy building for
                  Constituency Development Fund (CDF). The training was
                  organised by the Institute of Economic Affairs (IEA), Kenya
                  and conducted by Maer Associates at Lukenya Gateway, Nairobi,
                  Kenya
                </span>
              </p>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                Employment history{" "}
              </h1>
              <p className="font-semibold text-sm">
                September, 2019-todate: Independent consultant on international
                development:{" "}
                <span className="font-normal">
                  September, 2019-todate: Independent consultant on
                  international development: I have consulted for the following
                  international organisation on development matters during this
                  period: International Transport Workers Federation (ITF),
                  French Development Agency (FDA), German Corporation for
                  International Development (GIZ), Friedrich Ebert Stiftung
                  (FES)- Kenya Office, Inter-Governmental Authority on
                  Development (IGAD). Key tasks: commissioned research,
                  Preparation of reports, trainings, presentations to various
                  audiences.
                </span>
              </p>
              <p className="font-semibold text-sm">
                January, 2015-to date:{" "}
                <span className="font-normal">
                  Adjunct faculty member/visiting lecturer: St. Lawrence
                  University, New York, Kenya Semester Programme: Main task:
                  teaching course on critical issues in socio-economic
                  development in Kenya.
                </span>
              </p>
              <p className="font-semibold text-sm">
                January, 2020 - January, 2023: Part-time national researcher:{" "}
                <span className="font-normal">
                  governance sector in Kenya for Conflict Early Warning and
                  Response Mechanism Unit for (Kenya CEWERU –Kenya) and the
                  Conflict Early Warning and Response Mechanism (CEWARN)-IGAD
                  (Intergovernmental Authority on Development). Main tasks:
                  periodic situational analysis and prepare quarterly reports
                  for CEWERU Kenya on conflict early warning signs in the
                  governance sector in Kenya using CEWARN’s framework; periodic
                  commissioned research one conflict early warning sign
                  depending on emerging issues; occasionally brief the National
                  Steering Committee (NSC) on Conflict Management and Peace
                  Building on emerging, persistent, escalating and dissipating
                  conflict early warning signs; generate possible scenarios and
                  response options.
                </span>
              </p>
              <p className="font-semibold text-sm">
                August, 2015- August, 2019: Junior research fellow:{" "}
                <span className="font-normal">
                  August, 2015- August, 2019: Junior research fellow: Institute
                  of Development Studies (IDS), University of Nairobi. Main
                  tasks: research, teaching, consultancy and supervision of
                  Masters of Arts degree project papers.
                </span>
              </p>
              <p className="font-semibold text-sm">
                September, 2005 to August, 2009: Project assistant:{" "}
                <span className="font-normal">
                  Institute for Development Studies (IDS), University of
                  Nairobi. “Donor Proliferation, Coordination and State Capacity
                  Research Project & African Clothing and Footwear Research
                  Network”. Key tasks: preparing of research proposals, data
                  collection, analysis, report writing, dissemination of
                  findings in collaboration with other team members;
                  coordination of various tasks and preparing of various
                  administrative reports as outlined in the work plans.
                </span>
              </p>
              <p className="font-semibold text-sm">
                June 2001 –August 2003: Projects director:{" "}
                <span className="font-normal">
                  Bega Kwa Bega (BkB) Korogocho Projects, Korogocho Slums,
                  Nairobi. A project founded by the Comboni Missionaries in
                  Kenya in collaboration with ACCRI (Associazione di
                  Cooperazione Crisitana Internasionale), Italy. Its overall
                  goal was to eradicate poverty in the Slum through economic
                  empowerment, human formation and skills development. Main
                  tasks: Overseeing implementation of project activities in
                  accordance with project work plans; preparation of periodic
                  reports, corresponding and networking with project partners
                  (Fair trade companies in Germany, Italy and Japan).
                </span>
              </p>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                Additional information
              </h1>
              <ul className="list-disc pl-6">
                <li>
                  Research skills: quantitative and qualitative research
                  techniques.
                </li>
                <li>
                  {" "}
                  Advanced scientific writing: technical research reports,
                  academic pieces, policy briefs, opinion pieces • Skills in
                  adult training and community mobilisations • Skills in
                  monitoring, evaluation and learning.
                </li>
                <li>
                  Project management skills: use of log frames, work plans,
                  budgeting. Possess policy engagement skills within a complex
                  environment
                </li>
                <li>
                  Has lived and worked in multi-cultural set ups (i.e. United
                  Kingdom)
                </li>
                <li>Computer skills: excel, PowerPoint, word office,</li>
                <li>
                  • Language: proficient in speaking, reading and writing
                  English and Kiswahili
                </li>
              </ul>
            </div>
            <div className="mt-10">
              <h1 className="text-3xl md:text-xl font-semibold capitalize text-[#003d8c]">
                Experiences
              </h1>
            </div>
            <div className="mt-4">
              <h1 className="text-xl md:text-lg font-medium capitalize text-[#003d8c]">
                University teaching
              </h1>
            </div>
            <div>
              <h1 className="font-medium mt-5 mb-2 text-[#003d8c]">
                {" "}
                St. Lawrence University, New York, Kenya Semester Program
                (under-graduate programme)
              </h1>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  Critical Issues in Socio-Economic Development in Kenya (AFS
                  326/GOV326/SOC 326), 5 years (2015-2023)
                </li>
              </ul>
            </div>
            <div>
              <h1 className="font-medium mt-5 mb-2 text-[#003d8c]">
                {" "}
                Institute for Development Studies (IDS), University of Nairobi
                (Masters of Arts in Development Studies programme)
              </h1>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  Project/Research Paper Seminar; (CDS, 699); 1 year (2008-2009)
                  Development Management Theory; (CDS 501); 1 year (2015-2016)
                  Development Management Practice; (CDS 502); 4 years 2015-2019.
                </li>
                <li>
                  {" "}
                  Governance and Development (TDS, 627); 1 year (2018-2019)
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <h1 className="text-xl md:text-lg font-medium capitalize text-[#003d8c]">
                Post graduate supervision
              </h1>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  Gathua J. Nyambura (T50/82237/2015), Institute for Development
                  Studies (IDS), University of Nairobi. Co-supervision with Dr.
                  Njeri Kinyanjui. Formation, survival and performance of groups
                  in Dagoretti North constituency. Completed in 2020 and
                  graduated
                </li>
                <li>
                  {" "}
                  • Edwin Rwigi Kibui (T50/80710/2015) Institute for Development
                  Studies (IDS), University of Nairobi. Effects of Participatory
                  Budgeting on Healthcare Services Delivery in Makueni
                  Sub-County. Cosupervised with Prof. Winnie Mitullah and Dr.
                  George Michuki. Completed in 2018 and graduated.
                </li>
                <li>
                  {" "}
                  Lydia Midimo (T50/753032014), Institute for Development
                  Studies (IDS), University of Nairobi Research Topic: Assessing
                  Teachers Response to Appraisal as a Tool for Improving
                  Learning in Public Schools: A case Study of Public Secondary
                  School Teachers in Nyeri County. Co-supervised with Dr. Mary
                  Kinyanjui and Dr. Godwin Murunga. Completed in 2017 and
                  graduated.
                </li>
                <li>
                  {" "}
                  Elizabeth Ng’otho (T50/74544/2014), Institute for Development
                  Studies (IDS), University of Nairobi. Research Topic: Small
                  Holder Farmers and Adoption of Aflatoxin Reduction
                  Interventions: A Case Study of Makueni County. Co-supervised
                  with Dr. Rachel Musyoki. Completed in 2017 and graduated.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <h1 className="text-xl md:text-lg font-medium capitalize text-[#003d8c]">
                Institutional research projects
              </h1>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  <span className="underline">
                    January, 2020 – January, 2021: Institute for Development
                    Studies (IDS), University of Nairobi coinvestigator:
                  </span>
                  Urban Spaces Design, Mobility and Transport Safety of
                  Children: A Comparative Study of Nairobi and Kumasi Cities. A
                  project funded by Volvo Research and Educational Foundation
                  (VREF) “mobility and access in African cities 2019-2020
                  research grant for exploratory comparative research project.
                  Thematic Cluster 4: Safety, Health and the Urban. Main tasks:
                  study conceptualization, data collection, report writing and
                  presentation of key findings in key research/policy fora.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    Dec. 2014 – 2016: Institute for Development Studies (IDS),
                    University of Nairobi: Principal investigator:
                  </span>
                  How do Savings and Credit Cooperative Organisations as
                  Operational Arrangements for Paratransit Management Affect
                  Public Transport Users? Comparative Case Studies of Inter-City
                  Shuttles in Kenya. A project funded by Volvo Research and
                  Educational Foundation (VREF) under development and testing of
                  paratransit regulatory and operational alternatives through
                  African Center of Excellence for Studies in Public and
                  Non-Motorised Transport (ACET), University of Cape Town, Cape
                  Town. Key tasks: study conceptualisation, data collection,
                  report writing and presentation of key findings in key
                  research/policy fora
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    March-May 2011: Institute of Development Studies (IDS),
                    University of Nairobi Researcher:
                  </span>
                  National Opinion Poll Survey in Kenya May 2011.Survey funded
                  by National Democratic Institute (NDI), USA. Key tasks:
                  preparation of research instruments, data collection,
                  analysis, report preparation and presentation of key findings.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    2006-2009: Institute of Development Studies (IDS),
                    University of Nairobi:
                  </span>
                  Co-investigator: Donor Proliferation, Coordination and State
                  Capacity. The Case of Inclusive Industrialisation and
                  Governance Reform, 2000-2005. Project funded by Centre for
                  Future States (CFS), Development Research Consortium (DRC),
                  Institute of Development Studies (IDS) at the University of
                  Sussex, UK and the Ford Foundation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    May 2005, Research Assistant: UNESCO/Unitwin Chair,
                    University of Nairobi:
                  </span>
                  Evaluation Study on the Impacts on Stakeholder Mobilisation
                  and Participation in Community Dam Construction along River
                  Voi, Kaloleni, Voi Division. Main tasks: data collection,
                  analysis and preparation of final report.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <h1 className="text-xl md:text-lg font-medium capitalize text-[#003d8c]">
                Consultancy work
              </h1>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  <span className="underline">
                    December, 2022-April 2023: German Agency for International
                    Development (GIZ), Nairobi Office: Researcher:
                  </span>
                  Overview of public transport operation within Nairobi
                  metropolitan Area. Specific tasks: preparation of study
                  design, data collection, analysis, report preparation and
                  presentation of key findings.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    October –November, 2022: Just City, Friedrich Ebert Stiftung
                    (FES) Kenya Office: Investigator:
                  </span>
                  Nakuru City Mobility Plan. Specific tasks: data collection and
                  strategy preparation, presentation to Nakuru City Board for
                  input and final adoption of the report as a chapter of Nakuru
                  Vision 2050.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    April-September, 2022: Internal Federation of Transport
                    Workers (ITF), London Office: Local Researcher Kenya:
                  </span>
                  Union Building Along the Silk Road – Kenya case study: A multi
                  case study research project covering six countries (Austria,
                  Kazakhstan, Kenya, Mongolia, Pakistan, Thailand). Specific
                  tasks involved literature review, data collection, analysis
                  and report writing on Kenya’s case study.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    February –June, 2022: Friedrich Ebert Stiftung (FES), Kenya
                    Office: Researcher:
                  </span>
                  Preparation of a Discussion paper on Pathways to Jus
                  Transition in Transport System: The Case study of Nairobi
                  City. Specific tasks involved: conceptualization of the
                  research, data collection, analysis, report writing and
                  dissemination of the key research findings with relevant
                  stakeholders.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    April-May, 2021: Consultant, Devscapes Consulting Limited.
                  </span>
                  Mid-Term Review of Nyatike Constituency National Government
                  –Constituency Development Fund (NG-CDF) Strategic Plans
                  2018-2022. Main tasks: collecting data, analysis and
                  preparation of report.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    April –September, 2021: Internal Federation of Transport
                    Workers (ITF), London Office: Local Researcher-Kenya case
                    study:
                  </span>
                  Action-based research for project Just transition in urban
                  passenger transport and freight (inner city and local
                  delivery) services. Main tasks: Produce background research on
                  just transition literature in the context of the city of
                  Nairobi; Implement an action research for developing just
                  transition proposals for sustainable transport (passenger,
                  inner city and local delivery) in the city of Nairobi;
                  Implement an action research model in Nairobi in coordination
                  with local ITF affiliates; produce a report from the action
                  based research to be used for comparison and compilation with
                  the experiences of other participating cities; collect
                  materials and information coming out of the workshops to be
                  integrated into educational materials by the lead researcher
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    {" "}
                    March, 2020: Conflict Early Warning and Response Mechanism
                    (CEWARN)-Intergovernmental Authority on Development (IGAD)
                    and CEWERU Kenya: Researcher:
                  </span>
                  Rapid Assessment of the Conflict between Turkana in Loima and
                  Turkana West Sub-Counties and the Jie of Uganda. Main tasks:
                  study design preparation, data collection, analysis and report
                  preparation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    {" "}
                    November 2019-November, 2020: Conflict Early Warning and
                    Response Mechanism (CEWARN)Intergovernmental Authority on
                    Development (IGAD): Principal investigator:
                  </span>
                  Thematic research on conflict early warning: Kenya, Uganda and
                  South Sudan. Project funded by European Union through the
                  Austrian Development Cooperation (ADC) under periodic and
                  uniquely occurring/not occurring thematic research based on
                  reports. Main tasks: preparation of research design, data
                  collection, analysis, report writing and presentation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    October 2019-April 2020: Global Labour Institute (GLI),
                    Manchester: Researcher (data analyst):
                  </span>
                  Study of the Paratransit/Informal Transport System and Street
                  Usage in the Greater Kampala Metropolitan Area (GKMA) in view
                  of the Planned Bus Rapid Transit Pilot Project. Project
                  commissioned and funded by French Development Agency (FDA) in
                  collaboration with Ministry of Works and Transport of the
                  Republic of Uganda and the Kampala Capital City Authority.
                  Main tasks: preparation of research design, data collection,
                  analysis, report writing.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    July, 2019: Socially Just Public Transport Working Group
                    (SJ-PTWG), Nairobi and Just City Working Group (JCWG),
                    Kisumu: Researcher:{" "}
                  </span>
                  Assessment of Nairobi Bus Rapid Transit (BRT) in the light of
                  Socially Just Public Transport Pillars. Study undertaken with
                  the support of Friedrich Stiftung (FES), Kenya Office. Main
                  tasks: data collection, analysis, report writing and
                  presentation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    October 2017–January 2019: Internal Federation of Transport
                    Workers (ITF), London Office: Researcher:
                  </span>
                  Assessment of Nairobi Bus Rapid Transit (BRT) on Informal
                  Matatu Workforce in Nairobi, Building Union Influence and
                  Campaigning in BRT in Nairobi, Kenya Research Project. Main
                  tasks: preparation of research design, actual data collection,
                  analysis, report preparation and presentation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    November, 2016: Windle Trust, Kenya and United Nations
                    Children Fund (UNICEF), Kenya Office:
                  </span>{" "}
                  Research consultant. Baseline Survey for the Return of Out of
                  School Children to School (ROCS) in Dadaab, Turkana West,
                  Turkana and North Sub-Counties. Main tasks: review of research
                  instruments, analyse quantitative and qualitative survey data
                  and systemise them into a report to inform implementation;
                  develop the analysis framework in line with the already
                  developed tools for the baseline survey including use of
                  statistical tests and preparation of baseline survey report.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    September - October 2016: United Nations Development
                    Programme (UNDP):
                  </span>{" "}
                  Assistant national individual consultant: Case Studies on
                  African experiences in formulating and implementing plans for
                  emergence in Kenya. Main tasks: carrying out the research and
                  preparation of the synthesis report, for review by the UNDP
                  technical team and the lead consultant.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    July, 2016; Consultant for Farm Radio International
                    (formerly Developing Countries Farm Radio Network) and World
                    University Services of Canada (WUSC).
                  </span>
                  Evaluation of the Radio Component of the Kenya Equity in
                  Education Project (KEEP) in collaboration with World
                  University Services of Canada (WUSC). Specific tasks: carrying
                  out Focused Group Discussions and Key Informant Interviews in
                  both host communities and refugee camps in Kakuma, Turkana
                  South; and preparation of a brief synthesis report.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    July, 2016; Consultant for the Windle Trust, Kenya and World
                    University Services of Canada (WUSC), Kenya.
                  </span>
                  Phase Three Assessment of the Equity in Education for Refugee
                  Communities in Kenya (EERCK) in Kakuma and Dadaab Refugee
                  Camps. Main tasks: conducting a survey and preparation of the
                  survey report.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    July - August 2016; Plan International: Researcher:
                  </span>
                  Assessing the Drivers and Barriers of Five (5) Risk Behaviours
                  among Young People between 10-24 Years in Kibera, Nairobi. A
                  formative research project under Young Health Programme (YHP)
                  in Kibera, Nairobi. Main takes: preparation of research
                  design, data collection, management and field report
                  preparation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    July, 2015- January 2016: Local Capacity for Peace
                    International (LCPI), Kenya in collaboration with the C.A.C
                    International, Montreal, Canada: Research consultant:
                  </span>{" "}
                  Midline Households Survey: Kenya Equity in Education Project
                  (KEEP) (Kakuma and Dadaab, Northern Kenya). Project funded by
                  Girls Education Challenge (GEC), UK AID. Main tasks:
                  preparation of evaluation design, data collection, management
                  and field report preparation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    July 2014-July 2015: Saferworld Kenya and Local Capacities
                    for Peace International (LCPI):
                  </span>
                  National Researcher: Political Settlements and Security
                  Reforms in Post Conflict Situations: Comparative Case Studies
                  of Kenya and South Sudan. A research project funded by
                  International Development Research Centre (IDRC) under
                  inclusive and accountable political settlements programme.
                  Main tasks: preparation of study design, data collection,
                  analysis and report writing.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    January-February, 2015: Partnership for African Social &
                    Governance Research (PASGR):
                  </span>
                  Research consultant: Mapping of political settlements and
                  employment schemes in ten African Countries. Main tasks: data
                  collection and report preparation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    May 2009: International Labour Organisation (ILO): External
                    collaborator/co-investigator:
                  </span>
                  Evaluation Methodology Analysis on ILOKAP Repeat Survey on
                  Child Labour in Kenya. Main tasks: Developing the methodology
                  on Knowledge Attitude and Practices (KAP), data collection,
                  analysis and report preparation.
                </li>
                <li>
                  {" "}
                  <span className="underline">
                    Feb-March 2006: Institute of Economic Affairs (IEA),
                    Nairobi: Researcher:
                  </span>
                  Assessing the Planning and Implementation Processes of the
                  Constituency Development Fund in Rongo Constituency, Migori
                  District. Main tasks: preparation of research design, data
                  collection, analysis and report preparation.
                </li>
              </ul>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                Publications
              </h1>
              <span className="underline">
                a. Journal articles, book chapters & working/discussion papers
              </span>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  Erick Manga (forthcoming) Can the on – going Nairobi BRT
                  project guarantee a just transition? Signposts from labour
                  impact assessment report and other studies. Submitted for
                  publication in the ATINER Journal
                  http://www.athensjournals.gr/
                </li>
                <li>
                  {" "}
                  Erick Manga (2023).Gig Economy in Kenya’s Informal Transport
                  Sector: Manifestations, Benefits, Challenges and Prospects, in
                  Immanuel Ness (edit). Routledge Handbook of the Gig Economy.
                  New York: Routledge, 485-499.
                </li>
                <li>
                  {" "}
                  Anne Kamau and Erick Manga (2020). Is Kenya’s Public Transport
                  Socially Just? in Alfred Omenya (edit). Towards the Just City
                  in Kenya. Nairobi: Fredriech Ebert Stiftung Kenya office,
                  88-100.
                </li>
                <li>
                  {" "}
                  Erick Manga, Paula Hamilton and Stephen Kisingu (2020).
                  ‘Riding on A Union App: Uganda’s Public Transport Workers’
                  Response to Digital Platform’. Labour and Social Justice,
                  Trade Union in Transformation 4.0. Berlin: Friedrich Ebert
                  Stiftung.{" "}
                </li>
                <li>
                  {" "}
                  Dorothy McCormick, Erick Manga, Radha Upadhyaya, Paul Kamau,
                  Hebert Wamalwa and Samwuel Ngingi (2020). Informality and
                  Development in Africa, in Jacques Charmes (edit) Research
                  Handbook on Development and The Informal Economy. London:
                  Elgar,79-115. Kibui Edwin Rwigi, Erick Manga & George Michuki
                  (2020): ‘New wine in an old wineskin? Sociopolitical context
                  and participatory budgeting in Kenya’, Journal of Eastern
                  African Studies, 14(3):492-511.
                </li>
                <li>
                  {" "}
                  Dave Spooner and Erick Manga (2019) Nairobi Bus Rapid Transit:
                  Labour Impact Assessment Research Report. Manchester: Global
                  Labour Institute (GLI).
                </li>
                <li>
                  {" "}
                  Dorothy McCormick, Winnie Mitullah, and Erick Manga (2007).
                  “Extent and Forms of Donor Proliferation and Coordination in
                  Kenya: The Case of Inclusive Industrialisation and Governance
                  Reform, 2000-2005”, IDS Working Paper No. 546. Nairobi:
                  University of Nairobi, Institute for Development Studies
                  (IDS).
                </li>
                <li>
                  {" "}
                  Erick Manga (2006). ‘Global Civil Society: Dimensions of the
                  Non-Profit Sector’, East Africa Journal of Development
                  Studies, 1(1):83-90.
                </li>
              </ul>
              <span className="underline">b. Opinion pieces</span>
              <ul className="list-disc pl-6">
                <li>
                  Erick Manga and Tristan Kenderdine (2022). “What Kenya’s
                  presidential elections mean for China’s Belt and Road
                  Initiative”. The Diplomat Weekly Newsletter. 25 August, 2022.
                </li>
              </ul>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                Selected unpublished reports
              </h1>
              <span className="underline">a. Technical research reports</span>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  Erick Manga (2023). “Overview of public transport operation
                  within Nairobi metropolitan Area”. Research Report. German
                  Agency for International Development (GIZ), Kenya Office,
                  Nairobi.{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2023). “Pathways for a Just Transition in the
                  Transport Sector in Nairobi City.” Draft Discussion paper for
                  Friedrich Ebert Stiftung (FES), Nairobi Office, Nairobi.{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga and Constant Cap (2022). “Towards Sustainable
                  Mobility within Nakuru and its Environs; Nakuru Vision 2050:
                  Transport and Sustainable Mobility Strategy”. Draft Report.
                  Nakuru City Board.{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2022). “Union Building Along the Silk Road –Case
                  Studies of Railway System and Ports in Kenya.” Final Research
                  Report for International Federation of Transport Workers
                  (ITF), London{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2021). “Just Transition in Urban Transport: Key
                  findings of Action Based Research in Nairobi City.” Final
                  Research Report for International Federation of Transport
                  Workers (ITF), London.
                </li>
                <li>
                  {" "}
                  Erick Manga (2021). “Mid-Term Review of Nyatike Constituency
                  National Government – Constituency Development Fund (NG-CDF)
                  Strategic Plans 2018-2022.” Devscapes Consulting Limited,
                  Nairobi{" "}
                </li>
                <li>
                  {" "}
                  Anne Kamau, Erick Manga and Paschalin Basil (2001). “Urban
                  Spaces Design, Mobility and Transport Safety of Children: A
                  Comparative Study of Nairobi and Kumasi Cities.” Kenya Study
                  Report. Nairobi.{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2020) ‘Periodic and uniquely occurring or not
                  occurring thematic research: Kenya, Uganda and South Sudan
                  case studies’ Research report of a study commissioned by
                  IGAD/CEWARN with the support from the European Union through
                  the Austrian Development Cooperation (ADC){" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2020). “Analysis of the Kisumu of the Kisumu
                  County Transport Act, 2020 in the context of socially just
                  public transport pillars: A policy brief.” A report prepared
                  and submitted to the Just City Working Group (JCWG), Kisumu
                  with support of Friedrich Ebert Stiftung, Kenya Office.
                  Nairobi.{" "}
                </li>
                <li>
                  {" "}
                  Dave Spooner, John Mark Mwanika, Shadrack Natamba and Erick
                  Manga (2020). ‘Kampala Bus Rapid Transit: Understanding
                  Kampala’s Paratransit Market Structure’. Manchester: Global
                  Labour Institute (GLI) and French Development Agency (FCA).{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2019). “Can the Nairobi Bus Rapid Transit (BRT)
                  Provide a Just Public Transport Services? Preliminary Review
                  of Key Issues” A Position Paper for Socially Just Public
                  Transport Working Group (SJ-PTWG) with the support of
                  Friedrich Ebert Stiftung (FES), Kenya Office. Nairobi.
                </li>
                <li>
                  {" "}
                  Joseph Onjala and Erick Manga (2016). “Kenya’s Experience in
                  Formulating and Implementing Plans of Emergence: Status
                  Report’. Nairobi: UNDP Country Office, Kenya.{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2016). “Assessment of the Impact of Farm Radio
                  Programme on Girls’ Education in Kakuma: The Case of Refugees
                  Camp and Host Community” A Synthesis Report Prepared for Farm
                  Radio International and World University of Canada (WUSC).
                </li>
                <li>
                  {" "}
                  Erick Manga (2016). “Return Out of School Children to School
                  (ROCS) Baseline Survey Report. A report submitted to Windle
                  Trust and UNICEF.
                </li>
                <li>
                  {" "}
                  Erick Manga (2016). “Equity in Education for Refugee Community
                  in Kenya: Phase Three Assessment Result” Survey report
                  submitted to Windle Trust Kenya (WTK) and World University
                  Services of Canada (WUSC).{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2015) “Girls Education Challenge (GEC) Household
                  Midline Survey Field Report: Kenya Equity in Education
                  Programme (KEEP), Kakuma and Dada Districts” Report submitted
                  to World University Services of Canada (WUSC).
                </li>
              </ul>
              <span className="underline">
                b. Proceedings of conferences and workshops
              </span>
              <ul className="list-disc pl-6">
                <li>
                  {" "}
                  Erick Manga (2014). “Political economy and settlements
                  analyses of employment creation schemes in agriculture and
                  agro-Processing sectors in the context of inclusive growth in
                  Africa”. Proceedings of the methodology workshop convened by
                  Partnership for African Social and Governance Research
                  (PASGR). BOMA Hotel, Nairobi, 29-30 April 2014.
                </li>
                <li>
                  {" "}
                  Erick Manga (2014). “Learning for inclusive Road
                  Infrastructure Development: Integration of Non Motorised
                  Facilities in Developing Non Motorised Facilities in
                  Development of Thika Super Highway.” Proceedings of
                  Stakeholders’ Roundtable Discussion convened by Institute for
                  Development Studies (IDS), University of Nairobi. Safari Club,
                  Lilian Towers, Nairobi, 2 October 2014{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2013). “Gender and Conflict Early Warning.”
                  Proceedings of the CEWARN-IGAD Experts Meeting on Gender and
                  Conflict Early Warning. Hilton Hotel, Nairobi. 5-7 December
                  2013.
                </li>
                <li>
                  {" "}
                  Erick Manga (2008). Proceedings of the Country Coordinators’
                  Workshop, DelPHE Project. An international workshop organised
                  by African Clothing and Footwear Research Network at the
                  University of Cape Town, Cape, 28-29 October 2008.
                </li>
                <li>
                  {" "}
                  Erick Manga (2008). Proceedings of the Country Coordinators’
                  Workshop, DelPHE Project. An international workshop organised
                  by African Clothing and Footwear Research Network at Morogoro
                  Hotel Limited, Morogoro, 28-29 April 2008. Erick Manga (2008).
                  “Role of Faith Based Organisations in Social Transformation in
                  East Africa” Proceedings of the Regional Conference convened
                  by the Jesuit Hakimani Centre in collaboration with Trocaire
                  East Africa Office. Savleberg Retreat Center, Nairobi, 19-21
                  March 2008.{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2007). “Strengthening African Research on Labour
                  Studies and Social Issues in Africa” Proceedings of the
                  International Conference convened by the International
                  Institute for Labour Studies (IILS) and International Labour
                  Organisation (ILO) in collaboration with the Institute for
                  Development Studies (IDS), University of Nairobi. Utalii
                  Hotel, Nairobi, 24-25 September 2007.{" "}
                </li>
                <li>
                  {" "}
                  Erick Manga (2005). “NEPAD’s Kenya’s African Peer Review
                  Mechanism Exercise: Socio-Economic Development Sector”
                  Proceedings of the Provincial Consultative Forum for Rift
                  Valley and Central Provinces. Convened by the Institute for
                  Development Studies, University of Nairobi (Lead Consultant in
                  charge of Socio-Economic Development. Green Hill Hotel, Nyeri
                  and Hotel Kunste, Nakuru, Kenya, 11-13, July, 2005.
                </li>
              </ul>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default ConsultantDetailsPage;
