import axios from "axios";

class HttpRequest {
  static async post(url: string, data: any) {
    try {
      const res = await axios({
        method: "POST",
        url,
        data,
      });

      return res.data;
    } catch (err: any) {
      console.log(err);
      return err;
    }
  }
}

export default HttpRequest;
