import React from "react";
import WorkshopDetails from "../components/cards/Workshop";

const Workshop = () => {
  return (
    <>
      <div className="bg-[#e4e4e4] md:mx-4">
        <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
          <div className="text-gray-700 mx-4 lg:mt-20">
            <WorkshopDetails />
          </div>
        </div>
      </div>
    </>
  );
};

export default Workshop;
