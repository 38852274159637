import { useDispatch, useSelector } from "react-redux";
import DelegateForm from "./DelegateForm";
import OrganisationForm from "./OrganisationForm";
import { getOrganisationDataAction } from "../../../store/layout/actions";
import { useEffect, useState } from "react";
import { Button, Checkbox, Modal, notification } from "antd";
import { getCourseById, handlerSelectedCountry } from "../../../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconAddressBook,
  IconEmailArrowRightOutline,
  IconOrganisation,
  IconPersonCheckFill,
  IconRotaryinternational,
  IconSubtitlesOutline,
  IconTelephoneForward,
  IconVisaLine,
} from "../../../assets/IconsSvg";
import { bookCourseAction } from "../../../store/course/actions";
import { myLayoutActions } from "../../../store/layout";
import { bookWorkshopAction } from "../../../store/workshop/actions";
import PaymentProcess from "../../../assets/photo/paymentProccess.png";
import "react-phone-input-2/lib/style.css";
import UploadDocs from "../../upload/UploadDocs";
import BookWorkshopForm from "../BookWorkshopForm";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import { myCourseActions } from "../../../store/course";
import { myWorkshopActions } from "../../../store/workshop";
const serviceId = "service_912zkfk";
const courseSubCatgry = "template_aik7gih";
const workshopCatgry = "template_15sc5fq";
const publicKey = "M571j0YNV2Nu3Cjos";

const ApplyCourseForm = (props: any) => {
  const { t } = useTranslation();
  const { layout, workshop, course } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [selectedCourse, setSelectedCourse] = useState<any>("");
  const [imageUploades, setImmageUploaded] = useState<any>([]);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [allowToEditDelegate, setAllowToEditDelegate] = useState(false);
  const [allowToEditSelectedWorkshop, setAllowToEditSelectedWorkshop] =
    useState(false);
  const [allowToEditOrganisation, setAllowToEditOrganisation] = useState(false);
  const [localStorageUpdate, setLocalStorageUpdate] = useState(0);
  const delegate = layout?.organisation?.personalDetails?.delegates;

  const submissionKey = "courseData";

  const handleFormUpdate = () => {
    setLocalStorageUpdate((prev) => prev + 1);
  };

  useEffect(() => {
    const existingData = localStorage.getItem(submissionKey);
    if (existingData) {
      const parsedData = JSON.parse(existingData);
      getOrganisationDataAction(parsedData)(dispatch);
    }
  }, [localStorageUpdate, dispatch]);

  useEffect(() => {
    const fetchedCourse = getCourseById(courseId);
    setSelectedCourse(fetchedCourse);
  }, [courseId]);

  const handleSendCourse = (values: any, participants: any) => {
    emailjs
      .send(
        serviceId,
        courseSubCatgry,
        {
          courseName: t(selectedCourse?.title),
          participants: participants?.delegates?.length,
          name: delegate?.at(0)?.delegateName,
          companyName: values?.organisation,
          paymentProof: imageUploades?.at(0)?.response?.data?.secure_url,
          companyAddress: handlerSelectedCountry(values?.country),
          cost: selectedCourse?.courseFess,
          email: delegate?.at(0)?.delegateEmail,
          position: delegate?.at(0)?.jobTitle,
          phone: delegate?.at(0)?.phone,
          location: layout?.organisation?.selectedWowkshop?.option
            ? handlerSelectedCountry(
                layout?.organisation?.selectedWowkshop?.name
              )
            : layout?.organisation?.selectedWowkshop?.name,
          selectedDated: layout?.organisation?.selectedWowkshop?.date,
        },
        publicKey
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const handleSendWorkshop = (values: any, participants: any) => {
    emailjs
      .send(
        serviceId,
        workshopCatgry,
        {
          workshopName: "Financial Analysis Workshop",
          cost: "5,275",
          date: layout?.organisation?.selectedWowkshop?.date,
          participants: participants?.delegates?.length,
          name: values?.hrContactName,
          paymentLink: imageUploades?.at(0)?.response?.data?.secure_url,
          companyName: values?.organisation,
          companyAddress: handlerSelectedCountry(values?.country),
          email: values?.hrContactEmail,
          phone: values?.hrContactPhone,
          location: layout?.organisation?.selectedWowkshop?.option
            ? handlerSelectedCountry(
                layout?.organisation?.selectedWowkshop?.name
              )
            : layout?.organisation?.selectedWowkshop?.name,
        },
        publicKey
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };
  const showPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const handleConfirmPayment = () => {
    setIsPaymentModalOpen(false);
  };

  const handleCancelPayment = () => {
    setIsPaymentModalOpen(false);
  };

  const usersInfo = layout?.organisation?.personalDetails?.delegates?.map(
    (item: any) => {
      return {
        approvedByOrganisation: t(item?.approvedByOrganisation?.at(0)),
        delegateEmail: item?.delegateEmail,
        delegateName: item?.delegateName,
        jobTitle: item?.jobTitle,
        phone: item?.phone,
        title: item?.title,
      };
    }
  );
  const handleSubmitCourse = async () => {
    if (imageUploades?.length < 1) {
      return notification.error({ message: "Please Upload Proof of Payemnt" });
    }
    if (props?.route === "course") {
      await bookCourseAction({
        proof: imageUploades?.at(0)?.response?.data?.secure_url,
        courseName: t(selectedCourse?.title),
        delegates: usersInfo,
        orgnisationInfo: layout?.organisation?.organisationDetails,
        location: {
          date: layout?.organisation?.selectedWowkshop?.date,
          name: layout?.organisation?.selectedWowkshop?.option
            ? handlerSelectedCountry(
                layout?.organisation?.selectedWowkshop?.name
              )
            : layout?.organisation?.selectedWowkshop?.name,
        },
      })(dispatch);
      handleSendCourse(
        layout?.organisation?.organisationDetails,
        layout?.organisation?.personalDetails
      );
      dispatch(myLayoutActions.setOrganisationData(null));
      handleCancelPayment();
    } else {
      await bookWorkshopAction({
        proof: imageUploades?.at(0)?.response?.data?.secure_url,
        delegates: usersInfo,
        workshopData: {
          workshopName: "Financial Analysis Workshop",
          workshopCost: "5,275",
        },
        location: {
          workshopName: "Financial Analysis Workshop",
          workshopCost: String,
          date: layout?.organisation?.selectedWowkshop?.date,
          name: layout?.organisation?.selectedWowkshop?.option
            ? handlerSelectedCountry(
                layout?.organisation?.selectedWowkshop?.name
              )
            : layout?.organisation?.selectedWowkshop?.name,
        },
        orgnisationInfo: layout?.organisation?.organisationDetails,
      })(dispatch);
      handleSendWorkshop(
        layout?.organisation?.organisationDetails,
        layout?.organisation?.personalDetails
      );
      dispatch(myLayoutActions.setOrganisationData(null));
      handleCancelPayment();
    }
  };

  const handleUpdateDelegate = () => {
    setAllowToEditDelegate(true);
  };
  const cancelUpdateDelegate = () => {
    setAllowToEditDelegate(false);
  };

  const handleUpdateOrganisation = () => {
    setAllowToEditOrganisation(true);
  };
  const cancelUpdateOrganisation = () => {
    setAllowToEditOrganisation(false);
  };

  const handleUpdateSelectedWorkshop = () => {
    setAllowToEditSelectedWorkshop(true);
  };
  const cancelUpdateSelectedWorkshop = () => {
    setAllowToEditSelectedWorkshop(false);
  };

  const handleBack = () => {
    dispatch(myCourseActions.setCourseSubmited(false));
    dispatch(myWorkshopActions.setWorkshopSubmited(false));
    dispatch(myLayoutActions.setOrganisationData(null));
    dispatch(myLayoutActions.setSelectedWorkshop(null));
    if (props?.route === "course") {
      navigate(`/program/${selectedCourse?.category}`);
      localStorage.clear();
    } else {
      navigate("/");
      localStorage.clear();
    }
  };

  return (
    <>
      <div className="bg-white pt-20 lg:pt-36 pb-10 lg:mx-4">
        <div className="w-full max-w-[1100px] lg:mx-auto lg:p-4 lg:py-8">
          <div className="space-y-4">
            <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700">
              <h5 className="mb-2.5 md:pb-6 text-xl font-semibold tracking-tight text-[#003d97]">
                {`${
                  props?.route === "course"
                    ? `${t("Course")}`
                    : `${t("Workshop")}`
                } Information`}
              </h5>
              <div className="flex flex-col">
                <h1 className="mb-2 text-lg font-medium tracking-tight]">
                  {`${
                    props?.route === "workshop"
                      ? "Financial Analysis Workshop"
                      : t(selectedCourse?.title)
                  }
                    `}
                </h1>
                <span className="mb-2 text-lg font-medium tracking-tight]">
                  {t(selectedCourse?.desc)}
                </span>
                <span className="mb-2 text-lg font-bold tracking-tight]">{`$${
                  props?.route === "workshop"
                    ? "5,275"
                    : selectedCourse?.courseFess?.toLocaleString()
                } ${t("perIndividual")}`}</span>
                <span>{`${t("WorkshopAndCourseContent1")} ${
                  props?.route === "course"
                    ? `${t("Course")}`
                    : `${t("Workshop")}`
                } ${t("WorkshopAndCourseContent2")}`}</span>
              </div>

              <div>
                <h1 className="pt-10 text-[#0F0F47] text-base font-bold pb-2 uppercase">
                  {`${
                    props?.route === "course"
                      ? `${t("Course")}`
                      : `${t("Workshop")}`
                  } Destination`}
                </h1>
                {layout?.organisation?.selectedWowkshop &&
                !allowToEditSelectedWorkshop ? (
                  <div>
                    <p className="text-lg font-sans font-semibold">
                      {t("Location")}:{" "}
                      <span className="text-lg font-[Exo] font-medium">
                        {layout?.organisation?.selectedWowkshop?.option
                          ? handlerSelectedCountry(
                              layout?.organisation?.selectedWowkshop?.name
                            )
                          : layout?.organisation?.selectedWowkshop?.name}
                      </span>
                    </p>
                    <p className="text-lg font-sans font-semibold">
                      <span className="capitalize">{t("DATE")}: </span>
                      <span className="text-lg font-[Exo] font-medium">
                        {layout?.organisation?.selectedWowkshop?.date}
                      </span>
                    </p>
                    <button
                      className="rounded-md mt-3 shadow gap-x-2 py-2 text-center font-bold w-20 border text-white font-[Poppins] text-sm bg-green-400"
                      onClick={handleUpdateSelectedWorkshop}
                    >
                      {t("Edit")}
                    </button>{" "}
                  </div>
                ) : (
                  <BookWorkshopForm
                    onCancel={cancelUpdateSelectedWorkshop}
                    handleFormUpdate={handleFormUpdate}
                    route={props?.route}
                  />
                )}
              </div>
            </div>

            <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700 relative">
              <h5 className="mb-2.5 md:pb-6 text-xl font-semibold tracking-tight text-[#003d97]">
                {t("Delegates Information")}
              </h5>
              {layout?.organisation?.personalDetails && !allowToEditDelegate ? (
                <div>
                  {layout?.organisation?.personalDetails?.delegates?.map(
                    (items: any) => (
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mb-1 p-4">
                        <p className="flex items-center space-x-4 capitalize">
                          <IconPersonCheckFill />{" "}
                          <span>{`${items?.title} ${items?.delegateName}`}</span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <IconTelephoneForward /> <span> {items?.phone} </span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <IconEmailArrowRightOutline />{" "}
                          <span> {items?.delegateEmail} </span>
                        </p>
                        <p className="flex items-center space-x-4 capitalize">
                          <IconSubtitlesOutline />{" "}
                          <span> {items?.jobTitle} </span>
                        </p>
                      </div>
                    )
                  )}
                  <button
                    className="rounded-md mt-3 shadow gap-x-2 text-center font-bold py-2 w-20 border text-white font-[Poppins] text-sm bg-green-400"
                    onClick={handleUpdateDelegate}
                  >
                    {t("Edit")}
                  </button>{" "}
                </div>
              ) : (
                <DelegateForm
                  onCancel={cancelUpdateDelegate}
                  handleFormUpdate={handleFormUpdate}
                />
              )}
            </div>

            {layout?.organisation?.personalDetails && (
              <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700 relative">
                <h5 className="mb-2.5 md:pb-6 text-xl font-semibold tracking-tight text-[#003d97]">
                  {t("Organisation Information")}
                </h5>
                {layout?.organisation?.organisationDetails &&
                !allowToEditOrganisation ? (
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2"></div>
                    <p className="flex items-center space-x-4 capitalize">
                      <IconOrganisation />{" "}
                      <span>
                        {
                          layout?.organisation?.organisationDetails
                            ?.organisation
                        }
                      </span>
                    </p>
                    <p className="flex items-center space-x-4 capitalize">
                      <IconAddressBook />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails
                            ?.companyAddress
                        }{" "}
                      </span>
                    </p>
                    <p className="flex items-center space-x-4 capitalize">
                      <IconRotaryinternational />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails?.country
                        }{" "}
                      </span>
                    </p>
                    <button
                      className="rounded-md mt-3 shadow gap-x-2 py-2 text-center font-bold w-20 border text-white font-[Poppins] text-sm bg-green-400"
                      onClick={handleUpdateOrganisation}
                    >
                      {t("Edit")}
                    </button>{" "}
                  </div>
                ) : (
                  <OrganisationForm
                    onCancel={cancelUpdateOrganisation}
                    handleFormUpdate={handleFormUpdate}
                  />
                )}
              </div>
            )}

            {layout?.organisation?.personalDetails &&
              layout?.organisation?.organisationDetails && (
                <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700">
                  <h5 className="mb-2 text-sm font-medium tracking-tight xtext-[#003d97]">
                    {t("personaldetailsareveryimportant")}
                  </h5>
                  <Checkbox>{t("Accept")}</Checkbox>
                </div>
              )}
          </div>
          {layout?.organisation?.personalDetails &&
            layout?.organisation?.organisationDetails && (
              <button
                type="button"
                className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 my-2 outline-none ml-6 md:ml-0"
                onClick={showPaymentModal}
              >
                {t("Next")}
              </button>
            )}
        </div>
      </div>
      <Modal
        style={{ top: 150 }}
        open={isPaymentModalOpen}
        onOk={handleConfirmPayment}
        width={800}
        footer={null}
      >
        <div className="flex flex-col space-y-4 w-full">
          <div className="">
            <img src={PaymentProcess} className=" w-full object-cover" alt="" />
          </div>
          <div>
            <h1 className="text-sm md:text-lg font-semibold font-[Exo] text-[#003d97] pb-4">
              {t("Upload Proof of payment")}
            </h1>
            <UploadDocs setImmageUploaded={setImmageUploaded} />
          </div>
          <div className="flex items-start justify-start">
            <div className="flex items-center space-x-4">
              <Button
                className="text-white bg-gradient-to-br h-10 from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:outline-none font-medium rounded-lg text-sm  text-center  outline-none ml-6 md:ml-0"
                onClick={handleSubmitCourse}
                loading={course?.isFetching}
              >
                {t("Submit")}
              </Button>
              <button
                type="button"
                className="border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 border-red-500 text-red-500 hover:text-white hover:bg-red-600 focus:ring-red-900"
                onClick={handleCancelPayment}
              >
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={props?.modelTitle}
        open={workshop?.workshopSubmited || course?.courseSubmited}
        footer={null}
      >
        <>
          <div
            className="bg-green-200 border-green-600 text-green-600 border-l-4 p-4"
            role="alert"
          >
            <p className="font-bold">Success</p>
            <p>Congratulations, your request has been submitted successfully</p>
            <p>We Will get back to you as soon as possible</p>
          </div>
          <div>
            <button
              onClick={handleBack}
              type="button"
              className="text-white outline-none focus:outline-none focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 my-2 dark:bg-gray-800 bg-gray-700 focus:ring-gray-700 border-gray-700"
            >
              Back
            </button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ApplyCourseForm;
