import { Helmet } from "react-helmet";

export const setHelmet = (title, description, otherMeta = []) => {
  return (
    <Helmet>
      <title>{`${title} - NCTAR`}</title>
      <meta name="description" content={description} />
      {otherMeta?.map((meta, index) => (
        <meta key={index} {...meta} />
      ))}
    </Helmet>
  );
};
