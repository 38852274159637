import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";

const Layout = (props: any) => {
  return (
    <div className="bg-[##ffffff]">
      <Navbar />
      <div>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
