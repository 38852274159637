import { useTranslation } from "react-i18next";
import TopSection from "../../components/TopSection";

const Visa = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white pt-20 lg:pt-36 border border-gray-200 text-gray-900">
      <TopSection title={t("VisaApplicationsRequirements")} />
      <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
        <div className="text-gray-700 mx-4">
          <h1 className="pt-6 pb-2">
            {t("VisaApplicationsRequirementsDesc1")}
            <br />
            {t("VisaApplicationsRequirementsDesc2")}
          </h1>

          <div>
            <h1 className="py-4">{t("VisaApplicationsRequirementsDesc3")}</h1>
            <ul className="list-disc pl-6">
              <li>{t("VisaApplicationsRequirementsDesc4")}</li>
              <li>{t("VisaApplicationsRequirementsDesc5")}</li>
              <li>{t("VisaApplicationsRequirementsDesc6")}</li>
              <li>{t("VisaApplicationsRequirementsDesc7")}</li>
              <li>{t("VisaApplicationsRequirementsDesc8")}</li>
              <li>{t("VisaApplicationsRequirementsDesc9")}</li>
              <li>{t("VisaApplicationsRequirementsDesc10")}</li>
              <li>{t("VisaApplicationsRequirementsDesc11")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visa;
