export const links = [
  {
    name: "About",
    submenu: true,
    hasCategory: false,
    sublinks: [
      { name: "About Us", link: "/about" },
      { name: "Venues", link: "/about/vanues" },
    ],
  },
];
