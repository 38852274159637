import { myLayoutActions } from ".";

export const selectedWorkshopAction = (selected: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedWorkshop(selected));
  };
};

export const getOrganisationDataAction = (selected: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setOrganisationData(selected));
  };
};

export const hasOrganisationAction = (hasOrg: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setHasOrganisationData(hasOrg));
  };
};

export const hasUserDataAction = (hasUser: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setHasUserData(hasUser));
  };
};

