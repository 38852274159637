import React from "react";
import { ReactComponent as Icons } from "../assets/svg/Icons.svg";
import { category } from "../assets/data";

const CourseCategory = (props: any) => {
  return (
    <div className="md:pl-14 w-full">
      <h1 className="text-xl font-bold md:font-extrabold capitalize">
        Categories
      </h1>
      <div className="pt-6">
        <ul className="flex flex-col space-y-3">
          {category?.map((cat: any) => (
            <li
              className="flex items-center space-x-4 cursor-pointer"
              key={cat?.id}
              onClick={() => props?.handleCategory(cat?.name)}
            >
              <div>
                <span className="h-8 w-8 rounded-full bg-blue-600 flex items-center justify-center text-white">
                  <Icons />
                </span>
              </div>
              <h1
                className={`font-normal md:font-semibold text-lg ${
                  props?.selectedCategory === cat?.name &&
                  "text-blue-600 underline"
                } `}
              >
                {cat?.name}
              </h1>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CourseCategory;
