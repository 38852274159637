import { Courses } from "../assets/course/courses";
import { data } from "../assets/workshop/Country";
import { COUNTRIES } from "./libs/countries";

export const getCourseById = (courseId) => {
  const course = Courses.find((course) => course.id === +courseId);
  return course || null;
};

export const getCourseByCategory = (coursecategory) => {
  const course = Courses.filter((course) => course.category === coursecategory);
  return course || null;
};

export const getCourseByTitle = (title) => {
  const titleLowerCase = title.toLowerCase();
  return (
    Courses.find((course) => course.title.toLowerCase() === titleLowerCase) ||
    null
  );
};

export const getWorkshopById = (selectedWorkshop) => {
  const responce = data.find((workshop) =>
    selectedWorkshop?.toLowerCase()?.includes(workshop.country)
  );
  return responce || null;
};

export const getCoursesByCategory = (courses) => {
  const categoryCount = {};

  courses.forEach((course) => {
    const { category, image } = course;
    categoryCount[category] = {
      count: (categoryCount[category]?.count || 0) + 1,
      image,
    };
  });

  const result = Object.entries(categoryCount).map(
    ([category, { count, image }]) => ({
      category,
      count,
      image,
    })
  );

  return result;
};

export const handlerSelectedCountry = (countryCode) => {
  const country = COUNTRIES.find((d) => countryCode?.includes(d.value));
  return country?.title || null;
};
