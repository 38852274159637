import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import WhatsApp from "./components/whatsapp/WhatsApp";
import MainRoute from "./routes/MainRoute";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <MainRoute />
      </Layout>
      <WhatsApp />
    </BrowserRouter>
  );
}

export default App;
