import { Divider } from "antd";
import Const1 from "../../assets/constants/cons1.jpg";
import TopSection from "../../components/TopSection";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#e4e4e4] pb-10 border">
      <TopSection title={t("MeetourTeam")} bgImage={"ourTeam"} />
      <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
        <h2 className="text-3xl font-bold text-[#3a98d2]  text-center mb-12">
          CEO's Message
        </h2>

        <div className="text-gray-700 mt-6 lg:mt-20 space-y-8">
          <div className="flex flex-col bg-[#ffffff] rounded-md shadow-inner md:flex-row md:max-w-6xl md:mx-0 pt-10">
            <div className="md:px-10 xmd:w-4/5 pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full xmd:h-auto md:rounded-none"
                src={Const1}
                alt=""
              />
            </div>
            <div className="flex flex-col pb-6 leading-normal px-6 w-[100%]">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Mr. NYARWARI EVANS MAGOMA
              </h5>
              <h6 className="text-2xl font-medium tracking-tight">
                {t("EdgerOgaoRole")}
              </h6>
              <Divider />
              <div className="p-2 max-w-4xl mx-auto">
                <p className="text-lg mb-4">{t("ceoMessage1")}</p>
                <p className="text-lg mb-4">{t("ceoMessage2")}</p>
                <p className="text-lg mb-4">{t("ceoMessage3")}</p>
                <p className="text-lg mb-4">{t("ceoMessage4")}</p>
                <p className="text-lg mb-4">{t("ceoMessage5")}</p>
                <p className="text-lg mb-4">{t("ceoMessage6")}</p>
                <p className="text-lg mb-4">{t("ceoMessage7")}</p>
                <p className="text-lg mb-4 font-bold text-[#003d8f]">
                  {t("ceoMessage8")}
                </p>
                <p className="text-lg font-bold text-[#003d8f]">
                  Evans Nyarwari Magoma
                </p>
                <p className="text-lg">{t("ceoMessage9")}</p>
                <p className="text-lg">NCTAR Global Consultants Ltd</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Team;
