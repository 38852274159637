import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#e4e4e4] mx-4">
        <div className="w-full max-w-[1100px] mx-auto p-4 md:py-8">
          <div className="text-gray-700 mx-4 lg:mt-20">
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist. Still under Development"
              extra={
                <Button onClick={() => navigate("/")}>
                  <span>Go back</span>
                </Button>
              }
              style={{ background: "white", minHeight: "60vh" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
