import meetingRoom1 from "../../assets/photo/meetingRoom1.jpg";
import meetingRoom2 from "../../assets/photo/meetingRoom2.jpg";
import meetingRoom3 from "../../assets/photo/meetingRoom3.jpg";
import meetingRoom4 from "../../assets/photo/meetingRoom4.jpg";
import meetingRoom5 from "../../assets/photo/meetingRoom5.jpg";
import meetingRoom7 from "../../assets/photo/meetingRoom7.jpg";
import meetingRoom8 from "../../assets/photo/meetingRoom8.jpg";
import meetingRoom9 from "../../assets/photo/meetingRoom9.jpg";
import meetingRoom10 from "../../assets/photo/meetingRoom10.jpg";
import meetingRoom11 from "../../assets/photo/meetingRoom11.jpg";
import meetingRoom12 from "../../assets/photo/meetingRoom12.jpg";
import Carousel from "better-react-carousel";
import TopSection from "../../components/TopSection";
import { useNavigate } from "react-router";
import Office from "../../assets/photo/office.png";
import { useTranslation } from "react-i18next";

const Vanues = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const images = [
    meetingRoom1,
    meetingRoom2,
    meetingRoom3,
    meetingRoom4,
    meetingRoom5,
    meetingRoom7,
    meetingRoom8,
    meetingRoom9,
    meetingRoom10,
    meetingRoom11,
    meetingRoom12,
  ];

  return (
    <div className="bg-white  pb-10 border">
      <TopSection
        title={t("TrainingVenues")}
        content={t("TrainingVenuesDesc")}
        bgImage={"venueImage"}
      />
      <div className="w-full max-w-[1100px] lg:mx-auto px-6 lg:p-4 lg:py-8">
        <div className="flex flex-col-reverse xitems-center bg-white rounded-lg shadow lg:flex-row lg:max-w-6xl">
          <div className="flex flex-col xjustify-between p-4 leading-normal">
            <h5 className="mb-4 text-2xl font-semibold tracking-tight text-[#003d97]">
              {t("perfectSpacestoLearnGrow")}
            </h5>
            <p className="mb-3 font-normal text-gray-800 text-lg">
              {t("perfectSpacestoLearnGrowDesc1")}
              <br />
              <br />
              {t("perfectSpacestoLearnGrowDesc2")}
              <br />
              <br />
              {t("perfectSpacestoLearnGrowDesc3")}
            </p>
          </div>
          <img
            className="object-cover w-full px-6 pb-10 pt-0.5 lg:pb-0 lg:px-0 lg:h-auto lg:w-1/2 lg:rounded-none"
            src={Office}
            alt=""
          />
        </div>
        <div className="pt-16">
          <Carousel
            cols={3}
            rows={1}
            gap={10}
            loop
            autoplay={4000}
            step={1}
            mobileBreakpoint={768}
          >
            {images?.map((item: any) => (
              <Carousel.Item>
                <img src={item} className="w-full h-96 object-cover" alt="" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <button
          type="button"
          className="text-white mt-10 ml-5 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-3 text-center me-2 mb-2"
          onClick={() => navigate("/program/Management")}
        >
          <div className="flex items-center">
            <span> {t("View Open Courses")}</span>
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </div>{" "}
        </button>
      </div>
    </div>
  );
};

export default Vanues;
