import { useState } from "react";
import { faqLeft } from "../assets/data";
import "../assets/styles/css/responsive.css";
import "../assets/styles/css/style.css";
import TopSection from "../components/TopSection";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState<number | null>(null);

  const handleButtonClick = (id: number) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };
  return (
    <div className="bg-white pt-20 lg:pt-36 border border-gray-200 text-gray-900">
      <TopSection title={"FAQ"} />
      <div className="w-full max-w-[900px] md:mx-auto md:p-4 md:py-8">
        <div className="faq box-spach py-16">
          <div className="container">
            <div className="section-title-center v1">
              <h2 className="text-lg md:text-xl font-semibold md:font-medium text-center text-[#003d8c]">
                FREQUENTLY ASKED QUESTIONS
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="right-countent">
                  <ul className="accordion" id="accordionFAQ">
                    {faqLeft?.map((d: any) => (
                      <li key={d?.id}>
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq1"
                          onClick={() => handleButtonClick(d.id)}
                        >
                          <h4 className="text-sm">{d?.title}</h4>
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 28444 28444"
                            >
                              <path d="M26032 3553 14222 15363 2412 3553l1768-1768 10042 10042L24264 1785zm0 11296L14222 26659 2412 14849l1768-1768 10042 10042 10042-10042z"></path>
                            </svg>
                          </span>
                        </button>
                        <div
                          id="faq1"
                          className="xcollapse"
                          data-bs-parent="#accordionFAQ"
                        >
                          {activeId === d.id && (
                            <div className="box-content p-4 md:p-2">
                              <p>{d?.content}</p>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
