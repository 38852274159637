import React, { useState } from "react";
import { Modal } from "antd";
import TopSection from "../../components/TopSection";
import { consultantsData } from "../../assets/consultantsData";
import { useTranslation } from "react-i18next";

const TrainingConsultant = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summary, setSummary] = useState<any>("");

  const showModal = (values: any) => {
    setIsModalOpen(true);
    setSummary(values);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSummary(" ");
  };

  return (
    <>
      <div className="bg-white pb-10 border">
        <TopSection
          title={t("trainingConsultant")}
          content={t("trainingConsultantDesc")}
          bgImage={"consultantImage"}
        />
        <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
          <div className="text-gray-700 lg:mt-20 gap-6 flex mx-auto flex-col md:flex-row items-center md:flex-wrap">
            {consultantsData?.map((item: any) => (
              <div className="w-full max-w-[15rem] mt-4 md:mt-0 pt-4 bg-white border border-gray-200  rounded-lg shadow-inner h-64 relative">
                <div className="flex flex-col items-center pb-10">
                  <img
                    className="w-32 h-32 mb-3 rounded-full shadow-lg object-cover"
                    src={item?.images}
                    alt="Bonnieimage"
                  />
                  <h5 className="mb-1 text-xl font-medium text-gray-900 px-2.5">
                    {item?.name}
                  </h5>
                  <button
                    className="bg-[#003d8c] hover:bg-blue-400 text-white font-bold py-0.5 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded absolute bottom-1"
                    onClick={() => showModal(item)}
                  >
                    {t("ViewMore")}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        title={summary?.name}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <p>{t(summary?.summary)}</p>
        </div>
      </Modal>
    </>
  );
};

export default TrainingConsultant;
