import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CourseCard = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center">
      <Link to={`/program/${props?.data?.category}`}>
        <div className="max-w-sm hover:border hover:border-gray-200 rounded-lg relative h-fit transition-all duration-300 hover:shadow-lg">
          <a href="#x">
            <img
              className="rounded-b-2xl h-[200px] w-[250px] md:h-[250px] md:w-[250px] object-cover transition-all duration-300 transform hover:scale-110"
              src={props?.data?.image}
              alt=""
            />
          </a>
          <div className="p-5">
            <a href="#x">
              <h5 className="mb-2 text-lg md:text-lg tracking-tight text-gray-900">
                {t(props?.data?.category)}
              </h5>
            </a>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CourseCard;
