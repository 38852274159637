import React from "react";
import Slider from "../components/sliders/Slider";
import WelcomePage from "./WelcomePage";
import PartnerPage from "./PartnerPage";
import AboutUsPage from "./aboutPages/AboutUsPage";

const HomePage = () => {
  return (
    <>
      <div className="bg-white text-gray-600">
        <div className="relative mx-auto">
          <Slider />
        </div>
        <div className="bg-[#fafafa] opacity-90 pt-6">
          <div className="w-full max-w-[1100px] md:mx-auto md:p-4">
            <WelcomePage />
          </div>
        </div>
        <AboutUsPage />
        <div className="bg-white opacity-90 pt-10">
          <PartnerPage />
        </div>
      </div>
    </>
  );
};

export default HomePage;
