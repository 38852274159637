import { useNavigate } from "react-router-dom";
import TeamWork from "../../assets/photo/teamwork.jpg";
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="about relative py-8 px-4 bg-white" id="about">
      <div className="container mx-auto">
        <div className="section-header text-center mb-12">
          <h1 className="mb-2 text-xl tracking-tight font-semibold text-[#3a98d2]  uppercase">
            {t("aboutUs")}
          </h1>
        </div>

        <div className="flex flex-wrap gap-x-4 items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <div className="about-img relative overflow-hidden">
              <img src={TeamWork} alt="About Us" className="w-full" />
            </div>
          </div>

          <div className="w-full md:w-[45%]">
            <div className="about-content relative w-full mb-8 pb-5 border-b border-gray-200">
              <h2 className="text-lg md:text-xl tracking-wide capitalize mb-4 font-bold">
                NCTAR Global Consultants Ltd
              </h2>
              <p className="text-lg relative">{t("aboutUsDesc1")}</p>
            </div>

            <div className="about-content relative w-full">
              <h2 className="text-lg md:text-xl tracking-wide capitalize mb-4 font-bold">
                {t("Our Goal")}
              </h2>
              <p className="text-lg relative">{t("aboutUsDesc2")}</p>
            </div>
          </div>
          <button
            className="btn mt-10 px-6 py-3 text-sm text-center tracking-wide text-white bg-[#2624c2] hover:bg-[#2e2be4] transition duration-300 ease-in-out rounded-md"
            onClick={() => navigate("/about")}
          >
            {t("Learn More")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
