import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { SearchButton } from "../../components/Button/SearchButton";
import { CourseByAllocation } from "../../assets/course/courseByCategory";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopSection from "../../components/TopSection";

const CourseCalendarPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [allUpdateCourses, selAllUpdatedCaurses] =
    useState<any>(CourseByAllocation);

  const handleSearchChange = (e: any) => {
    const query = e?.target?.value;
    setSearchQuery(query);
  };

  var today = new Date();
  var currentYear = today.getFullYear();
  var currentMonthIndex = today.getMonth();
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var currentMonthName = monthNames[currentMonthIndex];

  function getRandomGap() {
    return Math?.floor(Math?.random() * 2) + 3;
  }

  function getRandomDates(month: number, year: number) {
    const dates: Date[] = [];
    let currentDate = new Date(year, month - 1, 1);
    while (currentDate?.getMonth() === month - 1) {
      dates?.push(new Date(currentDate));
      currentDate?.setDate(currentDate.getDate() + getRandomGap());
    }
    return dates;
  }

  function updateCourseDates(courses: any[], dates: Date[]) {
    return courses?.map((course, index) => {
      if (dates[index]) {
        return {
          ...course,
          date: dates[index]?.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "long",
          }),
        };
      }
      return course;
    });
  }

  useEffect(() => {
    const updateCourses = function () {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      const dates = getRandomDates(currentMonth, currentYear);
      selAllUpdatedCaurses((prevCourses: any) =>
        updateCourseDates(prevCourses, dates)
      );
    };

    updateCourses();

    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    nextMonth.setDate(1);

    const timeUntilNextMonth = nextMonth.getTime() - new Date().getTime();

    const timeoutId = setTimeout(function scheduleNextUpdate() {
      updateCourses();
      const nextNextMonth = new Date(nextMonth);
      nextNextMonth.setMonth(nextNextMonth.getMonth() + 1);
      const timeUntilNextNextMonth =
        nextNextMonth.getTime() - new Date().getTime();

      setTimeout(scheduleNextUpdate, timeUntilNextNextMonth);
    }, timeUntilNextMonth);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div className="bg-white border pt-20 text-gray-900">
        <TopSection title={t("Course Calendar")} />
        <div className="w-full max-w-[900px] md:mx-auto md:p-4 md:py-8 px-6">
          <h2 className="text-3xl font-bold text-[#3a98d2]  text-center mb-8 mt-4">
            {t("JoinUsToday")}
          </h2>
          <div className="flex items-center my-2 pl-6 md:pl-0">
            <h1>
              {currentMonthName} {currentYear}
            </h1>
            <div className="h-0.5 w-1/2 md:w-4/5 ml-4 bg-gray-200"></div>
          </div>
          <div className="text-gray-700 mx-4">
            <div className="flow-root w-full md:w-3/4">
              {allUpdateCourses?.map((el: any) => {
                return (
                  <ul className="text-gray-900 max-w-full">
                    <li className="py-4 flex  space-x-6">
                      <div className="flex-shrink-0 flex flex-col">
                        <span className="text-sm text-gray-700 truncate">
                          {el?.date?.slice(0, 3)}
                        </span>
                        <p className="font-bold">
                          {el?.date &&
                            el?.date?.replace(/,/g, "")?.split(" ")[2]}
                        </p>
                      </div>
                      <div className="flex items-center ">
                        <div className="flex-1 min-w-0 ms-4">
                          <p className="text-sm text-gray-700 truncate">
                            {el?.date}
                          </p>
                          <p
                            className="text-xl font-semibold md:font-bold font-sans text-gray-900 w-fit cursor-pointer"
                            onClick={() =>
                              navigate(`/program/${el?.category}/${el?.id}`)
                            }
                          >
                            {t(el?.title)}
                          </p>
                          <p className="text-sm text-gray-700 truncate py-1.5">
                            {" "}
                            <Button
                              className="bg-[#003d8c] text-white rounded-3xl capitalize flex items-center justify-center cursor-pointer"
                              onClick={() =>
                                navigate(`/program/${el?.category}`)
                              }
                            >
                              {" "}
                              <span>{t(el?.category)}</span>
                            </Button>
                          </p>
                          <p className="text-sm text-gray-700 truncate pb-1.5">
                            {" "}
                            <span className="font-semibold">
                              Nairobi and Kigali
                            </span>{" "}
                            {/* London, United Kingdom */}
                          </p>
                          <p className="text-sm text-gray-700 truncate font-semibold">
                            {`$${el?.courseFess?.toLocaleString()}`}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseCalendarPage;
