import React from "react";
import ConsultancyCard from "./components/ConsultancyCard";
import TopSection from "../../components/TopSection";
import { useTranslation } from "react-i18next";
import { CourseByAllocation } from "../../assets/course/courseByCategory";

const ConsultancyServices = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white  border">
      <TopSection title={t("TrainingProgram")} bgImage={"venueImage"} />
      <div className="w-full max-w-[1500px] lg:mx-auto px-2 lg:p-4 py-8">
        <div className="mx-auto px-4 md:py-10">
          <div className="container mx-auto">
            <div className="section-header text-center mb-12">
              <h2 className="text-3xl font-bold text-[#3a98d2] ">
                {t("OurTrainingProgram")}
              </h2>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-gray-900">
              {CourseByAllocation.map((item, index) => (
                <ConsultancyCard key={index} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultancyServices;
