import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import Logo from "../../assets/images/webLog.png";
import { IconSend } from "../icons/AddIcon";
import { useTranslation } from "react-i18next";

const WhatsApp = () => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [erronMessage, setErrorMessage] = useState("");

  const handleClick = () => {
    if (textMessage === "") {
      setErrorMessage("You can't send an empty message :)");
    } else {
      const phoneNumber = "+254720530113";
      const message = `${textMessage}`;
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        message
      )}`;
      window.open(url, "_blank");
      setIsOpened(!isOpened);
      setTextMessage("");
    }
  };

  return (
    <div className="flex flex-col fixed w-[22rem] md:w-[25rem] z-[100] right-2 md:right-4 bottom-[8.5%]">
      {isOpened && (
        <div className="sbg-green-200 h-52 mb-[6.5rem] relative bg-white shadow-inner">
          <div className="bg-[#25d165] h-14 flex items-center justify-center">
            <h1 className="text-[16px] font-[Exo] font-extrabold text-center">
              {t("whatsapp1")} NCTAR Global Consultants Ltd.
            </h1>
          </div>
          <div className="xbg-red-100 h-32 flex space-x-4 pl-2 items-center">
            <img
              src={Logo}
              alt=""
              className="w-16 h-16 border border-gray-100 p-2 rounded-full"
            />
            <span className="text-gray-700">{t("whatsapp2")}</span>
          </div>
          <div className="bg-white h-16 flex items-center relative">
            <input
              type="text"
              id="name"
              className="shadow-sm bg-gray-50 border border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              onChange={(e: any) => setTextMessage(e.target.value)}
            />
            <div
              className="h-fit absolute right-2 cursor-pointer"
              onClick={() => handleClick()}
            >
              <IconSend style={{ color: "black" }} />
            </div>
          </div>
          {textMessage === "" && (
            <span className="text-red-500 text-sm font-[Exo]">
              {erronMessage}
            </span>
          )}
        </div>
      )}
      <button
        onClick={() => setIsOpened(!isOpened)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "4rem",
          height: "4rem",
          borderRadius: "50%",
          backgroundColor: "green",
          position: "absolute",
          bottom: "2%",
          right: "0",
        }}
      >
        {isOpened ? (
          <CloseOutlined
            style={{
              fontSize: "16px",
              color: "#fff",
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faWhatsapp}
            size="2x"
            style={{ cursor: "pointer", color: "white" }}
          />
        )}
      </button>
    </div>
  );
};

export default WhatsApp;
