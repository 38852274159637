import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeImage2 from "../../assets/photo/homeImage2.jpg";
import HomeImage3 from "../../assets/photo/homeImage3.jpg";
import HomrImage4 from "../../assets/photo/meetingRoom3.jpg";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/styles/slider.css";
import { Autoplay, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";

const Slider = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  const [defaultImage, setDefaultImage] = useState(
    "https://img.freepik.com/free-photo/male-table-data-planning-closeup_1150-1644.jpg?w=1380&t=st=1704397294~exp=1704397894~hmac=d35534cc3c979e810696f26107c22d28ddd0607aa53cd67bf469eab50a5a1053"
  );

  useEffect(() => {
    setDefaultImage(
      (prevDefaultImage) =>
        prevDefaultImage ||
        "https://img.freepik.com/free-photo/male-table-data-planning-closeup_1150-1644.jpg?w=1380&t=st=1704397294~exp=1704397894~hmac=d35534cc3c979e810696f26107c22d28ddd0607aa53cd67bf469eab50a5a1053"
    );
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 30000,
          disableOnInteraction: false,
        }}
        effect="coverflow"
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="hero-swiper"
      >
        <SwiperSlide className="hero-swiper-slide">
          <div className="w-full h-full">
            <div className="xpt-20 lg:pt-20 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-900 opacity-75"></div>
              <div className="w-full xl:w-4/5 absolute top-[25%] mx-2 xl:left-[15%] lg:top-[35%]">
                <p className="w-full px-2 md:px-44 text-left text-[#f1f1f1ef] font-[Exo] font-medium text-xl md:text-3xl">
                  {t("slider1")}
                </p>
              </div>
              <img src={HomeImage2 ?? defaultImage} alt="" className="h-full" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="hero-swiper-slide">
          <div className="w-full h-full">
            <div className="xpt-20 lg:pt-20 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-[#1b5288] to-[#1b5288] opacity-75"></div>
              <div className="w-full xl:w-4/5 absolute top-[25%] mx-2 xl:left-[15%] lg:top-[35%]">
                <p className="w-full px-2 md:px-44 text-left text-[#f1f1f1ef] font-[Exo] font-medium text-xl md:text-3xl">
                  {t("slider2")}
                </p>
              </div>
              <img
                src={HomeImage3 ?? defaultImage}
                alt=""
                className="h-full "
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="hero-swiper-slide">
          <div className="w-full h-full">
            <div className="xpt-20 lg:pt-20 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-[#1b5288] to-[#1b5288] opacity-75"></div>
              <div className="w-full xl:w-4/5 absolute top-[25%] mx-2 xl:left-[15%] lg:top-[35%]">
                <p className="w-full px-2 md:px-44 text-left text-[#f1f1f1ef] font-[Exo] font-medium text-xl md:text-3xl">
                  {t("slider3")}
                </p>
              </div>
              <img
                src={HomrImage4 ?? defaultImage}
                alt=""
                className="h-full "
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
export default Slider;
